import React, { Component } from 'react'
import { connect } from 'react-redux'

import ContactCreate from 'modules/contacts/ContactCreate'

class OverlayContactCreate extends Component {
  render() {
    const {
      data
    } = this.props

    return (
      <ContactCreate
        data={data}
      />
    )
  }
}

export default connect()(OverlayContactCreate)
