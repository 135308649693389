const initialState = {
    data:[],
    typeahead:[],
    typeaheadLoading: false,
    count:null,
    loading:false,
    loaded:false,
    userCreated:false,
    storedUserData:{},
    createdUserID:null,
    error:null
  }
  export default function reducer(state = initialState, action){
    switch (action.type){
      case 'CLEAR_USERS':{
        state = {...state,data:[]}
        break
      }
      case 'FETCH_USERS_PENDING':{
        state = {...state,loading:true}
        break
      }
      case 'FETCH_USERS_REJECTED':{
        state = {...state,loading:false,error:action.payload}
        break
      }
      case 'FETCH_USERS_FULFILLED':{
        const users = action.payload.data.users.map((user)=>{
          const data = {
            id:user.id,
            username:user.username,
            business:user.business[0] ? user.business[0].name : '',
            venues_managing:user.venue_manager_count,
            created_at:user.created_at.standard,
            last_login:user.last_login ? user.last_login.standard : '',
            // authToken:user.authToken
          }
          return data
        })
        state =  {
          ...state,
          loading:false,
          loaded:true,
          data:action.meta ? users : state.data.concat(users),
          count:action.payload.data.count
        }
        break
      }
      case 'CREATE_USER_PENDING':{
        state = {
          ...state,
          loading: true,
          createdUserID: null
        }
        break
      }
      case 'CREATE_USER_REJECTED':{
        state = {
          ...state,
          loading: false,
          error: action.payload,
          storedUserData: action.meta
        }
        break
      }
      case 'CREATE_USER_FULFILLED':{
        state = {
          ...state,
          loading: false,
          error: null,
          userCreated: true,
          storedUserData: {},
          createdUserID: action.payload.data.res.data.id
        }
        break
      }
      case 'DELETE_USER_FULFILLED':{
        const users = state.data.filter(user => user.id !== action.meta)
        state = {...state,data:users}
        break
      }
      case 'SEARCH_USERS_PENDING':{
        state = {
          ...state,
          loading:true
        }
        break
      }
      case 'SEARCH_USERS_REJECTED':{
        state = {
          ...state,
          loading:false,
          error:action.payload
        }
        break
      }
      case 'SEARCH_USERS_FULFILLED':{
        const users = action.payload.data.users.map((user)=>{
          const data = {
            id:user.id,
            username:user.username,
            business:user.business[0] ? user.business[0].name : '',
            venues_managing:user.venue_manager_count,
            created_at:user.created_at.standard,
            last_login:user.last_login ? user.last_login.standard : '',
            // authToken:user.authToken
          }
          return data
        })
        state =  {
          ...state,
          loading:false,
          loaded:true,
          data:users,
          count:action.payload.data.count
        }
        break
      }
      case 'SEARCH_USERS_TYPEAHEAD_PENDING':{
        state = {
          ...state,
          typeahead:[],
          typeaheadLoading:true
        }
        break
      }
      case 'SEARCH_USERS_TYPEAHEAD_REJECTED':{
        state = {
          ...state,
          typeaheadLoading:false,
          error:action.payload
        }
        break
      }
      case 'SEARCH_USERS_TYPEAHEAD_FULFILLED':{
        state =  {
          ...state,
          typeaheadLoading:false,
          loaded:true,
          typeahead:state.typeahead.concat(action.payload.data),
        }
        break
      }
      case 'CLEAR_TYPEAHEAD':{
        state = {...state,typeahead:[]}
        break
      }
      default: // no default case
    }
    return state
  }
