import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  getVenueAnnouncements,
  getVenuePlaylists,
  updateAnnouncement,
  deleteAnnouncements,
  deleteAnnouncement
} from 'store/actions/venueAction'
import { toggleOverlay } from 'store/actions/overlayAction'

import Announcement from './announcements/Announcement'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'

const classname = 'venueAnnouncements'

class VenueAnnouncements extends Component{

  constructor(props){
    super(props)
    this.state = {
      announcementsMap:[]
    }
  }

  componentDidMount(){
    this.props.dispatch(getVenueAnnouncements(this.props.id))
    this.props.dispatch(getVenuePlaylists(this.props.id))
  }

  componentDidUpdate(prevProps){
    if(prevProps.announcements !== this.props.announcements){
      this.setAnnouncements()
    }
    if(prevProps.announcementsCreated !== this.props.announcementsCreated && this.props.announcementsCreated){
      this.props.dispatch(getVenueAnnouncements(this.props.id))
    }
    if(prevProps.announcementsDeleted !== this.props.announcementsDeleted && this.props.announcementsDeleted){
      this.props.dispatch(getVenueAnnouncements(this.props.id))
    }
  }

  changeTime(time,id,day){
    const {
      announcementsMap
    } = this.state
    const check = announcementsMap[day].find(announcement => announcement.start === time)
    if(check){
      return
    }
    this.props.dispatch(updateAnnouncement(time,id))
  }

  addAnnouncements(){
    this.props.dispatch(toggleOverlay(
      true,
      null,
      'announcements'
    ))
  }

  setAnnouncements(){
    let announcementsMap = []
    //put announcements in array per day
    for(let announcement of this.props.announcements){
      if(!announcementsMap[announcement.pivot.day]){
        announcementsMap[announcement.pivot.day] = []
      }
      //get hours/mins
      const annArray = announcement.pivot.start.split(':')
      const data = {
        hrs:annArray[0],
        mins:annArray[1],
        name:announcement.name,
        id:announcement.pivot.id,
        day:announcement.pivot.day,
        start:announcement.pivot.start
      }
      announcementsMap[announcement.pivot.day].push(data)
      //sort the announcements by time
      announcementsMap[announcement.pivot.day].sort((x,y)=>new Date('1970/01/01 ' + x.start) - new Date('1970/01/01 ' + y.start))
    }

    this.setState({
      announcementsMap:announcementsMap
    })
  }

  getAnnouncements(){
    return this.state.announcementsMap.map((dayMap,index)=>{
      return dayMap.map((announcement,index) => {
        return (
          <Announcement
            key={index}
            id={announcement.id}
            name={announcement.name}
            startHrs={announcement.hrs}
            startMins={announcement.mins}
            day={announcement.day}
            order={index}
            changeTime={(data,id,day)=>this.changeTime(data,id,day)}
            deleteAnnouncement={(id) => this.deleteAnnouncement(id)}
          />
        )
      })
    })
  }

  copyToVenues(){
    const {venueID, businessID} = this.props
    const data = {
      businessID:businessID,
      venueID:venueID
    }
    this.props.dispatch(toggleOverlay(
      true,
      data,
      'venueAnnouncementCopy'
    ))
  }

  clearAllAnnouncements(){
    const confirm = window.confirm('Are you sure you want to delete all announcements?')
    if(confirm){
      this.props.dispatch(deleteAnnouncements(this.props.id))
    }
  }

  deleteAnnouncement(id){
    this.props.dispatch(deleteAnnouncement(id))
  }

  render(){
    if(this.props.loading){
      return <Loader/>
    } else {
      return (
        <Container classname={classname} height="100%" column>
          <div className="announcements-actions">
            <div className="announcements-actions-left">

            </div>
            <div className="announcements-controls">

            </div>
            <div className="announcements-actions-right">
              <Icon name="add-circle" action={()=>this.addAnnouncements()}>
                <Tooltip pos="left" text="Create announcement"/>
              </Icon>
              <Icon name="copy" action={()=>this.copyToVenues()}>
                <Tooltip pos="left" text="Copy announcements to venues"/>
              </Icon>
              <Icon name="trash" action={()=>this.clearAllAnnouncements()}>
                <Tooltip pos="left" text="Delete announcements"/>
              </Icon>
            </div>
          </div>
          <div className="announcements-header">
            <div className="announcements-header-day">Monday</div>
            <div className="announcements-header-day">Tuesday</div>
            <div className="announcements-header-day">Wednesday</div>
            <div className="announcements-header-day">Thursday</div>
            <div className="announcements-header-day">Friday</div>
            <div className="announcements-header-day">Saturday</div>
            <div className="announcements-header-day">Sunday</div>
          </div>
          <div className="announcements-wrapper">
            {this.getAnnouncements()}
          </div>
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    loading:store.venue.announcementsLoading,
    announcements:store.venue.announcements,
    businessID: store.venue.details.businessID,
    venueID:store.venue.details.id,
    playlists:store.venue.playlists,
    announcementsCreated:store.venue.announcementsCreated,
    announcementsDeleted:store.venue.announcementsDeleted
  }
}

export default connect(mapStateToProps)(VenueAnnouncements)
