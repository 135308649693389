import React, {Component} from 'react'
import { connect } from 'react-redux'

import { getUserSettings, updateUserSettings } from 'store/actions/userAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Form from 'ui/Form'

const classname = 'userSettings'

class UserSettings extends Component {
  constructor(props){
    super(props)
    this.state = {
      displayName:null
    }
  }

  componentDidMount(){
    this.props.dispatch(getUserSettings(this.props.id))
  }

  componentDidUpdate(prevProps){
    // const {
    //   setHeader,
    //   user
    // } = this.props

    // if(user !== prevProps.user){
    //   setHeader(`${user.name}`)
    // }
  }

  onSubmit(form){
    console.log(form)
    this.props.dispatch(updateUserSettings(
      this.props.id,
      form
    ))
  }

  render(){
    const {
      error,
      loading,
      settings
    } = this.props

    if(loading){
      return <Loader/>
    } else {
      return (
        <Container classname={classname} height="100%" maxWidth>
          <Form
            classname={classname}
            submit={(form)=>this.onSubmit(form)}
            messages={this.state.message}
            data={settings}
            serverError={error && error.response.data.res}
            scroll
          />
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    settings:store.user.settings,
    loading:store.user.loading,
    error:store.user.error
  }
}

export default connect(mapStateToProps)(UserSettings)
