import React, { Component, Fragment } from 'react'

import { connect } from 'react-redux'

import {
  getSavedCalendars,
  loadSavedCalender
} from 'store/actions/venueAction'

import { formatDate } from 'helpers/DateHelper'

import Container from 'ui/Container'
import Button from 'ui/Button'
import Icon from 'ui/Icon'
import Loader from 'ui/Loader'

class OverlayVenueCalendarSchedulesSaved extends Component{

  constructor(props){
    super(props)
    this.state = {
      saved: [],
      selected: null
    }
  }

  componentDidMount(){
    this.props.dispatch(getSavedCalendars(
      this.props.data.venueID
    ))
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.saved_calendar_schedules !== this.props.saved_calendar_schedules){
      this.setStateSaved()
    }
  }

  setStateSaved(){
    const saved = this.props.saved_calendar_schedules
      .map(saved => {
        saved.selected = false
        return saved
      })

    this.setState({
      saved: saved
    })
  }

  getSavedCalendars(){
    if(this.props.loading){
      return <Loader/>
    } else {
      return this.state.saved.map((saved,index)=>{
        return (
          <div
            className={`schedule-saved-overlay ${saved.selected ? 'selected' : ''}`}
            key={index}
            onClick={()=>this.toggleSelect(index)}
          >
            {`${saved.name} - created ${formatDate(saved.created_at)}`} {saved.selected ? <Icon name="checkmark-circle"/>: ''}
          </div>
        )
      })
    }
  }

  toggleSelect(index){
    let saved = this.state.saved.map(saved => {
      saved.selected = false
      return saved
    })
    saved[index].selected = true
    this.setState({
      saved: saved,
      selected: saved[index]
    })
  }

  loadSavedCalender(){
    this.props.dispatch(
      loadSavedCalender(
        this.state.selected.id,
        this.props.data.venueID
      )
    )
  }

  render(){
    const {
      loading,
      saved_calendar_schedules
    } = this.props

    const venue = this.props.data
    return (
      <Container classname="overlay-schedule-saved" maxWidth height="100%" column>
        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            {saved_calendar_schedules && saved_calendar_schedules.length > 0 ? (
              <Fragment>
                <h2 className="header">Saved calendar schedules for {venue.details.name}</h2>
                <Container classname="overlay-schedule-save-wrapper" maxWidth height="100%" column>
                  {this.getSavedCalendars()}
                </Container>
                <Button
                  action={()=>this.loadSavedCalender ()}
                  disabled={!this.state.selected}
                  name={`Load schedule`}
                />
              </Fragment>
            ) : (
              <p>There are no saved calendar schedules for {venue.details.name}.</p>
            )}
          </Fragment>
        )}
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    saved_calendar_schedules: store.venue.saved_calendar_schedules,
    loading: store.venue.loading
  }
}

export default connect(mapStateToProps)(OverlayVenueCalendarSchedulesSaved)
