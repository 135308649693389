import React, {Component} from 'react'
import { connect } from 'react-redux'

import { updateTrack } from 'store/actions/libraryAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Form from 'ui/Form'

const classname = 'overlayEditTrack'

class OverlayEditTrack extends Component{

  constructor(props){
    super(props)
    this.state = {
      message:null,
      track:{
        title:null,
        artist:null,
        album:null,
        total_length:null,
        year:null,
        genres:[],
        private:''
      }
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps !== this.props){
      const {track} = this.props
      this.setState({
        track:{
          title:track.title,
          artist:track.artist,
          album:track.album,
          total_length:track.total_length,
          year:track.year,
          genres:track.genres,
          private:track.private
        }
      })
    }
  }


  onSubmit(form){
    this.props.dispatch(updateTrack(this.props.track.id,form))
  }

  render(){
    const {
      loading,
      allGenres
    } = this.props

    const {
      track
    } = this.state

    return (
      <Container classname="overlay-edit-track" height="100%" maxWidth column>
        {
          loading ? 
          <Loader/> : 
          <Form
            classname={classname}
            submit={(e)=>this.onSubmit(e)}
            messages={this.state.message}
            data={track}
            allGenres={allGenres}
            scroll
            isOverlay
          />
        }
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    track:store.library.track,
    allGenres:store.library.genres,
    loading:store.library.trackLoading
  }
}

export default connect(mapStateToProps)(OverlayEditTrack)