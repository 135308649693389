import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  updateBusinessAccounts,
  getBusinessAccounts
} from 'store/actions/businessAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Form from 'ui/Form'

const classname = 'businessAccounts'

class BusinessAccounts extends Component {
  componentDidMount(){
    this.props.dispatch(getBusinessAccounts(this.props.id))
  }

  onSubmit(form){
    this.props.dispatch(
      updateBusinessAccounts(
        this.props.id,
        form
      )
    )
  }

  render(){
    const {
      accounts,
      accountLeads,
      accountStatuses,
      billingCycles,
      billingTerritories,
      billingTypes,
      consultancyStatuses,
      contactFrequencies,
      contractStatuses,
      currencies,
      tiers,
      loading
    } = this.props

    const customerConnectOptions = [
      {
        display: '------',
        value: 0
      },
      {
        display: 'Live',
        value: 1
      },
      {
        display: 'Maybe',
        value: 2
      },
      {
        display: 'No',
        value: 3
      }
    ]

    if (loading) {
      return <Loader/>
    } else {
      return (
        <Container classname={classname} height="100%" maxWidth>
          <Form
            classname={classname}
            submit={(form)=>this.onSubmit(form)}
            data={accounts}
            accountStatuses={accountStatuses}
            accountLead={accountLeads}
            billingCycles={billingCycles}
            billingTerritories={billingTerritories}
            billingTypes={billingTypes}
            contactFrequencies={contactFrequencies}
            contractStatus={contractStatuses}
            consultancyStatuses={consultancyStatuses}
            currencies={currencies}
            customerConnectOptions={customerConnectOptions}
            tiers={tiers}
            scroll
          />
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    accounts: store.business.accounts,
    accountLeads: store.business.accountLeads,
    accountStatuses: store.business.accountStatuses,
    business: store.business.details,
    billingCycles: store.business.billingCycles,
    billingTypes: store.business.billingTypes,
    consultancyStatuses: store.business.consultancyStatuses,
    contactFrequencies: store.business.contactFrequencies,
    contractStatuses: store.business.contractStatuses,
    currencies: store.business.currencies,
    billingTerritories: store.business.billingTerritories,
    statuses: store.business.statuses,
    tiers: store.business.tiers,
    loading:store.business.accountsLoading
  }
}

export default connect(mapStateToProps)(BusinessAccounts)