import React from 'react'
import {Link} from 'react-router-dom'

import Icon from 'ui/Icon'
import Online from "ui/Online"
import TableModel from 'models/TableModel'

const BusinessVenuesTableRowActions = ({
  classname,
  id,
  name,
  more,
  data
}) => {
  return (
    <div>
      <Online online={data.online}/>
      <Link to={`${TableModel[classname].viewLink}/${id}?tab=schedule`}><Icon name="eye"/></Link>
      <Icon name="more" action={()=>more({id:id,name:name})}/>
    </div>
  )
}

export default BusinessVenuesTableRowActions