export function isSplit(type){
  if(type === 6 || type === 7 || type === 9 || type === 13){
    return true;
  } else {
    return false;
  }
}

export function canBeChild(parentType,childType){
  //if parent is split and child is split
  if((parentType === 6 || parentType === 7) && childType === 9){
    return true
  }
  //if parent is normal and child is normal
  if((parentType === 1 || parentType === 2 || parentType === 3 || parentType === 4) && childType === 5){
    return true
  }
  return false
}

export function isParent(type){
  if(type === 1 || type === 2 || type === 3 || type === 4 || type === 6 || type === 7 || type === 11){
    return true
  }
  return false
}

export function isChild(type){
  if(type === 5 || type === 9){
    return true
  }
  return false
}