import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import queryString from 'qs'

import {
  getVenues,
  searchVenues,
  searchVenuesTypeahead,
  clearVenues,
  clearTypeahead,
  createVenue
} from 'store/actions/venueAction'

import {
  getBusinessesTypeahead,
  clearBusinessesTypeahead
} from 'store/actions/businessAction'

import { getRowLimit } from 'helpers/TableHelper'
import { parseQueryString } from 'helpers/StringHelper'

import { toggleOverlay } from 'store/actions/overlayAction'

import Loader from 'ui/Loader'
import Tabs from 'ui/Tabs'
import Container from 'ui/Container'
import Form from 'ui/Form'
import TableWrapper from 'ui/TableWrapper'
import TableTypeahead from 'ui/TableTypeahead'
import VenuesTableActions from './venues/VenuesTableActions'

const classname = 'venues'

class VenuesContainer extends Component {

  constructor(props){
    super(props)
    this.state = {
      tab:'all',
      url:'venues',
      searchTab:[],
      disableTypeahead: false
    }
  }

  componentDidMount(){
    const params = parseQueryString(this.props.location.search)

    this.getTabQuery()

    if (
      (typeof params.tab === 'undefined' || params.tab === 'all' || params.tab === 'library') &&
      params.tab !== 'create'
    ) {
      this.props.dispatch(getVenues(getRowLimit(classname), 0, true))
    } else if(params.tab && params.tab === 'search' && params.term) {
      this.searchVenues(params.term)
    }

  }

  componentDidUpdate(prevProps, prevState){
    const {
      error,
      createdVenueID,
      copiedVenueID,
      history
    } = this.props

    const params = parseQueryString(this.state.tab)

    if(createdVenueID && prevProps.createdVenueID !== createdVenueID){
      history.push(`/venues/${createdVenueID}`)
    }

    if(copiedVenueID && prevProps.copiedVenueID !== copiedVenueID){
      history.push(`/venues/${copiedVenueID}`)
    }

    if (
      error &&
      (prevProps.error !== error) &&
      error.response.data.res &&
      error.response.data.res.data &&
      this.state.tab === 'create'
    ) {
      const serverErrorScrollPos = document.getElementsByName(error.response.data.res.data)[0].offsetTop + 200
      document.getElementsByClassName('container-venues')[0].scrollTo(0, serverErrorScrollPos)
    }

    if (
      (prevState.tab !== this.state.tab) &&
      this.state.tab !== 'create'
    ) {
      this.props.dispatch(clearVenues())
      if(typeof params.tab === 'undefined' || params.tab === 'all' || params.tab === 'library'){
        // show all venues
        this.props.dispatch(getVenues(getRowLimit(classname), 0, true))
      } else if(params.tab && params.tab === 'search' && params.term) {
        // standard search
        this.props.dispatch(searchVenues(getRowLimit(classname), 0, params.term, true))
      }
    }

    if ((this.props.location !== prevProps.location) && this.props.history.action === 'POP') {
      this.getTabQuery()
    }
  }

  getTabQuery(){
    const query = queryString.parse(this.props.location.search.slice(1))

    if(typeof query.tab !== 'undefined' && query.tab !== this.state.tab){
      this.setState({tab: query.tab})
    } else if (typeof query.tab === 'undefined') {
      // defines the default tab to loaded when no tab query is passed in the URL
      this.setState({tab: 'all'})
    }
  }

  selectTab(tab){
    this.setState({tab:tab},()=>{
      if(!tab.startsWith('tab=')) tab = 'tab=' + tab;
      this.props.history.push({
        search: `?${tab}`
      })
    })
  }

  closeTab(tab){
    const searchTabs = this.state.searchTab.filter(tb => tb !== tab)
    this.setState({
      searchTab:searchTabs,
      tab:'all',
      url:'venues'
    },()=>{
      this.selectTab('all')
    })
  }

  clearVenues(){
    this.props.dispatch(clearVenues())
  }

  getVenues(limit,offset){
    const params = parseQueryString(this.state.tab)
    if(params.tab === 'search'){
      // standard search
      this.props.dispatch(searchVenues(limit, offset, params.term))
    } else {
      // show all venues
      this.props.dispatch(getVenues(limit,offset))
    }
  }

  moreActions(data){
    this.props.dispatch(toggleOverlay(true,data,'venues'))
  }

  searchVenues(term){
    const link = `tab=search&term=${encodeURIComponent(term)}`
    // If the user performs a search, disable the typeahead dropdown
    this.setState({
      searchTab:this.state.searchTab.concat(
        {name:`Search: ${term}`,link:link,dismiss:true}
      ),
      tab:link,
      url:`venues/search/${term}`,
      disableTypeahead: true
    },()=>{
      this.props.history.push({
        search: `?${link}`
      })
    })
  }

  searchVenuesTypeahead(value){
    this.props.dispatch(searchVenuesTypeahead(value))
  }

  clearVenuesTypeahead(value){
    this.props.dispatch(clearTypeahead())
  }

  typeaheadBusinesses(value){
    this.props.dispatch(getBusinessesTypeahead(value))
  }

  clearBusinessesTypeahead(){
    this.props.dispatch(clearBusinessesTypeahead())
  }

  createVenue(data){
    this.props.dispatch(createVenue(data))
  }

  getComponent(){
    const {
      businessesTypeahead,
      businessesTypeaheadLoading,
      count,
      error,
      loading,
      storedVenueData,
      typeahead,
      typeaheadLoading,
      venues
    } = this.props

    const {
      message,
      tab
    } = this.state

    switch(tab){
      default:
      case 'all':
        return (
          <Container height="100%" classname={`${classname} container-tabview`} column>
            <TableWrapper
              userSelect
              controls
              classname={classname}
              data={venues}
              count={count}
              clearData={()=>this.clearVenues()}
              getData={(limit,offset)=>this.getVenues(limit,offset)}
              loading={loading}
              rowActions={<VenuesTableActions
                more={(data)=>this.moreActions(data)}
                classname={classname}/>
              }
              tableAction={<TableTypeahead
                classname={classname}
                action={(playlist)=>this.addPlaylist(playlist)}
                typeaheadSearch={(value)=>this.searchVenuesTypeahead(value)}
                typeaheadLoading={typeaheadLoading}
                search={(value)=>this.searchVenues(value)}
                clear={()=>this.clearVenuesTypeahead()}
                data={typeahead}/>
              }
            />
          </Container>
        )
      case 'create':
        return (
          <Container height="100%" classname={`${classname} container-tabview`} maxHeight column>
            {loading ? (
              <Loader/>
            ) : (
              <Form
                classname='createVenue'
                submit={(data)=>this.createVenue(data)}
                messages={message}
                data={storedVenueData}
                typeaheadSearch={(value)=>this.typeaheadBusinesses(value)}
                typeaheadData={businessesTypeahead}
                typeaheadLoading={businessesTypeaheadLoading}
                clearTypeahead={()=>this.clearBusinessesTypeahead()}
                serverError={error && error.response.data.res}
              />
            )}
          </Container>
        )
    }
  }

  render(){
    return (
      <Container classname={`${classname}-wrapper`} height="100%" column>
        <Container classname="tabs">
          <Tabs
            match={this.props.match}
            classname={classname}
            select={(tab)=>this.selectTab(tab)}
            active={this.state.tab}
            add={this.state.searchTab}
            closeTab={(tab)=>this.closeTab(tab)}
          />
        </Container>
          {this.getComponent()}
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    businessesTypeahead: store.businesses.typeahead,
    businessesTypeaheadLoading: store.businesses.typeaheadLoading,
    venues: store.venues.data,
    typeahead: store.venues.typeahead,
    typeaheadLoading: store.venues.typeaheadLoading,
    count: store.venues.count,
    createdVenueID: store.venues.createdVenueID,
    copiedVenueID: store.venues.copiedVenueID,
    storedVenueData: store.venues.storedVenueData,
    error: store.venues.error,
    loading: store.venues.loading
  }
}

export default withRouter(connect(mapStateToProps)(VenuesContainer))
