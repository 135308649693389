import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'qs'

import {
//   getUsers,
//   searchUsers,
//   searchUsersTypeahead,
  clearUser,
//   clearTypeahead,
//   createUser
  getUserDetails
} from 'store/actions/userAction'

// import UserDetails from 'modules/user/UserDetails'
import UserUser from 'modules/user/UserUser'
import UserVenues from 'modules/user/UserVenues'
import UserSettings from 'modules/user/UserSettings'

import Tabs from 'ui/Tabs'
import Container from 'ui/Container'

const classname = 'user'

class UserContainer extends Component {

  constructor(props){
    super(props)
    this.state = {
      tab:''
    }
  }

  componentDidMount(){
    const id = this.props.match.params.id
    this.props.dispatch(clearUser())
    this.props.dispatch(getUserDetails(id))
  }

  componentDidUpdate(prevProps){
    this.getTabQuery(prevProps)
  }

  getTabQuery(prevProps){
    const query = queryString.parse(this.props.location.search.slice(1))

    // If tab query does not match tab state
    if(typeof query.tab !== 'undefined' && query.tab !== this.state.tab){
      this.selectTabQuery(query)

      // If tab query is undefined or user change and tab query undefined at the same time
    } else if (typeof query.tab === 'undefined' || (typeof prevProps !== 'undefined' && typeof query.tab === 'undefined' && prevProps.user.id !== this.props.user.id)) {

      // Defines the default tab to loaded when no tab query is passed in the URL
      if(this.props.user.group_id === 9){
        this.selectTabQuery({tab: 'venues'})
      } else if(this.props.user.group_id === 10){
        this.selectTabQuery({tab: 'settings'})
      }

    }

  }

  selectTabQuery(query){
    this.props.history.push({
      search: `?tab=${query.tab}${query.view ? '&view='+query.view:''}`
    })
    this.setState({tab:query.tab})
  }

  setHeader(){
    const header = `${this.props.user.username}`
    this.setState({header:header})
  }

  selectTab(tab){
    this.props.history.push({
      search: `?tab=${tab}`
    })
    this.setState({tab:tab})
  }

  getComponent(){
    const id = this.props.match.params.id
    switch(this.state.tab){
      case 'user':
        return <UserUser setHeader={(header)=>this.setHeader(header)} id={id} />
      case 'venues':
        return <UserVenues id={id} />
      case 'settings':
        return <UserSettings id={id} />
      default: // no default case
    }
  }

  render(){
    return (
      <Container classname={classname} height="100%" column>
        <Container classname="tabs">
          <Tabs
            match={this.props.match}
            classname={classname}
            select={(tab)=>this.selectTab(tab)}
            active={this.state.tab}
            header={this.state.header}
            exclusions={(this.props.user.group_id !== 9 && ['venues']) || (this.props.user.group_id !== 10 && ['settings'])}
          />
        </Container>
        <Container height="100%" classname={`${classname} container-tabview`} column>
          {this.getComponent()}
        </Container>
      </Container>
    )
  }

}

function mapStateToProps(store){
  return {
    // loading:store.user.loading,
    user:store.user.details,
  }
}

export default connect(mapStateToProps)(UserContainer)
