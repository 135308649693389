import React, {Component} from 'react'
import { connect } from 'react-redux'
import { millisToMinutesAndSeconds } from 'helpers/TimeHelper'

import {
  addToFinalTracklist,
  removeFromFinalTracklist
} from 'store/actions/playlistAction'

import {
  getTrackUrl,
  getTrackInfo
} from 'store/actions/playerAction'

import { toggleOverlay } from 'store/actions/overlayAction'

import OverlayPlaylistImporterTableRowActions from './playlist-importer/OverlayPlaylistImporterTableRowActions'

import Button from 'ui/Button'
import Container from 'ui/Container'
import TableWrapper from 'ui/TableWrapper'


const classname = 'overlayPlaylistImporter'

class OverlayPlaylistImporter extends Component{

  constructor(props){
    super(props)

    this.state = {
      index: 0
    }
  }

  componentDidMount() {
    const {
      data,
      importerPlaylist
    } = this.props

    this.setState({
      index: importerPlaylist.tracks.findIndex(track=>track.id === data.id)
    })

  }

  updateOverlayData(index) {
    const {
      dispatch,
      importerMatches,
      importerPlaylist
    } = this.props

    const nextTrack = importerPlaylist.tracks[index]
    const nextMatch = importerMatches.filter(match=>match.spotifyID === nextTrack.id)
    const data = {
      ...nextTrack,
      total_length: millisToMinutesAndSeconds(nextTrack.total_length),
      tracks: nextMatch[0] ? nextMatch[0].tracks : []
    }

    dispatch(toggleOverlay(true, data, 'playlistImporter'))

    this.setState({
      index: index
    })
  }

  getNextTrack() {
    const {
      importerPlaylist
    } = this.props

    const {
      index
    } = this.state

    if (index < importerPlaylist.tracks.length - 1) {
      this.updateOverlayData(index+1)
    }

  }

  getPreviousTrack() {
    const {
      index
    } = this.state

    if (index > 0) {
      this.updateOverlayData(index-1)
    }
  }

  playTrack(track){
    const {
      dispatch
    } = this.props

    dispatch(getTrackInfo(track, 'playlist'))
    dispatch(getTrackUrl(track))
  }

  render(){
    const {
      data,
      dispatch,
      loading,
      importerFinalTracklist,
      importerPlaylist,
      playingTrack
    } = this.props

    const {
      index
    } = this.state

    return (
      <Container classname={classname} maxWidth height="100%" column>

        <div className='overlay-playlist-importer__controls'>
          <Button
            name='Previous'
            action={()=>this.getPreviousTrack()}
          />
          <div>{index+1}/{importerPlaylist.tracks.length}</div>
          <Button
            name='Next'
            action={()=>this.getNextTrack()}
          />
        </div>
        <div className='overlay-playlist-importer__target-track'>
          <p>{data.title}</p>
          <p>{data.artist}</p>
          <p>{data.total_length}</p>
        </div>
        <TableWrapper
          classname={classname}
          data={data.tracks}
          count={data.tracks.length}
          loading={loading}
          play={(track)=>this.playTrack(track)}
          playingTrack={playingTrack}
          rowActions={(
            <OverlayPlaylistImporterTableRowActions
              classname={classname}
              importerFinalTracklist={importerFinalTracklist}
              addTrack={(track)=>dispatch(addToFinalTracklist(track))}
              removeTrack={(track)=>dispatch(removeFromFinalTracklist(track))}
            />
          )}
        />
      </Container>
    )
  }
}
function mapStateToProps(store){
  return {
    importerMatches: store.playlists.importerMatches,
    importerPlaylist: store.playlists.importerPlaylist,
    importerFinalTracklist: store.playlists.importerFinalTracklist,
    playingTrack: store.player.track
  }
}
export default connect(mapStateToProps)(OverlayPlaylistImporter)
