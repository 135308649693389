import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  getUserVenues,
  updateUserVenues
} from 'store/actions/userAction'

import {
  getBusinessesTypeahead,
  clearBusinessesTypeahead
} from 'store/actions/businessAction'

import {
  getVenuesByBusiness,
  clearBusinessVenues
} from 'store/actions/venueAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Form from 'ui/Form'

const classname = 'userVenues'

class UserVenues extends Component {
  constructor(props){
    super(props)
    this.state = {
      displayName:null,
      checklistErrorMessage: ''
    }
  }

  componentDidMount(){
    this.props.dispatch(clearBusinessVenues())
    this.props.dispatch(getUserVenues(this.props.id))
  }

  componentDidUpdate(prevProps){
    const {
      // setHeader,
      user
    } = this.props

    if(user !== prevProps.user){
      // setHeader(`${user.name}`)
    }
  }

  onSubmit(form){
    this.props.dispatch(updateUserVenues(
      this.props.id,
      form
    ))
  }

  getBusinessesTypeahead(value){
    this.props.dispatch(getBusinessesTypeahead(value))
  }

  getBusinessesTypeaheadAction(data){
    const {
      businessVenues,
      searchedBusinesses
    } = this.props

    // Check if the searched business has already been added,
    // either through preloaded props or a previous typeahead search
    if (
      searchedBusinesses.find(business => { return business.id === data.id && business.hasVenues}) ||
      businessVenues.find(business => { return business.name === data.name })
    ) {
      this.setState({
        checklistErrorMessage: `Venues from business ${data.name} have already been added to the list.`
      })
      return false
    }

    this.setState({
      checklistErrorMessage: ''
    })

    this.props.dispatch(getVenuesByBusiness(data))
  }

  clearBusinessesTypeahead(){
    this.props.dispatch(clearBusinessesTypeahead())
  }

  preloadOwnedBusinessData() {
    const {
      business
    } = this.props

    if (business.length === 0) {
      return {}
    }

    return {
      'business' : business
    }
  }

  render(){
    const {
      error,
      loading,
      businessVenues,
      businessesTypeahead,
      businessesTypeaheadLoading
    } = this.props

    if(loading){
      return <Loader/>
    } else {
      return (
        <Container classname={classname} height="100%" maxWidth>
          <Form
            classname={classname}
            submit={(form)=>this.onSubmit(form)}
            messages={this.state.message}
            // data={user}
            // data={this.preloadOwnedBusinessData()}
            typeaheadSearch={(value)=>this.getBusinessesTypeahead(value)}
            typeaheadData={businessesTypeahead}
            typeaheadAction={(data)=>this.getBusinessesTypeaheadAction(data)}
            typeaheadLoading={businessesTypeaheadLoading}
            disableEnterToSearch={true}
            clearTypeahead={()=>this.clearBusinessesTypeahead()}
            checklistData={businessVenues}
            checklistErrorMessage={this.state.checklistErrorMessage}
            serverError={error && error.response.data.res}
            scroll
          />
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    user:store.user.details,
    loading:store.venues.loading,
    business:store.user.business,
    businessesTypeahead: store.businesses.typeahead,
    businessesTypeaheadLoading: store.businesses.typeaheadLoading,
    businessVenues: store.venues.businessVenues,
    // venues: store.venues.venues,
    searchedBusinesses: store.venues.searchedBusinesses,
    error:store.user.error
  }
}

export default connect(mapStateToProps)(UserVenues)
