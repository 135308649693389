import React from 'react'

import Loader from 'ui/Loader'

const Button = (props) => {
  const {action, size, type, name, disabled, loading, main} = props
  const classes = `button button-${type} button-${size} ${main ? 'button-main' : ''}`

  return (
    <button
      className={classes}
      disabled={disabled ? true : false}
      onClick={(e)=>action(e)}>
      {loading ? <Loader/> : name}
    </button>
  )
}

export default Button
