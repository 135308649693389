const initialState = {
  loading: false,
  uploading: false,
  uploadedTracks: [],
  uploadedFiles: [],
  uploadComplete: false,
  error:null
}
export default function reducer(state = initialState, action){
  switch (action.type){
    case 'TRACK_UPLOAD_PENDING':{
      state = {
        ...state,
        loading: true
      }
      break
    }
    case 'TRACK_UPLOAD_REJECTED':{
      state = {
        ...state,
        loading: false
      }
      break
    }
    case 'TRACK_UPLOAD_FULFILLED':{
      state = {
        ...state,
        loading: false,
        uploadedTracks: state.uploadedTracks.concat(action.payload.data.fileName),
        uploadedFiles: state.uploadedFiles.concat(action.payload.data.fileName)
      }
      break
    }
    case 'FILES_UPLOADING':{
      state = {
        ...state,
        uploading: action.payload
      }
      break
    }
    case 'FILE_UPLOAD_FULFILLED':{
      state = {
        ...state,
        uploadComplete: action.payload
      }
      break
    }
    case 'TRACK_UPLOAD_RESET':
    case 'LOGOUT':{
      state = {
        uploadedTracks: [],
        uploading: false,
        uploadedFiles: [],
        uploadComplete: false,
        error:null
      }
      break
    }
    default: // no default case
  }
  return state
}
