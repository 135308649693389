import axios from 'axios'

export function login(username,password){
  return (dispatch) => {
    dispatch({type:'FETCH_AUTH_PENDING'})
    axios.post('/login',{
        username:username,
        password:password
      })
      .then((res)=>{
        if(res.data.authToken){
          localStorage.setItem('Authentication',res.data.authToken)
          axios.defaults.headers.common['Authentication'] = res.data.authToken
          dispatch({type:'FETCH_AUTH_FULFILLED',payload:res.data})
        } else if(!res.data.authToken && res.data.error){
          dispatch({type:'FETCH_AUTH_REJECTED',payload:res.data.error})
        }
        
      })
      .catch((err)=>{
        dispatch({type:'FETCH_AUTH_REJECTED',payload:err ? err : '500'})
      })
  }
}

export function logout(){
  return (dispatch) => {
    localStorage.removeItem('Authentication')
    dispatch({type:'LOGOUT'})
  }
}

export function isAuthenticated(){
  
  return {
    type:'IS_AUTH',
    payload:axios.post('/login/check-login',{
      authToken:localStorage.getItem('Authentication')
    })
  }
}
