import React from 'react'
import Select from 'ui/Select'
import Icon from 'ui/Icon'
import Loader from 'ui/Loader'

const TableControls = (props) => {
  const { 
    rowLimit, 
    rowOptions,
    pageNo,
    totalPages,
    setTableLimit,
    paginate,
    loading
  } = props
  let loader = null
  if(loading){
    loader = <Loader/>
  }
  return (
    <div className="table-controls">
      <div className="table-controls-left">
        Rows per page:<Select 
          options={rowOptions} 
          selected={rowLimit}
          action={(data)=>setTableLimit(data.event.target.value)}/>
      </div>
      <div className="table-controls-right">
        {loader}
        <Icon name="ios-arrow-back" action={()=>paginate('back')}/>
        <span>{pageNo} of {totalPages}</span>
        <Icon name="ios-arrow-forward" action={()=>paginate('forward')}/>
      </div>
    </div>
  )
}

export default TableControls
