import axios from 'axios'
// import Websockets from 'lib/Websockets'
// let ws

export function getUsers(filter,limit,offset,initialLoad){

  console.log(filter)

  const headers = {
    'Filter':filter,
    'Limit':limit,
    'Offset':offset,
  }
  return (dispatch) => {
    return dispatch({
      type:'FETCH_USERS',
      // TODO: update URL to users
      // TODO: update endpoint controller to return users
      payload:axios.get('users',{headers:headers}),
      meta: initialLoad
    })
  }
}

export function clearUsers(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_USERS',
      payload:null
    })
  }
}

export function searchUsers(filter,limit,offset,term){
  const headers = {
    'Filter':filter,
    'Limit':limit,
    'Offset':offset
  }
  return (dispatch) => {
    return dispatch({
      type:'SEARCH_USERS',
      // TODO: update URL to users
      // TODO: update endpoint controller to return users
      payload:axios.get(`users/search/${term}`, {headers:headers})
    })
  }
}

export function searchUsersTypeahead(filter,term){
  const headers = {
    'Filter':filter
  }
  return (dispatch) => {
    return dispatch({
      type:'SEARCH_USERS_TYPEAHEAD',
      payload:axios.get(`users/typeahead/${term}`, {headers:headers})
    })
  }
}

export function clearTypeahead(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_TYPEAHEAD'
    })
  }
}

export function clearUser(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_USER'
    })
  }
}
export function getUserDetails(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_USER_DETAILS',
      payload:axios.get(`user/details/${id}`)
    })
  }
}
export function updateUserDetails(id, data){
  console.log('updateUserDetails', data)
  const userData = {
    id: data.id,
    group_id: data.group_id,
    username: data.username,
    password: data.password,
    confirm_password: data.confirmPassword,
  }

  return (dispatch) => {
    return dispatch({
      type:'UPDATE_USER_DETAILS',
      payload:axios.post(`user/details/${id}`, userData)
    })
  }
}
export function getUserVenues(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_USER_VENUES',
      payload:axios.get(`user/venues/${id}`)
    })
  }
}
export function updateUserVenues(id, data){
  console.log('updateUserVenues', data)
  const userData = {
    id: id,
    venues: data.venues,
  }

  return (dispatch) => {
    return dispatch({
      type:'UPDATE_USER_VENUES',
      payload:axios.post(`user/venues/${id}`, userData)
    })
  }
}

export function getUserSettings(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_USER_SETTINGS',
      payload:axios.get(`user/settings/${id}`)
    })
  }
}
export function updateUserSettings(id, data){
  console.log('updateUserSettings', data)
  const userData = {
    id: id,
    isFitness: (data.isFitness ? 1 : 0),
    hasVenues: (data.hasVenues ? 1 : 0),
    venue_hasControls: (data.venue_hasControls ? 1 : 0),
    playlists_hasDashboard: (data.playlists_hasDashboard ? 1 : 0),
    playlists_hasOEPlaylists: (data.playlists_hasOEPlaylists ? 1 : 0),
    playlists_hasSubscribed: (data.playlists_hasSubscribed ? 1 : 0),
    playlists_hasAssigned: (data.playlists_hasAssigned ? 1 : 0),
    library_isRestricted: (data.library_isRestricted ? 1 : 0)
  }

  return (dispatch) => {
    return dispatch({
      type:'UPDATE_USER_SETTINGS',
      payload:axios.post(`user/settings/${id}`, userData)
    })
  }
}
// export function getVenuePlaylists(id){
//   return (dispatch) => {
//     return dispatch({
//       type:'FETCH_VENUE_PLAYLISTS',
//       payload:axios.get(`venue/playlists/${id}`)
//     })
//   }
// }
// export function getVenueSchedule(id){
//   return (dispatch) => {
//     return dispatch({
//       type:'FETCH_VENUE_SCHEDULE',
//       payload:axios.get(`venue/schedule/${id}`)
//     })
//   }
// }
// export function createVenueSchedule(playlistData, playlistIndex){
//   return (dispatch) => {
//     return dispatch({
//       type:'CREATE_VENUE_SCHEDULE',
//       payload:axios.post(`venue/schedule/create`, playlistData),
//       meta: playlistIndex
//     })
//   }
// }
// export function updateVenueSchedule(id, playlistData){
//   return (dispatch) => {
//     return dispatch({
//       type:'UPDATE_VENUE_SCHEDULE',
//       payload:axios.post(`venue/schedule/update/${id}`, playlistData)
//     })
//   }
// }
// export function deleteVenueSchedule(id){
//   return (dispatch) => {
//     return dispatch({
//       type:'DELETE_VENUE_SCHEDULE',
//       payload:axios.delete(`venue/schedule/${id}`)
//     })
//   }
// }
// export function addInterleave(data, index,businessID){
//   return (dispatch) => {
//     return dispatch({
//       type:'CREATE_VENUE_INTERLEAVE',
//       payload:axios.post(`venue/schedule/interleave`, data),
//       meta:index
//     })
//   }
// }
// export function addInterleaves(data){
//   return (dispatch) => {
//     return dispatch({
//       type:'CREATE_VENUE_INTERLEAVES',
//       payload:axios.post(`venue/schedule/interleaves`, data)
//     })
//   }
// }
// export function deleteInterleave(id){
//   return (dispatch) => {
//     return dispatch({
//       type:'DELETE_VENUE_INTERLEAVE',
//       payload:axios.delete(`venue/schedule/interleave/${id}`)
//     })
//   }
// }
// export function updateInterleaveRatios(data){
//   return (dispatch) => {
//     return dispatch({
//       type:'UPDATE_VENUE_INTERLEAVE_RATIOS',
//       payload:axios.post(`venue/schedule/interleave/update/ratio`,data)
//     })
//   }
// }
// export function toggleInterleaveRatioChanger(isOpen){
//   return (dispatch) => {
//     return dispatch({
//       type:'TOGGLE_VENUE_INTERLEAVE_RATIO_CHANGER',
//       payload:isOpen
//     })
//   }
// }
// export function copyInterleaveToVenues(data){
//   return (dispatch) => {
//     return dispatch({
//       type:'COPY_VENUE_INTERLEAVES',
//       payload:axios.post(`venue/schedule/interleave/copy`,data)
//     })
//   }
// }
// export function copySchedulesToVenues(data){
//   return (dispatch) => {
//     return dispatch({
//       type:'COPY_VENUE_SCHEDULES',
//       payload:axios.post(`venue/schedule/copy`,data)
//     })
//   }
// }
// export function toggleScheduleCreator(isOpen){
//   return (dispatch) => {
//     dispatch({type:'TOGGLE_SCHEDULE_CREATOR',payload:isOpen})
//   }
// }
// export function setPlaylistInfo(isOpen){
//   return (dispatch) => {
//     dispatch({type:'SET_PLAYLIST_INFO',payload:isOpen})
//   }
// }
// export function getVenueCalendar(id, date){
//   return (dispatch) => {
//     return dispatch({
//       type:'FETCH_VENUE_CALENDAR',
//       payload:axios.get(`venue/schedule/${id}/calendar/${date}`)
//     })
//   }
// }
// export function createVenueCalendar(playlistData, playlistIndex){
//   return (dispatch) => {
//     return dispatch({
//       type:'CREATE_VENUE_CALENDAR',
//       payload:axios.post(`venue/schedule/calendar/create`, playlistData),
//       meta: playlistIndex
//     })
//   }
// }
// export function updateVenueCalendar(id, playlistData){
//   return (dispatch) => {
//     return dispatch({
//       type:'UPDATE_VENUE_CALENDAR',
//       payload:axios.post(`venue/schedule/calendar/update/${id}`, playlistData)
//     })
//   }
// }
// export function deleteVenueCalendar(id){
//   return (dispatch) => {
//     return dispatch({
//       type:'DELETE_VENUE_CALENDAR',
//       payload:axios.delete(`venue/schedule/calendar/${id}`)
//     })
//   }
// }
// export function getVenueAnnouncements(id){
//   return (dispatch) => {
//     return dispatch({
//       type:'FETCH_VENUE_ANNOUNCEMENTS',
//       payload:axios.get(`venue/announcements/${id}`)
//     })
//   }
// }
// export function updateAnnouncement(time,id){
//   return (dispatch) => {
//     return dispatch({
//       type:'UPDATE_VENUE_ANNOUNCEMENTS',
//       payload:axios.put(`venue/announcements/${id}`,{time:time}),
//       meta:{id:id,time:time}
//     })
//   }
// }

export function createUser(data){
  const userData = {
    business_id: data.business.id,
    group_id: data.group_id,
    username: data.username,
    password: data.password,
    confirm_password: data.confirmPassword,
  }

  const storedUserData = data
  delete storedUserData['password']
  delete storedUserData['confirmPassword']

  return (dispatch) => {
    return dispatch({
      type:'CREATE_USER',
      payload:axios.post(`user/create`, userData),
      meta: storedUserData
    })
  }
}
export function deleteUser(id){
  return (dispatch) => {
    return dispatch({
      type:'DELETE_USER',
      payload:axios.delete(`user/${id}`),
      meta:id
    })
  }
}
// export function deleteVenue(id){
//   return (dispatch) => {
//     return dispatch({
//       type:'DELETE_VENUE',
//       payload:axios.delete(`venue/${id}`),
//       meta:id
//     })
//   }
// }
// export function copyVenue(id){
//   return (dispatch) => {
//     return dispatch({
//       type:'COPY_VENUE',
//       payload:axios.put(`venue/copy/${id}`)
//     })
//   }
// }
// export function addPlaylistToVenue(data,playlist){
//   return (dispatch) => {
//     return dispatch({
//       type:'ADD_PLAYLIST_TO_VENUE',
//       payload:axios.post(`venue/playlist/add`,data),
//       meta:playlist
//     })
//   }
// }
// export function removePlaylistFromVenue(venueID,playlist){
//   return (dispatch) => {
//     return dispatch({
//       type:'REMOVE_PLAYLIST_FROM_VENUE',
//       payload:axios.delete(`venue/playlist/remove/${venueID}/${playlist.id}`),
//       meta:playlist
//     })
//   }
// }
// export function getVenueControls(id){
//   return (dispatch) => {
//     if(ws){
//       ws.endConnection()
//     }
//     return dispatch({
//       type:'FETCH_VENUE_CONTROLS',
//       payload:axios.get(`/manager/venue/${id}`)
//     })
//   }
// }
// export function openManagerWs(){
//   return (dispatch) => {
//     ws = new Websockets(dispatch)
//     ws.init()
//     dispatch({type:'WS_MANAGER_INIT',payload:{init:true}})
//   }
// }

// export function openVenueWS(token){
//   return (dispatch) => {
//     ws = new Websockets(dispatch)
//     ws.init(token)
//     dispatch({type:'WS_INIT',payload:{init:true}})
//   }
// }
// export function connectVenueWS(){
//   return () => {
//     ws.connect()
//   }
// }
// export function connectManagerWS(){
//   return () => {
//     ws.connect()
//   }
// }
// export function closeWSConnection(){
//   return (dispatch) => {
//     ws.endConnection()
//     dispatch({type:'WS_RESET',payload:null})
//   }
// }
// export function toggleEnergySlider(isActive){
//   ws.send('energyMan',{status:isActive,level:5})
//   return {
//     type:'TOGGLE_ENERGY_SLIDER',
//     payload:isActive
//   }
// }

// export function updateVenueEnergy(energy){
//   ws.send('energyMan',{status:true,level:energy})
//   return {
//     type:'UPDATE_VENUE_ENERGY',
//     payload:energy
//   }
// }

// export function setVolume(vol){
//   ws.send('volume',vol)
//   return {
//     type:'WS_SET_VOLUME',
//     payload:vol
//   }
// }

// export function getVenueOnline(token){
//   return () => {
//     ws.sendManager('checkPlOnlineMM',token,null)
//   }
// }

// export function skipBack(){
//   return () => {
//     ws.send('skipBack',null)
//   }
// }

// export function skipForward(){
//   return () => {
//     ws.send('skipForward',null)
//   }
// }

// export function playPause(isPlaying){
//   ws.send('playPause',null)
//   return {
//     type:'VENUE_ISPLAYING',
//     payload:isPlaying
//   }
// }

// export function toggleFullscreen(isFullscreen){
//   return {
//     type:'VENUE_TOGGLE_FULLSCREEN',
//     payload:isFullscreen
//   }
// }

// export function selectPtp(data){
//   return () => {
//     ws.send('pushToPlay',data)
//   }
// }

// export function cancelPtp(){
//   return () => {
//     ws.send('cancelPushToPlay',null)
//   }
// }
// export function restartPlayer(){
//   return () => {
//     ws.send('restartPlayer',null)
//   }
// }
// export function blockTrack(id){
//   return (dispatch) => {
//     return dispatch({
//       type:'BLOCK_TRACK',
//       payload:axios.put(`/block-track/${id}`)
//     })
//   }
// }
// export function getSavedSchedules(venue_id){
//   return (dispatch) => {
//     return dispatch({
//       type:'FETCH_SAVED_SCHEDULES',
//       payload:axios.get(`/venue/schedule/saved/${venue_id}`)
//     })
//   }
// }
// export function loadSchedule(saved_id,venue_id){
//   const data = {
//     saved_id:saved_id,
//     venue_id:venue_id
//   }
//   return (dispatch) => {
//     return dispatch({
//       type:'LOAD_SCHEDULE',
//       payload:axios.post(`/venue/schedule/load`,data)
//     })
//   }
// }
// export function saveSchedule(venue_id,name){
//   const data = {
//     venue_id:venue_id,
//     name:name
//   }
//   return (dispatch) => {
//     return dispatch({
//       type:'SAVE_SCHEDULE',
//       payload:axios.post(`/venue/schedule/save`,data)
//     })
//   }
// }
// export function getVenuesByBusiness(data){
//   return (dispatch) => {
//     return dispatch({
//       type:'FETCH_VENUES_BY_BUSINESS',
//       payload:axios.get(`venues/business/${data.id}`),
//       meta: {
//         name: data.name,
//         id: data.id
//       }
//     })
//   }
// }
// export function getVenueUser(id){
//   return (dispatch) => {
//     return dispatch({
//       type:'FETCH_VENUE_USER',
//       payload:axios.get(`venue/user/${id}`)
//     })
//   }
// }
// export function updateVenueUser(id,data){
//   return (dispatch) => {
//     return dispatch({
//       type:'UPDATE_VENUE_USER',
//       payload:axios.put(`venue/user/${id}`,data),
//       meta:data
//     })
//   }
// }
