import { formatDate } from 'helpers/DateHelper'
import { isSplit } from 'helpers/PlaylistHelper'
const initialState = {
  details:{
    id:undefined,
    name:undefined,
    displayName:undefined,
    trackCount:undefined,
    owner:undefined,
    style:undefined,
    type:undefined,
    allTypes:[],
    genres:undefined,
    allGenres:[],
    tags:undefined,
    allTags:[],
    hasTracksToImport: false,
    hasChangesToApprove: false,
    loading:false
  },
  business: {},
  settings:{},
  parent:{},
  playlistTypes:[],
  playlistCreated: false,
  playlistCreatedVenuesAdded: false,
  tracks:[],
  tracksToApprove:[],
  tracksSplit:[],
  selectedTracks:[],
  sortedTracks:[],
  importerTracks:[],
  venues:[],
  overlayVenues: [],
  children:[],
  curators:[],
  filter:'',
  metadata:{},
  count:null,
  loading:false,
  loaded:false,
  playlistDetailsLoading: false,
  addTrackToPlaylistLoading: false,
  addPlaylistToVenuesOverlayLoading: false,
  addPlaylistToVenueLoading: [],
  approveChangeLoading: [],
  rejectChangeLoading: [],
  removeVenueFromPlaylistLoading: [],
  error:null,
  copiedPlaylistID:null,
  playlistMoreLoading:false,
  playlistUpdated:false,
  settingsRadioLoading:false,
  ownerTypeahead:[],
  removeDuplicatesLoading:false,
  playlistBriefLoading: false,
  updateFrequencies: [],
  playlistLeads: []
}
export default function reducer(state = initialState, action){
  switch (action.type){
    //playlist
    case 'CLEAR_PLAYLIST':{
      state = {...state,tracks:[],venues:[]}
      break
    }
    case 'FETCH_PLAYLIST_TRACKS_PENDING':{
      state = {...state,loading:true,tracks:[]}
      break
    }
    case 'FETCH_PLAYLIST_TRACKS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_PLAYLIST_TRACKS_FULFILLED':{
      const tracks = action.payload.data.tracks.map(track => {

        const data = {
          id:track.id,
          title:track.title,
          artist:track.artist,
          album:track.album,
          genres:track.genres,
          total_length:track.total_length,
          energy:track.energy,
          bpm:track.bpm,
          added:formatDate(track.pivot.created_at),
          addedMysql:track.pivot.created_at,
          filter:`${track.title} ${track.artist} ${track.album} ${track.genres.map((genre)=>genre.genre).join(' ')}`,
          pivot_id:track.pivot.id,
          split_id:track.split_id ? track.split_id : state.details.id,
          checked:false,
          duplicate:track.duplicate ? true : false,
          filename:`${track.file.filename}.mp3`
        }
        if(track.designation){
          data.designation = track.designation
          data.parent_id = track.parent_id
        }
        return data
      })

      state =  {
        ...state,
        loading:false,
        loaded:true,
        tracks:state.tracks.concat(tracks),
        metadata:action.payload.data.metadata
      }
      break
    }
    case 'UPDATE_TRACK_DETAILS_FULFILLED':{
      const {track_id,data} = action.meta
      const tracks = state.tracks.map(track => {
        if(track.id === track_id){
          return track = {
            ...track,
            title:data.title,
            artist:data.artist,
            album:data.album,
            total_length:data.total_length,
            genres:data.genres
          }
        }
        return track
      })
      state = {
        ...state,
        tracks:tracks,
        loading:false
      }
      break
    }
    case 'UPDATE_TRACKS_DETAILS_FULFILLED':{
      const {
        tracks,
        data
      } = action.meta
      const tracksUpdate = state.tracks.map(track => {
        const foundTrack = tracks.find(findTrack => findTrack.id === track.id)
        if(foundTrack){
          return track = {
            ...track,
            artist:data.artist ? data.artist : track.artist,
            album:data.album ? data.album : track.album,
            genres:data.genres.length > 0 ? data.genres : track.genres
          }
        }
        return track
      })
      state = {
        ...state,
        tracks:tracksUpdate,
        loading:false
      }
      break
    }
    case 'FETCH_PLAYLIST_TRACKS_SPLIT_PENDING':{
      state = {...state,loading:true,tracksSplit:[]}
      break
    }
    case 'FETCH_PLAYLIST_TRACKS_SPLIT_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_PLAYLIST_TRACKS_SPLIT_FULFILLED':{
      const tracks = action.payload.data.tracks.map(track => {
        const genreArr = track.genres.map((genre)=>genre.genre)
        const data = {
          id:track.id,
          title:track.title,
          artist:track.artist,
          album:track.album,
          genres:track.genres,
          total_length:track.total_length,
          energy:track.energy,
          bpm:track.bpm,
          added:formatDate(track.pivot.created_at),
          addedMysql:track.pivot.created_at,
          filter:`${track.title} ${track.artist} ${track.album} ${genreArr.join(' ')}`,
          pivot_id:track.pivot.id,
          split_id:track.pivot.playlist_id,
          parent_id:track.parent_id ? track.parent_id : state.details.id,
          designation:track.designation ? track.designation : action.meta,
          checked:false
        }
        return data
      })

      state =  {
        ...state,
        loading:false,
        loaded:true,
        tracksSplit:state.tracksSplit.concat(tracks),
        metadata:action.payload.data.metadata
      }
      break
    }

    case 'SET_PLAYLIST_TRACKS_SORTED':{
      state = {
        ...state,
        sortedTracks: action.payload
      }
      break
    }

    case 'FETCH_PLAYLIST_TRACKS_ORDERED_PENDING':{
      state = {...state,loading:true,tracks:[]}
      break
    }
    case 'FETCH_PLAYLIST_TRACKS_ORDERED_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_PLAYLIST_TRACKS_ORDERED_FULFILLED':{
      const tracks = [].concat(action.payload.data.tracks)
        .sort((a,b)=>{
          if(a.ordered[0] && b.ordered[0]){
            return a.ordered[0].pivot.index - b.ordered[0].pivot.index
          }
          return false
        })
        .map(track => {
          const genreArr = track.genres.map((genre)=>genre.genre)
          const data = {
            id:track.id,
            title:track.title,
            artist:track.artist,
            album:track.album,
            genres:genreArr,
            length:track.total_length,
            energy:track.energy,
            bpm:track.bpm,
            added:formatDate(track.pivot.created_at),
            addedMysql:track.pivot.created_at,
            filter:`${track.title} ${track.artist} ${track.album} ${genreArr.join(' ')}`,
            pivot_id:track.pivot.id
          }
          return data
        })

      state =  {
        ...state,
        loading:false,
        loaded:true,
        tracks:state.tracks.concat(tracks),
        metadata:action.payload.data.metadata
      }
      break
    }
    case 'UPDATE_PARENT_PLAYLIST_PENDING':{
      state = {
        ...state,
        
      }
      break
    }
    case 'UPDATE_PARENT_PLAYLIST_REJECTED':{
      break
    }
    case 'UPDATE_PARENT_PLAYLIST_FULFILLED':{
      state = {
        ...state,
        parent:action.meta
      }
      break
    }
    case 'UPDATE_OWNER_PLAYLIST_PENDING':{
      break
    }
    case 'UPDATE_OWNER_PLAYLIST_REJECTED':{
      break
    }
    case 'UPDATE_OWNER_PLAYLIST_FULFILLED':{
      state = {
        ...state,
        owner:action.meta
      }
      break
    }
    case 'FETCH_PLAYLIST_TRACKS_FOR_APPROVAL_PENDING':{
      state = {...state,loading:true,tracksToApprove:[]}
      break
    }
    case 'FETCH_PLAYLIST_TRACKS_FOR_APPROVAL_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_PLAYLIST_TRACKS_FOR_APPROVAL_FULFILLED':{
      state =  {
        ...state,
        loading:false,
        tracksToApprove: action.payload.data || []
      }
      break
    }
    case 'APPROVE_CHANGE_PENDING':{
      state = {
        ...state,
        approveChangeLoading: state.approveChangeLoading.concat(action.meta)
      }
      break
    }
    case 'APPROVE_CHANGE_REJECTED':{
      state = {
        ...state,
        approveChangeLoading: state.approveChangeLoading.filter(trackID => trackID !== action.meta),
        error:action.payload
      }
      break
    }
    case 'APPROVE_CHANGE_FULFILLED':{
      state =  {
        ...state,
        approveChangeLoading: state.approveChangeLoading.filter(trackID => trackID !== action.meta),
        tracksToApprove: state.tracksToApprove.filter(track => track.id !== action.meta)
      }
      break
    }
    case 'REJECT_CHANGE_PENDING':{
      state = {
        ...state,
        rejectChangeLoading: state.rejectChangeLoading.concat(action.meta)
      }
      break
    }
    case 'REJECT_CHANGE_REJECTED':{
      state = {
        ...state,
        rejectChangeLoading: state.rejectChangeLoading.filter(id => id !== action.meta),
        error:action.payload
      }
      break
    }
    case 'REJECT_CHANGE_FULFILLED':{
      state =  {
        ...state,
        rejectChangeLoading: state.rejectChangeLoading.filter(id => id !== action.meta),
        tracksToApprove: state.tracksToApprove.filter(track => track.pivot.id !== action.meta)
      }
      break
    }
    case 'FILTER_PLAYLIST_TRACKS':{
      state = {...state,filter:action.payload}
      break
    }
    case 'FETCH_PLAYLIST_IMAGE_FULFILLED':{
      state = {
        ...state,
        image:action.payload.data.playlist.playlist_image
      }
      break
    }
    case 'DELETE_PLAYLIST_IMAGE_FULFILLED':{
      state = {
        ...state,
        image:null
      }
      break
    }
    case 'CREATE_PLAYLIST_IMAGE_FULFILLED':{
      state = {
        ...state,
        image:action.payload.data.image,
        loading:false
      }
      break
    }
    case 'PLAYLIST_IMAGE_LOADING':{
      state = {
        ...state,
        loading:true
      }
      break
    }
    case 'FETCH_TRACK_SOURCE_FULFILLED':{
      const tracks = state.tracks.map(track => {
        if(track.id === action.meta){
          track.isPlaying = true
        } else {
          track.isPlaying = false
        }
        return track
      })
      state = {
        ...state,
        tracks:tracks
      }
      break
    }
    case 'ORDER_PLAYLIST_TRACKS':{
      //create new array
      let tracks = state.tracks.slice()
      //remove old position of old track
      tracks.splice(action.payload.track.index,1)
      //set moved track
      tracks.splice(parseInt(action.payload.newIndex),0,action.payload.track)

      state = {
        ...state,
        tracks:tracks
      }
      break
    }
    case 'DELETE_PLAYLIST_TRACK_PENDING':
      state = {
        ...state,
        tracks:state.tracks.filter(track => track.id !== action.meta.id),
        tracksSplit:state.tracksSplit.filter(track => track.id !== action.meta.id)
      }
      break
    case 'DELETE_PLAYLIST_TRACKS_FULFILLED':{
      const tracks = action.meta.map(track => track.id)
      state = {
        ...state,
        tracks:state.tracks.filter(track => !tracks.includes(track.id)),
        tracksSplit:state.tracksSplit.filter(track => !tracks.includes(track.id))
      }
      break
    }
    case 'FETCH_PLAYLIST_VENUES_PENDING':{
      state = {...state,loading:true,venues:[]}
      break
    }
    case 'FETCH_PLAYLIST_VENUES_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_PLAYLIST_VENUES_FULFILLED':{
      const venues = action.payload.data.venues
        .map(venue => {
          const data = {
            id:venue.id,
            name:venue.name,
            createdAt:venue.created_at.standard,
            addedMysql:venue.created_at.iso
          }
          return data
        })
      state =  {
        ...state,
        loading:false,
        loaded:true,
        venues:venues
      }
      break
    }
    case 'REMOVE_VENUE_PLAYLIST_PENDING':{
      state = {
        ...state,
        removeVenueFromPlaylistLoading: state.removeVenueFromPlaylistLoading.concat(action.meta)
      }
      break
    }
    case 'REMOVE_VENUE_PLAYLIST_REJECTED':{
      state = {
        ...state,
        removeVenueFromPlaylistLoading:false,
        error:action.payload
      }
      break
    }
    case 'REMOVE_VENUE_PLAYLIST_FULFILLED':{
      state = {
        ...state,
        removeVenueFromPlaylistLoading: state.removeVenueFromPlaylistLoading.filter(venueID => venueID !== action.meta),
        venues:state.venues.filter(venue => venue.id !== action.meta)
      }
      break
    }
    case 'REMOVE_ALL_VENUES_FROM_PLAYLIST_PENDING':{
      state = {
        ...state,
        loading: true
      }
      break
    }
    case 'REMOVE_ALL_VENUES_FROM_PLAYLIST_REJECTED':{
      state = {
        ...state,
        loading: false,
        error: action.payload
      }
      break
    }
    case 'REMOVE_ALL_VENUES_FROM_PLAYLIST_FULFILLED':{
      state = {
        ...state,
        loading: false,
        venues: []
      }
      break
    }
    case 'FETCH_PLAYLIST_CHILDREN_PENDING':{
      state =  {
        ...state,
        loading:true,
        loaded:false,
        children:[]
      }
      break
    }
    case 'FETCH_PLAYLIST_CHILDREN_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_PLAYLIST_CHILDREN_FULFILLED':{
      const children = action.payload.data.children
        .map(child => {
          const data = {
            id:child.id,
            name:child.name,
            createdAt:child.created_at
          }
          return data
        })
      state =  {
        ...state,
        loading:false,
        loaded:true,
        children:children
      }
      break
    }
    case 'FETCH_PLAYLIST_CURATORS_PENDING':{
      state =  {
        ...state,
        loading:true,
        loaded:false,
        curators:[]
      }
      break
    }
    case 'FETCH_PLAYLIST_CURATORS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_PLAYLIST_CURATORS_FULFILLED':{
      const curators = action.payload.data.playlist_curating
        .map(curator => {
          const data = {
            id:curator.id,
            username:curator.username
          }
          return data
        })
      state =  {
        ...state,
        loading:false,
        loaded:true,
        curators:curators
      }
      break
    }
    case 'FETCH_PLAYLIST_DETAILS_PENDING':{
      state = {
        ...state,
        details:{},
        playlistUpdated:false,
        playlistDetailsLoading: true
      }
      break
    }
    case 'FETCH_PLAYLIST_DETAILS_REJECTED':{
      state = {
        ...state,
        playlistDetailsLoading: false,
        error:action.payload
      }
      break
    }
    case 'FETCH_PLAYLIST_DETAILS_FULFILLED':{
      const {data} = action.payload
      state =  {
        ...state,
        loaded:true,
        playlistDetailsLoading: false,
        details:{
          ...state.details,
          id:data.id,
          name:data.name,
          displayName:data.display_name,
          trackCount:data.tracks_count,
          owner:data.owner ? data.owner.username : '',
          style:data.style,
          type:data.playlist_types.id,
          isSplit:isSplit(data.playlist_types.id),
          allTypes:data.allTypes,
          genres:data.genres,
          allGenres:data.allGenres,
          tags:data.tags,
          allTags:data.allTags,
          isPinned:data.isPinned,
          pinned_id:data.pinned_id,
          hasTracksToImport:data.import_playlist.length > 0 ? data.import_playlist[0].tracks_count : null,
          hasChangesToApprove:data.tracks_pending_count,
          ...(data.playlist_settings && {
            rotation: data.playlist_settings.rotation,
            rotationCurrentDesignation: data.rotationCurrentDesignation
          })
        }
      }
      break
    }
    case 'FETCH_PLAYLIST_SETTINGS_PENDING':{
      state = {
        ...state,
        settings:{}
      }
      break
    }
    case 'FETCH_PLAYLIST_SETTINGS_REJECTED':{
      state = {
        ...state,
        error:action.payload
      }
      break
    }
    case 'FETCH_PLAYLIST_SETTINGS_FULFILLED':{
      const {data} = action.payload
      state =  {
        ...state,
        loaded:true,
        settings:data.settings,
        parent:data.parent,
        owner:data.owner
      }
      break
    }
    case 'UPDATE_PLAYLIST_DETAILS_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'UPDATE_PLAYLIST_DETAILS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'UPDATE_PLAYLIST_DETAILS_FULFILLED':{
      const data = action.payload.data.res.data
      state = {
        ...state,
        loading:false,
        loaded:true,
        details:{
          ...state.details,
          name:data.name,
          displayName:data.display_name,
          style:data.style,
          type:data.type_id,
          ...(data.playlist_settings && {
            rotation: data.playlist_settings.rotation,
            rotationCurrentDesignation: data.rotationCurrentDesignation
          })
        }
      }
      break
    }
    case 'PUT_CHANGE_DESIGNATION_PENDING':{
      const tracks = state.tracks.map(track => {
        if(action.meta.track_id === track.id){
          track.designation = action.meta.designation
          track.split_id = action.meta.split_id
        }
        return track
      })
      const tracksSplit = state.tracksSplit.map(track => {
        if(action.meta.track_id === track.id){
          track.designation = action.meta.designation
          track.split_id = action.meta.split_id
        }
        return track
      })
      state = {
        ...state,
        tracks:tracks,
        tracksSplit:tracksSplit
      }
      break
    }
    case 'PUT_CHANGE_DESIGNATION_MULTI_PENDING':{

      // index tracks for quick lookup
      let tracksById = []
      for(let i = 0; i < action.meta.length; i++){
        tracksById[action.meta[i].track_id] = action.meta[i]
      }

      const tracks = state.tracks.map(track => {
        if(typeof tracksById[track.id] !== 'undefined'){
          track.designation = tracksById[track.id].designation
          track.split_id = tracksById[track.id].new_split_id
        }
        return track
      })
      const tracksSplit = state.tracksSplit.map(track => {
        if(typeof tracksById[track.id] !== 'undefined'){
          track.designation = tracksById[track.id].designation
          track.split_id = tracksById[track.id].new_split_id
        }
        return track
      })
      state = {
        ...state,
        tracks:tracks,
        tracksSplit:tracksSplit
      }
      break
    }
    case 'PIN_PLAYLIST_FULFILLED':{
      state = {
        ...state,
        details:{
          ...state.details,
          isPinned:true
        }
      }
      break
    }
    case 'ADD_PLAYLIST_TRACK_OWNED_PENDING':{
      state = {
        ...state,
        addTrackToOwnedPlaylistLoading: action.meta.track.id
      }
      break
    }
    case 'ADD_PLAYLIST_TRACK_OWNED_REJECTED':{
      state = {
        ...state,
        addTrackToOwnedPlaylistLoading: false
      }
      break
    }
    case 'ADD_PLAYLIST_TRACK_OWNED_FULFILLED':{

      const {
        track,
        playlist
      } = action.meta
      const newTrack = {
        ...action.meta.track,
        filter:`${track.title} ${track.artist} ${track.album} ${track.genres.join(' ')}`,
        split_id:action.payload.data ? parseInt(action.payload.data.res.data) : null,
        parent_id:playlist.id,
        checked:false
      }

      const tracks = state.tracks.concat(newTrack)

      state = {
        ...state,
        tracks: tracks,
        addTrackToOwnedPlaylistLoading: false
      }
      break
    }
    case 'DELETE_PLAYLIST_TRACK_OWNED_PENDING':{
      state = {
        ...state,
        addTrackToOwnedPlaylistLoading: action.meta
      }
      break
    }
    case 'DELETE_PLAYLIST_TRACK_OWNED_REJECTED':{
      state = {
        ...state,
        addTrackToOwnedPlaylistLoading: false
      }
      break
    }
    case 'DELETE_PLAYLIST_TRACK_OWNED_FULFILLED':{
      const tracks = state.tracks.filter(track => track.id !== action.meta)
      state = {
        ...state,
        tracks:tracks,
        addTrackToOwnedPlaylistLoading: false
      }
      break
    }
    case 'ADD_PLAYLIST_TRACK_LIBRARY_PENDING':
    case 'ADD_TRACKS_TO_PLAYLIST_PENDING':{
      state = {
        ...state,
        addTrackToPlaylistLoading: action.meta
      }
      break
    }
    case 'ADD_PLAYLIST_TRACK_LIBRARY_FULFILLED':
    case 'ADD_TRACKS_TO_PLAYLIST_FULFILLED':{
      state = {
        ...state,
        addTrackToPlaylistLoading: false
      }
      break
    }
    case 'DELETE_PLAYLIST_TRACK_LIBRARY_PENDING':{
      state = {
        ...state,
        addTrackToPlaylistLoading: action.meta
      }
      break
    }
    case 'DELETE_PLAYLIST_TRACK_LIBRARY_FULFILLED':{
      state = {
        ...state,
        addTrackToPlaylistLoading: false
      }
      break
    }
    case 'DELETE_PLAYLIST_CURATOR_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'DELETE_PLAYLIST_CURATOR_FULFILLED':{
      const curators = state.curators.filter(curator => curator.id !== action.meta)
      state = {
        ...state,
        curators:curators
      }
      break
    }
    case 'FETCH_PLAYLIST_TYPES_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_PLAYLIST_TYPES_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_PLAYLIST_TYPES_FULFILLED':{
      const {data} = action.payload
      state =  {
        ...state,
        loading:false,
        loaded:true,
        playlistTypes: data
      }
      break
    }
    case 'CREATE_PLAYLIST_PENDING':
    case 'CREATE_IMPORTER_PLAYLIST_PENDING':{
      state = {
        ...state,
        loading:true
      }
      break
    }
    case 'CREATE_PLAYLIST_REJECTED':
    case 'CREATE_IMPORTER_PLAYLIST_REJECTED':{
      state = {
        ...state,
        loading:false,
        error:action.payload
      }
      break
    }
    case 'CREATE_PLAYLIST_FULFILLED':
    case 'CREATE_IMPORTER_PLAYLIST_FULFILLED':{
      const businessData = action.payload.data.business
      state =  {
        ...state,
        details:{
          ...state.details,
          id: action.payload.data.id
        },
        business: businessData || {},
        playlistCreated: true,
        loading: false
      }
      break
    }
    case 'CREATE_PLAYLIST_ADD_VENUES_FULFILLED':{
      state =  {
        ...state,
        details:{
          ...state.details,
          id:action.payload.data.id
        },
        playlistCreatedVenuesAdded: true
      }
      break
    }

    case 'RESET_CREATE_PLAYLIST':{
      state =  {
        ...state,
        playlistCreated: false,
        playlistCreatedVenuesAdded: false
      }
      break
    }
    case 'FETCH_PLAYLIST_TRACKS_TO_IMPORT_PENDING':{
      state = {...state,loading:true,tracks:[]}
      break
    }
    case 'FETCH_PLAYLIST_TRACKS_TO_IMPORT_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_PLAYLIST_TRACKS_TO_IMPORT_FULFILLED':{
      state =  {
        ...state,
        loading: false,
        loaded: true,
        importerTracks: action.payload.data[0] ? action.payload.data[0].tracks : []
      }
      break
    }
    case 'ADD_PLAYLIST_CURATOR_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'ADD_PLAYLIST_CURATOR_FULFILLED':{
      const user = {
        id:action.meta.id,
        username:action.meta.name
      }
      state =  {
        ...state,
        curators:state.curators.concat(user)
      }
      break
    }
    case 'DELETE_PLAYLIST_TRACKS_TO_IMPORT_PENDING':{
      state = {
        ...state,
        deletePlaylistTrackToImportLoading: action.meta
      }
      break
    }
    case 'DELETE_PLAYLIST_TRACKS_TO_IMPORT_REJECTED':{
      state = {
        ...state,
        deletePlaylistTrackToImportLoading: null,
        error:action.payload
      }
      break
    }
    case 'DELETE_PLAYLIST_TRACKS_TO_IMPORT_FULFILLED':{
      state =  {
        ...state,
        deletePlaylistTrackToImportLoading: null,
        importerTracks: state.importerTracks.filter(track=>track.id !== parseInt(action.payload.data.id)),
        details:{
          ...state.details,
          hasTracksToImport:state.details.hasTracksToImport - 1
        }
      }
      break
    }
    case 'CLEAR_OVERLAY_VENUES':{
      state = {...state,overlayVenues:[]}
      break
    }
    case 'PLAYLIST_ADD_TO_VENUES_SEARCH_PENDING':{
      state = {
        ...state,
        addPlaylistToVenuesOverlayLoading: true
      }
      break
    }
    case 'PLAYLIST_ADD_TO_VENUES_SEARCH_REJECTED':{
      state = {
        ...state,
        addPlaylistToVenuesOverlayLoading:false,
        error:action.payload
      }
      break
    }
    case 'PLAYLIST_ADD_TO_VENUES_SEARCH_FULFILLED': {
      // if the request has been cancelled by another request,
      // set the loading state to true
      if (action.payload === 'cancelled') {
        state = {
          ...state,
          addPlaylistToVenuesOverlayLoading: true
        }
        break
      }

      const venues = action.payload.data.venues.map((venue) => {
        const data = {
          id: venue.id,
          name: venue.name,
          business: venue.business ? venue.business.name : '',
          business_id: venue.business_id && venue.business_id,
          playlists_count: venue.playlists.length,
          total_tracks: venue.total_tracks,
          isVenueAssigned: venue.isVenueAssigned
        }
        return data
      })
      state = {
        ...state,
        addPlaylistToVenuesOverlayLoading: false,
        overlayVenues: action.meta ? venues : state.overlayVenues.concat(venues),
        count: action.payload.data.count
      }
      break
    }
    case 'ADD_PLAYLIST_TO_VENUE_PENDING':{
      state = {
        ...state,
        addPlaylistToVenueLoading: state.addPlaylistToVenueLoading.concat(action.meta.id)
      }
      break
    }
    case 'ADD_PLAYLIST_TO_VENUE_FULFILLED':{
      const overlayVenues = state.overlayVenues.map((venue)=>{
        venue.isVenueAssigned = action.meta.id === venue.id ? true : venue.isVenueAssigned
        return venue
      })

      const venues = state.venues.concat(action.meta)

      state = {
        ...state,
        addPlaylistToVenueLoading: state.addPlaylistToVenueLoading.filter(venueID => venueID !== action.meta.id),
        overlayVenues: overlayVenues,
        venues: venues
      }
      break
    }
    case 'COPY_PLAYLIST_PENDING':{
      state = {
        ...state,
        playlistMoreLoading:true,
        copiedPlaylistID:null
      }
      break
    }
    case 'COPY_PLAYLIST_REJECTED':{
      break
    }
    case 'COPY_PLAYLIST_FULFILLED':{
      state = {
        ...state,
        playlistMoreLoading:false,
        copiedPlaylistID:action.payload.data.res.data.playlist_id
      }
      break
    }
    case 'SPLIT_PLAYLIST_PENDING':{
      state = {
        ...state,
        playlistMoreLoading:true,
        copiedPlaylistID:null
      }
      break
    }
    case 'SPLIT_PLAYLIST_REJECTED':{
      break
    }
    case 'SPLIT_PLAYLIST_FULFILLED':{
      state = {
        ...state,
        playlistMoreLoading:false,
        playlistUpdated:true,
        copiedPlaylistID:action.meta
      }
      break
    }
    case 'UPDATE_PLAYLIST_TYPE_PENDING':{
      state = {
        ...state,
        playlistMoreLoading:true
      }
      break
    }
    case 'UPDATE_PLAYLIST_TYPE_REJECTED':{
      break
    }
    case 'UPDATE_PLAYLIST_TYPE_FULFILLED':{
      state = {
        ...state,
        details:{
          ...state.details,
          type:action.meta
        },
        playlistMoreLoading:false
      }
      break
    }
    case 'UPDATE_PLAYLIST_ROTATION_PENDING':{
      state = {
        ...state,
        playlistDetailsLoading:true
      }
      break
    }
    case 'UPDATE_PLAYLIST_ROTATION_REJECTED':{
      break
    }
    case 'UPDATE_PLAYLIST_ROTATION_FULFILLED':{
      state = {
        ...state,
        playlistDetailsLoading:false
      }
      break
    }
    case 'DELETE_PLAYLIST_PENDING':{
      state = {
        ...state,
        playlistMoreLoading:true
      }
      break
    }
    case 'DELETE_PLAYLIST_REJECTED':{
      state = {
        ...state,
        error:action.payload
      }
      break
    }
    case 'DELETE_PLAYLIST_FULFILLED':{
      state = {
        ...state,
        playlistMoreLoading:false
      }
      break
    }
    case 'UPDATE_PLAYLIST_AUTO_ADD_PENDING':{
      state = {
        ...state,
        playlistDetailsLoading:true
      }
      break
    }
    case 'UPDATE_PLAYLIST_AUTO_ADD_REJECTED':{
      break
    }
    case 'UPDATE_PLAYLIST_AUTO_ADD_FULFILLED':{
      state = {
        ...state,
        playlistDetailsLoading:false
      }
      break
    }
    case 'UPDATE_PLAYLIST_AUTO_DELETE_PENDING':{
      state = {
        ...state,
        playlistDetailsLoading:true
      }
      break
    }
    case 'UPDATE_PLAYLIST_AUTO_DELETE_REJECTED':{
      break
    }
    case 'UPDATE_PLAYLIST_AUTO_DELETE_FULFILLED':{
      state = {
        ...state,
        playlistDetailsLoading:false
      }
      break
    }
    case 'MERGE_PLAYLIST_PENDING':{
      state = {
        ...state,
        playlistMoreLoading:true,
        copiedPlaylistID:null
      }
      break
    }
    case 'MERGE_PLAYLIST_REJECTED':{
      break
    }
    case 'MERGE_PLAYLIST_FULFILLED':{
      state = {
        ...state,
        playlistMoreLoading:false,
        playlistUpdated:true,
        copiedPlaylistID:action.meta
      }
      break
    }
    case 'MERGE_PLAYLISTS_PENDING':{
      state = {
        ...state,
        playlistMoreLoading:true,
        copiedPlaylistID:null
      }
      break
    }
    case 'MERGE_PLAYLISTS_REJECTED':{
      break
    }
    case 'MERGE_PLAYLISTS_FULFILLED':{
      state = {
        ...state,
        playlistMoreLoading:false,
        playlistUpdated:true,
        copiedPlaylistID:action.payload.data.res.data.id
      }
      break
    }
    case 'SETTINGS_OWNER_TYPEAHEAD_PENDING':{
      state = {
        ...state,
        ownerTypeahead:[],
        ownerTypeaheadLoading:true
      }
      break
    }
    case 'SETTINGS_OWNER_TYPEAHEAD_REJECTED':{
      state = {
        ...state,
        ownerTypeaheadLoading:false,
        error:action.payload
      }
      break
    }
    case 'SETTINGS_OWNER_TYPEAHEAD_FULFILLED':{
      state =  {
        ...state,
        ownerTypeaheadLoading:false,
        loaded:true,
        ownerTypeahead:action.payload.data.users,
      }
      break
    }
    case 'REMOVE_DUPLICATES_PENDING':{
      state = {
        ...state,
        playlistDetailsLoading:true,
        removeDuplicatesLoading:true
      }
      break
    }
    case 'REMOVE_DUPLICATES_REJECTED':{
      state = {
        ...state,
        playlistDetailsLoading:false,
        removeDuplicatesLoading:false,
        error:action.payload
      }
      break
    }
    case 'REMOVE_DUPLICATES_FULFILLED':{
      state = {
        ...state,
        playlistDetailsLoading:false,
        removeDuplicatesLoading:false,
      }
      break
    }
    case 'ADD_PLAYLIST_SELECTED_TRACK':{
      state = {
        ...state,
        selectedTracks: state.selectedTracks.concat(action.payload)
      }
      break
    }
    case 'REMOVE_PLAYLIST_SELECTED_TRACK':{
      state = {
        ...state,
        selectedTracks: state.selectedTracks.filter(track => {
          return track.id !== action.payload.id
        })
      }
      break
    }
    case 'CLEAR_PLAYLIST_SELECTED_TRACKS':{
      state = {
        ...state,
        selectedTracks: []
      }
      break
    }

    case 'FETCH_PLAYLIST_BRIEF_PENDING':{
      state = {
        ...state,
        playlistBriefLoading: true
      }
      break
    }

    case 'FETCH_PLAYLIST_BRIEF_REJECTED':{
      state = {
        ...state,
        playlistBriefLoading: false,
        error: action.payload
      }
      break
    }

    case 'FETCH_PLAYLIST_BRIEF_FULFILLED':{
      const data = action.payload.data
      const brief = data.brief

      const initialValue = {
        display: '------',
        value: 0
      }

      const updateFreqs = data.update_freqs.map(freq => {
        return {
          display: freq.freq,
          value: freq.id
        }
      })

      updateFreqs.unshift(initialValue)

      const playlistLeads = data.music_leads[0].users.map(user => {
        return {
          display: user.email,
          value: user.id
        }
      })

      playlistLeads.unshift(initialValue)

      state = {
        ...state,
        playlistBriefLoading: false,
        brief: {
          playlistLead: brief ? brief.music_lead_id : 0,
          brief: brief ? brief.brief : null,
          targetTracks: brief ? brief.target_tracks : null,
          targetEnergy: brief ? brief.target_energy : 0,
          updateFrequency: brief ? brief.update_freq_id : 0,
          // genres: [
          //   {
          //     genre: 'Ambient',
          //     id: 2696
          //   },
          //   {
          //     genre: 'Blues',
          //     id: 9
          //   }
          // ]
        },
        updateFrequencies : updateFreqs,
        playlistLeads : playlistLeads
      }
      break
    }

    case 'UPDATE_PLAYLIST_BRIEF_PENDING':{
      state = {
        ...state,
        playlistBriefLoading: true
      }
      break
    }
    case 'UPDATE_PLAYLIST_BRIEF_REJECTED':{
      state = {
        ...state,
        playlistBriefLoading: false,
        error: action.payload
      }
      break
    }
    case 'UPDATE_PLAYLIST_BRIEF_FULFILLED':{
      const data = action.payload.data.res.data
      state = {
        ...state,
        playlistBriefLoading: false,
        brief: {
          ...state.brief,
          playlistLead: data.music_lead_id,
          brief: data.brief,
          targetTracks: data.target_tracks,
          targetEnergy: data.target_energy,
          updateFrequency: data.update_freq_id,
        },
      }
      break
    }

    default: // no default case
  }
  return state
}
