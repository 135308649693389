import React from 'react'

const WaveformDisplay = (props) =>{
  const { position, total, details} = props
  return (
    <div className="waveform-display">
      <div className="waveform-display-position">{position}</div>
      <div className="waveform-display-details">{details}</div>
      <div className="waveform-display-total">{total}</div>
    </div>
  )
}

export default WaveformDisplay