import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  getPlaylistVenues,
  removeVenueFromPlaylist,
  removeAllVenuesFromPlaylist
} from 'store/actions/playlistAction'

import {
  addPlaylistToVenue,
} from 'store/actions/venueAction'

import {
  toggleOverlay
} from 'store/actions/overlayAction'

import {
  toggleConfirm
} from 'store/actions/confirmAction'

import Button from 'ui/Button'
import Confirm from 'ui/Confirm'
import Container from 'ui/Container'
import Loader from 'ui/Loader'
import TableWrapper from 'ui/TableWrapper'

import PlaylistVenuesTableRowActions from './playlist-venues/PlaylistVenuesTableRowActions'
import { tableSorter } from 'helpers/TableHelper'

const classname = 'playlistVenues'

class PlaylistVenues extends Component {

  constructor(props){
    super(props)
    this.state = {
      filter:''
    }
  }

  componentDidMount(){
    this.props.dispatch(getPlaylistVenues(this.props.id))
  }

  addPlaylistToVenue(playlist){
    const data = {
      venue_id: this.props.id,
      playlist_id: playlist.id,
      business_id: this.props.businessID
    }
    this.props.dispatch(addPlaylistToVenue(data,playlist))
  }

  removeVenue(venue){
    this.props.dispatch(removeVenueFromPlaylist(venue.id,this.props.id))
  }

  filterVenues(e){
    const text = e.target.value
    this.setState({filter:text})
  }

  sortType(x,y){
    const {field, direction} = this.props.sorter

    // if no direction is provided, due to the sorting icon being reset,
    // or there is no value provided to be sorted on, use default values
    if (direction === null || !(field in x)) {
      return tableSorter(x,y,'name','desc')
    }

    return tableSorter(x,y,field,direction)
  }

  getVenues(){
    //sort tracks by date added to playlist and filter
    return this.props.playlistVenues
      .sort((x,y) => this.sortType(x,y))
      .filter(venue => {
        if(venue.name.toLowerCase().includes(this.state.filter)){
          return venue
        }
        return false
      })
  }

  getTable(){
    return (
      <TableWrapper
        classname={classname}
        data={this.getVenues()}
        loading={this.props.loading}
        rowActions={
          <PlaylistVenuesTableRowActions
            moreActions={(data)=>this.moreActions(data)}
            removeVenue={(data)=>this.removeVenue(data)}
            classname={classname}
            removeVenueFromPlaylistLoading={this.props.removeVenueFromPlaylistLoading}
          />
        }
      />
    )
  }

  toggleAddToVenuesOverlay() {
    const {
      dispatch
    } = this.props

    dispatch(
      toggleOverlay(
        true,
        {
          playlist_id: parseInt(this.props.id)
        },
        'playlistAddToVenues'
      )
    )
  }

  removeAllVenuesFromPlaylist() {
    const {
      dispatch,
      id,
      playlistVenues
    } = this.props

    const confirmData = {
      action: () => {
        dispatch(removeAllVenuesFromPlaylist(playlistVenues, id))
      },
      question: `Remove playlist from all venues?`
    }

    dispatch(toggleConfirm(true, confirmData))
  }

  render(){
    if(this.props.loading){
      return <Loader/>
    } else {
      return (
        <Container height='100%' column>
          <div className='playlist-venues__actions'>
            <Button
              action={()=>this.toggleAddToVenuesOverlay()}
              name='Add to venue'
            />
            <Button
              action={()=>this.removeAllVenuesFromPlaylist()}
              name='Remove from all venues'
            />
          </div>
          {this.getTable()}
          <Confirm />
        </Container>
      )
    }
  }
}
function mapStateToProps(store){
  return {
    playlistVenues:store.playlist.venues,
    loading:store.playlist.loading,
    removeVenueFromPlaylistLoading: store.playlist.removeVenueFromPlaylistLoading,
    sorter: store.table.sorter,
  }
}

export default connect(mapStateToProps)(PlaylistVenues)
