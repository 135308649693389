import React, { Component } from 'react'

import { connect } from 'react-redux'

import {
  getSavedSchedules,
  loadSchedule,
  deleteSavedSchedule
} from 'store/actions/venueAction'

import {
  toggleConfirm
} from 'store/actions/confirmAction'

import {
  toggleOverlay
} from 'store/actions/overlayAction'

import {formatDate} from 'helpers/DateHelper'

import Container from 'ui/Container'
import Button from 'ui/Button'
import Icon from 'ui/Icon'
import Loader from 'ui/Loader'
import Confirm from 'ui/Confirm'

class OverlayVenueSchedulesSaved extends Component{

  constructor(props){
    super(props)
    this.state = {
      saved:[],
      selected:null
    }
  }

  componentDidMount(){
    this.props.dispatch(getSavedSchedules(
      this.props.data.venueID
    ))
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.saved_schedules !== this.props.saved_schedules){
      this.setStateSaved()
    }
  }

  setStateSaved(){
    const saved = this.props.saved_schedules
      .map(saved => {
        saved.selected = false
        return saved
      })

    this.setState({
      saved:saved
    })
  }

  getSavedSchedules(){
    if(this.props.loading){
      return <Loader/>
    } else {
      return this.state.saved.map((saved,index)=>{
        return (
          <div
            key={index}
            className={'schedule-saved-overlay__wrapper'}
          >
            <div
              className={`schedule-saved-overlay ${saved.selected ? 'selected' : ''}`}
              onClick={()=>this.toggleSelect(index)}
            >
              {`${saved.name} - created ${formatDate(saved.created_at)}`} {saved.selected ? <Icon name="checkmark-circle"/>: ''}
            </div>
            <div className={'schedule-saved-overlay__delete'}>
              <Icon
                name='trash'
                action={()=>this.deleteSchedule(this.state.saved[index])}
              />
            </div>
          </div>
        )
      })
    }
  }

  toggleSelect(index){
    let saved = this.state.saved.map(saved => {
      saved.selected = false
      return saved
    })
    saved[index].selected = true
    this.setState({
      saved:saved,
      selected:saved[index]
    })
  }

  loadSchedule(){
    this.props.dispatch(
      loadSchedule(
        this.state.selected.id,
        this.props.data.venueID
      )
    )
  }

  deleteSchedule(schedule){
    const {
      dispatch
    } = this.props

    const confirmData = {
      action: () => {
        dispatch(deleteSavedSchedule(schedule.id))
      },
      question: `Delete saved schedule?`
    }

    dispatch(toggleConfirm(true, confirmData))

  }

  render(){
    const venue = this.props.data

    return (
      <Container classname="overlay-schedule-saved" maxWidth height="100%" column>
        <h2 className="header">Saved schedules for {venue.details.name}</h2>
        <p
          className='overlay-schedule-saved__close-btn'
          onClick={() => {
            this.props.dispatch(toggleOverlay(
              false
            ))
          }}
        >
          CLOSE
        </p>
        <Container classname="overlay-schedule-save-wrapper" maxWidth height="100%" column>
          {this.getSavedSchedules()}
        </Container>
        <Button
          action={()=>this.loadSchedule()}
          disabled={!this.state.selected}
          name={`Load schedule`}
        />
        <Confirm />
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    saved_schedules:store.venue.saved_schedules,
    loading:store.venue.loading
  }
}

export default connect(mapStateToProps)(OverlayVenueSchedulesSaved)
