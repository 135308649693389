import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux'

import { updateTrackEnergy } from 'store/actions/libraryAction'

import Icon from 'ui/Icon'
import Energy from 'ui/Energy'
import Select from 'ui/Select'

class TableCellEnergy extends Component {

  constructor(props){
    super(props)
    this.state = {
      isClicked:false,
      energy:null
    }
    this.energies = [
      {value:1,display:1},
      {value:2,display:2},
      {value:3,display:3},
      {value:4,display:4},
      {value:5,display:5},
      {value:6,display:6},
      {value:7,display:7},
      {value:8,display:8},
      {value:9,display:9},
      {value:10,display:10},
    ]
  }

  componentDidMount(){
    this.setState({
      energy:this.props.datum
    })
  }

  componentDidUpdate(prevProps){
    if (prevProps.datum !== this.props.datum) {
      this.setState({
        energy:this.props.datum
      })
    }
  }

  onClick(e,isClicked){
    const {
      disableAction
    } = this.props

    if(e.metaKey || e.shiftKey || disableAction){
      return
    }
    this.setState({
      isClicked:isClicked
    })
  }

  changeEnergy(data){
    this.setState({
      energy:data.event.target.value,
      isClicked:false
    })
    this.props.dispatch(updateTrackEnergy(data.data.id,data.event.target.value))
  }

  render(){
    const {
      classname,
      size,
      track
    } = this.props

    const {
      isClicked,
      energy
    } = this.state

    return (
      <td
        className={`table-cell table-cell-energy table-cell-${classname} table-cell-size-${size}`}
        onClick={(e)=>this.onClick(e,true)}
      >
        {
          isClicked ?
          <Fragment>
            <Select
              data={track}
              defaultOption="select"
              selected={energy}
              action={(data)=>this.changeEnergy(data)}
              options={this.energies}/>
            <Icon name="close-circle" action={()=>this.onClick(false)} stopPropagation/>
          </Fragment> :
          <Energy energy={energy}/>
        }
      </td>
    )
  }
}

export default connect()(TableCellEnergy)
