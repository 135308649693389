import React, {Component} from 'react'
import { connect } from 'react-redux'
import S3 from 'react-aws-s3'
import { v4 as uuidv4 } from 'uuid';

import Image from 'ui/Image'
import Button from 'ui/Button'
import Loader from 'ui/Loader'

import {
  getPlaylistImage,
  createPlaylistImage,
  deletePlaylistImage,
  imageUploading
} from 'store/actions/playlistAction'

class PlaylistImage extends Component {

  constructor(props){
    super(props)
    this.config = {
      bucketName: 'images.openearmusic.com',
      region: 'eu-west-1',
      accessKeyId: 'AKIARLNPHEHH24JQCE4O',
      secretAccessKey: '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',
      s3Url:'https://s3-eu-west-1.amazonaws.com/images.openearmusic.com'
    }
  }

  componentDidMount(){
    this.props.dispatch(getPlaylistImage(this.props.id))
  }

  uploadFile(e){
    this.props.dispatch(imageUploading())
    const ReactS3Client = new S3(this.config)
    const uuid = uuidv4()
    ReactS3Client
      .uploadFile(e.target.files[0], uuid)
      .then(data => {
        this.props.dispatch(createPlaylistImage({
          playlist_id:this.props.id,
          name:data.key
        }))
      })
      .catch(err => alert(err))
  }

  deleteImage(){
    this.props.dispatch(deletePlaylistImage(this.props.playlistImage.id))
  }

  render(){
    const image = this.props.playlistImage
    if(this.props.loading){
      return <Loader/>
    }
    if(image){
      return (
        <div>
          <Image name={image.name} width="500" height="500" alt={image.playlist_id} />
          <Button name="Delete" action={()=>this.deleteImage()}/>
        </div>
      )
    } else {
      return (
        <input type="file" name="file" onChange={(e) => this.uploadFile(e)}/>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    playlistImage:store.playlist.image,
    loading:store.playlist.loading
  }
}

export default connect(mapStateToProps)(PlaylistImage)
