import React from 'react'

import FormLabel from 'ui/FormLabel'

const FormInput = (props) => {
  const {
    type,
    name,
    value,
    change,
    label,
    classname,
    size,
    autocomplete,
    required,
    hasError,
    errorMessage,
    hasServerError,
    serverError,
    disabled,
    hideIfEmpty,
    editAction,
    hasErrorDisabled
  } = props

  const inputClasses = `input input-${classname} ${size ? size : ''} ${(hasError || hasServerError) && !disabled ? 'has-error' : ''} ${hasErrorDisabled && 'has-error'}`
  const wrapperClasses = `form-input-wrapper input-wrapper-${classname}`
  const labelClasses = `input-label input-label-${classname}`

  if (hideIfEmpty && value.length === 0) {
    return false
  }

  return (
    <div className={wrapperClasses}>
      <FormLabel label={label} className={labelClasses} required={required}/>
      <input
        type={type}
        name={name}
        className={inputClasses}
        value={value}
        onChange={(e)=>change(e)}
        autoComplete={autocomplete}
        disabled={disabled}
      />
      {hasServerError && !disabled && (
        <p className='form-input__error-message'>{serverError.message}</p>
      )}
      {hasError && errorMessage && !disabled && (
        <p className='form-input__error-message'>{errorMessage}</p>
      )}
      {
        editAction && (
          <div className="edit-action-wrapper">{editAction}</div>
        )
      }
      <span className="focus-border"></span>
    </div>
  )
}

export default FormInput
