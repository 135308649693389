import React from 'react'

import TableModel from 'models/TableModel'

const getColspan = (classname) => {
  let cols = 1;
  //if we have a play col
  if(TableModel[classname].play){
    cols++
  }
  if(TableModel[classname].checkbox){
    cols++
  }
  return TableModel[classname].headers.length + cols
}

const TableRowEmpty = (props) => {
  const {classname} = props
  return (
    <tr className={`table-row table-row-empty table-row-${classname}`}>
      <td colSpan={getColspan(classname)} className={`table-cell table-cell-empty table-cell-${classname}`}>
        No results
      </td>
    </tr>
  )
}

export default TableRowEmpty
