import React, {Component} from 'react'
import { connect } from 'react-redux'

import { deleteUser } from 'store/actions/userAction'

import Container from 'ui/Container'
import Button from 'ui/Button'

class OverlayUsers extends Component{

  // copyUser(){
  //   console.log('copy user')
  // }

  deleteUser(){
    this.props.dispatch(deleteUser(this.props.data.id))
  }

  render(){
    return (
      <Container classname="overlay-users" maxWidth height="100%" column>
        <h2>User Actions for {this.props.data.username}</h2>
        {/* <Button action={()=>this.copyUser()} name={`Copy user`}/> */}
        <Button action={()=>this.deleteUser()} name={`Delete user`}/>
      </Container>
    )
  }
}

export default connect()(OverlayUsers)