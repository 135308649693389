import React, {PureComponent, Fragment} from 'react'
import {connect} from "react-redux";

import {
  toggleConfirm
} from 'store/actions/confirmAction'

class Confirm extends PureComponent {
  render(){
    const {
      confirmActive,
      data
    } = this.props

    return (
      <Fragment>
        {confirmActive && (
          <div className='confirm-wrapper'>
            <div className='confirm'>
              <p>{data && data.question}</p>
              <div className='confirm__controls'>
                <button
                  type='button'
                  className='confirm__cancel-btn'
                  onClick={() =>{ this.props.dispatch(toggleConfirm(false)) }}
                >
                  Cancel
                </button>
                <button
                  type='button'
                  className='confirm__confirm-btn'
                  onClick={() => { data.action(); this.props.dispatch(toggleConfirm(false)) }}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    )
  }

}

function mapStateToProps(store){
  return {
    confirmActive:store.confirm.confirmActive,
    data: store.confirm.data
  }
}

export default connect(mapStateToProps)(Confirm)
