import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import queryString from 'qs'

import {
  getBusinesses,
  getBusinessesTypeahead,
  searchBusinesses,
  clearBusinesses,
  clearBusinessesTypeahead,
  createBusiness
} from 'store/actions/businessAction'

import { getRowLimit } from 'helpers/TableHelper'
import { parseQueryString } from 'helpers/StringHelper'

import { toggleOverlay } from 'store/actions/overlayAction'

import Loader from 'ui/Loader'
import Form from 'ui/Form'
import Tabs from 'ui/Tabs'
import Container from 'ui/Container'
import TableWrapper from 'ui/TableWrapper'
import TableTypeahead from 'ui/TableTypeahead'
import BusinessesTableActions from './businesses/BusinessesTableActions'

const classname = 'businesses'

class BusinessesContainer extends Component {

  constructor(props){
    super(props)
    this.state = {
      tab:'all',
      url:'businesses',
      searchTab:[],
      disableTypeahead:false
    }
  }

  componentDidMount(){
    const params = parseQueryString(this.props.location.search)

    this.getTabQuery()

    if(typeof params.tab === 'undefined' || params.tab === 'all'){
      // show all businesses
      this.props.dispatch(getBusinesses(getRowLimit(classname), 0, true))
    } else if(params.tab && params.tab === 'search' && params.term) {
      // standard search
      this.searchBusinesses(params.term)
      this.props.dispatch(searchBusinesses(getRowLimit(classname), 0, params.term))
    }

  }

  componentDidUpdate(prevProps, prevState){
    const {
      createdBusinessID,
      dispatch,
      error,
      sorter
    } = this.props

    const params = parseQueryString(this.state.tab)

    if(createdBusinessID && prevProps.createdBusinessID !== createdBusinessID){
      this.props.history.push(`/businesses/${createdBusinessID}`)
    }

    if (error && (prevProps.error !== error) && error.response.data.res && error.response.data.res.data) {
      const serverErrorScrollPos = document.getElementsByName(error.response.data.res.data)[0].offsetTop + 200
      document.getElementsByClassName('container-businesses')[0].scrollTo(0, serverErrorScrollPos)
    }

    if(prevState.tab !== this.state.tab){
      this.props.dispatch(clearBusinesses())
      if(typeof params.tab === 'undefined' || params.tab === 'all'){
        // show all businesses
        this.props.dispatch(getBusinesses(getRowLimit(classname), 0, true))
      } else if(params.tab && params.tab === 'search' && params.term) {
        // standard search
        console.log('standard search')
        this.props.dispatch(searchBusinesses(getRowLimit(classname), 0, params.term))
      }
    }

    //  handle forward and back browser button usage
    if ((this.props.location !== prevProps.location) && this.props.history.action === 'POP') {
      this.getTabQuery()
    }

    if (JSON.stringify(prevProps.sorter) !== JSON.stringify(sorter)) {
      const params = parseQueryString(this.state.tab)
      if(params.tab === 'search'){
        dispatch(
          searchBusinesses(
            getRowLimit(classname),
            0,
            params.term,
            sorter
          )
        )
      } else {
        dispatch(
          getBusinesses(
            getRowLimit(classname),
            0,
            true,
            sorter
          )
        )
      }
    }
  }

  getTabQuery(){
    const query = queryString.parse(this.props.location.search.slice(1))

    if(typeof query.tab !== 'undefined' && query.tab !== this.state.tab){
      this.setState({tab: query.tab})
    } else if (typeof query.tab === 'undefined') {
      // defines the default tab to loaded when no tab query is passed in the URL
      this.setState({tab: 'all'})
    }
  }

  selectTab(tab){
    this.setState({tab:tab},()=>{
      if(!tab.startsWith('tab=')) tab = 'tab=' + tab;
      this.props.history.push({
        search: `?${tab}`
      })
    })
  }

  closeTab(tab){
    const searchTabs = this.state.searchTab.filter(tb => tb !== tab)
    this.setState({
      searchTab:searchTabs,
      tab:'all',
      url:'businesses'
    },()=>{
      this.selectTab('all')
    })
  }

  clearBusinesses(){
    this.props.dispatch(clearBusinesses())
  }

  getBusinesses(limit,offset,sorter = null){
    const params = parseQueryString(this.state.tab)
    if(params.type === 'search'){
      // standard search
      this.props.dispatch(searchBusinesses(limit, offset, params.term,sorter))
    } else {
      // show all businesses
      this.props.dispatch(getBusinesses(limit,offset,false,sorter))
    }
  }

  moreActions(data){
    this.props.dispatch(toggleOverlay(true,data,'businesses'))
  }

  typeaheadBusinesses(value){
    this.props.dispatch(getBusinessesTypeahead(value))
  }

  clearTypeahead(){
    this.props.dispatch(clearBusinessesTypeahead())
  }

  searchBusinesses(term){
    const link = `tab=search&term=${encodeURIComponent(term)}`
    // If the user performs a search, disable the typeahead dropdown
    this.setState({
      searchTab:this.state.searchTab.concat(
        {name:`Search: ${term}`,link:link,dismiss:true}
      ),
      tab:link,
      url:`businesses/search/${term}`,
      disableTypeahead: true
    },()=>{
      this.props.history.push({
        search: `?${link}`
      })
    })
  }

  createBusiness(data){
    this.props.dispatch(createBusiness(data))
  }

  getComponent(){
    const {
      businesses,
      count,
      loading,
      typeahead,
      typeaheadLoading,
      storedBusinessData,
      error
    } = this.props

    const {
      message,
      tab
    } = this.state

    switch(tab){
      default:
      case 'all':
        return (
          <Container height="100%" classname={`${classname} container-tabview`} column>
            <TableWrapper
              controls
              classname={classname}
              data={businesses}
              count={count}
              clearData={()=>this.clearBusinesses()}
              getData={(limit,offset,sorter)=>this.getBusinesses(limit,offset,sorter)}
              loading={loading}
              rowActions={<BusinessesTableActions
                more={(data)=>this.moreActions(data)}
                classname={classname}/>
              }
              tableAction={<TableTypeahead
                classname={classname}
                typeaheadSearch={(value)=>this.typeaheadBusinesses(value)}
                typeaheadLoading={typeaheadLoading}
                search={(value) => this.searchBusinesses(value)}
                clear={()=>this.clearTypeahead()}
                data={typeahead}/>
              }
            />
          </Container>
        )
      case 'create':
        return (
          <Container height="100%" classname={`${classname} container-tabview`} maxHeight column>
            {loading ? (
              <Loader/>
            ) : (
              <Form
                classname={classname}
                submit={(data)=>this.createBusiness(data)}
                messages={message}
                data={storedBusinessData}
                serverError={error && error.response.data.res}
              />
            )}
          </Container>
        )
    }
  }

  render(){
    return (
      <Container classname={`${classname}-wrapper`} height="100%" column maxWidth>
        <Container classname="tabs">
          <Tabs
            match={this.props.match}
            classname={classname}
            select={(tab)=>this.selectTab(tab)}
            active={this.state.tab}
            add={this.state.searchTab}
            closeTab={(tab)=>this.closeTab(tab)}
          />
        </Container>
        {this.getComponent()}
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    businesses: store.businesses.data,
    typeahead: store.businesses.typeahead,
    typeaheadLoading: store.businesses.typeaheadLoading,
    count: store.businesses.count,
    createdBusinessID: store.businesses.createdBusinessID,
    storedBusinessData: store.businesses.storedBusinessData,
    sorter: store.table.sorter,
    error: store.businesses.error,
    loading: store.businesses.loading
  }
}

export default withRouter(connect(mapStateToProps)(BusinessesContainer))
