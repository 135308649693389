import { isSplit } from 'helpers/PlaylistHelper'

const initialState = {
  data:[],
  overlaySearchData:[],
  dashboard:{
    picks:[],
    featured:[],
    topical:[]
  },
  importerLoading: false,
  importerPlaylists: [],
  importerPlaylist: {},
  importerMatches: [],
  importerMatchesLoaded: [],
  importerMatchPerformed: false,
  importerFinalTracklist: [],
  typeahead:[],
  parentTypeahead:[],
  typeaheadLoading: false,
  count:null,
  loading:false,
  overlaySearchLoading:false,
  loaded:false,
  error:null
}
export default function reducer(state = initialState, action){
  switch (action.type){
    //playlists
    case 'CLEAR_PLAYLISTS':{
      state = {...state,data:[],overlaySearchData:[]}
      break
    }
    case 'SEARCH_PLAYLISTS_PENDING':
    case 'FETCH_PLAYLISTS_PENDING':{
      state = {
        ...state,
        loading:true,
        typeahead: [],
      }
      break
    }
    case 'SEARCH_PLAYLISTS_REJECTED':
    case 'FETCH_PLAYLISTS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'SEARCH_PLAYLISTS_FULFILLED':
    case 'FETCH_PLAYLISTS_FULFILLED':{
      // if the request has been cancelled by another request,
      // set the loading state to true
      if (action.payload === 'cancelled') {
        state = {...state,loading:true}
        break
      }

      const playlists = action.payload.data.playlists.map((playlist)=>{
        const data = {
          id:playlist.id,
          name:playlist.name,
          pinned_id:playlist.isPinned ? playlist.pinned_id : null,
          venues_count:playlist.venues_count,
          type:playlist.playlist_types.type,
          playlist_type:playlist.playlist_types.type,
          children_count:playlist.children_count,
          tracks_count:playlist.tracks_count,
          created_at:playlist.created_at,
          isTrackAssigned:playlist.isTrackAssigned,
          designation:playlist.trackDesignation,
          split_id:playlist.split_id,
          isSplit:isSplit(playlist.playlist_types.id)
        }
        return data
      })
      state =  {
        ...state,
        loading:false,
        loaded:true,
        data:state.data.concat(playlists),
        typeahead: [],
        count:action.payload.data.count
      }
      break
    }
    case 'ADD_PLAYLIST_TRACK_LIBRARY_PENDING':
    case 'ADD_TRACKS_TO_PLAYLIST_PENDING':{
      const playlists = state.data.map((playlist)=>{
        playlist.isTrackAssigned = action.meta === playlist.id ? true : playlist.isTrackAssigned
        return playlist
      })
      const overlaySearchData = state.overlaySearchData.map((playlist)=>{
        playlist.isTrackAssigned = action.meta === playlist.id ? true : playlist.isTrackAssigned
        return playlist
      })
      state =  {
        ...state,
        loading:false,
        loaded:true,
        data:playlists,
        overlaySearchData:overlaySearchData
      }
      break
    }
    case 'ADD_PLAYLIST_TRACK_LIBRARY_FULFILLED':
    case 'ADD_TRACKS_TO_PLAYLIST_FULFILLED':{
      //set split id and designation
      const playlists = state.data.map((playlist) => {
        if(playlist.id === action.meta){
          playlist = {
            ...playlist,
            split_id:action.payload.data.res.data,
            designation:'A'
          }
        }
        return playlist
      })
      const overlaySearchData = state.overlaySearchData.map((playlist) => {
        if(playlist.id === action.meta){
          playlist = {
            ...playlist,
            split_id:action.payload.data.res.data,
            designation:'A'
          }
        }
        return playlist
      })
      state =  {
        ...state,
        data:playlists,
        overlaySearchData:overlaySearchData
      }
      break
    }
    case 'ADD_PLAYLIST_TRACK_LIBRARY_SEARCH_PENDING':{
      const playlists = state.typeahead.map((playlist)=>{
        playlist.isTrackAssigned = action.meta === playlist.id ? true : playlist.isTrackAssigned
        return playlist
      })
      const overlaySearchData = state.overlaySearchData.map((playlist)=>{
        playlist.isTrackAssigned = action.meta === playlist.id ? true : playlist.isTrackAssigned
        return playlist
      })
      state =  {
        ...state,
        loading:false,
        loaded:true,
        data:playlists,
        overlaySearchData:overlaySearchData
      }
      break
    }
    case 'ADD_PLAYLIST_TRACK_LIBRARY_SEARCH_FULFILLED':{
      //set split id and designation
      const playlists = state.typeahead.map((playlist) => {
        if(playlist.id === action.meta){
          playlist = {
            ...playlist,
            split_id:action.payload.data.res.data,
            designation:'A'
          }
        }
        return playlist
      })
      const overlaySearchData = state.overlaySearchData.map((playlist) => {
        if(playlist.id === action.meta){
          playlist = {
            ...playlist,
            split_id:action.payload.data.res.data,
            designation:'A'
          }
        }
        return playlist
      })
      state =  {
        ...state,
        data:playlists,
        overlaySearchData:overlaySearchData
      }
      break
    }
    case 'DELETE_PLAYLIST_TRACK_LIBRARY_PENDING':{
      const playlists = state.data.map((playlist)=>{
        playlist.isTrackAssigned = action.meta === playlist.id ? false : playlist.isTrackAssigned
        return playlist
      })
      const overlaySearchData = state.overlaySearchData.map((playlist)=>{
        playlist.isTrackAssigned = action.meta === playlist.id ? false : playlist.isTrackAssigned
        return playlist
      })
      state =  {
        ...state,
        loading:false,
        loaded:true,
        data:playlists,
        overlaySearchData:overlaySearchData
      }
      break
    }
    case 'DELETE_PLAYLIST_TRACK_LIBRARY_SEARCH_PENDING':{
      const playlists = state.typeahead.map((playlist)=>{
        playlist.isTrackAssigned = action.meta === playlist.id ? false : playlist.isTrackAssigned
        return playlist
      })
      state =  {
        ...state,
        loading:false,
        loaded:true,
        typeahead:playlists
      }
      break
    }
    case 'FETCH_PLAYLISTS_DASHBOARD_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_PLAYLISTS_DASHBOARD_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_PLAYLISTS_DASHBOARD_FULFILLED':{
      const picks  = action.payload.data.picks.map((playlist)=>{
        const data = {
          id:playlist.id,
          name:playlist.name
        }
        return data
      })
      const featured = action.payload.data.featured.map((playlist)=>{
        const data = {
          id:playlist.id,
          name:playlist.name
        }
        return data
      })
      const topical = action.payload.data.topical.map((playlist)=>{
        const data = {
          id:playlist.id,
          name:playlist.name
        }
        return data
      })
      state =  {
        ...state,
        loading:false,
        loaded:true,
        dashboard:{
          ...state.dashboard,
          picks:state.dashboard.picks.concat(picks),
          featured:state.dashboard.featured.concat(featured),
          topical:state.dashboard.topical.concat(topical)
        },
        count:action.payload.data.count
      }
      break
    }
    case 'FETCH_PLAYLIST_USER_QUICK_PENDING':{
      state = {...state,loading:true,data:[]}
      break
    }
    case 'FETCH_PLAYLIST_USER_QUICK_FULFILLED':{
      state =  {
        ...state,
        loading:false,
        loaded:true,
        data:state.data.concat(action.payload.data.playlists),
        count:action.payload.data.count
      }
      break
    }
    case 'PLAYLISTS_TYPEAHEAD_ALL_PENDING':{
      state = {
        ...state,
        typeahead:[],
        typeaheadLoading: true
      }
      break
    }
    case 'PLAYLISTS_TYPEAHEAD_ALL_FULFILLED':{
      // if the request has been cancelled by another request,
      // set the typeahead to be empty
      if (action.payload === 'cancelled') {
        state = {
          ...state,
          typeaheadLoading:false,
          typeahead: []
        }
        break
      }

      const playlists = action.payload.data.playlists.map((playlist)=>{
        const data = {
          id:playlist.id,
          name:`${playlist.name} -- ${playlist.owner ? playlist.owner.username : 'NO USER'}`,
          tracksCount:playlist.tracks_count,
          createdAt:playlist.created_at
        }
        return data
      })

      state = {
        ...state,
        typeahead:playlists,
        typeaheadLoading: false
      }
      break
    }
    case 'OVERLAY_FITNESS_PLAYLISTS_TYPEAHEAD_ALL_PENDING':{
      state = {
        ...state,
        typeahead:[],
        typeaheadLoading: true
      }
      break
    }
    case 'OVERLAY_FITNESS_PLAYLISTS_TYPEAHEAD_ALL_REJECTED':{
      state = {
        ...state,
        error: action.payload,
        typeahead:[],
        typeaheadLoading: false
      }
      break
    }
    case 'OVERLAY_FITNESS_PLAYLISTS_TYPEAHEAD_ALL_FULFILLED':{

      const playlists = action.payload.data.playlists.map((playlist)=>{
        const data = {
          id: playlist.id,
          name: playlist.name
        }
        return data
      })

      state = {
        ...state,
        typeahead:playlists,
        typeaheadLoading: false
      }
      break
    }
    case 'OVERLAY_PARENT_PLAYLISTS_TYPEAHEAD_PENDING':{
      state = {
        ...state,
        parentTypeahead:[],
        typeaheadLoading: true
      }
      break
    }
    case 'OVERLAY_PARENT_PLAYLISTS_TYPEAHEAD_REJECTED':{
      state = {
        ...state,
        error: action.payload,
        parentTypeahead:[],
        typeaheadLoading: false
      }
      break
    }
    case 'OVERLAY_PARENT_PLAYLISTS_TYPEAHEAD_FULFILLED':{

      const playlists = action.payload.data.playlists.map((playlist)=>{
        const data = {
          id: playlist.id,
          name: playlist.name,
          type_id:playlist.type_id
        }
        return data
      })

      state = {
        ...state,
        parentTypeahead:playlists,
        typeaheadLoading: false
      }
      break
    }
    case 'PLAYLISTS_OVERLAY_SEARCH_PENDING':{
      state = {
        ...state,
        typeahead:[],
        overlaySearchData:[],
        overlaySearchLoading: true
      }
      break
    }
    case 'PLAYLISTS_OVERLAY_SEARCH_REJECTED':{
      state = {
        ...state,
        overlaySearchLoading:false,
        error:action.payload
      }
      break
    }
    case 'PLAYLISTS_OVERLAY_SEARCH_FULFILLED':{

      const playlists = action.payload.data.playlists.map((playlist)=>{
        const data = {
          id:playlist.id,
          name:`${playlist.name} -- ${playlist.owner ? playlist.owner.username : 'NO USER'}`,
          tracksCount:playlist.tracks_count,
          isTrackAssigned:playlist.isTrackAssigned,
          designation:playlist.trackDesignation,
          split_id:playlist.split_id,
          isSplit:isSplit(playlist.type_id),
          createdAt:playlist.created_at
        }
        return data
      })

      state = {
        ...state,
        typeahead:playlists,
        overlaySearchData:playlists,
        overlaySearchLoading: false
      }
      break
    }
    //clear typeahead
    //janky?
    case 'ADD_PLAYLIST_TO_VENUE_FULFILLED':
    case 'FETCH_VENUE_SCHEDULE_FULFILLED':
    case 'FETCH_VENUE_PLAYLISTS_FULFILLED':
    case 'CLEAR_PLAYLIST_TYPEAHEAD':{
      state = {
        ...state,
        typeahead:[]
      }
      break
    }
    case 'PIN_PLAYLIST_FULFILLED':{
      const playlists = state.data.map(playlist => {
        if(playlist.id === action.meta){
          playlist.pinned_id = action.meta
        }
        return playlist
      })
      state = {
        ...state,
        data:playlists
      }
      break
    }
    case 'UNPIN_PLAYLIST_FULFILLED':{
      state = {
        ...state,
        data:state.data.filter(pl => pl.id !== action.meta)
      }
      break
    }
    case 'PUT_UNSUBSCRIBE_PLAYLIST_FULFILLED':{
      const playlists = state.data.filter(playlist => playlist.id !== action.meta)
      state = {...state,data:playlists}
      break
    }
    case 'FETCH_IMPORTER_PLAYLISTS_PENDING':{
      state = {
        ...state,
        importerPlaylists: [],
        importerLoading: true
      }
      break
    }
    case 'FETCH_IMPORTER_PLAYLISTS_REJECTED':{
      state = {
        ...state,
        importerLoading: false
      }
      break
    }
    case 'FETCH_IMPORTER_PLAYLISTS_FULFILLED':{
      const playlists = action.payload.data.playlists
      const playlistsData = playlists.map((playlist)=>{
        const data = {
          count: playlist.playlist.tracks_count,
          createdAt: playlist.created_at.standard,
          id: playlist.id,
          name: playlist.playlist.name,
          requestedBy: playlist.user ? (playlist.user.business.length > 0 ? playlist.user.business[0].name : 'NOT SET') : 'NOT SET',
        }
        return data
      })
      state = {
        ...state,
        importerPlaylists: playlistsData,
        importerLoading: false
      }
      break
    }
    case 'FETCH_IMPORTER_PLAYLIST_PENDING':{
      state = {
        ...state,
        importerPlaylist: [],
        importerMatches: [],
        importerMatchPerformed: false,
        importerMatchesLoaded: [],
        importerFinalTracklist: [],
        importerLoading: true
      }
      break
    }
    case 'FETCH_IMPORTER_PLAYLIST_REJECTED':{
      state = {
        ...state,
        importerLoading: false
      }
      break
    }
    case 'FETCH_IMPORTER_PLAYLIST_FULFILLED':{
      const data = {
        createdAt: action.payload.data.playlist.created_at,
        id: action.payload.data.playlist.id,
        tracks: action.payload.data.playlist.tracks,
        name: action.payload.data.playlist.name
      }
      state = {
        ...state,
        importerPlaylist: data,
        importerLoading: false
      }
      break
    }
    case 'DELETE_IMPORTER_PLAYLIST_PENDING':{
      state = {
        ...state,
        importerLoading: true
      }
      break
    }
    case 'DELETE_IMPORTER_PLAYLIST_REJECTED':{
      state = {
        ...state,
        importerLoading: false,
        error: action.payload
      }
      break
    }
    case 'DELETE_IMPORTER_PLAYLIST_FULFILLED':{
      const id = action.payload.data.id
      const playlistsData = state.importerPlaylists.filter(playlist => playlist.id !== parseInt(id))

      state = {
        ...state,
        importerPlaylists: playlistsData,
        importerLoading: false
      }
      break
    }
    case 'FETCH_IMPORTER_MATCHES_PENDING':{
      state = {
        ...state
      }
      break
    }
    case 'FETCH_IMPORTER_MATCHES_REJECTED':{
      state = {
        ...state,
        importerMatchPerformed: false
      }
      break
    }
    case 'FETCH_IMPORTER_MATCHES_FULFILLED':{
      // add the spotifyID of the searched track to each track returned
      const importerMatchesData = {
        ...action.payload.data,
        tracks: action.payload.data.tracks ? action.payload.data.tracks.map((track) => {
          return ({
            ...track,
            spotifyID: action.payload.data.spotifyID
          })
        }) : []
      }

      state = {
        ...state,
        importerMatches: state.importerMatches.concat(importerMatchesData),
        importerMatchesLoaded: state.importerMatchesLoaded.concat(action.meta.spotifyID),
        importerMatchPerformed: true
      }
      break
    }
    case 'ADD_TO_FINAL_TRACKLIST':{
      state = {
        ...state,
        importerFinalTracklist: state.importerFinalTracklist.concat(action.meta)
      }
      break
    }
    case 'REMOVE_FROM_FINAL_TRACKLIST':{
      state = {
        ...state,
        importerFinalTracklist: state.importerFinalTracklist.filter(track => track !== action.meta)
      }
      break
    }
    case 'TRACK_UPLOAD_RESET':{
      state = {
        ...state,
        importerMatches: [],
        importerMatchesLoaded: [],
        importerMatchPerformed: false,
        importerFinalTracklist: [],
        error:null
      }
      break
    }
    case 'DELETE_PLAYLIST_FULFILLED':{
      state = {
        ...state,
        data:state.data.filter(playlist => playlist.id !== action.meta)
      }
      break
    }
    case 'LOGOUT':{
      state = {
        data:[],
        importerPlaylists: [],
        importerPlaylist: {},
        importerMatches: [],
        importerMatchesLoaded: [],
        importerMatchPerformed: false,
        importerFinalTracklist: [],
        count:null,
        loading:false,
        loaded:false,
        error:null
      }
      break
    }
    default: // no default case
  }
  return state
}
