const initialState = {
  sorter: {}
}
export default function reducer(state = initialState, action){
  switch (action.type){
    case 'SET_TABLE_SORTER':{
      state = {
        ...state,
        sorter: {...action.payload}
      }
      break
    }
    default: // no default case
  }
  return state
}
