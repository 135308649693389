import Icon from "./Icon"
import Loader from "./Loader"

const ButtonSwitch = ({
  action,
  active,
  name,
  type,
  size,
  main
}) => {

  const classes = `button button-switch ${active && 'button-switch-active'} button-${type} button-${size} ${main ? 'button-main' : ''}`

  return (
    <button 
      className={classes}
      onClick={()=>action()}>
        <div className="button-name">{name}</div> <Icon name="checkmark-circle"/>
    </button>
  )
}

export default ButtonSwitch