import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  getBusinessUser,
  updateBusinessUser
 } from 'store/actions/businessAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Form from 'ui/Form'

const classname = 'businessUser'

class BusinessUser extends Component {
  constructor(props){
    super(props)
    this.state = {
      displayName:null
    }
  }

  componentDidMount(){
    this.props.dispatch(getBusinessUser(this.props.id))
  }


  onSubmit(form){
    this.props.dispatch(updateBusinessUser(
      this.props.id,
      form
    ))
  }

  render(){
    const {
      user,
      loading,
      error
    } = this.props

    if(loading){
      return <Loader/>
    } else {
      return (
        <Container classname={classname} height="100%">
          <Form
            classname={classname}
            submit={(form)=>this.onSubmit(form)}
            messages={this.state.message}
            data={user}
            serverError={error && error.response.data.res}
            scroll
          />
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    business:store.business,
    user:store.business.user,
    loading:store.business.loading,
    error:store.business.error
  }
}

export default connect(mapStateToProps)(BusinessUser)
