import axios from 'axios'
const CancelToken = axios.CancelToken
let cancel

export function getBusinesses(limit, offset, initialLoad, sort = null){
  let headers = {
    'Limit':limit,
    'Offset':offset
  }
  if(sort && sort.direction){
    headers['Sort'] = true
    headers['Field'] = sort.field
    headers['Direction'] = sort.direction
  }
  return (dispatch) => {
    return dispatch({
      type:'FETCH_BUSINESSES',
      payload:axios.get('businesses',{headers:headers}),
      meta: initialLoad
    })
  }
}

export function clearBusinesses(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_BUSINESSES',
      payload:null
    })
  }
}

export function clearBusinessesTypeahead(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_BUSINESSES_TYPEAHEAD',
      payload:null
    })
  }
}

export function searchBusinesses(limit,offset,term,sort = null){
  let headers = {
    'Limit':limit,
    'Offset':offset
  }
  if(sort && sort.direction){
    headers['Sort'] = true
    headers['Field'] = sort.field
    headers['Direction'] = sort.direction
  }

  const searchTerm = encodeURIComponent(term.replace('/', ' '))

  return (dispatch) => {
    cancel && cancel('FETCH_BUSINESSES_TYPEAHEAD request cancelled by SEARCH_BUSINESSES')
    return dispatch({
      type:'SEARCH_BUSINESSES',
      payload:axios.get(`businesses/search/${searchTerm}`, {headers:headers})
    })
  }
}

export function getBusinessesTypeahead(term){
  const typeaheadTerm = encodeURIComponent(term.replace('/', ' '))

  return (dispatch) => {
    cancel && cancel('FETCH_BUSINESSES_TYPEAHEAD request cancelled')

    const request = axios.get(`businesses/typeahead/${typeaheadTerm}`,
      {cancelToken: new CancelToken(function executor(c) {
        cancel = c
      })
    }).catch((cancel) => {
      console.log(cancel)
    })

    return dispatch({
      type:'FETCH_BUSINESSES_TYPEAHEAD',
      payload:request
    })
  }
}

export function clearBusiness(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_BUSINESS',
      payload:null
    })
  }
}

export function getBusinessDetails(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_BUSINESS_DETAILS',
      payload:axios.get(`business/details/${id}`)
    })
  }
}

export function updateBusinessDetails(id,data){
  const businessData = {
    name: data.name,
    contact_name: data.contactName,
    contact_number: data.contactNumber,
    contact_email: data.contactEmail,
    music_lead: data.musicLead,
    account_lead: data.accountLead,
    sector: data.sector,
    general_info: data.generalInfo,
    url: data.url || ''
  }

  return (dispatch) => {
    return dispatch({
      type:'UPDATE_BUSINESS_DETAILS',
      payload:axios.put(`business/details/${id}`, businessData),
      meta: businessData
    })
  }
}

export function getBusinessUser(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_BUSINESS_USER',
      payload:axios.get(`business/user/${id}`)
    })
  }
}

export function updateBusinessUser(id,data){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_BUSINESS_USER',
      payload:axios.put(`business/user/${id}`,data),
      meta:data
    })
  }
}

export function getBusinessVenues(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_BUSINESS_VENUES',
      payload:axios.get(`business/venues/${id}`)
    })
  }
}

export function getBusinessGenreBreakdown(id){
    return (dispatch) => {
      return dispatch({
      type:'FETCH_BUSINESS_GENRES_BREAKDOWN',
      payload:axios.get(`report/business/genres/${id}`)
    })
  }
}

export function getBusinessTracksBreakdown(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_BUSINESS_TRACKS_BREAKDOWN',
      payload:axios.get(`report/business/tracks/${id}`)
    })
  }
}

export function bloop(id){
  return (dispatch) => {
    return dispatch({
      type:'BLOOP',
      payload:axios.get(`report/playlist/tracks/${id}`)
    })
  }
}

export function getBusinessLibrary(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_BUSINESS_LIBRARY',
      payload:axios.get(`report/library/count/${id}`)
    })
  }
}

export function getBusinessPlaylists(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_BUSINESS_PLAYLISTS',
      payload:axios.get(`business/playlists/${id}`)
    })
  }
}

export function addPlaylistToBusiness(data,playlist){
  return (dispatch) => {
    return dispatch({
      type:'ADD_PLAYLIST_TO_BUSINESS',
      payload:axios.post(`business/playlist/add`,data),
      meta:playlist
    })
  }
}

export function removePlaylistFromBusiness(businessID,playlist){
  return (dispatch) => {
    return dispatch({
      type:'REMOVE_PLAYLIST_FROM_BUSINESS',
      payload:axios.delete(`business/playlist/remove/${businessID}/${playlist.id}`),
      meta:playlist
    })
  }
}

export function createBusiness(data){
  const businessData = {
    business_name: data.businessName,
    contact_name: data.contactName,
    contact_number: data.contactNumber,
    contact_email: data.contactEmail,
    url: data.url || '',
    facebook_url: data.facebookUrl || '',
    setupComplete: data.setupComplete || '',
    username: data.username,
    password: data.password,
    confirm_password: data.confirmPassword,
  }

  const storedBusinessData = data
  delete storedBusinessData['password']
  delete storedBusinessData['confirmPassword']

  return (dispatch) => {
    return dispatch({
      type:'CREATE_BUSINESS',
      payload:axios.post(`business/create`, businessData),
      meta: storedBusinessData
    })
  }
}

export function deleteBusiness(id){
  return (dispatch) => {
    return dispatch({
      type:'DELETE_BUSINESS',
      payload:axios.delete(`business/${id}`),
      meta:id
    })
  }
}

export function deleteInterleavesFromBusiness(id){
  return (dispatch) => {
    return dispatch({
      type: 'DELETE_INTERLEAVES_FROM_BUSINESS',
      payload: axios.delete(`business/interleaves/delete/${id}`),
      meta: id
    })
  }
}
export function getBusinessSettings(id){
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_BUSINESS_SETTINGS',
      payload: axios.get(`business/settings/${id}`)
    })
  }
}
export function updateBusinessSettings(id,data){
  return (dispatch) => {
    return dispatch({
      type: 'UPDATE_BUSINESS_SETTINGS',
      payload: axios.put(`business/settings/${id}`,data)
    })
  }
}

export function getBusinessNotes(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_NOTES',
      payload: axios.get(`business/notes/${id}`)
    })
  }
}

export function addBusinessNote(id, data){
  const formData = {
    content: data.content,
    type_id: data.type
  }

  return (dispatch) => {
    return dispatch({
      type:'CREATE_NOTE',
      payload: axios.post(`business/notes/create/${id}`,formData)
    })
  }
}

export function deleteBusinessNote(note){
  return (dispatch) => {
    return dispatch({
      type:'DELETE_NOTE',
      payload: axios.delete(`business/notes/${note.id}`),
      meta: note
    })
  }
}

export function getBusinessAccounts(id){
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_BUSINESS_ACCOUNTS',
      payload: axios.get(`business/accounts/${id}`)
    })
  }
}

export function updateBusinessAccounts(id, data){
  return (dispatch) => {
    return dispatch({
      type: 'UPDATE_BUSINESS_ACCOUNTS',
      payload: axios.post(`business/accounts/${id}`, data)
    })
  }
}

export function getBusinessMusic(id){
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_BUSINESS_MUSIC',
      payload: axios.get(`business/music/${id}`)
    })
  }
}

export function updateBusinessMusic(id, data){
  return (dispatch) => {
    return dispatch({
      type: 'UPDATE_BUSINESS_MUSIC',
      payload: axios.post(`business/music/${id}`, data)
    })
  }
}

export function getBusinessContacts(id){
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_BUSINESS_CONTACTS',
      payload: axios.get(`business/contacts/${id}`)
    })
  }
}

export function addContactToBusiness(businessID, contactID){
  return (dispatch) => {
    return dispatch({
      type: 'ADD_CONTACT_TO_BUSINESS',
      payload: axios.put(`/business/contact/${businessID}/${contactID}`),
      meta: {
        businessID: businessID,
        contactID: contactID
      }
    })
  }
}

export function removeContactFromBusiness(businessID, contactID){
  return (dispatch) => {
    return dispatch({
      type: 'REMOVE_CONTACT_FROM_BUSINESS',
      payload: axios.delete(`/business/contact/${businessID}/${contactID}`)
    })
  }
}

export function clearOverlayContacts(){
  return (dispatch) => {
    return dispatch({
      type: 'CLEAR_OVERLAY_CONTACTS',
      payload: null
    })
  }
}

