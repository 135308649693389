import React, {Component} from 'react'

import TableCell from 'ui/TableCell'

import TableModel from 'models/TableModel'

class OverlayFitnessVenuesTableRow extends Component {

  constructor(props){
    super(props)
    this.ref = React.createRef()
  }

  render(){
    const {
      data,
      classname,
      size,
      index
    } = this.props

    let cells = TableModel[classname]['headers'].map((header,index)=>{
      return (
        <TableCell
          key={index}
          datum={data[header.field]}
          classname={classname}
          size={header.size}
        />
      )
    })
    return (
      <tr
        key={index}
        className='table-row'
      >
          {cells}
          <td className={`table-cell table-cell-actions table-cell-actions-${classname} table-cell-actions-${size}`}>
            {this.props.actionsComp}
          </td>
      </tr>
    )
  }
}

export default OverlayFitnessVenuesTableRow
