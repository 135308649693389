import React, {Component} from 'react'
import TableModel from 'models/TableModel'
import Icon from 'ui/Icon'

class TableHead extends Component{

  constructor(props){
    super(props)
    this.state = {
      headers:[],
      selectAll:false
    }
  }

  componentDidMount(){
    this.createSorter()
  }

  createSorter(){
    const {
      sorter
    } = this.props

    const headers = TableModel[this.props.classname]['headers'].map((head)=>{
      const isActive = sorter && sorter.field === head.field

      return {
        field: head.field,
        direction: isActive ? sorter.direction : null,
        arrow: isActive ? sorter.arrow : 'arrow-round-forward'
      }
    })

    this.setState({
      headers:headers
    })
  }

  selectAll(){
    this.setState({
      selectAll:!this.state.selectAll
    },()=>{
      this.props.selectAll(this.state.selectAll)
    })
  }

  sortTable(hd){
    //find head in state
    const headers = this.state.headers.map((head) => {
      if(hd.field === head.field){
        if(!head.direction){
          head.direction = 'asc'
          head.arrow = 'arrow-round-up'
        } else if(head.direction === 'asc'){
          head.direction = 'desc'
          head.arrow = 'arrow-round-down'
        } else {
          head.direction = null
          head.arrow = 'arrow-round-forward'
        }
        this.props.sortTable(head)
      } else {
        //reset other headers
        head.direction = null
        head.arrow = 'arrow-round-forward'
      }
      return head
    })
    this.setState({headers:headers})
  }

  render(){
    const { classname } = this.props

    let headers = TableModel[classname]['headers'].map((head,index)=>{
      if(head.sortable){
        const arrow = this.state.headers[index] ? this.state.headers[index].arrow : ''
        const active = this.state.headers[index] && this.state.headers[index].direction ? true : false
        return (
          <th
            className={`table-head-cell table-head-cell-${classname} table-head-cell-${head.size} table-head-cell-sortable ${active ? 'active' : ''}`}
            onClick={()=>this.sortTable(head)}
            key={index}>
              {head.title === 'Play' ? '' : head.title}<Icon name={arrow}/>
          </th>
        )
      } else {
        return (
          <th
            className={`table-head-cell table-head-cell-${classname} table-head-cell-${head.title === 'Play' ? 'play' : head.size}`}
            key={index}>
              {head.title === 'Play' ? '' : head.title}
          </th>
        )
      }
    }

    )
    if(TableModel[classname].play){
      headers.unshift(
        <th key="play" className={`table-head-cell table-head-cell-play table-head-cell-${classname}`}>

        </th>
      )
    }
    if(TableModel[classname].checkbox){
      headers.unshift(
        <th key="checkbox" className={`table-head-cell table-head-cell-checkbox table-head-cell-${classname}`}>
          {
            TableModel[classname].selectAll &&
            <input
              value={'selectAll'}
              name={'selectAll'}
              type="checkbox"
              onChange={(e)=>this.selectAll(e)}
              checked={this.state.selectAll}/>
          }
        </th>
      )
    }
    return (
      <thead className={`table-head table-head-${classname}`}>
        <tr className={`table-head-row table-head-row-${classname} table-head-row-${this.props.loading ? 'loading' : ''}`}>
          {headers}
          <th className={`table-head-cell table-head-cell-${classname} table-head-cell-actions`}>
            Actions
          </th>
        </tr>
      </thead>
    )
  }
}

export default TableHead
