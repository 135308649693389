import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  createVenueReplacementSD
} from 'store/actions/venueAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Form from 'ui/Form'

const classname = 'overlayVenueHardwareReplacementSD';

class OverlayVenueHardwareReplacementSD extends Component{

  onSubmit(form){
    const {
      data,
      dispatch
    } = this.props

    dispatch(createVenueReplacementSD(data.venueID, form))
  }

  render(){
    const {
      data,
      overlayVenueHardwareLoading
    } = this.props

    if (overlayVenueHardwareLoading) {
      return <Loader/>
    } else {
      return (
        <Container classname="overlay-venue-saved-calendar-create" maxWidth height="100%" column>
          <Form
            classname={classname}
            data={data}
            submit={(form)=>this.onSubmit(form)}
            // scroll
            isOverlay
          />
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    overlayVenueHardwareLoading: store.venue.overlayVenueHardwareLoading,
    error: store.venue.error
  }
}

export default connect(mapStateToProps)(OverlayVenueHardwareReplacementSD)

