import axios from 'axios'
const CancelToken = axios.CancelToken
let cancel

export function getContacts(limit,offset, initialLoad, sort = null) {
  const headers = {
    'Limit': limit,
    'Offset': offset
  }

  if(sort && sort.direction){
    headers['Sort'] = true
    headers['Field'] = sort.field
    headers['Direction'] = sort.direction
  }

  return (dispatch) => {
    return dispatch({
      type: 'FETCH_CONTACTS',
      payload: axios.get(`/contacts/all`, {headers:headers})
    })
  }
}

export function getContactsFields() {
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_CONTACTS_FIELDS',
      payload: axios.get(`/contacts/fields`)
    })
  }
}

export function createContact(data){
  const contactData = {
    name: data.name,
    email: data.email,
    phone_number: data.phoneNumber,
    status_id: parseInt(data.statusID),
    position_id: parseInt(data.positionID),
    job_title: data.jobTitle,
    notes: data.notes,
    business_id: data.business_id,
    venue_id: data.venue_id
  }

  return (dispatch) => {
    return dispatch({
      type: 'CREATE_CONTACT',
      payload: axios.post('/contacts/create', contactData)
    })
  }
}

export function updateContact(data){
  const contactData = {
    id: data.id,
    name: data.name,
    email: data.email,
    phone_number: data.phoneNumber,
    status_id: parseInt(data.statusID),
    position_id: parseInt(data.positionID),
    job_title: data.jobTitle,
    notes: data.notes,
    business_id: data.business_id,
    venue_id: data.venue_id
  }

  return (dispatch) => {
    return dispatch({
      type: 'UPDATE_CONTACT',
      payload: axios.post('/contacts/update', contactData)
    })
  }
}

export function deleteContact(id){
  return (dispatch) => {
    return dispatch({
      type: 'DELETE_CONTACT',
      payload: axios.delete(`/contacts/${id}`)
    })
  }
}

export function searchContacts(limit,offset,term,initialLoad){
  const headers = {
    'Limit':limit,
    'Offset':offset
  }

  const searchTerm = encodeURIComponent(term.replace('/', ' '))

  return (dispatch) => {
    // cancel && cancel('SEARCH_CONTACTS_TYPEAHEAD request cancelled by SEARCH_CONTACTS')
    return dispatch({
      type:'SEARCH_CONTACTS',
      payload:axios.get(`contacts/search/${searchTerm}`, {headers:headers}),
      meta: initialLoad
    })
  }
}

export function clearContacts(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_CONTACTS',
      payload:null
    })
  }
}
