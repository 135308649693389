import React, { Fragment } from 'react'

import moment from 'moment/moment'

import Button from 'ui/Button'
import Icon from 'ui/Icon'
import Loader from 'ui/Loader'

const VenueDeviceCard = (props) => {
  const {
    device,
    index,
    toggleDeviceUpdateOverlay,
    deleteVenueDevice,
    toggleReplacementSDOverlay,
    deleteReplacementSD,
    venueReplacementLoading
  } = props

  return (
    <Fragment>
      <div
        className={`device-card ${ index === 0 ? 'device-card--current' : '' }`}
      >
        <div className='device-card__left'>
          <div className='device-card__item'>
            <h4>Device</h4>
            <p>{device.deviceTypeName}</p>
          </div>
          {device.deviceType === 26 ? (
            <div className='device-card__item'>
              <h4>Stream URL</h4>
              <p>{device.streamURL}</p>
            </div>
          ) : (
            <Fragment>
              <div className='device-card__item'>
                <h4>Warranty</h4>
                <p>{device.warrantyTypeName}</p>
              </div>
              <div className='device-card__item'>
                <h4>Shipping date</h4>
                <p>{device.shippingDate ? moment(device.shippingDate).format('DD/MM/YY') : ''}</p>
              </div>
              <div className='device-card__item'>
                <h4>MAC address</h4>
                <p>{device.macAddress}</p>
              </div>
              <div className='device-card__item'>
                <h4>Connection type</h4>
                <p>{device.connectionTypeName}</p>
              </div>
              <div className='device-card__item'>
                <h4>AV Installer</h4>
                <p>{device.avInstaller}</p>
              </div>
            </Fragment>
          )}
        </div>
        <div className='device-card__right'>
          <div className='device-card__item--settings'>
            <h4>Settings</h4>
            <textarea readOnly value={device.settings} />
          </div>
          <div className='device-card__item-cta'>
            {device.deviceType !== 26 && (
              <Button
                action={()=>{
                  toggleReplacementSDOverlay(device)
                }}
                name='Add SD card'
              />
            )}
            <Button
              action={()=>{
                toggleDeviceUpdateOverlay(device)
              }}
              name='Edit device'
            />
            <Button
              action={()=>{
                deleteVenueDevice(device.id)
              }}
              name='Delete device'
            />
          </div>
        </div>
      </div>
      {device.replacements && (
        <Fragment>
          {device.replacements.map(replacement => {
            return (
              <div
                className={`device-card device-card--replacements ${ index === 0 ? 'device-card--current' : '' }`}
                key={replacement.id}
              >
                <div className='device-card__replacement-sd'>Replacement SD</div>
                <div className='device-card__replacement-sd'>
                  {replacement.shipped_date ? moment(replacement.shipped_date).format('DD/MM/YY') : ''}
                </div>
                <div className='device-card__replacement-sd'>{replacement.note}</div>
                <div className='device-card__replacement-sd-actions'>
                  {venueReplacementLoading.includes(replacement.id) ? (
                    <Loader />
                  ) : (
                    <Icon
                      name="trash"
                      action={()=>deleteReplacementSD(replacement.id)}
                    />
                  )}
                </div>
              </div>
            )
          })}
        </Fragment>
      )}
    </Fragment>
  )
}

export default VenueDeviceCard
