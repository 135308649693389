import React, { PureComponent, Fragment } from 'react'

import SchedulePlaylistOverlay from './SchedulePlaylistOverlay'
import Icon from 'ui/Icon'

class ScheduleItem extends PureComponent {
  render() {
    const {
      isActive,
      schedule
    } = this.props

    return (
      <Fragment>
        <div className='schedule__item-name'>
          <p>{schedule.playlistName}</p>
          {schedule.interleave && (schedule.h > 3) && (
            <Fragment>
              <p className="interleave-name">
                <Icon name="swap"/>
                {schedule.interleave.playlist ? schedule.interleave.playlist.name : `Missing playlist`}
              </p>
              <p>1 in {schedule.interleave.ratio}</p>
            </Fragment>
          )}
        </div>
        {isActive && (
          <SchedulePlaylistOverlay
            {...this.props}
          />
        )}
      </Fragment>
    )
  }
}

export default ScheduleItem
