import React from 'react'
import { Link } from 'react-router-dom'

const Badge = (props) => {
  if(props.action){
    return <span onClick={()=>props.action()} className={`badge badge-action badge-${props.type ? props.type : 'basic'}`}>{props.data}</span>
  } else if(props.link){
    return <Link to={props.link} className={`badge badge-link badge-${props.type ? props.type : 'basic'}`}>{props.data}</Link>
  } else {
    return <span className={`badge badge-${props.type ? props.type : 'basic'}`}>{props.data}</span>
  }
}

export default Badge
