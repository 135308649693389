import React, {Component,Fragment} from 'react'
import { connect } from 'react-redux'

import {
  createAnnouncements
} from 'store/actions/venueAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Button from 'ui/Button'
import Select from 'ui/Select'

const classname = "overlayAnnouncements"

class OverlayAnnouncements extends Component{

  constructor(props) {
    super(props)

    this.state = {
      hours:[...Array(24).keys()].map(key => {
        const hour = key < 10  ?  '0' + key : key
        return {value:key,display:hour}
      }),
      mins:[...Array(60).keys()].map(key => {
        const min = key < 10  ?  '0' + key : key
        return {value:key,display:min}
      }),
      frequency:[
        {value:0,display:'One off'},
        {value:1,display:'Repeat'}
      ],
      freqRepeat:null,
      days:[
        {value:7,display:'Every day'},
        {value:8,display:'Weekdays'},
        {value:9,display:'Weekend'},
        {value:0,display:'Monday'},
        {value:1,display:'Tuesday'},
        {value:2,display:'Wednesday'},
        {value:3,display:'Thursday'},
        {value:4,display:'Friday'},
        {value:5,display:'Saturday'},
        {value:6,display:'Sunday'},
      ],
      selected:{
        playlist_id:0,
        startHour:0,
        startMin:0,
        freq:0,
        freqRep:0,
        repeatHour:0,
        repeatMin:0,
        days:7,
        venue_id:null
      }
    }
  }

  componentDidMount(){
    this.setState((prevState) => ({
      selected:{
        ...prevState.selected,
        venue_id:this.props.venue_id,
      }
    }))
  }

  createAnnouncements(){
    const {
      playlist_id,
      freqRep,
      freq
    } = this.state.selected
    //no selected playlist or repeat selected but no freq
    if(playlist_id === 0 || (freqRep === 0 && freq === 1)){
      return
    }
    console.log(this.state.selected)
    //validate
    this.props.dispatch(createAnnouncements(this.state.selected))

  }

  selectPlaylist(data){
    const playlist_id = data.event.target.value
    this.setState((prevState) => ({
      selected:{
        ...prevState.selected,
        playlist_id:parseInt(playlist_id),
      }
    }))
  }

  changeStartHour(data){
    const startHour = parseInt(data.event.target.value)
    this.setState((prevState) => ({
      selected:{
        ...prevState.selected,
        startHour:startHour,
        repeatHour:prevState.selected.repeatHour < startHour ? startHour : prevState.selected.repeatHour
      }
    }))
  }

  changeStartMin(data){
    const startMin = parseInt(data.event.target.value)
    const {
      selected
    } = this.state
    const startTimeMins = (selected.startHour * 60) + startMin
    const repeatEndMins = (selected.repeatHour * 60) + selected.repeatMin
    this.setState((prevState) => ({
      selected:{
        ...prevState.selected,
        startMin:startMin,
        repeatMin:repeatEndMins < startTimeMins ? (selected.freqRep !== 0 ? startMin + selected.freqRep : startMin+10) : prevState.selected.repeatMin
      }
    }))
  }

  changeFrequency(data){
    const freq = data.event.target.value
    this.setState((prevState) => ({
      selected:{
        ...prevState.selected,
        freq:parseInt(freq),
      }
    }))
  }

  changeFreqRepeat(data){
    const freqRep = data.event.target.value
    this.setState((prevState) => ({
      selected:{
        ...prevState.selected,
        freqRep:parseInt(freqRep),
      }
    }))
  }

  /*changeHourRepeat(data){
    const hour = data.event.target.value
    this.setState((prevState) => ({
      selected:{
        ...prevState.selected,
        hour:parseInt(hour),
      }
    }))
  }*/

  changeHourRepeat(data){
    const repeatHour = parseInt(data.event.target.value)
    const {
      selected
    } = this.state
    this.setState((prevState) => ({
      selected:{
        ...prevState.selected,
        repeatHour:repeatHour < selected.startHour ? selected.startHour : repeatHour,
      }
    }))
  }

  changeMinRepeat(data){
    const min = data.event.target.value
    this.setState((prevState) => ({
      selected:{
        ...prevState.selected,
        repeatMin:parseInt(min),
      }
    }))
  }

  changeDays(data){
    const days = data.event.target.value
    this.setState((prevState) => ({
      selected:{
        ...prevState.selected,
        days:parseInt(days),
      }
    }))
  }

  getPlaylists(){
    const playlists = this.props.playlists.map(playlist => {
      const data = {
        value:playlist.id,
        display:playlist.name
      }
      return data
    })
    playlists.unshift({
      value:0,
      display:'Select playlist'
    })
    return playlists
  }

  getFreqRepeat(){
    const repeat = [...Array(61).keys()].map(key => {
      // const min = key < 10  ?  '0' + key : key
      return {value:key,display:`every ${key} minutes`}
    })
    .filter(min => min.value > 0)
    repeat.unshift({value:0,display:'Select frequency'})
    return repeat
  }

  render(){
    const {
      hours,
      mins,
      frequency,
      days,
      selected
    } = this.state
    const {
      announcementsLoading
    } = this.props

    return (
      <Container classname={classname} maxWidth height="100%" column scroll>
        {
          announcementsLoading ?
          <Loader/> :
          <Fragment>
            <h3>Create Announcements</h3>
            <div className="playlist-wrapper">
              <h4>Announcement playlist</h4>
              <Select
                classname={`${classname} playlist-select`}
                options={this.getPlaylists()}
                action={(data)=>this.selectPlaylist(data)}
                width="100%"
                theme="light"
              />
            </div>
            <div className="time-wrapper">
              <h4>{selected.freq === 1 && 'Start'} Time</h4>
              <Select
                classname={`${classname} hours`}
                options={hours}
                action={(data)=>this.changeStartHour(data)}
                theme="light"
              />
              <span> : </span>
              <Select
                classname={`${classname} mins`}
                options={mins}
                action={(data)=>this.changeStartMin(data)}
                theme="light"
              />
            </div>
            <div className="freq-wrapper">
              <h4>Frequency</h4>
              <Select
                classname={`${classname} frequency`}
                options={frequency}
                action={(data)=>this.changeFrequency(data)}
                width="100%"
                theme="light"
              />
              {selected.freq === 1 && (
                <div>
                  <h4>Frequency repeat</h4>
                  <Select
                    classname={`${classname} frequency-repeat`}
                    options={this.getFreqRepeat()}
                    action={(data)=>this.changeFreqRepeat(data)}
                    theme="light"
                  />
                  <span> until </span>
                  <Select
                    classname={`${classname} hours`}
                    selected={selected.repeatHour}
                    options={hours}
                    action={(data)=>this.changeHourRepeat(data)}
                    theme="light"
                  />
                  <span> : </span>
                  <Select
                    classname={`${classname} mins`}
                    selected={selected.repeatMin}
                    options={mins}
                    action={(data)=>this.changeMinRepeat(data)}
                    theme="light"
                  />
                </div>
              )}
            </div>
            <div className="days-wrapper">
              <h4>Days</h4>
              <Select
                classname={`${classname} days`}
                options={days}
                action={(data)=>this.changeDays(data)}
                width="100%"
                theme="light"
              />
            </div>
            <Button action={()=>this.createAnnouncements()} name={`Create announcements`}/>
          </Fragment>
        }
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    announcements:store.venue.announcements,
    announcementsLoading:store.venue.announcementsLoading,
    playlists:store.venue.playlists,
    venue_id:store.venue.details.id,
    loading:store.playlist.loading
  }
}

export default connect(mapStateToProps)(OverlayAnnouncements)
