import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import {
  createPlaylist,
  createPlaylistAddVenues,
  clearPlaylistTypeahead,
  getPlaylistTypes,
  resetCreatePlaylist
} from 'store/actions/playlistAction'

import {
  getBusinessesTypeahead,
  clearBusinessesTypeahead
} from 'store/actions/businessAction'

import {
  clearBusinessVenues,
  getVenuesByBusiness
} from 'store/actions/venueAction'


import Form from 'ui/Form'
import Loader from 'ui/Loader'

class PlaylistsCreate extends Component {

  constructor(props){
    super(props)
    this.state = {
      tab:null,
      checklistErrorMessage: '',
      header: ''
    }
  }

  componentDidMount(){
    const {
      dispatch
    } = this.props

    dispatch(resetCreatePlaylist())
    dispatch(getPlaylistTypes())
    dispatch(clearBusinessVenues())
    this.setState({ checklistErrorMessage: '' })
  }

  componentDidUpdate(prevProps, prevState){
    const {
      history,
      playlist,
      playlistCreatedVenuesAdded,
      searchedBusinesses
    } = this.props

    if (
      searchedBusinesses &&
      searchedBusinesses.length > 0 &&
      (prevProps.searchedBusinesses !== searchedBusinesses) &&
      searchedBusinesses[searchedBusinesses.length - 1].hasVenues === false
    ) {
      this.setState({
        checklistErrorMessage: 'This business does not have any venues'
      })
    }

    if ((prevProps.playlistCreatedVenuesAdded !== playlistCreatedVenuesAdded) && playlistCreatedVenuesAdded) {
      history.push(`/playlists/${playlist.id}?tab=details`)
    }
  }

  clearTypeahead(){
    this.props.dispatch(clearPlaylistTypeahead())
  }

  getBusinessesTypeahead(value){
    this.props.dispatch(getBusinessesTypeahead(value))
  }

  getBusinessesTypeaheadAction(data){
    const {
      businessVenues,
      searchedBusinesses
    } = this.props

    // Check if the searched business has already been added,
    // either through preloaded props or a previous typeahead search
    if (
      searchedBusinesses.find(business => { return business.id === data.id && business.hasVenues}) ||
      businessVenues.find(business => { return business.name === data.name })
    ) {
      this.setState({
        checklistErrorMessage: `Venues from business ${data.name} have already been added to the list.`
      })
      return false
    }

    this.setState({
      checklistErrorMessage: ''
    })

    this.props.dispatch(getVenuesByBusiness(data))
  }

  clearBusinessesTypeahead(){
    this.props.dispatch(clearBusinessesTypeahead())
  }

  createPlaylist(form){
    const {
      dispatch,
      customCreatePlaylistAction
    } = this.props

    if (customCreatePlaylistAction) {
      customCreatePlaylistAction(form)
    } else {
      dispatch(createPlaylist(form))
    }
  }

  createPlaylistAddVenues(form){
    this.props.dispatch(createPlaylistAddVenues(form, this.props.details.id))
  }

  skipCreatePlaylistAddVenues() {
    const {
      closeOverlay,
      history,
      playlist
    } = this.props

    history.push(`/playlists/${playlist.id}?tab=details`)

    if (closeOverlay) {
      closeOverlay()
    }
  }

  preloadOwnedBusinessData() {
    const {
      business
    } = this.props

    if (business.length === 0) {
      return {}
    }

    return {
      'business' : business
    }
  }

  render(){
    const {
      customClassname,
      loading,
      playlistCreated,
      businessesTypeahead,
      businessesTypeaheadLoading,
      playlistTypes
    } = this.props

    return (
      <div className='playlists-create'>
        {loading ? (
          <Loader/>
        ) : (
          <Fragment>
            {playlistCreated ? (
              <Form
                classname='createPlaylistAddVenues'
                submit={(details)=>this.createPlaylistAddVenues(details)}
                messages={this.state.message}
                data={this.preloadOwnedBusinessData()}
                typeaheadSearch={(value)=>this.getBusinessesTypeahead(value)}
                typeaheadData={businessesTypeahead}
                typeaheadAction={(data)=>this.getBusinessesTypeaheadAction(data)}
                typeaheadLoading={businessesTypeaheadLoading}
                clearTypeahead={()=>this.clearBusinessesTypeahead()}
                checklistData={this.props.businessVenues}
                checklistErrorMessage={this.state.checklistErrorMessage}
                skipFunction={()=>this.skipCreatePlaylistAddVenues()}
                disableEnterToSearch
              />
            ) : (
              <Form
                classname={customClassname || 'createPlaylist'}
                submit={(e)=>this.createPlaylist(e)}
                messages={this.state.message}
                playlistTypes={playlistTypes.map((type, index) => {
                  return (
                    {
                      'display': type.type,
                      'value': type.id
                    }
                  )
                })}
                typeaheadSearch={(value)=>this.getBusinessesTypeahead(value)}
                typeaheadData={businessesTypeahead}
                typeaheadLoading={businessesTypeaheadLoading}
                clearTypeahead={()=>this.clearBusinessesTypeahead()}
              />
            )}
          </Fragment>
        )}
      </div>
    )
  }
}
function mapStateToProps(store){
  return {
    playlist:store.playlist.details,
    playlistCreated:store.playlist.playlistCreated,
    playlistCreatedVenuesAdded:store.playlist.playlistCreatedVenuesAdded,
    playlistTypes:store.playlist.playlistTypes,
    loading:store.playlist.loading,
    details:store.playlist.details,
    business:store.playlist.business,
    businessesTypeahead: store.businesses.typeahead,
    businessesTypeaheadLoading: store.businesses.typeaheadLoading,
    businessVenues: store.venues.businessVenues,
    searchedBusinesses: store.venues.searchedBusinesses
  }
}

export default connect(mapStateToProps)(PlaylistsCreate)
