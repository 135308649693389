import React from 'react'
import { connect } from 'react-redux'

import Container from "ui/Container"
import Form from "ui/Form"
import Loader from "ui/Loader"
import {
  getBusinessSettings, updateBusinessSettings
} from '../../store/actions/businessAction'

const classname = 'businessSettings'

class BusinessSettings extends React.Component{

  constructor(props){
    super(props)
    this.playback_options = [
      {display:'80',value:1},
      {display:'160',value:2},
      {display:'320',value:3},
    ]
  }

  componentDidMount(){
    const {
      dispatch,
      id
    } = this.props
    dispatch(getBusinessSettings(id))
  }

  componentDidUpdate(prevProps){

  }

  onSubmit(form){
    const {
      id,
      dispatch
    } = this.props
    dispatch(updateBusinessSettings(id,form))
  }

  render(){
    const {
      settings,
      loading
    } = this.props
    if(loading){
      return <Loader/>
    }
    return(
      <Container classname={classname} height="100%" maxWidth>
        <Form
          data={settings}
          submit={(form)=>this.onSubmit(form)}
          playback_options={this.playback_options}
          classname={classname}/>
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    settings:store.business.settings,
    loading:store.business.settingsLoading
  }
}

export default connect(mapStateToProps)(BusinessSettings)