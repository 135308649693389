import axios from 'axios'

const actions = {
  setup: (store,history) => {
    if(process.env.REACT_APP_STAGE){
      axios.defaults.baseURL = 'https://api.superadmindev.openearmusic.com/v1/';
    }
    else if(process.env.REACT_APP_TESTING){
      axios.defaults.baseURL = 'https://api.superadmintest.openearmusic.com/v1/';
    }
    else if(process.env.NODE_ENV === 'development'){
      axios.defaults.baseURL = 'http://localhost:8000/public/';
    }
    else if(process.env.NODE_ENV === 'production'){
      axios.defaults.baseURL = 'https://api.superadmin.openearmusic.com/v1/';
    }
    axios.defaults.headers.common['Authentication'] = localStorage.getItem('Authentication');
    axios.interceptors.response.use((res) => {
    // Do something before response is sent
    if(res.data.res && res.data.res.message){
      console.log(res.data.res.message)
    }
    return res
  }, (error) => {
      // Additional check required to avoid errors from deliberately cancelled requests
      if (error.response) {
        // Do something with response error
        if(error.response.status === 401){
          localStorage.removeItem('Authentication')
          axios.defaults.headers.common['Authentication'] = null
          store.dispatch({type:'LOGOUT',payload:`API STATUS ${error.response.status}`})
        }
        //global 500
        if(error.response.status === 500){
          store.dispatch({type:'GLOBAL_ERROR',payload:error ? error : 'Unknown 500'})
        }
        //422 form input incorrect
        if(error.response.status === 422){
          console.log(error.response)
          //store.dispatch({type:'FORM_ERROR',payload:error.response})
        }
      }
      return Promise.reject(error);
    });
  }
}

export default actions
