import React, { Component } from 'react'
import { connect } from 'react-redux'

import { deleteBusiness } from 'store/actions/businessAction'

import Container from 'ui/Container'
import Button from 'ui/Button'

class OverlayVenues extends Component{

  copyBusiness(){
    console.log('copy business')
  }

  deleteBusiness(){
    const confirm = window.confirm('Are you sure you want to delete this business?')

    if(confirm){
      this.props.dispatch(deleteBusiness(this.props.data.id))
    }
  }

  render(){
    return (
      <Container classname="overlay-business" maxWidth height="100%" column>
        <h2>Business Actions for {this.props.data.name}</h2>
        {/*<Button action={()=>this.copyBusiness()} name={`Copy business`}/>*/}
        <Button action={()=>this.deleteBusiness()} name={`Delete business`}/>
      </Container>
    )
  }
}

export default connect()(OverlayVenues)
