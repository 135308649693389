import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  toggleOverlay
} from 'store/actions/overlayAction'

import {
  getNoteTypes
} from 'store/actions/notesAction'

import Container from 'ui/Container'
import Form from 'ui/Form'
import Loader from 'ui/Loader'

const classname = 'overlayNotesAdd';

class OverlayNotesAdd extends Component{

  componentDidMount() {
    const {
      dispatch
    } = this.props

    dispatch(getNoteTypes())
  }

  onSubmit(form){
    const {
      dispatch,
      data
    } = this.props

    dispatch(data.addNote(form))

    dispatch(
      toggleOverlay(
        false,
        {},
        'overlayNotesAdd'
      )
    )
  }

  render(){
    const {
      data,
      loading,
      types
    } = this.props

    const typesArray = [
      {
        display: 'Please select',
        value: 0
      },
      ...types
    ]

    return (
      <Container classname="overlay-notes-add" maxWidth height="100%" column>
        {loading ? (
          <Loader />
        ) : (
          <Form
            classname={classname}
            types={typesArray}
            data={data}
            submit={(form)=>this.onSubmit(form)}
            isOverlay
          />
        )}
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    loading: store.notes.overlayNotesAddLoading,
    types: store.notes.types
  }
}

export default connect(mapStateToProps)(OverlayNotesAdd)

