import React from 'react'

import Button from 'ui/Button'
import Icon from 'ui/Icon'

const InterleaveRatioChanger = (props) => {

  const {
    setRatio,
    save,
    close,
    selected
  } = props

  const ratios = props.ratios.map(ratio => {
    return (
      <option key={ratio} value={ratio}>
        {ratio}
      </option>
    )
  })

  return (
    <div className="interleave-ratio-changer-overlay" onClick={()=>close()}>
      <div className="interleave-ratio-changer-wrapper" onClick={(e)=>e.stopPropagation()}>
        <h1>Change Interleave Ratios <Icon name="close" action={()=>close()}/></h1>
        <div className="interleave-ratio-changer-controls">
          <select
            className="interleave-ratio-changer"
            onChange={(event) => setRatio(event.target.value)}
            value={selected}>
            {ratios}
          </select>
          <Button action={()=>save()} name="Save"/>
        </div>
      </div>
    </div>
  )
}

export default InterleaveRatioChanger