import React from 'react'

import TableCell from './TableCell'
import TableCellBadge from './TableCellBadge'
import TableCellImg from './TableCellImg'
import TableCellLink from './TableCellLink'
import TableCellEnergy from './TableCellEnergy'
import TableCellGenres from './TableCellGenres'
import LibraryTableCell from 'modules/library/LibraryTableCell'
import Icon from 'ui/Icon'

import TableModel from 'models/TableModel'

const TableRow = (props) => {

  const {
    data,
    classname,
    play,
    size,
    checkboxAction,
    selected,
    index,
    libraryAction,
    playingTrack,
    userSelect
  } = props

  const isSelected = playingTrack && (playingTrack.id === data.id)
  const currentlyPlaying = isSelected && playingTrack.isPlaying

  const classes = `table-row table-row-${classname} table ${isSelected ? 'isPlaying' : ''} ${userSelect && 'user-select'}`
  let isPlaying = false

  const playTrack = (track) => {
    isPlaying = !isPlaying
    play(track)
  }

  let cells = TableModel[classname]['headers'].map((header,index)=>{
    if(header.field === 'energy') {
      return <TableCellEnergy
        track={data}
        key={index}
        datum={data[header.field]}
        classname={classname}
        size={header.size}
        disableAction={header.disableAction}
      />
    } else if(header.field === 'genres') {
      return <TableCellGenres
        track={data}
        key={index}
        datum={data[header.field]}
        classname={classname}
        size={header.size}/>
    } else if(header.image) {
      return <TableCellImg
        key={index}
        datum={data[header.field]}
        field={header.field}
        id={data.id}
        classname={classname}
        size={header.size}/>
    } else if(header.badge) {
      return <TableCellBadge
        key={index}
        datum={data[header.field]}
        field={header.field}
        id={data.id}
        classname={classname}
        size={header.size}/>
    } else if(header.link) {
      return <TableCellLink key={index} datum={data[header.field]} field={header.field} linkField={data[header.linkField]} classname={classname} size={header.size}/>
    } else if(header.libraryAction){
      return <LibraryTableCell key={index} datum={data[header.field]} field={header.field} classname={classname} size={header.size} libraryAction={(data) => libraryAction(data)}/>
    } else {
      return <TableCell
        key={index}
        datum={data[header.field]}
        classname={classname}
        size={header.size}
      />
    }
  })
  //if has play
  if(TableModel[classname].play){
    cells.unshift(
      <td key="play" className={`table-cell table-cell-play table-cell-${classname}`}>
        <Icon name={`ios-${currentlyPlaying ? 'volume-high' : 'play'}`} action={()=>playTrack(data)}/>
      </td>
    )
  }
  //if the table has a checkbox
  if(TableModel[classname].checkbox){
    //what a fuckabout this was. selected state held in parent component and passed down to the table wrapper
    //value and name set to stop connected/unconnected problem
    cells.unshift(
      <td key="checkbox" className={`table-cell table-cell-checkbox table-cell-${classname}`}>
        <input value={index || ''} name={index || ''} type="checkbox" onChange={(e)=>checkboxAction(e,data)} checked={selected || false}/>
      </td>
    )
  }
  return (
    <tr className={classes} onDoubleClick={typeof play === 'function' ? ()=>playTrack(data) : null}>
      {cells}
      <td className={`table-cell table-cell-actions table-cell-actions-${classname} table-cell-actions-${size}`}>
        {props.children || props.action}
      </td>
    </tr>
  )
}

export default TableRow
