import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import queryString from 'qs'

import { getVenueDetails } from 'store/actions/venueAction'

import VenueDetails from 'modules/venue/VenueDetails'
import VenuePlaylists from 'modules/venue/VenuePlaylists'
import VenueSchedule from 'modules/venue/VenueSchedule'
import VenueAnnouncements from 'modules/venue/VenueAnnouncements'
import VenueUser from 'modules/venue/VenueUser'
import VenueManager from './venue/VenueManager'
import VenueCalendarGenerator from './venue/VenueCalendarGenerator'
import VenueBlocked from './venue/VenueBlocked'
import VenueHardware from 'modules/venue/VenueHardware'
import VenueContacts from 'modules/venue/VenueContacts'
import VenueNotes from 'modules/venue/VenueNotes'

import Tabs from 'ui/Tabs'
import Container from 'ui/Container'
import Loader from 'ui/Loader'

const classname = 'venue'

class VenueContainer extends Component {

  constructor(props){
    super(props)
    this.state = {
      tab:''
    }
  }

  componentDidMount(){
    const id = this.props.match.params.id
    this.props.dispatch(getVenueDetails(id))
    this.getTabQuery()
  }

  componentDidUpdate(prevProps, prevState){
    if (this.state.tab !== prevState.tab) {
      this.getTabQuery()
    }

    if(this.props.venue !== prevProps.venue){
      this.setHeader()
    }

    //  handle forward and back browser button usage
    if ((this.props.location !== prevProps.location) && this.props.history.action === 'POP') {
      this.getTabQuery()
    }
  }

  getTabQuery(){
    const query = queryString.parse(this.props.location.search.slice(1))

    if(typeof query.tab !== 'undefined' && query.tab !== this.state.tab){
      this.setState({tab: query.tab})
    } else if (typeof query.tab === 'undefined') {
      // defines the default tab to loaded when no tab query is passed in the URL
      this.setState({tab: 'schedule'})
    }
  }

  selectTabQuery(query){
    this.props.history.push({
      search: `?tab=${query.tab}${query.view ? '&view='+query.view:''}`
    })
    this.setState({tab:query.tab})
  }

  selectTab(tab){
    this.props.history.push({
      search: `?tab=${tab}`
    })
    this.setState({tab:tab})
  }

  setHeader(){
    const {
      venue
    } = this.props

    const header = <Link to={`/businesses/${venue.businessID}?tab=venues`}>{venue.name}</Link>

    this.setState({
      header:header
    })
  }

  getComponent(){
    const id = this.props.match.params.id
    switch(this.state.tab){
      case 'details':
        return <VenueDetails setHeader={(header)=>this.setHeader(header)} id={id}/>
      case 'playlists':
        return <VenuePlaylists id={id}/>
      case 'schedule':
        return <VenueSchedule id={id}/>
      case 'calendarGenerator':
        return <VenueCalendarGenerator id={id} />
      case 'announcements':
        return <VenueAnnouncements id={id}/>
      case 'blocked':
        return <VenueBlocked id={id}/>
      case 'control':
        return <VenueManager id={id}/>
      case 'user':
        return <VenueUser id={id}/>
      case 'hardware':
        return <VenueHardware id={id}/>
      case 'contacts':
        return <VenueContacts setHeader={(header)=>this.setHeader(header)} id={id}/>
      case 'notes':
        return <VenueNotes id={id}/>
      default: // no default case
    }
  }

  render(){
    const {
      venueDetailsLoading
    } = this.props
    return (
      <Container classname={classname} height="100%" column>
        <Container classname="tabs">
          <Tabs
            match={this.props.match}
            classname={classname}
            select={(tab)=>this.selectTab(tab)}
            active={this.state.tab}
            header={venueDetailsLoading ? <Loader /> : this.state.header}
          />
        </Container>
        <Container height="100%" classname={`${classname} container-tabview`} column>
          {this.getComponent()}
        </Container>
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    loading:store.venue.loading,
    venue:store.venue.details,
    venueDetailsLoading: store.venue.venueDetailsLoading
  }
}

export default connect(mapStateToProps)(VenueContainer)
