import React from 'react'

import {Link} from 'react-router-dom'

import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'

const PlaylistTableRowActions = (props) => {
  const {
    id,
    classname,
    removePlaylist,
    playlist,
    changePlaylistActive
  } = props
  return (
    <div className={`${classname}-table-row-actions`}>
      <Icon name={playlist.active === 1 ? 'checkmark-circle' : 'close-circle'} action={()=>changePlaylistActive(playlist)}>
        <Tooltip pos="left" text={playlist.active === 1 ? 'Deactive playlist' : 'Activate playlist'}/>
      </Icon>
      <Link to={`/playlists/${id}`}>
        <Icon name="eye">
          <Tooltip pos="left" text="View playlist"/>
        </Icon>
      </Link>
      <Icon name="close" action={()=>removePlaylist(playlist)}>
        <Tooltip pos="left" text="Remove playlist from venue"/>
      </Icon>
    </div>
  )
}

export default PlaylistTableRowActions