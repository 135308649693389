import React from 'react'
import {Link} from 'react-router-dom'

import Icon from 'ui/Icon'
import Loader from 'ui/Loader'
import Tooltip from 'ui/Tooltip'

import TableModel from 'models/TableModel'

const FitnessVenuesTableActions = (props) => {
  const {
    classname,
    id,
    removeFitnessClassFromVenue,
    removingVenueIDs
  } = props

  return (
    <div>
      <Link to={`${TableModel[classname].viewLink}/${id}`}>
        <Icon name='eye' />
      </Link>
      {removingVenueIDs.includes(id) ? (
        <Loader />
      ) : (
        <Icon
          name='trash'
          action={()=>removeFitnessClassFromVenue(id)}
        >
          <Tooltip
            pos="left"
            text='Remove class from venue'
          />
        </Icon>
      )}
    </div>
  )
}

export default FitnessVenuesTableActions
