import React, {Component, Fragment} from 'react'
import FitnessTimelineEdit from './FitnessTimelineEdit'

class FitnessTimelinePlaylist extends Component {
  constructor(props) {
    super(props)

    this.setWrapperRef = this.setWrapperRef.bind(this)
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  render() {
    const {
      clickPane,
      editDuration,
      item,
      index,
      isActive,
      hidePlaylist,
      removePlaylist,
      playlistInfo,
      unitWidth,
      updating
    } = this.props;

    return (
      <div ref={this.setWrapperRef}>
        <div
          className='fitness-timeline-playlist'
          onClick={() => {clickPane(item, index)}}
        >
          <p>
            {item.name}
          </p>
          <p>{item.displayWidth/unitWidth} mins</p>
        </div>
        {isActive && (
          <FitnessTimelineEdit
            playlistInfo={playlistInfo}
            hidePlaylist={() => {hidePlaylist()}}
            removePlaylist={() => {removePlaylist(playlistInfo)}}
            editDuration={editDuration}
            unitWidth={unitWidth}
            updating={updating}
            wrapperRef={this.wrapperRef}
          />
        )}
      </div>
    );
  }
}

export default FitnessTimelinePlaylist
