import React, {PureComponent} from 'react'
import {connect} from 'react-redux'

import { playlistTypeaheadAll } from 'store/actions/playlistAction'

import ScheduleTypeahead from './ScheduleTypeahead'

const classname = 'interleavePlaylists'

class InterleaveCreator extends PureComponent {
  getRatios() {
    const {
      interleaveRatios
    } = this.props;

    return interleaveRatios.map(ratio => {
      return (
        <option
          key={ratio}
          value={ratio}
        >
          {ratio}
        </option>
      )
    })
  }

  searchPlaylists(term){
    this.props.dispatch(playlistTypeaheadAll(term))
  }

  getTypeaheadData(){

    let results = []
    for(let ta of this.props.typeahead) {
      let found = this.props.playlists.find(pl => pl.id === ta.id)
      if(!found) {
        results.push(ta)
      }
    }
    return results

  }

  render() {
    const {
      editArray,
      editMulti,
      multiInterleave,
      playlists,
      schedule,
      scheduleArray,
      selectedPlaylist,
      typeahead,
      typeaheadLoading,
      addInterleave,
      setSelectedPlaylist,
      setSelectedInterleaveRatio,
      addMultiInterleave
    } = this.props;

    let playlistArray = [...playlists]

    editArray.forEach((item) => {
      const activeItem = scheduleArray.find((playlist) => {
        return playlist.i === item.uuid
      })

      playlistArray = playlistArray.filter((playlist) => {
        return playlist.id !== activeItem.playlistID;
      })
    })

    return (
      <div className='interleave-creator'>
        <h3>Add interleave</h3>
        <ScheduleTypeahead
          classname={classname}
          getData={(value)=>this.searchPlaylists(value)}
          action={(playlist)=>setSelectedPlaylist(playlist.name, playlist.id)}
          data={typeahead}
          typeaheadLoading={typeaheadLoading}
          interleaveValue={selectedPlaylist}
          isInterleave
        />
        <p className='interleave-creator__ratio'>
          every
          <select
            className='interleave-creator__ratio-select'
            onChange={(event) => {
              setSelectedInterleaveRatio(event.target.value)
            }}
          >
            {this.getRatios()}
          </select>
          tracks
        </p>
        {selectedPlaylist && (
          <div className='schedule-creator__submit-btn-container'>
            <button
              type='button'
              className='schedule-creator__submit-btn'
              onClick={
                editMulti || multiInterleave ? (
                  () => {
                    editMulti ? addMultiInterleave() : addMultiInterleave(true)
                  }
                ) : (
                  () => {
                    addInterleave(selectedPlaylist, schedule.id, schedule.i);
                  }
                )}
            >
              Save
            </button>
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps(store){
  return {
    playlists:store.venue.playlists,
    typeahead:store.playlists.typeahead,
    typeaheadLoading:store.playlists.typeaheadLoading,
    loading:store.venue.loading,
    businessID: store.venue.businessID
  }
}

export default connect(mapStateToProps)(InterleaveCreator)
