import React from 'react'
import {Link} from 'react-router-dom'

import Icon from 'ui/Icon'
import Loader from 'ui/Loader'
import Tooltip from 'ui/Tooltip'

import TableModel from 'models/TableModel'

const FitnessTableActions = (props) => {
  const {
    classname,
    deleteFitnessClass,
    id,
    removingClassIDs
  } = props

  return (
    <div>
      <Link to={`${TableModel[classname].viewLink}/${id}`}>
        <Icon name="eye"/>
      </Link>
      {removingClassIDs && removingClassIDs.includes(id) ? (
        <Loader />
      ) : (
        <Icon
          name='trash'
          action={()=>deleteFitnessClass(id)}
        >
          <Tooltip
            pos="left"
            text='Delete class'
          />
        </Icon>
      )}
    </div>
  )
}

export default FitnessTableActions
