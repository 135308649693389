const FormModel = {
  businesses:{
    form:[
      {elem:'legend',label:'Create a business'},
      {elem:'input',type:'text',name:'businessName',label:'Business Name',required:true,errorMessage:'Please enter the business name'},
      {elem:'input',type:'text',name:'contactName',label:'Contact Name',required:true,errorMessage:'Please enter the contact name'},
      {elem:'input',type:'text',name:'contactNumber',label:'Contact number',required:true,errorMessage:'Please enter a valid phone number'},
      {elem:'input',type:'text',name:'contactEmail',label:'Contact email',required:true,errorMessage:'Please enter a valid email address'},
      {elem:'input',type:'text',name:'url',label:'URL'},
      {elem:'input',type:'text',name:'facebookUrl',label:'Facebook URL'},
      {elem:'input',type:'checkbox',name:'setupComplete',label:'Setup complete?'},
      {elem:'legend',label:'Business user account'},
      {elem:'input',type:'text',name:'username',label:'Business user name',required:true,errorMessage:'Please enter a username for the business'},
      {elem:'input',type:'password',name:'password',label:'Password',required:true,isPassword: true, confirm: 'confirmPassword',errorMessage:'Please enter a password'},
      {elem:'input',type:'password',name:'confirmPassword',label:'Confirm password',required:true,isConfirm:true,password:'password',errorMessage:'Please ensure passwords match'},
    ],
    submitLabel:'Create business'
  },
  businessDetails:{
    form:[
      {elem:'legend',label:'Business details'},
      {elem:'input',type:'text',name:'name',label:'Business name',field:'name',required:true,errorMessage:'Please enter the business name'},
      {elem:'textarea',name:'generalInfo',label:'General Info',field:'generalInfo'},
      {elem:'select',label:'Sector',name:'sector',field:'sector',options:'sectors', defaultValue: '0'},
      {
        elem:'row',
        elems: [
          {elem:'select',label:'Account lead',name:'accountLead',field:'accountLead',options:'accountLeads', defaultValue: '0'},
          {elem:'select',label:'Music lead',name:'musicLead',field:'musicLead',options:'musicLeads', defaultValue: '0'},
        ]
      },
      {elem:'input',type:'text',name:'url',label:'URL',field:'url'},
      {elem:'legend',label:'Business contact'},
      {elem:'input',type:'text',name:'contactName',label:'Contact name',field:'contactName',required:true,errorMessage:'Please enter the contact name'},
      {elem:'input',type:'text',name:'contactEmail',label:'Contact email',field:'contactEmail',required:true,errorMessage:'Please enter a valid email address'},
      {elem:'input',type:'text',name:'contactNumber',label:'Contact number',field:'contactNumber',required:true,errorMessage:'Please enter a valid phone number'}
    ],
    submitLabel:'Save business details'
  },
  businessMusic:{
    form:[
      {elem:'legend',label:'Music'},
      {elem:'select',label:'Music lead',name:'musicLead',field:'musicLead',options:'musicLeads', defaultValue: '0',required:true,errorMessage:'Please select the music lead'},
      {elem:'textarea',name:'brief',label:'Brief',field:'brief'}
    ],
    submitLabel:'Save business details'
  },
  businessAccounts:{
    form:[
      {elem:'legend',label:'Accounts'},
      {elem:'select',label:'Account lead',name:'accountLead',field:'accountLead',options:'accountLead', defaultValue: '0',required:true,errorMessage:'Please select the account lead'},
      {
        elem:'row',
        elems: [
          {elem:'date',name:'startDate',label:'Start date',field:'startDate'}
        ]
      },
      {
        elem:'row',
        elems: [
          {elem:'select',label:'Status',name:'status',field:'status',options:'accountStatuses'},
          {elem:'select',label:'Contract status',name:'contractStatus',field:'contractStatus',options:'contractStatus', defaultValue: '0'},
        ]
      },
      {
        elem:'row',
        elems: [
          {elem:'select',label:'Tier',name:'tier',field:'tier',options:'tiers'},
          {elem:'select',label:'Contact Frequency',name:'contactFrequency',field:'contactFrequency',options:'contactFrequencies', defaultValue: '0'},
        ]
      },
      {
        elem:'row',
        elems: [
          {elem:'select',label:'Currency',name:'currency',field:'currency',options:'currencies',defaultValue: '0'},
          {elem:'input',type:'text',name:'subscriptionFee',label:'Subscription fee',field:'subscriptionFee',disabled:true,}
        ]
      },
      {
        elem:'row',
        elems: [
          {elem:'select',label:'Billing cycle',name:'billingCycle',field:'billingCycle',options:'billingCycles', defaultValue: '0'},
          {elem:'select',label:'Billing type',name:'billingType',field:'billingType',options:'billingTypes', defaultValue: '0'},
          {elem:'select',label:'Territory',name:'billingTerritory',field:'billingTerritory',options:'billingTerritories', defaultValue: '0'},
        ]
      },
      {elem:'checkbox',name:'setup',label:'Disable Dashboard onboarding flow?',field:'setup'},
      {elem:'checkbox',name:'venueAddressCompleted',label:'Venue address completed?',field:'venueAddressCompleted'},
      {elem:'select',label:'Customer Connect',name:'customerConnect',field:'customerConnect',options:'customerConnectOptions', defaultValue: '0'},
      {elem:'select',label:'Consultancy status',name:'consultancyStatus',field:'consultancyStatus',options:'consultancyStatuses', defaultValue: '0',displayTrigger:true},
      {elem:'input',type:'text',name:'consultancyFee',label:'Consultancy fee',field:'consultancyFee',displayTriggerValue:'/2|3/'},
      {elem:'textarea',name:'consultancyDescription',label:'Description',field:'consultancyDescription',displayTriggerValue:'/2|3/'}
    ],
    submitLabel:'Save business account details'
  },
  businessSettings:{
    form:[
      {elem:'legend',label:'Business settings'},
      {elem:'checkbox',name:'importer',label:'Spotify importer?',field:'importer'},
      {elem:'select',label:'Dashboard playback quality',name:'playback_option',field:'playback_option',options:'playback_options'}
    ],
    submitLabel:'Save business details'
  },
  overlayNotesAdd:{
    form:[
      {elem:'legend',label:'Notes'},
      {elem:'select',label:'Type',name:'type',field:'type',options:'types',required:true,errorMessage:'Please select a type'},
      {elem:'textarea',name:'content',label:'Note',field:'note',required:true,errorMessage:'Please enter a note'}
    ],
    submitLabel:'Add note'
  },
  contactCreate:{
    form:[
      {elem:'legend',label:'Add new contact'},
      {elem:'input',type:'text',name:'name',label:'Name',field:'name',required:true,errorMessage:'Please enter the contact name'},
      {elem:'select',label:'Status',name:'statusID',field:'statusID',options:'contactStates',required:true,errorMessage:'Please select the contact status'},
      {elem:'input',type:'text',name:'email',label:'Email',field:'email',required:true,errorMessage:'Please enter a valid email address'},
      {elem:'input',type:'text',name:'phoneNumber',label:'Phone number',field:'phoneNumber',required:true,errorMessage:'Please enter a valid phone number'},
      {elem:'input',type:'text',name:'jobTitle',label:'Job title',field:'jobTitle'},
      {elem:'select',label:'Position',name:'positionID',field:'positionID',options:'contactPosition',required:true,errorMessage:'Please select the contact position'},
      {elem:'textarea',name:'notes',label:'Notes',field:'notes'}
    ],
    submitLabel:'Add contact'
  },
  overlayContactUpdate:{
    form:[
      {elem:'legend',label:'Edit contact'},
      {elem:'input',type:'text',name:'name',label:'Name',field:'name',required:true,errorMessage:'Please enter the contact name'},
      {elem:'select',label:'Status',name:'statusID',field:'statusID',options:'contactStates',required:true,errorMessage:'Please select the contact status'},
      {elem:'input',type:'text',name:'email',label:'Email',field:'email',required:true,errorMessage:'Please enter a valid email address'},
      {elem:'input',type:'text',name:'phoneNumber',label:'Phone number',field:'phoneNumber',required:true,errorMessage:'Please enter a valid phone number'},
      {elem:'input',type:'text',name:'jobTitle',label:'Job title',field:'jobTitle'},
      {elem:'select',label:'Position',name:'positionID',field:'positionID',options:'contactPosition',required:true,errorMessage:'Please select the contact position'},
      {elem:'textarea',name:'notes',label:'Notes',field:'notes'}
    ],
    submitLabel:'Update contact'
  },
  businessUser:{
    form:[
      {elem:'legend',label:'Business user'},
      {elem:'input',type:'text',name:'username',label:'Business user',field:'username',required:true,errorMessage:'Please enter the business user name'},
      {
        elem:'checkbox',
        name:'changePw',
        label:'Change password?',
        field:'changePw'
      },
      {
        elem:'input',
        type:'password',
        name:'password',
        label:'Password',
        field:'password',
        isPassword:true,
        confirm:'passwordConf',
        required:true,
        errorMessage:'Please enter a password',
        disabled:true,
        enabledBy:'changePw'
      },
      {
        elem:'input',
        type:'password',
        name:'passwordConf',
        label:'Confirm Password',
        field:'passwordConf',
        required:true,
        isConfirm:true,
        password:'password',
        errorMessage:'Please ensure passwords match',
        disabled:true,
        enabledBy:'changePw'
      }
    ],
    submitLabel:'Save business details'
  },
  createVenue:{
    form:[
      {elem:'legend',label:'Select business'},
      {elem:'typeahead',name:'business',placeholder:'Search for businesses...',required:true,errorMessage:'Please enter the business name'},
      {elem:'legend',label:'Create a venue'},
      {elem:'input',type:'text',name:'venueName',label:'Venue Name',required:true,errorMessage:'Please enter the venue name'},
      {elem:'input',type:'text',name:'contactName',label:'Contact Name',required:true,errorMessage:'Please enter the contact name'},
      {elem:'input',type:'text',name:'contactNumber',label:'Contact number',required:true,errorMessage:'Please enter a valid phone number'},
      {elem:'input',type:'text',name:'address1',label:'Address Line 1',required:true,errorMessage:'Please enter the first line of the address'},
      {elem:'input',type:'text',name:'address2',label:'Address Line 2'},
      {elem:'input',type:'text',name:'town',label:'Town'},
      {elem:'input',type:'text',name:'postcode',label:'Postcode',required:true,errorMessage:'Please enter the postcode'},
      {elem:'legend',label:'Venue user account'},
      {elem:'input',type:'text',name:'username',label:'Venue username',required:true,errorMessage:'Please enter a username for the venue'},
      {elem:'input',type:'password',name:'password',label:'Password',required:true, isPassword: true, confirm: 'confirmPassword',errorMessage:'Please enter a password'},
      {elem:'input',type:'password',name:'confirmPassword',label:'Confirm password',required:true,isConfirm:true,password:'password',errorMessage:'Please ensure passwords match'},
    ],
    submitLabel:'Create venue'
  },
  overlayVenueCopy:{
    form:[
      {elem:'legend',label:'Select business'},
      {elem:'typeahead',name:'business',placeholder:'Search for businesses...',required:true,errorMessage:'Please enter the business name'},
      {elem:'input',type:'text',name:'venueName',label:'Venue Name',required:true,errorMessage:'Please enter the venue name'},
      {elem:'input',type:'text',name:'username',label:'Venue username',required:true,errorMessage:'Please enter a username for the venue'},
      {elem:'input',type:'password',name:'password',label:'Password',required:true, isPassword: true, confirm: 'confirmPassword',errorMessage:'Please enter a password'},
      {elem:'input',type:'password',name:'confirmPassword',label:'Confirm password',required:true,isConfirm:true,password:'password',errorMessage:'Please ensure passwords match'},
    ],
    submitLabel:'Copy venue'
  },
  venueDetails:{
    form:[
      {elem:'legend',label:'Venue details'},
      {elem:'input',type:'text',name:'name',label:'Venue name',field:'name',required:true,errorMessage:'Please enter the venue name'},
      {elem:'input',type:'text',name:'contactName',label:'Contact name',field:'contactName',required:true,errorMessage:'Please enter the contact name'},
      {elem:'input',type:'text',name:'contactNumber',label:'Contact number',field:'contactNumber',required:true,errorMessage:'Please enter a valid contact number'},
      {elem:'input',type:'text',name:'addressLine1',label:'Address Line 1',field:'addressLine1',required:true,errorMessage:'Please enter the first line of thevenue address'},
      {elem:'input',type:'text',name:'addressLine2',label:'Address Line 2',field:'addressLine2'},
      {elem:'input',type:'text',name:'town',label:'Town',field:'town',required:true,errorMessage:'Please enter the town or city the venue is located in'},
      {elem:'input',type:'text',name:'postcode',label:'Postcode',field:'postcode',required:true,errorMessage:'Please enter a valid postcode'},
      {elem:'legend',label:'Venue settings'},
      {elem:'select',label:'File size',name:'fileSize',field:'fileSize',options:'fileSizes'},
    ],
    submitLabel:'Save venue details'
  },
  venueUser:{
    form:[
      {elem:'legend',label:'Venue user'},
      {elem:'input',type:'text',name:'loginHash',label:'Login hash',field:'loginHash',disabled:true},
      {elem:'input',type:'text',name:'username',label:'Venue user',field:'username',required:true,errorMessage:'Please enter the venue user name'},
      {
        elem:'checkbox',
        name:'changePw',
        label:'Change password?',
        field:'changePw'
      },
      {
        elem:'input',
        type:'password',
        name:'password',
        label:'Password',
        field:'password',
        isPassword:true,
        confirm:'passwordConf',
        required:true,
        errorMessage:'Please enter a password',
        disabled:true,
        enabledBy:'changePw'
      },
      {
        elem:'input',
        type:'password',
        name:'passwordConf',
        label:'Confirm Password',
        field:'passwordConf',
        required:true,
        isConfirm:true,
        password:'password',
        errorMessage:'Please ensure passwords match',
        disabled:true,
        enabledBy:'changePw'
      }
    ],
    submitLabel:'Save venue details'
  },
  overlayVenueHardwareCreate:{
    form:[
      {elem:'legend',label:'Add new device'},
      {elem:'select',label:'Device',name:'deviceType',field:'deviceType',options:'deviceTypes',displayTrigger:true},
      {elem:'select',label:'Warranty',name:'warrantyType',field:'warrantyType',options:'warrantyTypes',hiddenTriggerValue: '/26/'},
      {elem:'date',name:'shippingDate',label:'Shipping date',field:'shippingDate',hiddenTriggerValue: '/26/'},
      {elem:'input',type:'text',name:'macAddress',label:'MAC address',field:'macAddress', hiddenTriggerValue: '/26/'},
      {elem:'select',label:'Connection type',name:'connectionType',field:'connectionType',options:'connectionTypes',hiddenTriggerValue: '/26/'},
      {elem:'input',type:'text',name:'avInstaller',label:'AV Installer',field:'avInstaller',hiddenTriggerValue: '/26/'},
      {elem:'input',type:'text',name:'streamURL',label:'Stream URL',field:'streamURL',displayTriggerValue: '/26/'},
      {elem:'textarea',name:'settings',label:'Settings',field:'settings'}
    ],
    submitLabel:'Add device'
  },
  overlayVenueHardwareUpdate:{
    form:[
      {elem:'legend',label:'Edit device'},
      {elem:'select',label:'Device',name:'deviceType',field:'deviceType',options:'deviceTypes',displayTrigger:true},
      {elem:'select',label:'Warranty',name:'warrantyType',field:'warrantyType',options:'warrantyTypes',hiddenTriggerValue: '/26/'},
      {elem:'date',name:'shippingDate',label:'Shipping date',field:'shippingDate',hiddenTriggerValue: '/26/'},
      {elem:'input',type:'text',name:'macAddress',label:'MAC address',field:'macAddress', hiddenTriggerValue: '/26/'},
      {elem:'select',label:'Connection type',name:'connectionType',field:'connectionType',options:'connectionTypes',hiddenTriggerValue: '/26/'},
      {elem:'input',type:'text',name:'avInstaller',label:'AV Installer',field:'avInstaller',hiddenTriggerValue: '/26/'},
      {elem:'input',type:'text',name:'streamURL',label:'Stream URL',field:'streamURL',displayTriggerValue: '/26/'},
      {elem:'textarea',name:'settings',label:'Settings',field:'settings'}
    ],
    submitLabel:'Update device'
  },
  overlayVenueHardwareReplacementSD:{
    form:[
      {elem:'legend',label:'Add replacement SD card'},
      {elem:'date',name:'shippedDate',label:'Shipping date',field:'shippedDate',hiddenTriggerValue:'/26/',required:true,errorMessage:'Please enter the shipping date'},
      {elem:'textarea',name:'note',label:'Note',field:'note',required:true,errorMessage:'Please enter a note'}
    ],
    submitLabel:'Add card'
  },
  playlistDetails:{
    form:[
      {elem:'legend',label:'Playlist details'},
      {elem:'input',type:'text',name:'name',label:'Name',field:'name'},
      {elem:'input',type:'text',name:'displayName',label:'Display name',field:'displayName'},
      {elem:'textarea',name:'style',label:'Description',field:'style'},
      {elem:'input',type:'text',name:'rotationCurrentDesignation',label:'Current playlists in rotation',field:'rotationCurrentDesignation',disabled:true,hideIfEmpty:true},
      {elem:'legend',label:'Genres'},
      {elem:'tagger',allData:'allGenres',data:'genres',name:'genres',placeholder:'Search for genres...',display:'genre'},
      {elem:'legend',label:'Tags'},
      {elem:'tagger',allData:'allTags',data:'tags',name:'tags',placeholder:'Search for tags...',display:'name'}
    ],
    submitLabel:'Save playlist details'
  },
  playlistBrief:{
    form:[
      {elem:'legend',label:'Playlist brief'},
      {elem:'select',label:'Playlist lead',name:'playlistLead',field:'playlistLead',options:'playlistLeads', defaultValue: '0',required: true,errorMessage:'Please select the playlist lead'},
      {elem:'textarea',name:'brief',label:'Brief',field:'brief'},
      {elem:'select',label:'Update frequency',name:'updateFrequency',field:'updateFrequency',options:'updateFrequencies', defaultValue: '0'},
      {
        elem:'row',
        elems: [
          {elem:'input',type:'number',name:'targetTracks',label:'Target tracks',field:'targetTracks'},
          {elem:'select',label:'Target energy',name:'targetEnergy',field:'targetEnergy',options:'targetEnergies', defaultValue: '5'},
        ]
      },
      // are these the existing genres used in client facing scenarios? Or a new, internal only, tags?
      // {elem:'legend',label:'Genres'},
      // {elem:'tagger',field:'genres',allData:'allGenres',data:'genres',name:'genres',placeholder:'Search for genres...',display:'genre'}
    ],
    submitLabel:'Save playlist brief'
  },
  overlayEditTrack:{
    form:[
      {
        elem:'legend',
        label:'Track details'
      },
      {
        elem:'input',
        type:'text',
        name:'title',
        label:'Title',
        field:'title'
      },
      {
        elem:'input',
        type:'text',
        name:'artist',
        label:'Artist',
        field:'artist'
      },
      {
        elem:'input',
        type:'text',
        name:'album',
        label:'Album',
        field:'album'
      },
      {
        elem:'input',
        type:'text',
        name:'total_length',
        label:'Track Length',
        field:'total_length'
      },
      {
        elem:'input',
        type:'text',
        name:'year',
        label:'Year',
        field:'year'
      },
      {
        elem:'checkbox',
        name:'private',
        label:'Private',
        field:'private'
      },
      {
        elem:'legend',
        label:'Genres'
      },
      {
        elem:'tagger',
        field:'genres',
        allData:'allGenres',
        data:'genres',
        name:'genres',
        placeholder:'Search for genres...',
        display:'genre'
      }
    ],
    submitLabel:'Update Track'
  },
  overlayMultiEditTracks:{
    form:[
      {
        elem:'legend',
        label:'Track details'
      },
      {
        elem:'input',
        type:'text',
        name:'artist',
        label:'Artist',
        field:'artist'
      },
      {
        elem:'input',
        type:'text',
        name:'album',
        label:'Album',
        field:'album'
      },
      {
        elem:'legend',
        label:'Genres'
      },
      {
        elem:'tagger',
        field:'genres',
        allData:'allGenres',
        data:'genres',
        name:'genres',
        placeholder:'Search for genres...',
        display:'genre'
      }
    ],
    submitLabel:'Update Tracks'
  },
  createPlaylist:{
    form:[
      {elem:'legend',label:'Create a playlist'},
      {elem:'input',type:'text',name:'name',label:'Playlist name',field:'name',required:true,errorMessage:'Please enter the playlist name'},
      {elem:'textarea',name:'style',label:'Playlist description',field:'description'},
      {elem:'select',label:'Playlist type',name:'type',field:'type',options:'playlistTypes',displayTrigger:true},
      {elem:'typeahead',label:'Owner',name:'business',placeholder:'Search for businesses...',displayTriggerValue: '/3/'}
    ],
    submitLabel:'Create playlist'
  },
  createImporterPlaylist:{
    form:[
      {elem:'legend',label:'Create a playlist'},
      {elem:'input',type:'text',name:'name',label:'Playlist name',field:'name',required:true,errorMessage:'Please enter the playlist name'},
      {elem:'textarea',name:'style',label:'Playlist description',field:'description'},
      {elem:'select',label:'Playlist type',name:'type',field:'type',options:'playlistTypes',displayTrigger:true,defaultValue:'11' },
      {elem:'typeahead',label:'Owner',name:'business',placeholder:'Search for businesses...',required:true,displayTriggerValue: '/3/'}
    ],
    submitLabel:'Create playlist'
  },
  createPlaylistAddVenues:{
    form:[
      {elem:'legend',label:'Add venues'},
      {elem:'typeahead',name:'business',placeholder:'Search for businesses...'},
      {elem:'checklist',name:'venues'}
    ],
    skipLabel:'Skip this step',
    submitLabel:'Add venues'
  },
  createUser:{
    form:[
      {elem:'legend',label:'Select business'},
      {elem:'typeahead',name:'business',placeholder:'Search for businesses...',required:true,errorMessage:'Please enter the business name'},
      {elem:'legend',label:'Create a user'},
      {elem:'select',label:'Account type',name:'group_id',field:'group_id',options:'userTypes',displayTrigger:true},
      {elem:'input',type:'text',name:'username',label:'Email address (username)',field:'username',required:true,errorMessage:'Please enter the email/username'},
      {
        elem:'input',
        type:'password',
        name:'password',
        label:'Password',
        field:'password',
        isPassword:true,
        confirm:'confirmPassword',
        required:true,
        errorMessage:'Please enter a password',
      },
      {
        elem:'input',
        type:'password',
        name:'confirmPassword',
        label:'Confirm Password',
        field:'confirmPassword',
        required:true,
        isConfirm:true,
        password:'password',
        errorMessage:'Please ensure passwords match',
      },
    ],
    submitLabel:'Create user'
  },
  userUser:{
    form:[
      {elem:'legend',label:'User details'},
      {elem:'select',label:'Account type',name:'group_id',field:'group_id',options:'userTypes',displayTrigger:true},
      {elem:'input',type:'text',name:'username',label:'Email address (username)',field:'username',required:true,errorMessage:'Please enter the email/username'},
      {
        elem:'checkbox',
        name:'changePw',
        label:'Change password?',
        field:'changePw'
      },
      {
        elem:'input',
        type:'password',
        name:'password',
        label:'Password',
        field:'password',
        isPassword:true,
        confirm:'passwordConf',
        required:true,
        errorMessage:'Please enter a password',
        disabled:true,
        enabledBy:'changePw'
      },
      {
        elem:'input',
        type:'password',
        name:'passwordConf',
        label:'Confirm Password',
        field:'passwordConf',
        required:true,
        isConfirm:true,
        password:'password',
        errorMessage:'Please ensure passwords match',
        disabled:true,
        enabledBy:'changePw'
      }
    ],
    submitLabel:'Save user details'
  },
  userVenues:{
    form:[
      {elem:'legend',label:'Add/Remove Venues'},
      {elem:'typeahead',name:'business',placeholder:'Search for businesses...'},
      {elem:'checklist',name:'venues'}
    ],
    submitLabel:'Save venues'
  },
  userSettings:{
    form:[
      {elem:'legend',label:'User settings'},
      {
        elem:'checkbox',
        name:'isFitness',
        label:'Enable fitness mode',
        field:'isFitness'
      },
      {
        elem:'checkbox',
        name:'hasVenues',
        label:'Show venues',
        field:'hasVenues'
      },
      {
        elem:'checkbox',
        name:'venue_hasControls',
        label:'Display controls',
        field:'venue_hasControls'
      },
      {
        elem:'checkbox',
        name:'playlists_hasDashboard',
        label:'Display playlist dashboard',
        field:'playlists_hasDashboard'
      },
      {
        elem:'checkbox',
        name:'playlists_hasOEPlaylists',
        label:'Display Open Ear playlists',
        field:'playlists_hasOEPlaylists'
      },
      {
        elem:'checkbox',
        name:'playlists_hasSubscribed',
        label:'Has playlists subscribed',
        field:'playlists_hasSubscribed'
      },
      {
        elem:'checkbox',
        name:'playlists_hasAssigned',
        label:'Has playlists assigned',
        field:'playlists_hasAssigned'
      },
      {
        elem:'checkbox',
        name:'library_isRestricted',
        label:'Restricted library',
        field:'library_isRestricted'
      },
    ],
    submitLabel:'Save settings'
  },
  auth:{
    form:[
      {elem:'input',type:'text',name:'username',label:'Username'},
      {elem:'input',type:'password',name:'password',label:'Password'}
    ],
    submitLabel:'Login'
  },
  fitnessClasses:{
    form:[
      {elem:'legend',label:'Create a class'},
      {elem:'input',type:'text',name:'className',label:'Class Name'}
    ],
    submitLabel:'Create class'
  }
}

export default FormModel
