import React, { Component } from 'react'

import Badge from 'ui/Badge'
import Icon from 'ui/Icon'
import TableCell from 'ui/TableCell'
import TableCellEnergy from 'ui/TableCellEnergy'
import TableCellGenres from 'ui/TableCellGenres'

import TableModel from 'models/TableModel'

class PlaylistTracksToApproveRow extends Component {

  render(){
    const {
      data,
      classname,
      size,
      play,
      playingTrack,
    } = this.props

    const isSelected = playingTrack && (playingTrack.id === data.id)
    const currentlyPlaying = isSelected && playingTrack.isPlaying

    const classes = `
      table-row
      table-row-${classname}
      table
      ${isSelected ? 'isPlaying' : ''}
    `

    let isPlaying = false

    const playTrack = (track) => {
      isPlaying = !isPlaying
      play(track)
    }

    let cells = TableModel[classname]['headers'].map((header,index)=> {
      if (header.field === 'energy') {
        return (
          <TableCellEnergy
            track={data}
            key={index}
            datum={data[header.field]}
            classname={classname}
            size={header.size}
          />
        )
      } else if (header.field === 'genres') {
        return (
          <TableCellGenres
            track={data}
            key={index}
            datum={data[header.field]}
            classname={classname}
            size={header.size}
          />
        )
      } else if(header.field === 'type') {
        return (
          <TableCell
            track={data}
            key={index}
            datum={
              data[header.field] === 1 ?
                <Badge
                  data='Add'
                  type='add'
                /> :
                <Badge
                  data='Remove'
                  type='remove'
                />
            }
            classname={classname}
            size={header.size}
          />
        )
      } else {
        return (
          <TableCell
            key={index}
            datum={data[header.field]}
            classname={classname}
            size={header.size}
          />
        )
      }
    })

    cells.unshift(
      <td key="play" className={`table-cell table-cell-play table-cell-${classname}`}>
        <Icon
          name={`${currentlyPlaying ? 'volume-high' : 'play'}`}
          action={()=>playTrack(data)}
        />
      </td>
    )

    return (
      <tr
        className={classes}
        onDoubleClick={()=>playTrack(data)}
      >
        {cells}
        <td className={`table-cell table-cell-actions table-cell-actions-${classname} table-cell-actions-${size}`}>
          {this.props.actionsComp}
        </td>
      </tr>
    )
  }
}

export default PlaylistTracksToApproveRow
