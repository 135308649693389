import React from 'react'

import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'
import Loader from 'ui/Loader'

const PlaylistTracksToImportTableRowAction = (props) => {
  const {
    id,
    deletePlaylistTrackToImport,
    deletePlaylistTrackToImportLoading
  } = props

  const isLoading = deletePlaylistTrackToImportLoading === id

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <Icon
          name='close-circle'
          action={()=>deletePlaylistTrackToImport(id)}
        >
          <Tooltip
            text='Track has been added to playlist and library?'
            pos='left'
          />
        </Icon>
      )}
    </div>
  )
}

export default PlaylistTracksToImportTableRowAction
