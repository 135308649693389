import React, {Component} from 'react'

import { connect } from 'react-redux'

import {
  createSavedCalendar
} from 'store/actions/venueAction'

import Container from 'ui/Container'
import Button from 'ui/Button'
import Input from 'ui/Input'
import Radio from 'ui/Radio'

class OverlayVenueSavedCalendarCreate extends Component{

  constructor(props){
    super(props)
    this.state = {
      value:'',
      template: 'active'
    }
  }

  createSavedCalendar(){
    const {
      data,
      dispatch
    } = this.props

    const {
      template,
      value
    } = this.state

    dispatch(
      createSavedCalendar(
        data.venueID,
        value,
        template,
        data.saved_id
      )
    )
  }

  selectTemplate(template) {
    this.setState({
      template: template
    })
  }

  render(){
    const venue = this.props.data
    return (
      <Container classname="overlay-venue-saved-calendar-create" maxWidth height="100%" column>
        <h3>Create calendar schedule for {venue.details.name}</h3>
        <Input
          change={(e) => this.setState({
            value: e.target.value
          })}
          placeholder={`Enter saved schedule name`}
        />
        <Radio
          action={(e)=>this.selectTemplate(e.target.value)}
          name='templateType'
          title='Use existing schedule?'
          options={[
            {
              label: 'Live venue schedule',
              value: 'active',
              defaultChecked: true
            },
            {
              label: venue.calendarScheduleName,
              value: 'current',
              hidden: venue.schedules.length === 0
            },
            {
              label: 'Blank',
              value: 'blank'
            }
          ]}
          vertical
        />
        <Button
          action={()=>this.createSavedCalendar()}
          name={`Create schedule`}
          disabled={this.state.value.length === 0 || false}/>
      </Container>
    )
  }
}

export default connect()(OverlayVenueSavedCalendarCreate)
