import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { linkify } from 'helpers/StringHelper'

import { toggleOverlay } from 'store/actions/overlayAction'
import { toggleConfirm } from 'store/actions/confirmAction'

import Container from 'ui/Container'
import Icon from 'ui/Icon'
import Button from 'ui/Button'
import Confirm from 'ui/Confirm'
import Filter from 'ui/Filter'
import Loader from 'ui/Loader'

class Notes extends Component{
  constructor(props){
    super(props)
    this.state = {
      filter: ''
    }
  }

  componentDidMount(){
    const {
      dispatch,
      getNotes,
      id
    } = this.props

    dispatch(getNotes(id))
  }

  addNote() {
    const {
      dispatch,
      addNote
    } = this.props

    dispatch(
      toggleOverlay(
        true,
        {
          addNote: (form) => addNote(form)
        },
        'overlayNotesAdd'
      )
    )
  }

  deleteNote(note) {
    const {
      dispatch,
      deleteNote
    } = this.props

    const confirmData = {
      action: () => {
        dispatch(deleteNote(note))
      },
      question: `We want to maintain the full note history as much as possible. Are you sure you want to delete this note?`
    }

    dispatch(toggleConfirm(true, confirmData))
  }

  filterNotes(e) {
    const text = e.target.value.toLowerCase()
    this.setState({
      filter: text
    })
  }

  render(){
    const {
      currentUser,
      notes,
      loading,
      newNoteLoading,
      noteDeleting
    } = this.props

    const {
      filter
    } = this.state

    return (
      <Container classname='notes' height="100%" column>
        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            <div className='notes__controls'>
              <Button
                action={()=>this.addNote()}
                name={`Add note`}
              />
              {notes.length > 0 && (
                <Filter
                  action={(e)=>this.filterNotes(e)}
                  placeholder='Filter notes...'
                />
              )}
            </div>
            <Container column scroll>
              {newNoteLoading && (
                <div className='note note__loading'>
                  <Loader />
                </div>
              )}
              {notes.map(note => {
                if (
                  note.content.toLowerCase().includes(filter) ||
                  note.type.toLowerCase().includes(filter) ||
                  note.user.username.toLowerCase().includes(filter)
                ) {
                  return (
                    <div
                      className={`note ${noteDeleting.includes(note.id) ? 'note--deleting' : ''}`}
                      key={note.date}
                    >
                      <p
                        className='note__text'
                        dangerouslySetInnerHTML={{ __html: linkify(note.content) }}
                      />
                      <p className='note__info'>
                        {note.user.username} &bull; {note.type} &bull; {moment(note.date).format('DD/MM/YY hh:mm:ss')}
                      </p>
                      {currentUser.id === note.user.id && (
                        <div className='note__delete'>
                          <Icon
                            name="trash"
                            action={() => this.deleteNote(note)}
                          />
                        </div>
                      )}
                    </div>
                  )
                }
              })}
            </Container>
            <Confirm />
          </Fragment>
        )}
      </Container>
    )
  }

}

function mapStateToProps(store){
  return {
    currentUser: store.auth.user,
    notes: store.notes.data,
    loading: store.notes.loading,
    newNoteLoading: store.notes.newNoteLoading,
    noteDeleting: store.notes.noteDeleting
  }
}

export default connect(mapStateToProps)(Notes)
