import axios from 'axios'

export function getNoteTypes() {
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_NOTE_TYPES',
      payload: axios.get(`/notes/types`)
    })
  }
}
