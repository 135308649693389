import React from 'react'

import Typeahead from 'ui/Typeahead'
import Badge from 'ui/Badge'

const PlaylistTableActions = (props) => {
  const {
    classname,
    typeaheadSearch,
    search,
    typeaheadAction,
    typeaheadLoading,
    getData,
    clear,
    data,
    totalTracks
  } = props
  return (
    <div className={`table-typeahead table-typeahead-${classname}`}>
      <Typeahead
        getData={(value)=>getData(value)}
        search={(value) => search(value)}
        typeaheadSearch={(value)=>typeaheadSearch(value)}
        typeaheadAction={(data)=>typeaheadAction(data)}
        typeaheadLoading={typeaheadLoading}
        clear={()=>clear()}
        data={data}
        classname={classname}/>
        <div className={`metadata`}>
          <Badge data={`Total tracks ${totalTracks}`}/>
        </div>
    </div>
  )
}

export default PlaylistTableActions
