import React from 'react'

import {Link} from 'react-router-dom'

import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'

const PlaylistTableRowActions = (props) => {
  const {id, removePlaylist, playlist} = props
  return (
    <div>
      <Link to={`/playlists/${id}`}>
        <Icon name="eye">
          <Tooltip pos="left" text="View playlist"/>
        </Icon>
      </Link>
      <Icon name="close" action={()=>removePlaylist(playlist)}>
        <Tooltip pos="left" text="Unsubscribe from playlist"/>
      </Icon>
    </div>
  )
}

export default PlaylistTableRowActions