import React from 'react'

const TableCell = (props) => {
  return (
    <td className={`table-cell table-cell-${props.classname} table-cell-size-${props.size}`}>
      {Array.isArray(props.datum) ? props.datum.join(', ') : props.datum}
    </td>
  )
}

export default TableCell