import React, { PureComponent } from 'react'

class ReportsRow extends PureComponent {
  render() {
    const {
      children
    } = this.props;

    return (
      <div className='reports-row'>
        {children}
      </div>
    )
  }
}

export default ReportsRow
