const TableModel = {
  playlists:{
    results:{
      limit:25,
      options:[
        {display:25,value:25},
        {display:50,value:50},
        {display:75,value:75},
        {display:100,value:100},
        {display:150,value:150},
        {display:200,value:200}
      ]
    },
    actionSize:3,
    viewLink:'/playlists',
    headers:[
      {title:'Name',field:'name',size:'large',sortable:true},
      {title:'Venues',field:'venues_count',size:'small',sortable:true},
      {title:'Type',field:'type',size:'normal',sortable:true},
      {title:'Children',field:'children_count',size:'small',sortable:true},
      {title:'Tracks',field:'tracks_count',size:'small'},
      {title:'Created',field:'created_at',size:'normal',sortable:true}
    ]
  },
  playlistsSearch:{
    results:{
      limit:50,
      options:[
        {display:50,value:50},
        {display:75,value:75},
        {display:100,value:100},
        {display:150,value:150},
        {display:200,value:200}
      ]
    },
    actionSize:3,
    viewLink:'/playlists',
    headers:[
      {title:'Name',field:'name',size:'large',sortable:true},
      {title:'Venues',field:'venues_count',size:'small'},
      {title:'Type',field:'playlist_type',size:'normal',sortable:true},
      {title:'Children',field:'children_count',size:'small'},
      {title:'Tracks',field:'tracks_count',size:'small',sortable:true},
      {title:'Created',field:'created_at',size:'normal'}
    ]
  },
  playlistsImporter :{
    actionSize:3,
    headers:[
      {title:'Name',field:'name',size:'normal'},
      {title:'Requested by',field:'requestedBy',size:'normal'},
      {title:'Track count',field:'count',size:'normal'},
      {title:'Created',field:'createdAt',size:'normal'}
    ],
    viewLink:'/playlists',
  },
  playlistImporterTracks :{
    actionSize:3,
    headers:[
      {title:'Title',field:'title',size:'normal'},
      {title:'Artist',field:'artist',size:'normal'},
      {title:'Album',field:'album',size:'normal'},
      {title:'Length',field:'total_length',size:'small'}
    ],
  },
  playlistTracksToImport:{
    actionSize:3,
    headers:[
      {title:'Title',field:'title',size:'normal',sortable:true},
      {title:'Artist',field:'artist',size:'normal',sortable:true},
      {title:'Album',field:'album',size:'normal',sortable:true},
    ],
  },
  playlistTracksToApprove:{
    actionSize:3,
    headers:[
      {title:'Title',field:'title',size:'normal',sortable:true},
      {title:'Artist',field:'artist',size:'normal',sortable:true},
      {title:'Album',field:'album',size:'normal',sortable:true},
      {title:'Genres',field:'genres',size:'normal'},
      {title:'Energy',field:'energy',size:'small',sortable:true,disableAction:true},
      {title:'Type',field:'type',size:'small',sortable:true},
    ],
    play:true
  },
  overlayPlaylistsTracksAdd:{
    results:{
      limit:50,
      options:[
        {display:50,value:50},
        {display:75,value:75},
        {display:100,value:100},
        {display:150,value:150},
        {display:200,value:200}
      ]
    },
    actionSize:3,
    viewLink:'/playlists',
    headers:[
      {title:'Name',field:'name',size:'large'},
      {title:'Type',field:'type',size:'normal'}
    ]
  },
  overlayPlaylistsTracksAddMulti:{
    results:{
      limit:50,
      options:[
        {display:50,value:50},
        {display:75,value:75},
        {display:100,value:100},
        {display:150,value:150},
        {display:200,value:200}
      ]
    },
    actionSize:3,
    viewLink:'/playlists',
    headers:[
      {title:'Name',field:'name',size:'large'},
      {title:'Type',field:'type',size:'normal'}
    ]
  },
  overlayPlaylistsSearch:{
    results:{
      limit:50,
      options:[
        {display:50,value:50},
        {display:75,value:75},
        {display:100,value:100},
        {display:150,value:150},
        {display:200,value:200}
      ]
    },
    actionSize:3,
    viewLink:'/playlists',
    headers:[
      {title:'Name',field:'name',size:'large'}
    ]
  },
  overlayPlaylistsTracksAddSearch:{
    results:{
      limit:50,
      options:[
        {display:50,value:50},
        {display:75,value:75},
        {display:100,value:100},
        {display:150,value:150},
        {display:200,value:200}
      ]
    },
    actionSize:3,
    viewLink:'/playlists',
    headers:[
      {title:'Name',field:'name',size:'large'}
    ]
  },
  overlayPlaylistImporter:{
    headers:[
      {title:'Title',field:'title',size:'normal'},
      {title:'Artist',field:'artist',size:'normal'},
      {title:'Album',field:'album',size:'normal'},
      {title:'Length',field:'total_length',size:'small'},
      {title:'BPM',field:'bpm',size:'small'}
    ],
    play:true
  },
  playlistTracks:{
    headers:[
      {title:'Title',field:'title',size:'normal',sortable:true},
      {title:'Artist',field:'artist',size:'normal',sortable:true,libraryAction:'?tab=search&type=artist&term='},
      {title:'Album',field:'album',size:'normal',sortable:true,libraryAction:'?tab=search&type=album&term='},
      {title:'Genres',field:'genres',size:'normal'},
      {title:'Length',field:'total_length',size:'small',sortable:true},
      {title:'Energy',field:'energy',size:'small',sortable:true},
      {title:'BPM',field:'bpm',size:'small',sortable:true},
      {title:'Split',field:'designation',size:'small',sortable:true},
      {title:'Added',field:'added',size:'normal',sortable:true}
    ],
    play:true,
    checkbox:true,
    selectAll:true
  },
  playlistTracksSplit:{
    headers:[
      {title:'Title',field:'title',size:'normal',sortable:true},
      {title:'Artist',field:'artist',size:'normal',sortable:true},
      {title:'Album',field:'album',size:'normal',sortable:true},
      {title:'Genres',field:'genres',size:'normal'},
      {title:'Length',field:'total_length',size:'small',sortable:true},
      {title:'Energy',field:'energy',size:'small',sortable:true},
      {title:'BPM',field:'bpm',size:'small',sortable:true},
      {title:'Added',field:'added',size:'normal',sortable:true}
    ],
    play:true,
    checkbox:true
  },
  playlistOrder:{
    headers:[
      {title:'Title',field:'title',size:'normal'},
      {title:'Artist',field:'artist',size:'normal'},
      {title:'Album',field:'album',size:'normal'},
      {title:'Genres',field:'genres',size:'normal'},
      {title:'Length',field:'length',size:'small'},
      {title:'Energy',field:'energy',size:'small'},
      {title:'BPM',field:'bpm',size:'small'},
      {title:'Added',field:'added',size:'normal'}
    ],
    play:true
  },
  playlistVenues:{
    headers:[
      {title:'Name',field:'name',size:'normal',sortable:true},
      {title:'Added',field:'createdAt',size:'normal',sortable:true}
    ],
    viewLink:'/venues',
  },
  overlayPlaylistAddToVenues:{
    results:{
      limit:50,
      options:[
        {display:50,value:50},
        {display:75,value:75},
        {display:100,value:100},
        {display:150,value:150},
        {display:200,value:200}
      ]
    },
    actionSize:2,
    viewLink:'/venues',
    headers:[
      {title:'Name',field:'name',size:'normal'},
      {title:'Business',field:'business',size:'normal',link:'/businesses/',linkField:'business_id'},
      {title:'Playlists',field:'playlists_count',size:'small',badge:true,badgeLink:'playlists'},
      {title:'Tracks',field:'total_tracks',size:'small'}
    ]
  },
  playlistChildren:{
    headers:[
      {title:'Name',field:'name',size:'normal',sortable:true},
      {title:'Added',field:'createdAt',size:'normal',sortable:true}
    ],
    viewLink:'/playlists',
  },
  playlistCurators:{
    headers:[
      {title:'Username',field:'username',size:'normal',sortable:true},
      //{title:'Added',field:'createdAt',size:'normal',sortable:true}
    ]
  },
  venues:{
    results:{
      limit:50,
      options:[
        {display:50,value:50},
        {display:75,value:75},
        {display:100,value:100},
        {display:150,value:150},
        {display:200,value:200}
      ]
    },
    actionSize:2,
    viewLink:'/venues',
    headers:[
      {title:'Name',field:'name',size:'normal'},
      {title:'Venue user',field:'venue_user',size:'normal'},
      {title:'Business',field:'business',size:'normal',link:'businesses/',linkField:'business_id'},
      {title:'Playlists',field:'playlists_count',size:'small',badge:true,badgeLink:'playlists'},
      {title:'Tracks',field:'total_tracks',size:'small'},
      {title:'Blocked',field:'blocked_count',size:'small',badge:true,badgeLink:'blocked'},
      {title:'Created',field:'created_at',size:'normal'},
      {title:'Last login',field:'last_login',size:'normal'}
    ]
  },
  venuePlaylists:{
    headers:[
      {title:'Name',field:'name',size:'large'},
      {title:'Type',field:'type',size:'normal'},
      {title:'Tracks',field:'tracksCount',size:'small'},
      {title:'Energy',field:'energy',size:'small',disableAction:true},
      {title:'Parent',field:'parent_name',size:'normal',link:'/playlists/',linkField:'parent_id'},
      {title:'Scheduled',field:'isScheduled',size:'normal'},
      {title:'Created',field:'createdAt',size:'normal'}
    ],
    viewLink:'/playlists',
  },
  venueBlocked:{
    headers:[
      {title:'Title',field:'title',size:'normal',sortable:true},
      {title:'Artist',field:'artist',size:'normal',sortable:true},
      {title:'Album',field:'album',size:'normal',sortable:true},
      {title:'Length',field:'total_length',size:'small',sortable:true},
      {title:'Energy',field:'energy',size:'small',sortable:true},
      {title:'BPM',field:'bpm',size:'small',sortable:true},
      {title:'Blocked',field:'added',size:'normal',sortable:true}
    ],
    play:true
  },
  businesses:{
    results:{
      limit:50,
      options:[
        {display:50,value:50},
        {display:75,value:75},
        {display:100,value:100},
        {display:150,value:150},
        {display:200,value:200}
      ]
    },
    actionSize:2,
    viewLink:'/businesses',
    headers:[
      {title:'Name',field:'name',size:'normal',sortable:true},
      {title:'Contact name',field:'contact',size:'normal',sortable:true},
      {title:'Signed up',field:'created_at',size:'normal',sortable:true},
      {title:'State',field:'state',size:'small'},
      {title:'Venues',field:'venues_count',size:'small',badge:true,badgeLink:'venues',sortable:true},
      {title:'Subscribed playlists',field:'playlistsCount',size:'small'},
      {title:'Last login',field:'lastLogin',size:'normal'}
    ]
  },
  businessVenues:{
    headers:[
      {title:'Name',field:'name',size:'normal',sortable:true},
      {title:'Venue user',field:'venue_user',size:'normal',sortable:true},
      {title:'Playlists',field:'playlists_count',size:'small',sortable:true},
      {title:'Tracks',field:'total_tracks',size:'small',sortable:true},
      {title:'Blocked',field:'blocked_count',size:'small',sortable:true},
      {title:'Created',field:'created_at',size:'normal'},
      {title:'Last login',field:'last_login',size:'normal'}
    ],
    viewLink:'/venues',
  },
  businessSubscribed:{
    headers:[
      {title:'Name',field:'name',size:'large'},
      {title:'Type',field:'type',size:'normal'},
      {title:'Tracks',field:'tracksCount',size:'small'},
      {title:'Created',field:'createdAt',size:'normal'}
    ],
    viewLink:'/subscribed',
  },
  library:{
    results:{
      limit:50,
      options:[
        {display:50,value:50},
        {display:75,value:75},
        {display:100,value:100},
        {display:150,value:150},
        {display:200,value:200}
      ]
    },
    actionSize:3,
    viewLink:'/library',
    headers:[
      {title:'Title',field:'title',size:'large',sortable:true},
      {title:'Artist',field:'artist',size:'large',sortable:true,libraryAction:'?tab=search&type=artist&term='},
      {title:'Album',field:'album',size:'large',sortable:true,libraryAction:'?tab=search&type=album&term='},
      {title:'Genres',field:'genres',size:'normal'},
      {title:'Length',field:'total_length',size:'small',sortable:true},
      {title:'Energy',field:'energy',size:'small',sortable:true},
      {title:'BPM',field:'bpm',size:'small'},
      {title:'Added',field:'created_at',size:'small',sortable:true}
    ],
    play:true,
    checkbox:true
  },
  fitnessClasses:{
    headers:[
      {title:'Name',field:'name',size:'normal'},
      {title:'Added',field:'created_at',size:'normal'},
      {title:'Sections',field:'sections_count',size:'normal'},
      {title:'Venues',field:'venues_count',size:'normal'}
    ],
    viewLink:'/class',
  },
  fitnessClassVenues:{
    headers:[
      {title:'Name',field:'name',size:'normal'},
      {title:'Added',field:'created_at',size:'normal'}
    ],
    viewLink:'/venues',
  },
  overlayFitnessVenues:{
    headers:[
      {title:'Name',field:'name',size:'normal'}
    ],
    viewLink:'/venues'
  },
  users:{
    results:{
      limit:50,
      options:[
        {display:50,value:50},
        {display:75,value:75},
        {display:100,value:100},
        {display:150,value:150},
        {display:200,value:200}
      ]
    },
    actionSize:2,
    viewLink:'/users',
    headers:[
      {title:'Username',field:'username',size:'normal'},
      {title:'Business',field:'business',size:'normal'},
      {title:'Venues managing',field:'venues_managing',size:'small',badge:true,badgeLink:'venues'},
      {title:'Created',field:'created_at',size:'normal'},
      {title:'Last login',field:'last_login',size:'normal'}
    ]
  },
  upload:{
    actionSize: 1,
    // viewLink:'/library',
    headers:[
      {title:'Artwork',field:'artworkURL',size:'small',image:true},
      {title:'Title',field:'title',size:'large'},
      {title:'Artist',field:'artist',size:'large'},
      {title:'Album',field:'album',size:'large'},
      {title:'Year',field:'year',size:'small'},
      {title:'Length',field:'length',size:'small'},
      {title:'BPM',field:'bpm',size:'small'}
    ],
  },
  overlayUploadTracks:{
    headers:[
      {title:'Title',field:'title',size:'normal'},
      {title:'Artist',field:'artist',size:'normal'},
      {title:'Album',field:'album',size:'normal'},
      {title:'Length',field:'total_length',size:'small'},
      {title:'BPM',field:'bpm',size:'small'}
    ],
    play:true
  },
  contacts:{
    results:{
      limit:50,
      options:[
        {display:50,value:50},
        {display:75,value:75},
        {display:100,value:100},
        {display:150,value:150},
        {display:200,value:200}
      ]
    },
    headers:[
      {title:'Name',field:'name',size:'normal',sortable:true},
      {title:'Email',field:'email',size:'normal',sortable:true},
      {title:'Phone',field:'phoneNumber',size:'normal'},
      {title:'Job Title',field:'jobTitle',size:'small'},
      {title:'Position',field:'position',size:'small'},
      {title:'Status',field:'status',size:'small'},
      // {title:'Notes',field:'notes',size:'small'},
      {title:'Businesses',field:'businesses_count',size:'small'},
      {title:'Venues',field:'venues_count',size:'small'},
      {title:'Added',field:'created_at',size:'normal',sortable:true}
    ],
  },
  overlayContactsAddToBusiness:{
    results:{
      limit:50,
      options:[
        {display:50,value:50},
        {display:75,value:75},
        {display:100,value:100},
        {display:150,value:150},
        {display:200,value:200}
      ]
    },
    actionSize:2,
    viewLink:'/contacts',
    headers:[
      {title:'Name',field:'name',size:'normal'},
      {title:'Email',field:'email',size:'normal'},
      {title:'Phone',field:'phoneNumber',size:'normal'},
      {title:'Job Title',field:'jobTitle',size:'small'},
      {title:'Position',field:'position',size:'small'},
      {title:'Status',field:'status',size:'small'},
      // {title:'Notes',field:'notes',size:'small'},
      // {title:'Businesses',field:'businesses',size:'small'},
      // {title:'Venues',field:'venues',size:'small'},
      // {title:'Added',field:'added',size:'normal'}
    ]
  },
  overlayContactsAddToVenue:{
    results:{
      limit:50,
      options:[
        {display:50,value:50},
        {display:75,value:75},
        {display:100,value:100},
        {display:150,value:150},
        {display:200,value:200}
      ]
    },
    actionSize:2,
    viewLink:'/contacts',
    headers:[
      {title:'Name',field:'name',size:'normal'},
      {title:'Email',field:'email',size:'normal'},
      {title:'Phone',field:'phoneNumber',size:'normal'},
      {title:'Job Title',field:'jobTitle',size:'small'},
      {title:'Position',field:'position',size:'small'},
      // {title:'Status',field:'status',size:'small'},
      // {title:'Notes',field:'notes',size:'small'},
      // {title:'Businesses',field:'businesses',size:'small'},
      // {title:'Venues',field:'venues',size:'small'},
      // {title:'Added',field:'added',size:'normal'}
    ]
  },
}
export default TableModel
