import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

import {
  getBusinessVenues
} from 'store/actions/businessAction'

import {
  openManagerWs,
  getVenueOnline,
} from 'store/actions/venueAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import TableWrapper from 'ui/TableWrapper'
import BusinessVenuesTableRowActions from './business-venues/BusinessVenuesTableRowActions'
import Online from 'ui/Online'
import Filter from 'ui/Filter'
import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'
import { toggleOverlay } from "store/actions/overlayAction"
import { tableSorter } from 'helpers/TableHelper'

const classname = 'businessVenues'

class BusinessVenues extends Component {
  constructor(props){
    super(props)
    this.state = {
      filter:'',
      view:'grid'
    }
  }

  componentDidMount(){
    this.props.dispatch(getBusinessVenues(this.props.id))
    this.props.dispatch(openManagerWs())
  }

  componentDidUpdate(prevProps){
    
    const {
      sorter,
      venues,
      ws
    } = this.props
    
    if (
      ws.init &&
      ws.open &&
      prevProps.venues !== venues &&
      venues.length > 0
    ) {
      this.getVenuesOnline()
    }
  }

  getVenuesOnline(){

    const {
      venues,
      dispatch
    } = this.props

    // loop through all venues
    for(let i = 0; i < venues.length; i++){
      if(!venues[i].onlineChecked){
        this.props.dispatch(getVenueOnline(venues[i].authToken))
      }
    }

  }

  filterVenues(e){
    const text = e.target.value.toLowerCase()
    this.setState({filter:text})
  }

  more(data){
    this.props.dispatch(toggleOverlay(true,data,'venues'))
  }

  sortType(x,y){
    const {field, direction} = this.props.sorter

    // if no direction is provided, due to the sorting icon being reset,
    // or there is no value provided to be sorted on, use default values
    if (direction === null || !(field in x)) {
      return tableSorter(x,y,'name','desc')
    }

    return tableSorter(x,y,field,direction)
  }

  getTable(){
    const venues = this.props.venues
      .sort((x, y) => this.sortType(x, y))
      .filter(venue => {
        if(venue.name.toLowerCase().includes(this.state.filter)){
          return venue
        }
        return false
      })
    return <TableWrapper
      classname={classname}
      data={venues}
      count={this.props.count}
      clearData={()=>this.clearVenues()}
      getData={(limit,offset)=>this.getVenues(limit,offset)}
      loading={this.props.loading}
      rowActions={<BusinessVenuesTableRowActions
        more={(data)=>this.more(data)}
        classname={classname}/>
      }/>
  }

  getCards(){
    return this.props.venues.map((venue,index) => {
      if(venue.name.toLowerCase().includes(this.state.filter)){
        return (
          <Link
            to={`/venues/${venue.id}?tab=schedule`}
            className={`card-container ${isMobile && 'mobile'}`}
            key={index}>
            <div className="venue-name">{venue.name}</div>
            <div className="venue-tracks">{venue.total_tracks} available tracks</div>
            <div className="venue-blocked">{venue.blocked_count} blocked tracks</div>
            <div className="venue-online">
              {venue.online ? 'online' : 'offline'}
            </div>
            <Online online={venue.online}/>
          </Link>
        )
      }
      return false
    })
  }

  getView(){
    if(this.state.view === 'grid'){
      return this.getCards()
    } else {
      return this.getTable()
    }
  }

  changeView(view){
    this.setState({
      view:view
    })
  }

  render(){
    if(this.props.loading){
      return <Loader/>
    } else {
      if (this.props.venues.length === 0) {
        return (
          <Container classname={classname} height="100%" maxWidth column>
            <h3>This business has no venues</h3>
          </Container>
        )
      }
      return (
        <Container classname={classname} height="100%" maxWidth column>
          <Container classname="filter-wrapper">
            <Filter action={(e)=>this.filterVenues(e)} placeholder="Filter venues..."/>
            <Icon
              name="ios-grid"
              classname={this.state.view === 'grid' ? 'active' : ''}
              action={()=>this.changeView('grid')}>
                <Tooltip text="Grid view" pos="left"/>
            </Icon>
            <Icon
              name="ios-list"
              classname={this.state.view === 'table' ? 'active' : ''}
              action={()=>this.changeView('table')}>
                <Tooltip text="Table view" pos="left"/>
            </Icon>
          </Container>
          <Container classname={`${classname}cards`} height="100%" scroll>
            {this.getView()}
          </Container>
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    venues:store.business.venues,
    loading:store.business.loading,
    loaded:store.venues.loaded,
    sorter:store.table.sorter,
    ws:store.venueWS.ws
  }
}

export default connect(mapStateToProps)(BusinessVenues)
