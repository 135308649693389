import React from 'react'

import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'

const PlaylistChildrenTableRowActions = (props) => {
  const {id,viewPlaylist} = props
  return (
    <div>
      <Icon name="eye" action={() => viewPlaylist(id)}>
        <Tooltip pos="left" text="View playlist"/>
      </Icon>
    </div>
  )
}

export default PlaylistChildrenTableRowActions
