import React from 'react'

import Icon from 'ui/Icon'

const Energy = (props) => {
  return (
    <span className={`energy energy-${props.energy}`}>
      <span className="energy-number">{props.energy}</span>
      <Icon name="flash"/>
    </span>
  )
} 

export default Energy
