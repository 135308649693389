import React, {Fragment} from 'react'

import Icon from 'ui/Icon'
import Loader from 'ui/Loader'

const OverlayPlaylistTracksAddTableRowActions = (props) => {
  const {
    addTrackToPlaylistLoading,
    classname,
    playlist
  } = props

  return (
    <div className={`${classname}`}>
      {addTrackToPlaylistLoading === playlist.id ? (
        <Loader />
      ): (
        <Fragment>
          {playlist.isTrackAssigned ?
            <Icon
              name="checkmark-circle"
              success
            />
            :
            <Icon
              name="add-circle"
            />
          }
        </Fragment>
      )}
    </div>
  )
}

export default OverlayPlaylistTracksAddTableRowActions
