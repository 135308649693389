import React, { PureComponent } from 'react'
import GridLayout, { WidthProvider } from 'react-grid-layout'

import ScheduleItem from './ScheduleItem'

const ScheduleGrid = WidthProvider(GridLayout)

let dragTimer;

class Schedule extends PureComponent {
  constructor(props){
    super(props)

    this.state = {
      layout: [],
      days:[
        'Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'
      ],
      wasDrag: false
    }

    this.openPlaylistInfo = this.openPlaylistInfo.bind(this)
    this.checkMaxPos = this.checkMaxPos.bind(this)
    this.ref = React.createRef()
  }

  checkMaxPos(layout, oldItem, newItem) {
    const {
      days,
      disabledSchedule,
      fullTimes,
      selectedType,
      scheduleArray,
      setScheduleArray,
      setScheduleTemplateArray,
      updateVenueSchedule
    } = this.props;

    // get the index of the changed card
    const index = scheduleArray.findIndex((schedule) => {
      return schedule.i === newItem.i
    })

    clearTimeout(dragTimer);

    // if there has been no change in card co-ords, return false
    if (oldItem.y === newItem.y && oldItem.h === newItem.h && oldItem.x === newItem.x) {
      return false
    }

    // if the new card end time is greater than the max allowed, set it to the initial values
    if (newItem.y + newItem.h > 48) {
      layout[index].h = oldItem.h;
      layout[index].y = oldItem.y;
      layout[index].x = oldItem.x;
    }

    // update the schedule array with the updated card data
    if (scheduleArray[index] && scheduleArray[index].id) {
      let newScheduleArray = []

      scheduleArray.forEach((playlist, index)=>{
        let item = {};
        item.i = playlist.i;
        item.x = layout[index].x;
        item.y = layout[index].y;
        item.w = 1;
        item.h = layout[index].h;
        item.maxW = 1;
        item.playlistName = playlist.playlistName;
        item.id = playlist.id;
        item.playlistID = playlist.playlistID;
        if (selectedType === 'calendar') {
          item.date = days[layout[index].x].date;
        }
        item.static = playlist.static;
        item.interleave = playlist.interleave;

        newScheduleArray.push(item);
      })

      if (disabledSchedule) {
        setScheduleTemplateArray(newScheduleArray)
      } else {
        setScheduleArray(newScheduleArray)
      }

      updateVenueSchedule(scheduleArray[index].id, {
        'playlistID': newScheduleArray[index].playlistID,
        'midnight_overlap': (layout[index].y < 40) && ((layout[index].y + layout[index].h) >= 40),
        'start': `${fullTimes[layout[index].y]}:00`,
        'end': `${fullTimes[layout[index].h + layout[index].y]}:00`,
        'day': layout[index].x,
        'date': newScheduleArray[index].date || null
      })
    }
  }

  dragStart() {
    const {
      setActivePlaylistInfo
    } = this.props;

    dragTimer = setTimeout(() => {
      this.setState({
        wasDrag: true
      });


      setActivePlaylistInfo('')
    }, 200)
  }

  openPlaylistInfo(playlistIndex, playlistID, e) {
    const {
      activePlaylistInfo,
      disabledSchedule,
      editArray,
      isInterleave,
      setActivePlaylistInfo,
      setEditArray,
      setSelectedPlaylist,
      setSelectedInterleaveRatio
    } = this.props;

    const {
      wasDrag
    } = this.state;

    const isActive = activePlaylistInfo === playlistIndex;

    if (disabledSchedule) {
      return false;
    }

    clearTimeout(dragTimer)
    e.stopPropagation()

    if (wasDrag) {
      this.setState({
        wasDrag: false
      });
      return false;
    }

    if (e.metaKey) {
      let newEditArray = [...editArray]

      setActivePlaylistInfo('')

      if (editArray.some(e => e.uuid === playlistIndex)) {
        newEditArray = newEditArray.filter((item) => {
          return item.uuid !== playlistIndex
        })
      } else {
        newEditArray.push({
          uuid: playlistIndex,
          scheduleID: playlistID
        })
      }

      setEditArray(newEditArray)
      return
    }

    if (isInterleave) {
      setEditArray([])
      setSelectedPlaylist('', '')
      setSelectedInterleaveRatio(2)
    }

    if (!isActive) {
      setActivePlaylistInfo(playlistIndex)
    } else {
      setActivePlaylistInfo('')
    }
  }

  render() {
    const {
      disabledSchedule,
      editArray,
      isInterleave,
      scheduleArray,
      activePlaylistInfo
    } = this.props

    const {
      days
    } = this.state

    return (
      <ScheduleGrid
        autoSize={false}
        className='schedule'
        cols={7}
        isResizeable={true}
        margin={[0 ,0]}
        preventCollision={true}
        compactType={null}
        rowHeight={15}
        layout={scheduleArray}
        onDragStart={() => {
          this.dragStart();
        }}
        onDragStop={
          this.checkMaxPos
        }
        onResizeStart={() => {
          this.dragStart();
        }}
        onResizeStop={
          this.checkMaxPos
        }
        ref={this.ref}
      >
        {scheduleArray.map((schedule)=>{
          const isActive = activePlaylistInfo === schedule.i;
          const isForEdit = editArray && editArray.some(e => e.uuid === schedule.i);

          return (
            <div
              className={
                `schedule__item
                ${isActive ? ' schedule__item--active' : ''}
                ${isForEdit ? ' schedule__item--for-edit' : ''}
                ${isInterleave ? ' schedule__item--interleave' : ''}
                ${(isInterleave && schedule.interleave) ? ' schedule__item--active-interleave' : ''}
                ${(disabledSchedule) ? ' schedule__item--disabled' : ''}`
              }
              onClick={(e) => { this.openPlaylistInfo(schedule.i, schedule.id, e); }}
              key={schedule.i}
              data-grid={{
                i: schedule.i
              }}
            >
              <ScheduleItem
                {...this.props}
                days={days}
                isActive={isActive}
                schedule={schedule}
                scheduleRef={this.ref.current ? this.ref.current.elementRef.current : null}
              />
            </div>
          )
        })}
      </ScheduleGrid>
    )
  }
}

export default Schedule
