import React, {Component} from 'react'
import { connect } from 'react-redux'

import { getVenueDetails, updateVenueDetails } from 'store/actions/venueAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Form from 'ui/Form'

const classname = 'venueDetails'

class VenueDetails extends Component {
  constructor(props){
    super(props)
    this.state = {
      displayName:null
    }
    this.fileSizes = [
      {display:'Please select',value:0},
      {display:'Small - 80',value:1},
      {display:'Standard - 160',value:2}
    ]
  }

  componentDidMount(){
    this.props.dispatch(getVenueDetails(this.props.id))
  }

  componentDidUpdate(prevProps){
    const {
      setHeader,
      venue
    } = this.props

    if(venue !== prevProps.venue){
      setHeader(`${venue.name}`)
    }
  }

  onSubmit(form){
    this.props.dispatch(updateVenueDetails(
      this.props.id,
      form
    ))
  }

  render(){
    const {
      error,
      venueDetailsLoading,
      venue
    } = this.props

    if(venueDetailsLoading){
      return <Loader/>
    } else {
      return (
        <Container classname={classname} height="100%" maxWidth>
          <Form
            classname={classname}
            submit={(form)=>this.onSubmit(form)}
            messages={this.state.message}
            data={venue}
            fileSizes={this.fileSizes}
            serverError={error && error.response.data.res}
            scroll
          />
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    venue:store.venue.details,
    venueDetailsLoading:store.venue.venueDetailsLoading,
    error:store.venue.error
  }
}

export default connect(mapStateToProps)(VenueDetails)
