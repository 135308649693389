import React, { Component } from 'react'

import Container from 'ui/Container'
import Icon from 'ui/Icon'

export default class VenueDisplay extends Component{

  fullscreen(e){
    this.props.fullscreen()
  }
  retry(){
    window.location.reload()
  }
  render(){
    if(!this.props.hasPlayer){
      return (
        <Container classname="venue-display-not-connected" column>
          <p>Not connected...</p>
          <Icon name="refresh" action={()=>this.retry()}/>
        </Container>
      )
    } else {
      return(
        <Container classname="venue-display" maxWidth column>
          <div className="display-header">Now playing at {this.props.name}</div>
          <div className="display-track">{this.props.display.track}</div>
          <div className="display-artist">{this.props.display.artist}</div>
          <div className="display-playlist">{this.props.energy.isActive ? `Energy set at ${this.props.energy.level}` : (this.props.display.playlist ? this.props.display.playlist : '')}</div>
          <div className="display-ends">{this.props.display.ends ?  'Schedule ends ' + this.props.display.ends : ''}</div>
        </Container>
      )
    }
  }
}