import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux'

import { getBusinessVenues } from 'store/actions/businessAction'
import { copyAnnouncementsToVenues } from 'store/actions/venueAction'


import Container from 'ui/Container'
import Icon from 'ui/Icon'
import Button from 'ui/Button'
import Loader from 'ui/Loader'

const classname = 'overlayVenueScheduleCopy'

class OverlayAnnouncementCopy extends Component{

  constructor(props){
    super(props)
    this.state = {
      venues:[],
      allSelected:false
    }
  }

  componentDidMount(){
    const {data} = this.props
    this.props.dispatch(getBusinessVenues(data.businessID))
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.venues !== this.props.venues){
      this.setStateVenues()
    }
  }
  
  setStateVenues(){
    const venues = this.props.venues
      .map(venue => {
        venue.selected = false
        return venue
      })
      //remove current venue from selection
      .filter(venue => venue.id !== this.props.data.venueID)

    this.setState({
      venues:venues
    })
  }

  toggleSelect(index){
    let venues = [...this.state.venues]
    venues[index].selected = !venues[index].selected
    this.setState({
      venues:venues
    })
  }

  getVenues(){
    return this.state.venues.map((venue,index) => {
      return <div
        className={`venue-overlay ${venue.selected ? 'selected' : ''}`}
        key={index}
        onClick={()=>this.toggleSelect(index)}>
          {venue.name} {venue.selected ? <Icon name="checkmark-circle"/>: ''}
      </div>
    })
  }

  selectDeselectAll(){
    const allSelected = !this.state.allSelected
    if(allSelected){
      const venues = this.state.venues.map(venue => {
        venue.selected = true
        return venue
      })
      this.setState({
        venues:venues,
        allSelected:allSelected
      })
    } else {
      const venues = this.state.venues.map(venue => {
        venue.selected = false
        return venue
      })
      this.setState({
        venues:venues,
        allSelected:allSelected
      })
    }
  }

  copySchedules(){
    const {
      businessID,
      venueID
    } = this.props.data
    const venues = [...this.state.venues]
    let selectedVenues = []
    for(let venue of venues){
      if(venue.selected){
        selectedVenues.push(venue.id)
      }
    }

    this.props.dispatch(copyAnnouncementsToVenues({
      businessID:businessID,
      venueID:venueID,
      selectedVenues:selectedVenues
    }))
  }

  render(){
    return (
      <Container classname={classname} maxWidth height="100%" column>
        {
          this.props.announcementsLoading ? 
          <Loader/> : 
          <Fragment>
            <div className="header">
              <span>Select venues to copy to.</span>
              <div className="select-deselect" onClick={() => this.selectDeselectAll()}>
                {this.state.allSelected ? 'deselect all' : 'select all'}
              </div>
            </div>
            <div className="venues-overlay-wrapper">
              {this.getVenues()}
            </div>
            <Button 
              action={()=>this.copySchedules()}
              name="Copy Schedules to Venues"/>
          </Fragment>
        }
      </Container>
    )
  }
}
function mapStateToProps(store){
  return {
    venues:store.business.venues,
    announcementsLoading:store.venue.announcementsLoading,
    loading:store.overlay.loading
  }
}
export default connect(mapStateToProps)(OverlayAnnouncementCopy)