import React, { Component } from 'react'
import {
  Cell,
  PieChart,
  Pie,
  ResponsiveContainer
} from 'recharts';

class ReportsPieChart extends Component {

  constructor(props){
    super(props)

    this.colours = ['#4ba0b3', '#22aaae', '#12b39c', '#3fb980', '#6cbc5d', '#9bbb37', '#ccb408', '#ffa600']
  }

  render() {
    const {
      data
    } = this.props

    if (!data || data.length === 0) {
      return null
    }

    let pieData = [];

    Object.keys(data).map((key, index) => {
      if (key !== 'keys' && index < 8) {
        pieData.push({name: key, value: data[key]})
      }
      return false
    });

    return (
      <ResponsiveContainer width="99%" height={300}>
        <PieChart>
          <Pie
            data={pieData}
            dataKey="value"
            fill="#8884d8"
            label={(entry)=>`${entry.name}`}
            labelLine
            outerRadius="50%"
          >
            {pieData.map((entry, index) =>
              <Cell
                key={`cell-${index}`}
                fill={this.colours[index % this.colours.length]}
                style={{ fontFamily: 'Walsheim, sans-serif', fontSize: '11px' }}
              />
            )}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    )
  }
}

export default ReportsPieChart
