const initialState = {
  data:[],
  class:{},
  createdClass: null,
  typeahead:[],
  typeaheadLoading:false,
  count:null,
  loading:false,
  loaded:false,
  updating:false,
  venues: [],
  removingClassIDs: [],
  updatingVenueIDs: [],
  removingVenueIDs: [],
  error:null
}
export default function reducer(state = initialState, action){
  switch (action.type){
    //playlists
    case 'CLEAR_FITNESS_CLASSES':{
      state = {...state,data:[]}
      break
    }
    case 'FETCH_FITNESS_CLASSES_PENDING':{
      state = {...state,loading:true,data:[]}
      break
    }
    case 'FETCH_FITNESS_CLASSES_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_FITNESS_CLASSES_FULFILLED':{
      const fitnessClasses = action.payload.data.map((fitnessClass)=>{
        const data = {
          id: fitnessClass.id,
          name: fitnessClass.name,
          created_at: fitnessClass.created_at.standard,
          sections_count: fitnessClass.sections_count,
          venues_count: fitnessClass.venues_count
        }
        return data
      })

      state =  {
        ...state,
        loading:false,
        loaded:true,
        data: fitnessClasses,
        count:action.payload.data.length
      }
      break
    }
    case 'SEARCH_FITNESS_CLASSES_TYPEAHEAD_PENDING':{
      state = {
        ...state,
        typeahead:[],
        typeaheadLoading:true
      }
      break
    }
    case 'SEARCH_FITNESS_CLASSES_TYPEAHEAD_REJECTED':{
      state = {
        ...state,
        typeaheadLoading:false,
        error:action.payload
      }
      break
    }
    case 'SEARCH_FITNESS_CLASSES_TYPEAHEAD_FULFILLED':{
      state =  {
        ...state,
        typeahead:state.typeahead.concat(action.payload ? action.payload.data : []),
        typeaheadLoading: false
      }
      break
    }
    case 'CLEAR_FITNESS_CLASSES_TYPEAHEAD':{
      state = {
        ...state,
        typeahead:[]
      }
      break
    }
    case 'CREATE_FITNESS_CLASS_PENDING':{
      state = {
        ...state,
        loading:true
      }
      break
    }
    case 'CREATE_FITNESS_CLASS_REJECTED':{
      state = {
        ...state,
        loading:false,
        error:action.payload
      }
      break
    }
    case 'CREATE_FITNESS_CLASS_FULFILLED':{
      state =  {
        ...state,
        loading:false,
        loaded:true,
        class:action.payload.data
      }
      break
    }
    case 'CLEAR_FITNESS_CLASS':{
      state = {
        ...state,
        class:{}
      }
      break
    }
    case 'FETCH_FITNESS_CLASS_PENDING':{
      state = {...state,loading:true,class:{}}
      break
    }
    case 'FETCH_FITNESS_CLASS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_FITNESS_CLASS_FULFILLED':{
      state =  {
        ...state,
        loading:false,
        loaded:true,
        class:action.payload.data
      }
      break
    }
    case 'UPDATE_FITNESS_CLASS_PENDING':{
      state = {
        ...state,
        updating:true
      }
      break
    }
    case 'UPDATE_FITNESS_CLASS_REJECTED':{
      state = {
        ...state,
        updating:false,
        error:action.payload
      }
      break
    }
    case 'UPDATE_FITNESS_CLASS_FULFILLED':{
      state =  {
        ...state,
        updating:false
      }
      break
    }
    case 'DELETE_FITNESS_CLASS_PENDING':{
      state = {
        ...state,
        removingClassIDs: state.removingClassIDs.concat(action.meta)
      }
      break
    }
    case 'DELETE_FITNESS_CLASS_REJECTED':{
      state = {
        ...state,
        removingClassIDs: state.removingClassIDs.filter(venueId=>venueId !== action.meta),
        error: action.payload
      }
      break
    }
    case 'DELETE_FITNESS_CLASS_FULFILLED':{
      state =  {
        ...state,
        removingClassIDs: state.removingClassIDs.filter(venueId=>venueId !== action.meta),
        data: state.data.filter(fitnessClass=>fitnessClass.id !== action.meta)
      }
      break
    }
    case 'FETCH_FITNESS_CLASS_VENUES_PENDING':{
      state = {...state,
        loading: action.meta
      }
      break
    }
    case 'FETCH_FITNESS_CLASS_VENUES_REJECTED':{
      state = {
        ...state,
        loading: false,
        error: action.payload
      }
      break
    }
    case 'FETCH_FITNESS_CLASS_VENUES_FULFILLED':{
      const venues = action.payload.data.venues.map((venue)=>{
        const data = {
          id:venue.id,
          name:venue.name,
          created_at:venue.created_at.standard
        }
        return data
      })

      state =  {
        ...state,
        loading: false,
        loaded: true,
        venues: venues
      }
      break
    }
    case 'ADD_FITNESS_CLASS_TO_VENUE_PENDING':{
      state = {
        ...state,
        updatingVenueIDs: state.updatingVenueIDs.concat(action.meta)
      }
      break
    }
    case 'ADD_FITNESS_CLASS_TO_VENUE_REJECTED':{
      state = {
        ...state,
        updatingVenueIDs: state.updatingVenueIDs.filter(venueId=>venueId !== action.meta),
        error:action.payload
      }
      break
    }
    case 'ADD_FITNESS_CLASS_TO_VENUE_FULFILLED':{
      const venue = {
        id: action.payload.data.id,
        name: action.payload.data.name,
        created_at: action.payload.data.created_at.standard
      }

      state =  {
        ...state,
        updatingVenueIDs: state.updatingVenueIDs.filter(venueId=>venueId !== action.meta),
        venues: state.venues.concat(venue)
      }
      break
    }
    case 'REMOVE_FITNESS_CLASS_FROM_VENUE_PENDING':{
      state = {
        ...state,
        removingVenueIDs: state.removingVenueIDs.concat(action.meta)
      }
      break
    }
    case 'REMOVE_FITNESS_CLASS_FROM_VENUE_REJECTED':{
      state = {
        ...state,
        removingVenueIDs: state.removingVenueIDs.filter(venueId=>venueId !== action.meta),
        error: action.payload
      }
      break
    }
    case 'REMOVE_FITNESS_CLASS_FROM_VENUE_FULFILLED':{
      state =  {
        ...state,
        removingVenueIDs: state.removingVenueIDs.filter(venueId=>venueId !== action.meta),
        venues: state.venues.filter(venue=>venue.id !== action.payload.data)
      }
      break
    }
    default: // no default case
  }
  return state
}
