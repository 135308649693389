import React from 'react'
import { connect } from "react-redux"
import {
  getBlockedTracks,
  removeBlockedTrack
} from '../../store/actions/venueAction'
import { getTrackUrl, getTrackInfo } from 'store/actions/playerAction'

import { tableSorter } from 'helpers/TableHelper'

import VenueBlockedTableRowActions from './blocked/VenueBlockedTableRowActions'

import Container from 'ui/Container'
import TableWrapper from 'ui/TableWrapper'
import Confirm from 'ui/Confirm'
import PlaylistTableActions from './playlist/PlaylistTableActions'

const classname = 'venueBlocked'

class VenueBlocked extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      filter:null,
      actionActive:false
    }
    this.selectedTracks = []
  }

  componentDidMount(){
    this.props.dispatch(getBlockedTracks(this.props.id))
  }

  componentDidUpdate(prevProps){
    const {
      sorter
    } = this.props

    if (JSON.stringify(prevProps.sorter) !== JSON.stringify(sorter)) {
      this.setTracks()
    }
  }

  removeBlockedTrack(id){
    this.props.dispatch(removeBlockedTrack(id))
  }

  selectAll(selected){
    const {
      filter,
      tracks
    } = this.props

    // create new array, so that we do not mutate the props data
    // allows proper reset of sorting order
    const sortedTracks = [...tracks]

    //sort tracks by date added to playlist and filter
    if(selected){
      sortedTracks
        .sort((x, y) => this.sortType(x, y))
        .filter(track => {
          //console.log(track.filter)
          if (track.filter && track.filter.toLowerCase().includes(filter.toLowerCase())) {
            this.selectedTracks.push(track)
          }
          return false
        })
        this.setState({actionActive:true})
    } else {
      this.selectedTracks = []
      this.setState({actionActive:false})
    }
    
  }

  sortType(x,y){
    const {field, direction} = this.props.sorter

    // if no direction is provided, due to the sorting icon being reset,
    // or there is no value provided to be sorted on, use default values
    if (direction === null || !(field in x)) {
      return tableSorter(x,y,'title','asc')
    }

    return tableSorter(x,y,field,direction)
  }

  setTracks(){
    const {
      filter,
      tracks
    } = this.props

    //sort tracks by date added to playlist and filter
    const sortedTracks = tracks
      .sort((x, y) => this.sortType(x, y))
      .filter(track => {
        if (track.filter && track.filter.toLowerCase().includes(filter.toLowerCase())) {
          return track
        }
        return false
      })
    //set tracks in state for use in table
    this.setState({
      tracks:sortedTracks
    })
  }

  playTrack(track){
    console.log(track)
  }

  playTrack(track){
    const {
      dispatch
    } = this.props

    dispatch(getTrackInfo(track, 'playlist'))
    dispatch(getTrackUrl(track, 'playlist '))
  }

  render(){
    return(
      <Container classname={classname} height="100%" maxWidth column>
        <TableWrapper
          userSelect
          classname={classname}
          checkboxSelected={this.selectedTracks}
          checkboxAction={(e,data,index)=>this.checkboxAction(e,data,index)}
          clickRow={(e,data,isChecked,index)=>this.clickRow(e,data,isChecked,index)}
          data={this.props.tracks}
          count={this.props.tracks.length}
          selectAll={(selected)=>this.selectAll(selected)}
          play={(track)=>this.playTrack(track)}
          playingTrack={this.props.playingTrack}
          rowActions={<VenueBlockedTableRowActions
            removeBlockedTrack={(id)=>this.removeBlockedTrack(id)}
            />
          }
        />
        <Confirm />
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    tracks:store.venue.blockedTracks,
    playingTrack: store.player.track,
    sorter: store.table.sorter
  }
}

export default connect(mapStateToProps)(VenueBlocked)