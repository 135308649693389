import React, { Component } from 'react'
import queryString from 'qs'

import FitnessTimeline from 'modules/fitness/FitnessTimeline'
import FitnessVenues from 'modules/fitness/FitnessVenues'

import Tabs from 'ui/Tabs'
import Container from 'ui/Container'
import { connect } from 'react-redux'

const classname = 'fitnessClass'

class FitnessClassContainer extends Component {

  constructor(props){
    super(props)
    this.state = {
      tab:'timeline'
    }
  }

  componentDidMount(){
    this.getTabQuery()
  }

  getTabQuery(){
    const query = queryString.parse(this.props.location.search.slice(1))
    if(typeof query.tab !== 'undefined' && query.tab !== this.state.tab){
      this.selectTab(query.tab)
    }
  }

  selectTab(tab){
    this.setState({tab:tab},()=>{
      this.props.history.push({
        search: `?tab=${tab}`
      })
    })
  }

  setHeader(header){
    this.setState({header:header})
  }

  getComponent(){
    const id = this.props.match.params.id

    switch(this.state.tab){
      case 'timeline':
        return (
          <FitnessTimeline
            id={id}
          />
        )
      case 'venues':
        return (
          <FitnessVenues
            id={id}
          />
        )
      default: // no default case
    }
  }

  render(){
    return (
      <Container classname={`${classname}-wrapper container-tabview`} column>
        <Container classname='tabs'>
          <Tabs
            match={this.props.match}
            classname={classname}
            select={(tab)=>this.selectTab(tab)}
            active={this.state.tab}
            header={this.props.fitnessClass.name}
          />
        </Container>
        <Container height="100%" maxWidth column>
          {this.getComponent()}
        </Container>
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    fitnessClass: store.fitness.class
  }
}

export default connect(mapStateToProps)(FitnessClassContainer)
