import React from 'react'

import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'
import Loader from 'ui/Loader'

const LibraryTableActions = (props) => {
  const {
    favouriteTrack,
    track,
    playlistTracks,
    addTrackToPlaylist,
    removeTrackFromPlaylist,
    addTrackToQueue,
    editTrack,
    addTrackToOwnedPlaylistLoading,
    deleteTrack
  } = props

  let playlistTrack = null
  if(playlistTracks){
    playlistTrack = playlistTracks.find(ptrack => ptrack.id === track.id)
  }

  return (
    <div>
        {
          addTrackToOwnedPlaylistLoading === track.id ? (
            <Loader/>
          ) : (
            <Icon
              name={playlistTrack ? 'remove-circle' : 'add-circle'}
              action={()=>{
                playlistTrack ? (
                  removeTrackFromPlaylist(playlistTrack)
                ) : (
                  addTrackToPlaylist({track:track})
                )
              }}
            >
            <Tooltip
              pos="left"
              text={playlistTrack ? 'Remove track from playlist' : 'Add track to playlist'}
            />
          </Icon>
          )
        }
      <Icon name="ios-list" action={()=>addTrackToQueue({track:track})} stopPropagation>
        <Tooltip pos="left" text="Add track to queue"/>
      </Icon>
      <Icon name="ios-create" action={()=>editTrack(track)} stopPropagation>
        <Tooltip pos="left" text="Edit track"/>
      </Icon>
      <Icon name="star" action={()=>favouriteTrack({track:track})}>
        <Tooltip pos="left" text="Favourite track"/>
      </Icon>
      <Icon name="trash" action={()=>deleteTrack(track)}>
        <Tooltip pos="left" text="Delete track"/>
      </Icon>
    </div>
  )
}

export default LibraryTableActions
