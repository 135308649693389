import React, { PureComponent } from 'react'

class ReportsUnit extends PureComponent {
  render(){
    const {
      children,
      header,
      width
    } = this.props;

    return (
      <div className={`reports-unit reports-unit__col-${width || '100'}`}>
        <div className='reports-unit__inner'>
          <h3 className='reports-unit__header'>
            {header}
          </h3>
          {children}
        </div>
      </div>
    )
  }
}

export default ReportsUnit
