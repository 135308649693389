import React from 'react'
import {Link} from 'react-router-dom'

import Icon from 'ui/Icon'
import TableModel from 'models/TableModel'

const BusinessesTableActions = (props) => {
  const {classname,id,name,more} = props
  return (
    <div>
      <Link to={`${TableModel[classname].viewLink}/${id}?tab=details`}><Icon name="eye"/></Link>
      <Icon name="more" action={()=>more({id:id,name:name})}/>
    </div>
  )
}

export default BusinessesTableActions
