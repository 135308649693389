import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  getBusinessNotes,
  addBusinessNote,
  deleteBusinessNote
 } from 'store/actions/businessAction'

import Notes from 'components/Notes'

import Container from 'ui/Container'
import Loader from 'ui/Loader'

const classname = 'businessNotes'

class BusinessNotes extends Component {

  render(){
    const {
      id,
      loading
    } = this.props

    if(loading){
      return <Loader/>
    } else {
      return (
        <Container classname={classname} height="100%" column>
          <Notes
            getNotes={()=>getBusinessNotes(id)}
            addNote={(note)=>addBusinessNote(id, note)}
            deleteNote={(note)=>deleteBusinessNote(note)}
          />
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    loading:store.business.loading,
    error:store.business.error
  }
}

export default connect(mapStateToProps)(BusinessNotes)
