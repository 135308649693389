import React, { Component } from 'react'
import { connect } from 'react-redux'

import Container from 'ui/Container'
import Icon from 'ui/Icon'
import Badge from 'ui/Badge'
import MessageItem from './messages/MessageItem'

import { toggleMessages } from 'store/actions/messagesAction'

class Overlay extends Component{

  constructor(props){
    super(props)
    this.state = {
      isActive:false,
      error:false
    }
  }

  componentDidMount(){
    this.checkErrors()
  }

  componentDidUpdate(prevProps){
    if(prevProps.messages !== this.props.messages){
      this.checkErrors()
    }
  }

  toggleMessages(){
    this.setState({isActive:!this.props.isActive},()=>{
      this.props.dispatch(toggleMessages(this.state.isActive))
    })
  }

  getMessages(){
    return this.props.messages.map((message,index)=>
      <MessageItem key={index} icon={message.icon} type={message.type} text={message.text}/>
    )
  }

  checkErrors(){
    let error = false
    if(this.props.messages.length > 0){
      this.props.messages.map((message)=>{
        if(message.type === 'error'){
          error = true
        }
        return false
      })
    }
    this.setState({error:error})
  }

  stopPropagation(e){
    e.stopPropagation()
  }

  render(){
    return (
      <Container classname="messages-wrapper">
        <Icon name="notifications" action={()=>this.toggleMessages()}/>
        {this.props.loading ? <Icon name="sync"/> : (this.state.error ? <Icon name="alert"/> : <Badge data={this.props.messages.length}/>)}
          <Container classname={`messages ${this.props.isActive ? 'active' : ''}`} scroll column action={(e)=>this.stopPropagation(e)}>
            {this.getMessages()}
          </Container>
      </Container>
    )
  }

}

function mapStateToProps(store){
  return {
    messages:store.messages.data,
    loading:store.messages.loading,
    isActive:store.messages.isActive
  }
}

export default connect(mapStateToProps)(Overlay)
