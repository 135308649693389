import { formatDate } from 'helpers/DateHelper'

const initialState = {
  count: 0,
  contactsPositions: [],
  contactsStates: [],
  data: [],
  loading: false,
  contactFieldsLoading: false,
  error: null
}

export default function reducer(state = initialState, action){
  switch (action.type){
    case 'FETCH_CONTACTS_PENDING':{
      state = {
        ...state,
        loading: true
      }
      break
    }
    case 'FETCH_CONTACTS_REJECTED':{
      state = {
        ...state,
        loading: false,
        error: action.payload
      }
      break
    }
    case 'FETCH_CONTACTS_FULFILLED':{
      const contacts = action.payload.data.contacts.map(data => {
        return ({
            id: data.id,
            name: data.name,
            email: data.email,
            phoneNumber: data.phone_number,
            jobTitle: data.job_title,
            position: data.position ? data.position.position : null,
            positionID: data.position_id,
            status: data.status ? data.status.status : null,
            statusID: data.status_id,
            notes: data.notes,
            businesses: data.businesses,
            businesses_count: data.businesses_count,
            venues: data.venues,
            venues_count: data.venues_count,
            created_at: formatDate(data.created_at)
          }
        )
      })

      state = {
        ...state,
        count: action.payload.data.count,
        data: contacts,
        loading: false
      }
      break
    }

    case 'FETCH_CONTACTS_FIELDS_PENDING':{
      state = {
        ...state,
        contactFieldsLoading: true
      }
      break
    }

    case 'FETCH_CONTACTS_FIELDS_REJECTED':{
      state = {
        ...state,
        loading: false,
        error: action.payload
      }
      break
    }

    case 'FETCH_CONTACTS_FIELDS_FULFILLED':{
      const initialValue = {
        display: '------',
        value: 0
      }

      const contactsPositions = action.payload.data.positions.map((position) => {
        return {
          display: position.position,
          value: position.id
        }
      })

      contactsPositions.unshift(initialValue)

      const contactsStates = action.payload.data.states.map((status) => {
        return {
          display: status.status,
          value: status.id
        }
      })

      contactsStates.unshift(initialValue)

      state = {
        ...state,
        contactsStates: contactsStates,
        contactsPositions: contactsPositions,
        contactFieldsLoading: false
      }
      break
    }

    case 'SEARCH_CONTACTS_PENDING':{
      state = {
        ...state,
        loading:true
      }
      break
    }
    case 'SEARCH_CONTACTS_REJECTED':{
      state = {
        ...state,
        loading:false,
        error:action.payload
      }
      break
    }
    case 'SEARCH_CONTACTS_FULFILLED':{
      const contacts = action.payload.data.contacts.map(data => {
        return ({
          id: data.id,
          name: data.name,
          email: data.email,
          phoneNumber: data.phone_number,
          jobTitle: data.job_title,
          position: data.position ? data.position.position : null,
          positionID: data.position_id,
          status: data.status ? data.status.status : null,
          statusID: data.status_id,
          notes: data.notes,
          businesses: data.businesses,
          businesses_count: data.businesses.length,
          venues: data.venues,
          venues_count: data.venues.length,
          created_at: data.created_at
        })
      })

      state =  {
        ...state,
        loading:false,
        loaded:true,
        data: action.meta ? contacts : state.data.concat(contacts),
        count:action.payload.data.count
      }
      break
    }
    case 'SEARCH_CONTACTS_TYPEAHEAD_PENDING':{
      state = {
        ...state,
        typeahead:[],
        typeaheadLoading:true
      }
      break
    }
    case 'SEARCH_CONTACTS_TYPEAHEAD_REJECTED':{
      state = {
        ...state,
        typeaheadLoading:false,
        error:action.payload
      }
      break
    }
    case 'SEARCH_CONTACTS_TYPEAHEAD_FULFILLED':{
      state =  {
        ...state,
        typeahead:state.typeahead.concat(action.payload ? action.payload.data : []),
        typeaheadLoading: false
      }
      break
    }

    case 'CLEAR_CONTACTS':{
      state = {
        ...state,
        data:[]
      }
      break
    }

    case 'CREATE_CONTACT_PENDING':{
      state = {
        ...state,
        loading: true
      }
      break
    }

    case 'CREATE_CONTACT_REJECTED':{
      state = {
        ...state,
        loading: false,
        error: action.payload
      }
      break
    }

    case 'CREATE_CONTACT_FULFILLED':{
      const data = action.payload.data

      const newContact = {
        id: data.id,
        name: data.name,
        status: data.status ? data.status.status : null,
        statusID: data.status_id,
        email: data.email,
        phoneNumber: data.phone_number,
        notes: data.notes,
        job_title: data.jobTitle,
        position: data.position ? data.position.position : null,
        positionID: data.position_id
      }

      state =  {
        ...state,
        loading: false,
        data: state.data.concat(newContact)
      }
      break
    }

    case 'UPDATE_CONTACT_PENDING':{
      state = {
        ...state,
        loading: true
      }
      break
    }

    case 'UPDATE_CONTACT_REJECTED':{
      state = {
        ...state,
        loading: false,
        error: action.payload
      }
      break
    }

    case 'UPDATE_CONTACT_FULFILLED':{
      const data = action.payload.data
      let contacts = [...state.data]

      const updatedIndex = contacts.findIndex(contact => contact.id === action.payload.data.id)

      contacts[updatedIndex] = {
        id: data.id,
        name: data.name,
        email: data.email,
        phoneNumber: data.phone_number,
        job_title: data.jobTitle,
        position: data.position ? data.position.position : null,
        positionID: data.position_id,
        status: data.status ? data.status.status : null,
        statusID: data.status_id,
        notes: data.notes,
        businesses: data.businesses,
        businesses_count: data.businesses_count,
        venues: data.venues,
        venues_count: data.venues_count,
        created_at: formatDate(data.created_at)
      }

      state =  {
        ...state,
        loading: false,
        data: contacts
      }
      break
    }

    case 'DELETE_CONTACT_PENDING':{
      state = {
        ...state,
        loading: true
      }
      break
    }

    case 'DELETE_CONTACT_REJECTED':{
      state = {
        ...state,
        loading: false,
        error: action.payload
      }
      break
    }

    case 'DELETE_CONTACT_FULFILLED': {
      state = {
        ...state,
        loading: false,
        data: state.data.filter(contact => contact.id !== action.payload.data)
      }
      break
    }

    default: // no default
  }
  return state
}
