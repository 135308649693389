import React, { Component } from 'react'

class FitnessTimelineHeading extends Component {
  constructor(props) {
    super(props)

    this.state = {
      timelineHeading: []
    }
  }

  componentDidMount() {
    this.generateHeadingArray()
  }

  componentDidUpdate(prevProps) {
    const {
      duration,
    } = this.props

    if (prevProps.duration !== duration) {
      this.generateHeadingArray()
    }
  }

  generateHeadingArray() {
    const {
      maxDuration,
      unitWidth
    } = this.props;

    const totalDuration = maxDuration/unitWidth

    let timelineHeadingArray = []

    for (let value = 0; value <= totalDuration; value = value + 10) {
      timelineHeadingArray.push(value)
    }

    this.setState({
      timelineHeading: timelineHeadingArray
    })
  }

  calculateWidth() {
    const {
      unitWidth
    } = this.props

    return (unitWidth * 100)
  }

  render() {
    const {
      unitWidth
    } = this.props

    const {
      timelineHeading
    } = this.state

    return (
      <div
        className='fitness-timeline-heading'
        style={{ width: `${this.calculateWidth()}px` }}
      >
        {timelineHeading.map((item, index) => {
          return (
            <div
              className='fitness-timeline-heading__section'
              key={index}
              style={{width: `${unitWidth * 10}px`}}
            >
              {item} mins
            </div>
          )
        })}
      </div>
    )
  }
}

export default FitnessTimelineHeading
