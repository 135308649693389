import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  getPlaylistBrief,
  updatePlaylistBrief
} from 'store/actions/playlistAction'

// import {
//   getGenres
// } from 'store/actions/libraryAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Form from 'ui/Form'

const classname = 'playlistBrief'

class PlaylistBrief extends Component {
  constructor(props){
    super(props)
    this.state = {
      genres: []
    }
  }

  componentDidMount(){
    const {
      dispatch,
      id
    } = this.props

    dispatch(getPlaylistBrief(id))
    // dispatch(getGenres())
  }

  // componentDidUpdate(prevProps) {
  //   const {
  //     brief
  //   } = this.props
  //
  //   if (prevProps.brief !== brief) {
  //     this.setState({
  //       genres: brief.genres
  //     })
  //   }
  // }

  generateTargetEnergies() {
    return (
      [...Array(11)].map((_, i) => {
        if (i === 0) {
          return {
            display: '------',
            value: 0
          }
        }
        return {
          display: i.toString(),
          value: i
        }
      })
    )
  }

  onSubmit(form){
    this.props.dispatch(updatePlaylistBrief(this.props.id, form))
  }

  render(){
    const {
      loading,
      brief,
      genres,
      playlistLeads,
      updateFrequencies
    } = this.props

    if (loading) {
      return <Loader/>
    } else {
      return (
        <Container classname='playlist-brief' height='100%'>
          <Form
            classname={classname}
            submit={(e)=>this.onSubmit(e)}
            messages={this.state.message}
            data={brief}
            playlistLeads={playlistLeads}
            updateFrequencies={updateFrequencies}
            targetEnergies={this.generateTargetEnergies()}
            // allGenres={genres}
            scroll
          />
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    brief: store.playlist.brief,
    genres: store.library.genres,
    loading: store.playlist.playlistBriefLoading,
    playlistLeads: store.playlist.playlistLeads,
    updateFrequencies: store.playlist.updateFrequencies
  }
}

export default connect(mapStateToProps)(PlaylistBrief)