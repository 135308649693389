import React from 'react'
import {Link} from 'react-router-dom'

import Icon from 'ui/Icon'
import Loader from 'ui/Loader'
import Tooltip from 'ui/Tooltip'
import TableModel from 'models/TableModel'

const PlaylistVenuesTableRowActions = (props) => {
  const {
    classname,
    removeVenue,
    removeVenueFromPlaylistLoading,
    venue
  } = props

  return (
    <div>
      <Link to={`${TableModel[classname].viewLink}/${venue.id}`}>
        <Icon name="eye"/>
      </Link>
      {removeVenueFromPlaylistLoading.includes(venue.id) ? (
        <Loader />
      ) : (
        <Icon
          name="ios-arrow-dropright-circle"
          action={()=>removeVenue(venue)}
        >
          <Tooltip
            pos='left'
            text='Remove playlist from venue'
          />
        </Icon>
      )}
    </div>
  )
}

export default PlaylistVenuesTableRowActions
