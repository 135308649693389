import React, {Component} from 'react'

import { connect } from 'react-redux'

import Container from 'ui/Container'
import Button from 'ui/Button'

class OverlayPlaylistTracksMore extends Component{
  favouriteTrack(){

  }

  copyPlaylist(){

  }

  render(){
    const track = this.props.data.data
    return (
      <Container classname="overlay-venues" maxWidth height="100%" column>
        <h2>Track actions for {track.title}</h2>
        <Button action={()=>this.favouriteTrack()} name="Favourite track"/>
        <Button action={()=>this.copyPlaylist()} name="Edit track"/>
        <Button action={()=>this.copyPlaylist()} name="Delete track from library"/>
      </Container>
    )
  }
}

export default connect()(OverlayPlaylistTracksMore)
