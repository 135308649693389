import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  approveChange,
  getPlaylistTracksForApproval,
  rejectChange
} from 'store/actions/playlistAction'

import {
  getTrackUrl,
  getTrackInfo
} from 'store/actions/playerAction'

import { tableSorter } from 'helpers/TableHelper'

import PlaylistTracksToApproveRow from './playlist-tracks/PlaylistTracksToApproveRow'
import PlaylistTracksToApproveTableRowActions from './playlist-tracks/PlaylistTracksToApproveTableRowActions'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import TableWrapper from 'ui/TableWrapper'

const classname = 'playlistTracksToApprove'

class playlistTracksToApprove extends Component {
  constructor(props){
    super(props)
    this.state = {
      tracks: []
    }
  }

  componentDidMount(){
    this.props.dispatch(getPlaylistTracksForApproval(this.props.id))
  }

  componentDidUpdate(prevProps,prevState){
    const {
      sorter,
      tracks
    } = this.props

    if(
      prevProps.tracks !== tracks ||
      JSON.stringify(prevProps.sorter) !== JSON.stringify(sorter)
    ) {
      this.setTracks()
    }
  }

  sortType(x,y){
    const {
      field,
      direction
    } = this.props.sorter

    // if no direction is provided, due to the sorting icon being reset,
    // or there is no value provided to be sorted on, use default values
    if (direction === null || !(field in x)) {
      return tableSorter(x,y,'title','asc')
    }

    return tableSorter(x,y,field,direction)
  }

  setTracks(){
    const {
      tracks
    } = this.props

    //sort tracks
    const sortedTracks = tracks
      .sort((x, y) => this.sortType(x, y))
    //set tracks in state for use in table
    this.setState({
      tracks:sortedTracks
    })
  }

  playTrack(track){
    const {
      dispatch
    } = this.props

    dispatch(getTrackInfo(track, 'playlist'))
    dispatch(getTrackUrl(track, 'playlist '))
  }

  approveChange(pending_id,track_id,type,playlist_id) {
    const {
      dispatch
    } = this.props

    const data = {
      id: pending_id,
      playlistID: playlist_id,
      trackID: track_id,
      type: type
    }

    dispatch(approveChange(data))
  }

  render(){
    const {
      approveChangeLoading,
      dispatch,
      loading,
      playingTrack,
      rejectChangeLoading
    } = this.props

    if(loading){
      return <Loader/>
    } else {
      return (
        <Container classname="playlist-tracks" height="100%" column>
          <TableWrapper
            classname={classname}
            data={this.state.tracks}
            rowComp={
              <PlaylistTracksToApproveRow
                play={(track)=>this.playTrack(track)}
                playingTrack={playingTrack}
                checkboxSelected={this.selectedTracks}
                clickRow={(e,data,isChecked,index)=>this.clickRow(e,data,isChecked,index)}
              />
            }
            rowActions={(
              <PlaylistTracksToApproveTableRowActions
                classname={classname}
                approveChange={(pending_id,track_id,type,playlist_id)=>this.approveChange(pending_id,track_id,type,playlist_id)}
                approveChangeLoading={approveChangeLoading}
                rejectChange={(id)=>dispatch(rejectChange(id))}
                rejectChangeLoading={rejectChangeLoading}
              />
            )}
          />
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    approveChangeLoading: store.playlist.approveChangeLoading,
    loading: store.playlist.loading,
    playingTrack: store.player.track,
    rejectChangeLoading: store.playlist.rejectChangeLoading,
    sorter: store.table.sorter,
    tracks: store.playlist.tracksToApprove
  }
}

export default connect(mapStateToProps)(playlistTracksToApprove)
