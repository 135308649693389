import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'qs'
import { millisToMinutesAndSeconds } from 'helpers/TimeHelper'

import {
  clearPlaylists,
  getImporterPlaylists,
  getImporterPlaylist,
  getImporterMatches,
  addToFinalTracklist,
  deleteImporterPlaylist
} from 'store/actions/playlistAction'

import { toggleOverlay } from 'store/actions/overlayAction'

import { toggleConfirm } from 'store/actions/confirmAction'

import Confirm from 'ui/Confirm'
import Loader from 'ui/Loader'
import Container from 'ui/Container'
import Button from 'ui/Button'
import TableWrapper from 'ui/TableWrapper'

import PlaylistsImporterTableRowActions from './PlaylistsImporterTableRowActions'
import PlaylistImporterTableRowActions from './PlaylistImporterTableRowActions'

const classname = 'playlistsImporter'

class PlaylistsImporter extends Component {

  constructor(props){
    super(props)
    this.state = {
      playlistID: null,
      finalTracklist: []
    }
  }

  componentDidMount(){
    this.props.dispatch(clearPlaylists())
    this.getTabQuery()
  }

  componentDidUpdate(prevProps, prevState){
    const {
      dispatch,
      location,
      importerPlaylist,
      importerMatches,
      setHeader
    } = this.props

    // make sure this only fires when switching between the two importer views
    // will come back and split them into separate components so this won't be required
    if (prevProps.location.search.match(/importer/g) && prevProps.location.search !== location.search) {
      this.getTabQuery()
    }

    if (importerPlaylist && importerPlaylist !== prevProps.importerPlaylist) {
      setHeader(importerPlaylist.name)
    }

    // add exact matches to the final tracklist
    if (importerMatches && (importerMatches !== prevProps.importerMatches)) {
      const newMatch = importerMatches
        .filter(x => !prevProps.importerMatches.includes(x))
        .concat(prevProps.importerMatches.filter(x => !importerMatches.includes(x)))

      if (newMatch[0] && newMatch[0].tracks && newMatch[0].tracks.length === 1 && newMatch[0].match_type === 'exact') {
        dispatch(addToFinalTracklist(newMatch[0].tracks[0]))
      }
    }
  }

  getTabQuery() {
    const {
      dispatch,
      importerPlaylist,
      location,
      setHeader
    } = this.props

    const {
      playlistID
    } = this.state

    const query = queryString.parse(location.search.slice(1))

    if(typeof query.id !== 'undefined' && query.id !== playlistID){
      this.setState({playlistID: query.id},()=>{
        dispatch(getImporterPlaylist(query.id))
        setHeader(importerPlaylist.name)
      })
    } else {
      this.setState({playlistID: null}, ()=>{
        dispatch(getImporterPlaylists())
        setHeader('')
      })
    }
  }

  getImporterTracks() {
    const {
      importerMatchPerformed,
      importerMatches,
      importerPlaylist
    } = this.props

    return importerPlaylist.tracks.map((track) => {
      const trackFound = importerMatches.filter(match=>match.spotifyID === track.id)
      return {
        ...track,
        importerMatchPerformed: importerMatchPerformed,
        total_length: millisToMinutesAndSeconds(track.total_length),
        tracks: trackFound[0] ? trackFound[0].tracks : []
      }
    })
  }

  async scanForMatches(tracks) {
    const {
      dispatch
    } = this.props

    for(const track of tracks) {
      const data = {
        artist: track.artist,
        title: track.title,
        spotifyID: track.id
      }

      await dispatch(getImporterMatches(data))
    }
  }

  getScanButtonText() {
    const {
      importerPlaylist,
      importerMatchPerformed,
      importerMatchesLoaded,
    } = this.props

    if(importerMatchPerformed && (importerPlaylist.tracks.length > importerMatchesLoaded.length)) {
      return `Scanning: ${importerMatchesLoaded.length}/${importerPlaylist.tracks.length}`
    } else {
      return `Scan for matches`
    }
  }

  getScanButton() {
    const {
      importerFinalTracklist,
      importerMatchPerformed,
      importerMatchesLoaded,
      importerPlaylist
    } = this.props

    if (importerMatchPerformed && (importerPlaylist.tracks.length === importerMatchesLoaded.length)) {
      return (
        <Button
          action={()=>{
            this.toggleCreatePlaylistOverlay(importerFinalTracklist)
          }}
          name={`${importerFinalTracklist.length}/${importerPlaylist.tracks.length} found - create playlist?`}
        />
      )
    } else {
      return (
        <Button
          action={importerMatchPerformed && (importerPlaylist.tracks.length > importerMatchesLoaded.length) ? (
            ()=>{return false}
          ) : (
            ()=>{this.scanForMatches(importerPlaylist.tracks)}
          )}
          name={this.getScanButtonText()}
        />
      )
    }
  }

  toggleCreatePlaylistOverlay(tracks) {
    const {
      dispatch,
      history,
      importerPlaylist
    } = this.props

    const overlayData = {
      tracks: tracks,
      history: history,
      sp_playlist_id: importerPlaylist.id
    }

    dispatch(toggleOverlay(true, overlayData, 'playlistImporterCreatePlaylist'))
  }

  toggleImportedTracksOverlay(data) {
    const {
      dispatch
    } = this.props

    dispatch(toggleOverlay(true, data, 'playlistImporter'))
  }

  deleteImporterPlaylist(id) {
    const {
      dispatch
    } = this.props

    const confirmData = {
      action: () => {
        dispatch(deleteImporterPlaylist(id))
      },
      question: `Delete selected playlist?`
    }

    dispatch(toggleConfirm(true, confirmData))
  }

  render() {
    const {
      count,
      loading,
      importerFinalTracklist,
      importerPlaylist,
      importerMatchesLoaded,
      playlists
    } = this.props

    const {
      playlistID
    } = this.state

    return (
      <Container classname={`${classname}-wrapper`} height="100%" column maxWidth>
        {loading ? (
          <Loader/>
        ) :(
          <Fragment>
            {playlistID ? (
              <Fragment>
                {Object.keys(importerPlaylist).length ? (
                  <Container column>
                    <div>
                      {this.getScanButton()}
                    </div>
                    <TableWrapper
                      classname='playlistImporterTracks'
                      data={this.getImporterTracks()}
                      count={count}
                      loading={loading}
                      rowActions={(
                        <PlaylistImporterTableRowActions
                          classname={classname}
                          toggleImportedTracksOverlay={(tracks)=>this.toggleImportedTracksOverlay(tracks)}
                          importerFinalTracklist={importerFinalTracklist}
                          importerMatchesLoaded={importerMatchesLoaded}
                        />
                      )}
                    />
                  </Container>
                ) : (
                  <Loader />
                )}
              </Fragment>
            ) : (
              <TableWrapper
                classname={classname}
                data={playlists}
                count={count}
                loading={loading}
                rowActions={(
                  <PlaylistsImporterTableRowActions
                    classname={classname}
                    deleteImporterPlaylist={(id)=>this.deleteImporterPlaylist(id)}
                  />
                )}
              />
            )}
          </Fragment>
        )}
        <Confirm />
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    playlists:store.playlists.importerPlaylists,
    importerFinalTracklist: store.playlists.importerFinalTracklist,
    importerMatchPerformed: store.playlists.importerMatchPerformed,
    importerMatches: store.playlists.importerMatches,
    importerMatchesLoaded: store.playlists.importerMatchesLoaded,
    importerPlaylist: store.playlists.importerPlaylist,
    count: store.playlists.count,
    loading: store.playlists.importerLoading
  }
}

export default withRouter(connect(mapStateToProps)(PlaylistsImporter))
