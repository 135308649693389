import React, {Component} from 'react'
import moment from 'moment'
import Calendar from 'react-calendar'

import { connect } from 'react-redux'

import {
  addSavedCalendarToCalendar
} from 'store/actions/venueAction'

import Container from 'ui/Container'
import Button from 'ui/Button'

class OverlayVenueCalendarScheduleAddToCalendar extends Component{

  constructor(props){
    super(props)
    this.state = {
      startDate: moment().isoWeekday(1).toDate(),
      endDate: moment().isoWeekday(1).add(6, 'd').toDate(),
      showStartDateCalendar: false,
      showEndDateCalendar: false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      startDate,
      endDate
    } = this.state

    // if the start date is set to be after the end date, or equal to it,
    // set the end date to the day after the new start date
    if (prevState.startDate !== startDate && moment(endDate).isBefore(startDate)) {
      this.setState({
        endDate: moment(startDate).add(1, 'd').toDate()
      })
    }

    // if the end date is set to be before the start date,
    // set the start date to the day before the new end date
    if (prevState.endDate !== endDate && moment(endDate).isBefore(startDate)) {
      this.setState({
        startDate: moment(endDate).subtract(1, 'd').toDate()
      })
    }
  }

  toggleCalendar(calendar) {
    const {
      showStartDateCalendar,
      showEndDateCalendar
    } = this.state

    if (calendar === 'startDate') {
      this.setState({
        showStartDateCalendar: !showStartDateCalendar,
        showEndDateCalendar: false
      })
    } else {
      this.setState({
        showStartDateCalendar: false,
        showEndDateCalendar: !showEndDateCalendar
      })
    }
  }

  addSavedCalendarToCalendar(data) {
    const {
      dispatch
    } = this.props

    const {
      endDate,
      startDate,
    } = this.state

    const savedCalendarData = {
      venue_id: data.venueID,
      saved_id: data.savedID,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD')
    }

    dispatch(
      addSavedCalendarToCalendar(savedCalendarData)
    )
  }

  render(){
    const {
      data
    } = this.props

    const {
      startDate,
      endDate,
      showStartDateCalendar,
      showEndDateCalendar,
    } = this.state


    return (
      <Container classname="overlay-saved-calendar-add-to-calendar" maxWidth height="100%" column>
        <h2>Add schedule to calendar</h2>
        <div className='overlay-saved-calendar-add-to-calendar__date-section'>
          <div className='overlay-saved-calendar-add-to-calendar__date-select'>
            <h4>Start date</h4>
            <input
              name='startDateCalendar'
              type='text'
              onClick={()=>this.toggleCalendar('startDate')}
              value={moment(startDate).format('ddd Do MMM YYYY')}
              readOnly
            />
            {showStartDateCalendar && (
              <Calendar
                value={startDate}
                onChange={(date) => {
                  this.setState({
                    startDate: date,
                    showStartDateCalendar: false
                  })
                }}
              />
            )}
          </div>
          <div className='overlay-saved-calendar-add-to-calendar__date-select'>
            <h4>End date</h4>
            <input
              name='endDateCalendar'
              type='text'
              onClick={()=>this.toggleCalendar('endDate')}
              value={moment(endDate).format('ddd Do MMM YYYY')}
              readOnly
            />
            {showEndDateCalendar && (
              <Calendar
                value={endDate}
                onChange={(date) => {
                  this.setState({
                    endDate: date,
                    showEndDateCalendar: false
                  })
                }}
              />
            )}
          </div>
        </div>
        <Button
          action={()=>this.addSavedCalendarToCalendar(data)}
          name={`Add schedule to calendar`}
        />
      </Container>
    )
  }
}

export default connect()(OverlayVenueCalendarScheduleAddToCalendar)
