import React from 'react'

import TableModel from 'models/TableModel'
import Badge from 'ui/Badge'

const TableCellBadge = (props) => {
  const header = TableModel[props.classname].headers.find(header => header.field === props.field)
  return (
    <td className={`table-cell table-cell-${props.classname} table-cell-size-${props.size} table-cell-link`}>
      <Badge
        data={props.datum}
        link={`${TableModel[props.classname].viewLink}/${props.id}?tab=${header.badgeLink}`}
      />
    </td>
  )
}

export default TableCellBadge
