import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  getPlaylistCurators,
  removePlaylistCurator,
  addPlaylistCurator
} from 'store/actions/playlistAction'
import {
  searchUsersTypeahead,
  clearTypeahead
} from 'store/actions/userAction'

import Container from 'ui/Container'
import TableWrapper from 'ui/TableWrapper'

import PlaylistCuratorTableActions from './playlist-curators/PlaylistCuratorTableActions'
import PlaylistCuratorsTableRowActions from './playlist-curators/PlaylistCuratorsTableRowActions'

const classname = 'playlistCurators'

class PlaylistCurators extends Component {

  constructor(props){
    super(props)
    this.state = {
      filter:'',
      sorter:{
        field:'added',
        direction:'desc'
      }
    }
  }

  componentDidMount(){
    this.props.dispatch(getPlaylistCurators(this.props.id))
  }

  searchUsers(term){
    this.props.dispatch(searchUsersTypeahead(10,term))
  }

  addCurator(curator){
    this.props.dispatch(addPlaylistCurator(curator,this.props.id))
  }

  removeCurator(curator_id){
    this.props.dispatch(removePlaylistCurator(curator_id,this.props.id))
  }

  getTypeaheadData(){

    let results = []
    //filter out playlists that we already have from the typeahead
    if(this.props.typeahead){
      for(let ta of this.props.typeahead){
        let found = this.props.curators.find(pl => pl.id === ta.id)
        if(!found){
          results.push(ta)
        }
      }
    }
    return results

  }

  clearTypeahead(){
    this.props.dispatch(clearTypeahead())
  }

  render(){
    return (
      <Container classname={classname} height="100%" maxWidth scroll column>
        <TableWrapper
          classname={classname}
          data={this.props.curators}
          count={this.props.count}
          clearData={()=>this.clearVenues()}
          getData={(limit,offset)=>this.getVenues(limit,offset)}
          loading={this.props.loading}
          rowActions={<PlaylistCuratorsTableRowActions
            classname={classname}
            removeCurator={(curator_id)=>this.removeCurator(curator_id)}
            />
          }
          tableAction={<PlaylistCuratorTableActions
            classname={classname}
            typeaheadSearch={(value)=>this.searchUsers(value)}
            typeaheadAction={(id)=>this.addCurator(id)}
            typeaheadLoading={this.props.typeaheadLoading}
            clear={()=>this.clearTypeahead()}
            data={this.getTypeaheadData()}/>
          }
        />
      </Container>
    )
  }

}

function mapStateToProps(store){
  return {
    curators:store.playlist.curators,
    typeahead:store.users.typeahead,
    loading:store.playlist.loading
  }
}

export default connect(mapStateToProps)(PlaylistCurators)
