import React from 'react'

const Image = (props) => {
  const baseUrl = 'https://openearmusic.imgix.net/'
  const {
    alt,
    name,
    width,
    height
  } = props

  return (
    <div className="image-wrapper">
      <img
        src={`${baseUrl}${name}?w=${width ? width : 200}&h=${height ? height : 200}`}
        alt={alt}
      />
    </div>
  )
}

export default Image
