import React, { Component } from 'react'
import { connect } from 'react-redux'

import { closeMessageOverlay } from 'store/actions/messageOverlayAction'

import MessageOverlaySaved from './message-overlay/MessageOverlaySaved'
import MessageOverlayInfo from './message-overlay/MessageOverlayInfo'

class MessageOverlay extends Component{
  constructor(props){
    super(props)
    this.state = {
      comp:'default'
    }
  }

  componentDidUpdate(){
    //set close overlay if already active
    if(this.props.messageOverlay.isActive){
      setTimeout(()=>{
        this.props.dispatch(closeMessageOverlay())
      },1500);
    }
  }

  getComponent(){
    const {message, type} = this.props.messageOverlay
    switch(type){
      case 'saved':
        return <MessageOverlaySaved/>
      case 'info':
        return <MessageOverlayInfo message={message}/>
      default: //no default case
    }
  }

  render(){
    if(this.props.messageOverlay.isActive){
      return (
        <div className="saved-wrapper">
          {this.getComponent()}
        </div>
      )
    } else {
      return null
    }
  }
}
function mapStateToProps(store){
  return {
    messageOverlay:store.messageOverlay
  }
}

export default connect(mapStateToProps)(MessageOverlay)
