const initalState = {
  isAuthenticated:false,
  loading:false,
  loaded:false,
  error:null,
  user:{}
}
export default function reducer(state = initalState, action){
  switch (action.type){
    case 'FETCH_AUTH_PENDING':{
      state =  {...state,loading:true,loaded:false}
      break
    }
    case 'FETCH_AUTH_REJECTED':{
      state = {...state,loading:false,loaded:false,error:action.payload.err}
      break
    }
    case 'FETCH_AUTH_FULFILLED':{
      state =  {
        ...state,
        loading:false,
        loaded:true,
        isAuthenticated:true,
        user:action.payload
      }
      break
    }
    case 'IS_AUTH_PENDING':{
      state = {
        ...state,
        loading:true,
        isAuthenticated:false
      }
      break
    }
    case 'IS_AUTH_FULFILLED':{
      state = {
        ...state,
        loading:false,
        loaded:true,
        isAuthenticated:action.payload ? action.payload.data.auth : false,
        user:{
          id:action.payload.data.user_id
        }
      }
      break
    }
    case 'IS_AUTH_REJECTED':{
      state = {
        ...state,
        isAuthenticated:false,
        loading:false,
        loaded:false,
        error:null,
        venue:{},
        authToken:null
      }
      break
    }
    case 'LOGOUT':{
      state = {
        isAuthenticated:false,
        loading:false,
        loaded:false,
        error:action.payload,
        user:{}
      }
      break
    }
    default: // no default
  }
  return state
}
