import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  getBusinessMusic,
  updateBusinessMusic
} from 'store/actions/businessAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Form from 'ui/Form'

const classname = 'businessMusic'

class BusinessMusic extends Component {
  componentDidMount(){
    this.props.dispatch(
      getBusinessMusic(
        this.props.id
      )
    )
  }

  onSubmit(form){
    this.props.dispatch(
      updateBusinessMusic(
        this.props.id,
        form
      )
    )
  }

  render(){
    const {
      music,
      musicLeads,
      loading
    } = this.props

    if (loading) {
      return <Loader/>
    } else {
      return (
        <Container classname={classname} height="100%" maxWidth>
          <Form
            classname={classname}
            submit={(form)=>this.onSubmit(form)}
            data={music}
            musicLeads={musicLeads}
            scroll
          />
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    music: store.business.music,
    musicLeads: store.business.musicLeads,
    loading:store.business.musicLoading
  }
}

export default connect(mapStateToProps)(BusinessMusic)
