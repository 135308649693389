import React, { Component } from 'react'

import ReportsData from './ReportsData'
import ReportsBarChart from './ReportsBarChart'
import ReportsPieChart from './ReportsPieChart'


import FormSelect from 'ui/FormSelect'

class ReportsPlaylist extends Component {

  constructor(props){
    super(props)

    this.state = {
      playlistInteger: 0
    }
  }

  componentDidMount() {
    const {
      playlistIndex
    } = this.props;

    this.setState({
      playlistInteger: playlistIndex || 0
    })
  }

  render(){
    const {
      data,
      pdfMode
    } = this.props;

    const {
      playlistInteger
    } = this.state;

    return (
      <div className='reports-playlist'>
        <div className='reports-playlist__data'>
          {pdfMode ? (
            <ReportsData>
              <h6>{data[playlistInteger].name}</h6>
            </ReportsData>
          ) : (
            <div className='reports-playlist__form'>
              <FormSelect
                action={(e) => {
                  this.setState({
                    playlistInteger: e.currentTarget.value
                  })
                }}
                options={data.map((playlist, index) => {
                  return (
                    {
                      'display': playlist.name,
                      'value': index
                    }
                  )
                })}
                selected={playlistInteger}
              />
            </div>
          )}
          <ReportsData>
            <h6>Total tracks</h6>
            {data[playlistInteger].total_tracks}
          </ReportsData>
          <ReportsData>
            <h6>Total playlist duration</h6>
            {data[playlistInteger].total_length}
          </ReportsData>
        </div>
        <div className='reports-playlist__genres'>
          <h6>Genres</h6>
          <ReportsPieChart
            data={data[playlistInteger].genres}
          />
        </div>
        <div className='reports-playlist__breakdown'>
          <h6>Track breakdown</h6>
          <ReportsBarChart
            data={data[playlistInteger].track_breakdown}
          />
        </div>
      </div>
    )
  }
}

export default ReportsPlaylist
