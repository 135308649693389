import { getLocalStorage, setLocalStorage, deleteLocalStorage } from './LocalStorage'

export function getQueue(){
  const queue = getLocalStorage('queue')
  return queue
}

export function addToQueue(track){
  const queue = getLocalStorage('queue')
  if(!queue){
    setLocalStorage('queue', [track])
  } else {
    queue.push(track)
    setLocalStorage('queue', queue)
  }
}

export function removeFromQueue(track){
  let queue = getLocalStorage('queue')
  const index = queue.findIndex((trk) => trk.id === track.id)
  queue.splice(index,1)
  setLocalStorage('queue', queue)
}

export function updateQueue(tracks){
  deleteLocalStorage('queue')
  setLocalStorage('queue',tracks)
}

export function deleteQueue() {
  deleteLocalStorage('queue')
}
