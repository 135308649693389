import React, {Component} from 'react'

import TableCell from 'ui/TableCell'

import TableModel from 'models/TableModel'

class OverlayPlaylistTracksAddTableRow extends Component {

  constructor(props){
    super(props)
    this.ref = React.createRef()
  }

  assignPlaylist() {
    const {
      assignPlaylist,
      data,
      playlistID
    } = this.props

    // disable functionality for the currently viewed playlist
    if (playlistID && (parseInt(playlistID) === data.id)) {
      return false
    }

    assignPlaylist(data)
  }

  render(){
    const {
      data,
      classname,
      index,
      size
    } = this.props

    let cells = TableModel[classname]['headers'].map((header,index)=>{
      return <TableCell
        key={index}
        datum={data[header.field]}
        classname={classname}
        size={header.size}/>
    })

    return (
      <tr
        onClick={()=>{this.assignPlaylist()}}
        key={index}
        className={classname}
      >
          {cells}
          <td className={`table-cell table-cell-actions table-cell-actions-${classname} table-cell-actions-${size}`}>
            {this.props.actionsComp}
          </td>
      </tr>
    )
  }
}

export default OverlayPlaylistTracksAddTableRow
