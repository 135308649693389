import React from 'react'
import {Link} from 'react-router-dom'

import Icon from 'ui/Icon'
import Loader from 'ui/Loader'
import Tooltip from 'ui/Tooltip'
import TableModel from 'models/TableModel'

const VenueBlockedTableRowActions = (props) => {
  const {
    removeBlockedTrack
  } = props

  return (
    <div>
      <Icon
        name="ios-arrow-dropright-circle"
        action={()=>removeBlockedTrack(props.blocked_id)}
      >
        <Tooltip
          pos='left'
          text='Remove blocked track'
        />
      </Icon>
    </div>
  )
}

export default VenueBlockedTableRowActions
