const initalState = {
  type:null,
  isActive:false,
  message:null
}

export default function reducer(state = initalState,action){
  switch (action.type){
    case 'OPEN_MESSAGE_OVERLAY':{
      state = {
        ...state,
        isActive:true,
        type:action.payload.type,
        message:action.payload.message
      }
      break
    }
    case 'DELETE_PLAYLIST_TRACK_PENDING':
    case 'DELETE_PLAYLIST_TRACKS_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'CREATE_PLAYLIST_FULFILLED':
    case 'CREATE_PLAYLIST_ADD_VENUES_FULFILLED':
    case 'ADD_PLAYLIST_TRACK_OWNED_FULFILLED':
    case 'ADD_TRACKS_TO_PLAYLIST_FULFILLED':
    case 'UPDATE_BUSINESS_DETAILS_FULFILLED':
    case 'UPDATE_BUSINESS_ACCOUNTS_FULFILLED':
    case 'UPDATE_BUSINESS_MUSIC_FULFILLED':
    case 'UPDATE_BUSINESS_USER_FULFILLED':
    case 'UPDATE_BUSINESS_SETTINGS_FULFILLED':
    case 'UPDATE_PLAYLIST_BRIEF_FULFILLED':
    case 'UPDATE_VENUE_DETAILS_FULFILLED':
    case 'UPDATE_VENUE_USER_FULFILLED':
    case 'UPDATE_USER_DETAILS_FULFILLED':
    case 'UPDATE_USER_VENUES_FULFILLED':
    case 'UPDATE_USER_SETTINGS_FULFILLED':
    case 'UPDATE_PLAYLIST_DETAILS_FULFILLED':
    case 'COPY_CALENDAR_TO_VENUES_FULFILLED':
    case 'COPY_CALENDAR_INTERLEAVE_TO_VENUES_FULFILLED':
    case 'ADD_SAVED_CALENDAR_TO_CALENDAR_FULFILLED':
    case 'APPROVE_CHANGE_FULFILLED':
    case 'REJECT_CHANGE_FULFILLED':
    case 'ADD_PLAYLIST_TO_VENUE_FULFILLED':
    case 'CREATE_CONTACT_FULFILLED':
    case 'REMOVE_CONTACT_FROM_BUSINESS_FULFILLED':
    case 'REMOVE_CONTACT_FROM_VENUE_FULFILLED':{
      state = {
        ...state,
        type:'saved',
        isActive:true
      }
      break
    }
    case 'CLOSE_MESSAGE_OVERLAY':{
      state = {
        ...state,
        isActive:false
      }
      break
    }
    default: // no default case
  }
  return state
}
