import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  addPlaylistToVenue
} from 'store/actions/venueAction'

import {
  clearOverlayVenues,
  getPlaylistAddToVenuesSearch
} from 'store/actions/playlistAction'

import { toggleConfirm } from 'store/actions/confirmAction'

import { getRowLimit } from 'helpers/TableHelper'

import OverlayPlaylistAddToVenuesTableRowActions from './playlist-add-to-venues/OverlayPlaylistAddToVenuesTableRowActions'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import TableWrapper from 'ui/TableWrapper'
import Typeahead from 'ui/Typeahead'
import Confirm from 'ui/Confirm'

const classname = 'overlayPlaylistAddToVenues'

class OverlayPlaylistAddToVenue extends Component{

  constructor(props){
    super(props)

    this.state = {
      searchTerm:null
    }
  }

  componentDidMount(){
    const {
      dispatch
    } = this.props

    dispatch(clearOverlayVenues())
  }

  getVenues(limit,offset){
    const {
      data,
      dispatch
    } = this.props

    const {
      searchTerm
    } = this.state

    dispatch(
      getPlaylistAddToVenuesSearch(
        limit,
        offset,
        searchTerm,
        data.playlist_id
      )
    )
  }

  searchVenues(term){
    const {
      data,
      dispatch
    } = this.props

    this.setState({
      searchTerm:term
    },()=>{
      const {
        searchTerm
      } = this.state

      dispatch(clearOverlayVenues())

      dispatch(
        getPlaylistAddToVenuesSearch(
          getRowLimit(classname),
          0,
          searchTerm,
          data.playlist_id,
          true
        )
      )
    })
  }

  clearTypeahead(){
    this.props.dispatch(clearOverlayVenues())
  }

  addPlaylistToVenue(venue){
    const {
      data,
      dispatch
    } = this.props

    const actionData = {
      venue_id: venue.id,
      playlist_id: data.playlist_id,
      business_id: venue.business_id
    }

    if (venue.total_tracks > 1500) {
      const confirmData = {
        action: () => {
          dispatch(addPlaylistToVenue(actionData, venue))
        },
        question: `Venue has over 1500 tracks. Add playlist anyway?`
      }

      dispatch(toggleConfirm(true, confirmData))
    } else {
      dispatch(addPlaylistToVenue(actionData, venue))
    }



  }

  render(){
    const {
      addPlaylistToVenueLoading,
      addPlaylistToVenuesOverlayLoading,
      count,
      overlayVenues
    } = this.props

    return (
      <Container height="100%" classname={classname} column>
        <Typeahead
          typeaheadSearch={(value)=>this.searchVenues(value)}
          search={(value)=>this.searchVenues(value)}
          clear={()=>this.clearTypeahead()}
          data={overlayVenues}
          classname={classname}
          hideDropdown
        />
        <TableWrapper
          controls
          classname={classname}
          data={overlayVenues}
          count={count}
          getData={(limit,offset)=>this.getVenues(limit,offset)}
          loading={addPlaylistToVenuesOverlayLoading}
          rowActions={
            <OverlayPlaylistAddToVenuesTableRowActions
              addPlaylistToVenue={(venue)=>this.addPlaylistToVenue(venue)}
              addPlaylistToVenueLoading={addPlaylistToVenueLoading}
              classname={classname}
            />
          }
        />
        {addPlaylistToVenuesOverlayLoading && (
          <div className='overlay-playlist-add-to-venues__updating'>
            <Loader />
            Updating
          </div>
        )}
        <Confirm />
      </Container>
    )
  }
}
function mapStateToProps(store){
  return {
    addPlaylistToVenueLoading: store.playlist.addPlaylistToVenueLoading,
    addPlaylistToVenuesOverlayLoading: store.playlist.addPlaylistToVenuesOverlayLoading,
    count: store.playlist.count,
    error: store.venues.error,
    overlayVenues: store.playlist.overlayVenues
  }
}
export default connect(mapStateToProps)(OverlayPlaylistAddToVenue)
