import React, {Component} from 'react'
import { connect } from 'react-redux'

import { getPlaylistOrdered, updatePlaylistOrder, orderPlaylist } from 'store/actions/playlistAction'
import { getTrackUrl, getTrackInfo } from 'store/actions/playerAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'

import TableWrapper from 'ui/TableWrapper'
import PlaylistOrderTableRowActions from './playlist-order/PlaylistOrderTableRowActions'

import { debounce } from 'helpers/Debounce'

const classname = 'playlistOrder'

class PlaylistOrder extends Component {

  constructor(props){
    super(props)
    this.state = {
      filter:'',
      actionActive:false
    }
    this.filterTracksDispatch = debounce(this.filterTracksDispatch,300)
  }

  componentDidMount(){
    this.props.dispatch(getPlaylistOrdered(this.props.id))
  }

  componentDidUpdate(prevProps){
    //check if tracks have changed and that prev tracks had loaded
    if(prevProps.tracks !== this.props.tracks && prevProps.tracks.length > 0){
      this.props.dispatch(updatePlaylistOrder(this.props.tracks,this.props.id))
    }
  }

  playTrack(track){
    const {
      dispatch
    } = this.props

    dispatch(getTrackInfo(track, 'playlist'))
    dispatch(getTrackUrl(track))
  }

  changeOrder(res){
    let newIndex = res.event.target.value
    this.props.dispatch(orderPlaylist(newIndex,res.data))
  }

  //@TODO actions to a switch function //eh? // ah, create function that returns table actions inside the table wrapper //oh, maybe not
  getTable(){
    return <TableWrapper
      play={(track)=>this.playTrack(track)}
      playingTrack={this.props.playingTrack}
      classname={classname}
      data={this.props.tracks}
      count={this.props.count}
      clearData={()=>this.clearPlaylists()}
      getData={(limit,offset)=>this.getPlaylists(limit,offset)}
      loading={this.props.loading}
      rowActions={<PlaylistOrderTableRowActions
        favouriteTrack={(data)=>this.favouriteTrack(data)}
        moreActions={(data)=>this.moreActions(data)}
        removeTrack={(data)=>this.removeTrack(data)}
        openPlaylistOverlay={(data)=>this.openPlaylistOverlay(data)}
        changeOrder={(data)=>{this.changeOrder(data)}}
        classname={classname}/>
      }
      />
  }

  render(){
    if(this.props.loading){
      return <Loader/>
    } else {
      return (
        <Container classname="playlist-tracks" height="100%">
          {this.getTable()}
        </Container>
      )
    }
  }
}
function mapStateToProps(store){
  return {
    tracks:store.playlist.tracks,
    loading:store.playlist.loading,
    playingTrack: store.player.track
  }
}

export default connect(mapStateToProps)(PlaylistOrder)
