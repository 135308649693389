import React, { PureComponent } from 'react'

class ReportsData extends PureComponent {
  render(){
    const {
      children
    } = this.props;

    return (
      <div className='reports-data'>
        {children}
      </div>
    )
  }
}

export default ReportsData
