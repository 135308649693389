import React from 'react'

const Input = (props) => {
  const {
    type,
    name,
    value,
    change,
    label,
    classname,
    size,
    autocomplete,
    placeholder
  } = props
  const inputClasses = `input input-${classname} ${size ? size : ''}`
  const wrapperClasses = `input-wrapper input-wrapper-${classname}`
  const labelClasses = `input-label input-label-${classname}`
  return (
    <div className={wrapperClasses}>
      <input 
        type={type} 
        name={name} 
        className={inputClasses}
        value={value}
        onChange={(e)=>change(e)}
        autoComplete={autocomplete}
        placeholder={placeholder}
        />
        <label className={labelClasses}>{label}</label>
        <span className="focus-border"></span>
    </div>
  )
}

export default Input