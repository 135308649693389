import React from 'react'

import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'
import Loader from 'ui/Loader'

const PlaylistTracksToApproveTableRowActions = (props) => {
  const {
    approveChange,
    approveChangeLoading,
    rejectChange,
    rejectChangeLoading,
    track
  } = props

  return (
    <div>
      {approveChangeLoading.includes(track.id) ? (
        <Loader />
      ) : (
        <Icon
          name='checkmark-circle'
          action={()=>approveChange(track.pivot.id,track.id, track.type,track.playlist_id)}
          success
        >
          <Tooltip
            text='Accept change'
            pos='left'
          />
        </Icon>
      )}
      {rejectChangeLoading.includes(track.pivot.id) ? (
        <Loader />
      ) : (
        <Icon
          name='close-circle'
          action={()=>rejectChange(track.pivot.id)}
        >
          <Tooltip
            text='Reject change'
            pos='left'
          />
        </Icon>
      )}
    </div>
  )
}

export default PlaylistTracksToApproveTableRowActions
