import React from 'react'

import Select from 'ui/Select'

const PlaylistOrderTableActions = (props) => {
  const {changeOrder,track,length,index} = props

  let options = []

  for(let i=0;i<length;i++){
    options.push({value:i,display:i})
  }

  track.index = index

  const select = <Select
    options={options}
    data={track}
    selected={index}
    action={(data)=>changeOrder(data)}/>
  return (
    <div>
      {select}
    </div>
  )
}

export default PlaylistOrderTableActions
