import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import queryString from 'qs'

import {
  createSavedCalendarItem,
  updateSavedCalendarItem,
  deleteSavedCalendarItem,
  toggleScheduleCreator,
  setPlaylistInfo,
  toggleInterleaveRatioChanger,
  resetSavedCalendarGenerator,
  addSavedCalendarInterleave,
  addSavedCalendarInterleaves,
  deleteSavedCalendarInterleave,
  updateSavedCalendarInterleaveRatios,
  fetchSavedCalender
} from 'store/actions/venueAction'

import { toggleOverlay } from 'store/actions/overlayAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'
import Button from 'ui/Button'
import Radio from 'ui/Radio'

import { getTimeIndex } from 'helpers/TimeHelper'

import Schedule from './scheduler/Schedule'
import ScheduleCreator from './scheduler/ScheduleCreator'
import InterleaveRatioChanger from './scheduler/InterleaveRatioChanger'
import 'react-calendar/dist/Calendar.css'

const classname = 'venueSchedule'

class VenueScheduleCalendarGenerator extends Component {
  constructor(props){
    super(props)

    this.state = {
      currentWeek: moment().isoWeekday(1).format('YYYY-MM-DD'),
      times:[
        '04:00','05:00','06:00','07:00','08:00','09:00',
        '10:00','11:00','12:00','13:00','14:00','15:00',
        '16:00','17:00','18:00','19:00','20:00','21:00',
        '22:00','23:00','00:00','01:00','02:00','03:00'
      ],
      fullTimes:[
        '04:00','04:30','05:00','05:30','06:00','06:30','07:00','07:30','08:00','08:30','09:00','09:30',
        '10:00','10:30','11:00','11:30','12:00','12:30','13:00','13:30','14:00','14:30','15:00','15:30',
        '16:00','16:30','17:00','17:30','18:00','18:30','19:00','19:30','20:00','20:30','21:00','21:30',
        '22:00','22:30','23:00','23:30','00:00','00:30','01:00','01:30','02:00','02:30','03:00','03:30',
        '04:00'
      ],
      days:[
        {value:0,display:'Monday',selected:false,active:false,date:''},
        {value:1,display:'Tuesday',selected:false,active:false,date:''},
        {value:2,display:'Wednesday',selected:false,active:false,date:''},
        {value:3,display:'Thursday',selected:false,active:false,date:''},
        {value:4,display:'Friday',selected:false,active:false,date:''},
        {value:5,display:'Saturday',selected:false,active:false,date:''},
        {value:6,display:'Sunday',selected:false,active:false,date:''}
      ],
      interleaveRatios:[
        2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20
      ],
      repeatTypes:[
        {value:'0',display:'Every Day'},
        {value:'1',display:'Weekdays'},
        {value:'2',display:'Weekends'}
      ],
      editArray: [],
      editMulti: false,
      multiInterleave: false,
      fillGaps: false,
      selectedInterleaveRatio: 2,
      scheduleArray: [],
      scheduleTemplateArray: [],
      selectedType:'template',
      showRepeatTypes: false,
      showCalendar: false,
      selectedPlaylist: '',
      selectedPlaylistID: 0,
      selectedRepeatType: 0,
      searchedDate: new Date(),
      selectedDate: moment().isoWeek(1),
      selectedDay: 0,
      selectedFrom: 0,
      selectedTill: 2,
      timeError: false,
      scheduleLoaded: false
    }

    this.updateSavedCalendarItem = this.updateSavedCalendarItem.bind(this)
    this.deleteSavedCalendarItem = this.deleteSavedCalendarItem.bind(this)
    this.openScheduleCreator = this.openScheduleCreator.bind(this)
    this.addSchedule = this.addSchedule.bind(this)
    this.addMultiSchedule = this.addMultiSchedule.bind(this)
    this.addMultiInterleave = this.addMultiInterleave.bind(this)
    this.addGapSchedule = this.addGapSchedule.bind(this)
    this.addInterleave = this.addInterleave.bind(this)
    this.deleteInterleave = this.deleteInterleave.bind(this)
    this.editMultiSchedule = this.editMultiSchedule.bind(this)
    this.removePlaylist = this.removePlaylist.bind(this)
    this.resetTimeError = this.resetTimeError.bind(this)
    this.setActivePlaylistInfo = this.setActivePlaylistInfo.bind(this)
    this.setFillGaps = this.setFillGaps.bind(this)
    this.setSelectedPlaylist = this.setSelectedPlaylist.bind(this)
    this.setSelectedRepeatType = this.setSelectedRepeatType.bind(this)
    this.setSelectedFrom = this.setSelectedFrom.bind(this)
    this.setSelectedTill = this.setSelectedTill.bind(this)
    this.setSelectedInterleaveRatio = this.setSelectedInterleaveRatio.bind(this)
    this.setEditArray = this.setEditArray.bind(this)
    this.setScheduleArray = this.setScheduleArray.bind(this)
    this.setScheduleTemplateArray = this.setScheduleTemplateArray.bind(this)
    this.hideScheduleCreator = this.hideScheduleCreator.bind(this)
  }

  componentDidMount() {
    this.props.dispatch(resetSavedCalendarGenerator())
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      savedID,
      schedules,
      newPlaylistID,
      newInterleaveID,
      newInterleaveIDs,
      venueID
    } = this.props;

    const {
      scheduleArray,
      selectedType
    } = this.state;


    let newScheduleArray = [...scheduleArray]

    if (prevState.selectedType !== selectedType){
      this.setState({
        editArray: []
      })

      this.props.dispatch(fetchSavedCalender(savedID, venueID))
    }

    if (prevProps.schedules !== schedules){
      this.prepareSchedule(false)
    }

    if (prevProps.newPlaylistID !== newPlaylistID) {
      const newPlaylistIndex = newScheduleArray.findIndex((item) => {
        return item.i === newPlaylistID.index
      })
      newScheduleArray[newPlaylistIndex].id = newPlaylistID.id
    }

    if (prevProps.newInterleaveID !== newInterleaveID) {
      const newInterleaveIndex = newScheduleArray.findIndex((item) => {
        return item.i === newInterleaveID.index
      })
      newScheduleArray[newInterleaveIndex].interleave.id = newInterleaveID.id
    }

    if (prevProps.newInterleaveIDs !== newInterleaveIDs) {
      newInterleaveIDs.forEach((newInterleave) => {
        const newInterleaveIndex = newScheduleArray.findIndex((item) => {
          return item.id === newInterleave.schedule_calendar_saved_id
        })

        newScheduleArray[newInterleaveIndex].interleave.id = newInterleave.id
      })
    }
  }

  setType(){
    const query = queryString.parse(this.props.location.search.slice(1))
    if(typeof query.type !== 'undefined' && query.type !== this.state.selectedType){
      this.setState({
        selectedType: query.type
      })
    }
  }

  updateSavedCalendarItem(id, data) {
    this.props.dispatch(updateSavedCalendarItem(id, data))
  }

  deleteSavedCalendarItem(id) {
    this.props.dispatch(deleteSavedCalendarItem(id))
  }

  getDays() {
    const {
      days
    } = this.state;

    return (
      <div className="schedule-header">
        <div className="schedule-header-time">&nbsp;</div>
        {days.map((day) => {
          return (
            <div
              className='schedule-header-day'
              key={day.display}
            >
              {day.display}
            </div>
          )
        })}
      </div>
    )
  }

  getTimes() {
    const {
      days,
      selectedType,
      times
    } = this.state;

    return times.map((time,index) => {
      const timeInteger = index;
      return (
        <div key={index} className={`time-row ${selectedType === 'interleave' ? 'time-row--interleave' : ''}`}>
          <div
            className={`time time-${time}`}
            onClick={(e)=>{
              this.openScheduleCreator(false, true, null, timeInteger * 2);
              e.stopPropagation()
            }}
          >
            {time}
          </div>
          {days.map((day,index)=> {
            return (
              <div key={index} className="time-grid">
                <Icon
                  name="add"
                  action={()=>this.openScheduleCreator(false, false, day, timeInteger * 2)}
                  stopPropagation
                />
              </div>
            )
            }
          )}
        </div>
      )
    })
  }

  prepareSchedule(isCalendar) {
    const {
      schedules,
      schedule_template
    } = this.props;

    const {
      fullTimes,
      selectedType
    } = this.state;

    let scheduleArray = []

    const newSchedule = isCalendar ? schedule_template : schedules;

    if (!isCalendar && schedules && schedules.length === 0) {
      this.setState({
        scheduleArray: [],
        scheduleTemplateArray: []
      })
      return;
    }

    if(newSchedule){
      newSchedule.forEach((schedule)=>{
        const data = schedule.pivot;
        const interleave = schedule.interleave;
        const startIndex = getTimeIndex(fullTimes, data.start)
        const endIndex = getTimeIndex(fullTimes, data.end)
        let item = {};
        item.i = uuidv4();
        item.x = parseInt(data.day);
        item.y = startIndex
        item.w = 1;
        item.h = (endIndex === 0 ? fullTimes.length-1 : endIndex ) - startIndex;
        item.maxW = 1;
        item.playlistName = schedule.name;
        item.id = data.id;
        item.playlistID = data.playlist_id;
        item.date = data.date || null;
        item.static = (selectedType === 'interleave')
        item.interleave = interleave || null

        scheduleArray.push(item);
      })
    }

    if (isCalendar) {
      this.setState({
        scheduleTemplateArray: scheduleArray
      })
    } else {
      this.setState({
        scheduleArray: scheduleArray
      })
    }
  }

  openScheduleCreator(edit, repeat, day, time) {
    const {
      activePlaylistInfo,
      playlists
    } = this.props;

    const {
      selectedFrom,
      selectedTill,
      selectedType
    } = this.state;

    if (activePlaylistInfo || selectedType === 'interleave') {
      this.props.dispatch(setPlaylistInfo(false))
      return
    }

    this.setState({
      editMulti: edit,
      multiInterleave: false,
      showRepeatTypes: repeat,
      selectedPlaylist: playlists[0].display_name ? playlists[0].display_name : playlists[0].name,
      selectedPlaylistID: playlists[0].id,
      selectedDay: day ? day.value : null,
      selectedDate: day ? day.date : null,
      selectedFrom: time || time === 0 ? time : selectedFrom,
      selectedTill: time || time === 0 ? time + 2 : selectedTill,
      selectedRepeatType: 0
    })

    this.props.dispatch(toggleScheduleCreator(true))
  }

  openInterleaveCreator(multiInterleave) {
    const {
      activePlaylistInfo
    } = this.props;

    if (activePlaylistInfo) {
      this.props.dispatch(setPlaylistInfo(false))
      return
    }

    this.setState({
      editMulti: !multiInterleave,
      multiInterleave: multiInterleave,
      showRepeatTypes: false,
      selectedPlaylist: '',
      selectedPlaylistID: '',
      selectedDay: null,
      selectedDate: null,
      selectedInterleaveRatio: 2
    })

    this.props.dispatch(toggleScheduleCreator(true))
  }

  checkCollisionPositions(currX, currY, currH, newX, newY, newH) {
    return (currX === newX) && (( ((newY < currY) || (newY === currY)) && ((newY + newH) > currY) ) || ( ((newY > currY) && (newY < (currY + currH))) ))
  }

  checkCollisions(currSchedule, newItems) {
    const {
      id,
      savedID
    } = this.props;

    const {
      fullTimes
    } = this.state;

    const duplicateSchedule = currSchedule.filter(({x, y, h}) =>
      newItems.some(o => this.checkCollisionPositions(x, y, h, o.x, o.y, o.h))
    );

    if (duplicateSchedule.length > 0) {
      this.setState({
        timeError: true
      })
    } else {
      this.props.dispatch(toggleScheduleCreator(false))
      this.setState({
        scheduleArray: currSchedule.concat(newItems),
        timeError: false
      }, () => {
        newItems.forEach((item) => {
          const playlist = {
            'playlistID': item.playlistID,
            'midnight_overlap': (item.y < 40) && ((item.y + item.h) >= 40),
            'start': `${fullTimes[item.y]}:00`,
            'end': `${fullTimes[item.h + item.y]}:00`,
            'day': item.x,
            'venueID': id,
            'date': item.date || null,
            'savedID': savedID
          }

          this.props.dispatch(createSavedCalendarItem(playlist, item.i))
        })
      })
    }
  }

  addSchedule() {
    const {
      scheduleArray,
      selectedPlaylist,
      selectedPlaylistID,
      selectedDay,
      selectedDate,
      selectedFrom,
      selectedTill
    } = this.state;

    let newItemArray = []

    newItemArray.push({
      i: uuidv4(),
      x: selectedDay,
      y: selectedFrom,
      w: 1,
      h: selectedTill - selectedFrom,
      maxW: 1,
      playlistName: selectedPlaylist,
      playlistID: selectedPlaylistID,
      date: selectedDate
    })

    this.checkCollisions(scheduleArray, newItemArray)
  }

  addGapSchedule() {
    const {
      scheduleArray,
      selectedPlaylist,
      selectedPlaylistID,
      selectedDay,
      selectedDate,
      selectedFrom,
      selectedTill
    } = this.state;

    let gapSchedule = scheduleArray.filter((schedule) => {
      return schedule.x === selectedDay
    })

    gapSchedule.push({x: selectedDay, y: selectedFrom, h: selectedTill})

    gapSchedule.sort((a,b) => {
      return a.y - b.y;
    })

    const newIndex = gapSchedule.findIndex((schedule) => {
      return schedule.y === selectedFrom
    })

    let low = gapSchedule[newIndex - 1];
    let high = gapSchedule[newIndex + 1];

    low = low ? low.y + low.h : 0;
    high = high ? high.y - low : 48 - low;

    let newItemArray = []

    newItemArray.push({
      i: uuidv4(),
      x: selectedDay,
      y: low,
      w: 1,
      h: high,
      maxW: 1,
      playlistName: selectedPlaylist,
      playlistID: selectedPlaylistID,
      date: selectedDate
    })

    this.checkCollisions(scheduleArray, newItemArray)
  }

  addMultiSchedule() {
    const {
      days,
      scheduleArray,
      selectedPlaylist,
      selectedPlaylistID,
      selectedRepeatType,
      selectedFrom,
      selectedTill
    } = this.state;

    let multiScheduleArray = [];
    let minInteger;
    let maxInteger;

    if (selectedRepeatType === 2) {
      minInteger = 4;
      maxInteger = 6;
    } else {
      minInteger = 0;
      maxInteger = 7;
    }

    for (let i = minInteger; i < maxInteger; i++) {
      multiScheduleArray.push({
        i: uuidv4(),
        x: i,
        y: selectedFrom,
        w: 1,
        h: selectedTill - selectedFrom,
        maxW: 1,
        date: days[i].date || null,
        playlistName: selectedPlaylist,
        playlistID: selectedPlaylistID
      })
    }

    if (selectedRepeatType === 1) {
      const weekendIndexArray = [5,6]

      multiScheduleArray = multiScheduleArray.filter((item) => {
        return !weekendIndexArray.includes(item.x)
      })
    }

    this.checkCollisions(scheduleArray, multiScheduleArray)
  }

  editMultiSchedule() {
    const {
      days,
      editArray,
      fullTimes,
      scheduleArray,
      selectedPlaylist,
      selectedPlaylistID
    } = this.state;

    let newScheduleArray = [...scheduleArray];

    editArray.forEach((item) => {
      const editIndex = newScheduleArray.findIndex((playlist) => {
        return playlist.id === item.scheduleID
      })

      const arrayItem = newScheduleArray[editIndex];

      arrayItem.playlistID = selectedPlaylistID;
      arrayItem.playlistName = selectedPlaylist;

      this.updateSavedCalendarItem(arrayItem.id, {
        'playlistID': arrayItem.playlistID,
        'midnight_overlap': (arrayItem.y < 40) && ((arrayItem.y + arrayItem.h) >= 40),
        'start': `${fullTimes[arrayItem.y]}:00`,
        'end': `${fullTimes[arrayItem.h + arrayItem.y]}:00`,
        'day': arrayItem.x,
        'date': days[arrayItem.x].date || null
      })
    })

    this.setScheduleArray(newScheduleArray);

    this.setState({
      editArray: [],
      editMulti: false
    })

    this.props.dispatch(toggleScheduleCreator(false))
  }

  deleteSchedule() {
    const {
      editArray,
      scheduleArray,
      selectedType
    } = this.state;

    if (editArray.length) {
      let newScheduleArray = [...scheduleArray];

      editArray.map((item) => {
        const editIndex = newScheduleArray.findIndex((playlist) => {
          return playlist.id === item.scheduleID
        })

        const arrayItem = newScheduleArray[editIndex];

        if (selectedType === 'interleave') {
          if (arrayItem.interleave) {
            return this.deleteInterleave(arrayItem.interleave.id)
          }
        } else {
          this.deleteSavedCalendarItem(arrayItem.id)

          newScheduleArray = newScheduleArray.filter((value) => {
            return value.id !== arrayItem.id;
          })
        }

        return false
      })

      this.setScheduleArray(newScheduleArray);

      this.setState({
        editArray: [],
        editMulti: false
      })
    } else if (selectedType === 'interleave') {
      const confirm = window.confirm('Are you sure you want to remove all interleaves?')

      if(confirm){
        scheduleArray.map((playlist) => {
          if (playlist.interleave) {
            return this.deleteInterleave(playlist.interleave.id)
          }

          return false
        })
      }
    } else {
      const confirm = window.confirm('Are you sure you want to delete all playlists from this schedule?')

      if(confirm){
        scheduleArray.map((playlist) => {
          return this.deleteSavedCalendarItem(playlist.id)
        })

        this.setState({
          scheduleArray: []
        })
      }
    }
  }

  addInterleave(playlist, playlistId, uuid) {
    const {
      dispatch
    } = this.props

    const {
      scheduleArray,
      selectedPlaylistID,
      selectedInterleaveRatio
    } = this.state

    let newScheduleArray = [...scheduleArray]

    const playlistIndex = newScheduleArray.findIndex((item) => {
      return item.i === uuid
    })

    if (scheduleArray[playlistIndex].interleave) {
      this.deleteInterleave(scheduleArray[playlistIndex].interleave.id)
    }

    newScheduleArray[playlistIndex].interleave = {
      id: selectedPlaylistID,
      playlist: {
        name: playlist,
      },
      playlist_id: selectedPlaylistID,
      ratio: selectedInterleaveRatio
    }

    const data = {
      'playlist_id': selectedPlaylistID,
      'schedule_template_id': playlistId,
      'ratio': selectedInterleaveRatio,
      'business_id':this.props.businessID,
      'venue_id':this.props.venueID
    }

    this.setActivePlaylistInfo('')

    dispatch(addSavedCalendarInterleave(data, uuid))
  }

  addInterleaves(interleaves,playlist){
    const{
      dispatch
    } = this.props

    const {
      scheduleArray,
      selectedPlaylistID,
      selectedInterleaveRatio
    } = this.state

    let newScheduleArray = [...scheduleArray]

    interleaves.forEach((interleave) => {
      newScheduleArray[interleave.index].interleave = {
        id: selectedPlaylistID,
        playlist: {
          name: playlist,
        },
        playlist_id: selectedPlaylistID,
        ratio: selectedInterleaveRatio
      }
    })

    const dataInterleaves = interleaves.map((interleave)=>{
      return {
        'playlist_id': selectedPlaylistID,
        'schedule_template_id': interleave.id,
        'schedule_index': interleave.index,
        'ratio': selectedInterleaveRatio
      }
    })

    const post = {
      playlist_id:selectedPlaylistID,
      business_id:this.props.businessID,
      venue_id:this.props.venueID,
      data:dataInterleaves
    }

    this.setActivePlaylistInfo('')

    dispatch(addSavedCalendarInterleaves(post))
  }


  addMultiInterleave(allPlaylists) {
    const {
      editArray,
      scheduleArray,
      selectedPlaylist
    } = this.state

    let newScheduleArray = [...scheduleArray];
    //send array to API
    let sendData = []
    if (allPlaylists) {
      newScheduleArray.forEach((item, index) => {
        if (item.interleave) {
          this.deleteInterleave(item.interleave.id)
        }

        sendData.push({
          id: item.id,
          index: index
        })
      })
    } else {
      editArray.forEach((item) => {
        const editIndex = newScheduleArray.findIndex((playlist) => {
          return playlist.id === item.scheduleID
        })
        if (newScheduleArray[editIndex].interleave) {
          this.deleteInterleave(newScheduleArray[editIndex].interleave.id)
        }

        sendData.push({
          id: item.scheduleID,
          index: editIndex
        })
      })

      this.setState({
        editArray: [],
        editMulti: false
      })
    }

    this.addInterleaves(sendData,selectedPlaylist)
  }

  deleteInterleave(id) {
    const {
      scheduleArray
    } = this.state;

    let newScheduleArray = [...scheduleArray];

    const editIndex = newScheduleArray.findIndex((playlist) => {
      if (playlist.interleave) {
        return playlist.interleave.id === id
      }
      return false
    })

    newScheduleArray[editIndex].interleave = null;
    this.setScheduleArray(newScheduleArray);
    this.setActivePlaylistInfo('')
    this.props.dispatch(deleteSavedCalendarInterleave(id))
  }

  toggleInterleaveRatioChanger(isOpen){
    this.props.dispatch(toggleInterleaveRatioChanger(isOpen))
  }

  changeInterleaveRatios(){
    const {
      editArray,
      scheduleArray,
      selectedInterleaveRatio
    } = this.state

    this.toggleInterleaveRatioChanger(false)
    //send array to API
    let sendData = []

    if(editArray.length > 0){
      //selected schedules only
      editArray.forEach((item) => {
        const index = scheduleArray.findIndex(schedule => schedule.id === item.scheduleID)
        //check has interleave
        if(scheduleArray[index].interleave){
          const data = {
            id:scheduleArray[index].interleave.id,
            ratio:selectedInterleaveRatio,
            schedule_index: index
          }
          sendData.push(data)
          scheduleArray[index].interleave.ratio = selectedInterleaveRatio
        }
      })
      this.setState({
        editArray: [],
        editMulti: false,
        scheduleArray: scheduleArray
      })
    } else {
      //all
      scheduleArray.forEach((item, index) => {
        if(item.interleave){
          const data = {
            id: item.interleave.id,
            ratio: selectedInterleaveRatio,
            schedule_index: index
          }
          sendData.push(data)
          scheduleArray[index].interleave.ratio = selectedInterleaveRatio
        }
      })
      this.setState({
        editArray: [],
        editMulti: false,
        scheduleArray: scheduleArray
      })
    }
    this.props.dispatch(updateSavedCalendarInterleaveRatios(sendData))
  }

  hideScheduleCreator() {
    this.props.dispatch(toggleScheduleCreator(false))
  }

  resetTimeError() {
    this.setState({
      timeError: false
    })
  }

  removePlaylist(playlist) {
    const {
      scheduleArray
    } = this.state;

    let schedule = [...scheduleArray];

    schedule = schedule.filter((item) => {
      return item.i !== playlist;
    })

    this.setState({
      scheduleArray: schedule
    })

  }

  setSelectedPlaylist(playlist, playlistID) {
    this.setState({
      selectedPlaylist: playlist,
      selectedPlaylistID: playlistID
    });
  }

  setSelectedRepeatType(repeatType) {
    this.setState({
      selectedRepeatType: parseInt(repeatType)
    });
  }

  setFillGaps(fill) {
    this.setState({
      fillGaps: fill
    });
  }

  setSelectedFrom(time) {
    const {
      selectedTill
    } = this.state;

    this.setState({
      selectedFrom: time
    });

    if (time >= selectedTill) {
      this.setState({selectedTill: time + (time === 47 ? 1 : 2)})
    }
  }

  setSelectedTill(time) {
    const {
      selectedFrom
    } = this.state;

    this.setState({
      selectedTill: time
    })

    if (time <= selectedFrom) {
      this.setState({selectedFrom: time - (time === 1 ? 1 : 2)})
    }
  }

  setScheduleArray(schedule) {
    this.setState({
      scheduleArray: schedule
    })
  }

  setScheduleTemplateArray(schedule) {
    this.setState({
      scheduleTemplateArray: schedule
    })
  }

  setEditArray(schedule) {
    this.setState({
      editArray: schedule
    })
  }

  setActivePlaylistInfo(playlist) {
    this.props.dispatch(setPlaylistInfo(playlist))
  }

  setSelectedInterleaveRatio(ratio) {
    this.setState({
      selectedInterleaveRatio: ratio
    })
  }

  selectType(value){
    this.props.history.push({
      search: `?tab=calendarGenerator&type=${value}`
    })
    this.setState({selectedType: value})
  }

  createCalendarSchedule(){
    const {
      dispatch,
      venue
    } = this.props

    dispatch(toggleOverlay(
      true,
      venue,
      'venueSavedCalendarCreate'
    ))
  }

  viewSavedCalendarSchedules(){
    const {
      dispatch,
      venue
    } = this.props

    dispatch(toggleOverlay(
      true,
      venue,
      'venueSavedCalendars'
    ))
  }

  savedCalendarAddToCalendar(){
    const {
      dispatch,
      savedID,
      venueID
    } = this.props

    dispatch(toggleOverlay(
      true,
      {
        venueID: venueID,
        savedID: savedID
      },
      'venueSavedCalendarAddToCalendar'
    ))
  }

  render() {
    const {
      loading,
      playlists,
      scheduleLoading,
      showScheduleCreator,
      activePlaylistInfo,
      showInterleaveRatioChanger,
      saveCalendarScheduleLoaded,
      calendarScheduleName
    } = this.props

    const {
      days,
      editArray,
      editMulti,
      interleaveRatios,
      multiInterleave,
      fillGaps,
      fullTimes,
      repeatTypes,
      scheduleArray,
      showRepeatTypes,
      selectedPlaylist,
      selectedDay,
      selectedFrom,
      selectedInterleaveRatio,
      selectedTill,
      selectedType,
      timeError,
    } = this.state

    if(loading) {
      return <Loader/>
    } else {
      return (
        <Container classname={classname} height="100%" maxWidth column>
          <div className='venue-calendar-generator__controls'>
            <Button
              name='Create new'
              action={()=>{ this.createCalendarSchedule()}}
            />
            <Button
              name='Load saved schedule'
              action={()=>{this.viewSavedCalendarSchedules()}}
            />
            {calendarScheduleName && (
              <h4>{calendarScheduleName}</h4>
            )}
          </div>
          {((scheduleArray.length > 0) || saveCalendarScheduleLoaded) && (
            <Fragment>
              <div className="schedule-actions">
                <div className="schedule-actions-left">
                  <Radio
                    action={(e)=>this.selectType(e.target.value)}
                    name='selectedType'
                    options={[
                      {
                        label: 'Schedule',
                        value: 'schedule',
                        defaultChecked: true
                      },
                      {
                        label: 'Interleave',
                        value: 'interleave'
                      }
                    ]}
                  />
                </div>
                <div className="schedule-actions-right">
                  {//change all ratios
                    <Icon
                      name="swap"
                      action={() => this.toggleInterleaveRatioChanger(true)}
                      disabled={selectedType !== 'interleave'}
                    >
                      <Tooltip
                        text={editArray.length > 0 ? 'Change selected ratios' : 'Change all ratios'}
                        pos="left"
                      />
                    </Icon>
                  }
                  <Icon
                    name="create"
                    action={selectedType === 'interleave' ? (
                      ()=>this.openInterleaveCreator(false  )
                    ) : (
                      ()=>this.openScheduleCreator(true, false)
                    )}
                    stopPropagation
                  >
                    <Tooltip text="Edit" pos="left" />
                  </Icon>
                  {editArray.length === 0 && (
                    <Icon
                      name="add-circle"
                      action={selectedType === 'interleave' ? (
                        ()=>this.openInterleaveCreator(true)
                      ) : (
                        ()=>this.openScheduleCreator(false, true)
                      )}
                      stopPropagation
                    >
                      <Tooltip
                        text={selectedType === 'interleave' ? (
                          `Add interleave`
                        ) : (
                          `Create schedule`
                        )}
                        pos="left"
                      />
                    </Icon>
                  )}
                  <Icon
                    name="trash"
                    action={()=>this.deleteSchedule()}
                    stopPropagation
                  >
                    <Tooltip
                      text={selectedType === 'interleave' ? (
                        `Clear interleaves`
                      ) : (
                        `Clear schedule`
                      )}
                      pos="left"
                    />
                  </Icon>
                  <Icon
                    name="calendar"
                    action={()=>this.savedCalendarAddToCalendar()}
                    stopPropagation
                    disabled={scheduleArray.length === 0}
                  >
                    <Tooltip text="Add schedule to calendar" pos="left" />
                  </Icon>
                </div>
              </div>
              {this.getDays()}
              <div className={`schedules-wrapper${scheduleLoading ? ' schedules-wrapper--loading' : ''}`}>
                {this.getTimes()}
                {scheduleLoading ? (
                  <Loader />
                ) : (
                  <Fragment>
                    <Schedule
                      days={days}
                      fullTimes={fullTimes}
                      editArray={editArray}
                      isInterleave={selectedType === 'interleave'}
                      interleaveRatios={interleaveRatios}
                      playlists={playlists}
                      scheduleArray={scheduleArray}
                      activePlaylistInfo={activePlaylistInfo}
                      selectedView={selectedType}
                      selectedPlaylist={selectedPlaylist}
                      selectedInterleaveRatio={selectedInterleaveRatio}
                      setEditArray={this.setEditArray}
                      setScheduleArray={this.setScheduleArray}
                      setSelectedPlaylist={this.setSelectedPlaylist}
                      setSelectedInterleaveRatio={this.setSelectedInterleaveRatio}
                      setActivePlaylistInfo={this.setActivePlaylistInfo}
                      removePlaylist={this.removePlaylist}
                      updateVenueSchedule={this.updateSavedCalendarItem}
                      deleteVenueSchedule={this.deleteSavedCalendarItem}
                      addInterleave={this.addInterleave}
                      deleteInterleave={this.deleteInterleave}
                    />
                  </Fragment>
                )}
              </div>
              {showScheduleCreator && (
                <ScheduleCreator
                  editArray={editArray}
                  editMulti={editMulti}
                  multiInterleave={multiInterleave}
                  fillGaps={fillGaps}
                  isInterleave={selectedType === 'interleave'}
                  interleaveRatios={interleaveRatios}
                  playlists={playlists}
                  repeatTypes={repeatTypes}
                  scheduleArray={scheduleArray}
                  showRepeatTypes={showRepeatTypes}
                  selectedPlaylist={selectedPlaylist}
                  selectedDay={selectedDay}
                  selectedFrom={selectedFrom}
                  selectedTill={selectedTill}
                  timeError={timeError}
                  resetTimeError={this.resetTimeError}
                  setFillGaps={this.setFillGaps}
                  setSelectedPlaylist={this.setSelectedPlaylist}
                  setSelectedRepeatType={this.setSelectedRepeatType}
                  setSelectedFrom={this.setSelectedFrom}
                  setSelectedTill={this.setSelectedTill}
                  setSelectedInterleaveRatio={this.setSelectedInterleaveRatio}
                  hideScheduleCreator={this.hideScheduleCreator}
                  addSchedule={this.addSchedule}
                  addGapSchedule={this.addGapSchedule}
                  addMultiSchedule={this.addMultiSchedule}
                  editMultiSchedule={this.editMultiSchedule}
                  addMultiInterleave={this.addMultiInterleave}
                />
              )}
              {showInterleaveRatioChanger && (
                <InterleaveRatioChanger
                  setRatio={(ratio)=>this.setSelectedInterleaveRatio(ratio)}
                  ratios={interleaveRatios}
                  save={()=>this.changeInterleaveRatios()}
                  close={()=>this.toggleInterleaveRatioChanger(false)}
                  selected={selectedInterleaveRatio}
                />
              )
              }
            </Fragment>
          )}
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    schedules:store.venue.schedules,
    venue:store.venue,
    saveCalendarScheduleLoaded:store.venue.saveCalendarScheduleLoaded,
    schedule_template:store.venue.schedule_template,
    playlists:store.venue.playlists,
    loading:store.venue.loading,
    scheduleLoading:store.venue.scheduleLoading,
    newPlaylistID: store.venue.newPlaylistID,
    newInterleaveID: store.venue.newInterleaveID,
    newInterleaveIDs: store.venue.newInterleaveIDs,
    showScheduleCreator: store.venue.showScheduleCreator,
    showInterleaveRatioChanger:store.venue.showInterleaveRatioChanger,
    newInterleaveRatios:store.venue.newInterleaveRatios,
    activePlaylistInfo: store.venue.activePlaylistInfo,
    businessID: store.venue.businessID,
    venueID: store.venue.venueID,
    savedID: store.venue.saved_id,
    calendarScheduleName: store.venue.calendarScheduleName
  }
}

export default withRouter(connect(mapStateToProps)(VenueScheduleCalendarGenerator))
