import React, { Component } from 'react'

import Logo from 'ui/Logo'
import Loader from 'ui/Loader'
import Form from 'ui/Form'
import Container from 'ui/Container'

const classname = 'auth'

class Auth extends Component {

  constructor(props){
    super(props)
    this.state = {
      username:'',
      password:''
    }
  }

  handleChange(e) {
    const name = e.target.name
    this.setState({[name]: e.target.value});
  }

  onSubmit(res){
    this.props.login(res)
  }

  render(){
    if(this.props.loading){
      return <Loader/>
    } else {
      return (
        <Container classname={classname}>
          <Logo/>
          <Form
            classname={classname}
            submit={(res)=>this.onSubmit(res)}
            messages={this.state.message}
            change={(e)=>this.handleChange(e)}
            altInput>
          </Form>
        </Container>
      )
    }
  }
}

export default Auth
