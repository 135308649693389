import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  getUsers,
  searchUsers,
  searchUsersTypeahead,
  clearUsers,
  clearTypeahead,
  createUser
} from 'store/actions/userAction'

import {
  getBusinessesTypeahead,
  clearBusinessesTypeahead
} from 'store/actions/businessAction'

import { getRowLimit } from 'helpers/TableHelper'
import { parseQueryString } from 'helpers/StringHelper'

import { toggleOverlay } from 'store/actions/overlayAction'

import Loader from 'ui/Loader'
import Tabs from 'ui/Tabs'
import Container from 'ui/Container'
import Form from 'ui/Form'
import TableWrapper from 'ui/TableWrapper'
import TableTypeahead from 'ui/TableTypeahead'
import UsersTableActions from './users/UsersTableActions'

const classname = 'users'

class UsersContainer extends Component {

  constructor(props){
	  super(props)

    // initial state
    this.state = {
      tab: this.props.location.search.slice(1),
      url:'users',
      searchTab:[],
      disableTypeahead: false,
    }

    // apply tab and search state
    const params = parseQueryString(this.props.location.search)
    if(params.term){
      this.state = {
        ...this.state,
        ...this.searchUsers(params.term, true)
      }
    } else {
      this.state.tab = params.tab || 'managers'
    }

    // user types for create form
    this.userTypes = [
      {display:'Manager',value:9},
      {display:'Curator',value:10}
    ]

  }

  componentDidMount(){
    this.dispatchTab()
  }

  componentDidUpdate(prevProps, prevState){
    const {
      error,
      createdUserID,
      history
    } = this.props

    // const params = parseQueryString(tab)

    if(createdUserID && prevProps.createdUserID !== createdUserID){
      history.push(`/users/${createdUserID}`)
    }

    if(error && (prevProps.error !== error) && error.response.data.res && error.response.data.res.data) {
      const serverErrorScrollPos = document.getElementsByName(error.response.data.res.data)[0].offsetTop + 200
      document.getElementsByClassName('container-users')[0].scrollTo(0, serverErrorScrollPos)
    }

    if(prevState.tab !== this.state.tab){
      this.props.dispatch(clearUsers())
      this.dispatchTab()
    }
  }

  dispatchTab(){
    const rowLimit = getRowLimit(classname)
    const query = parseQueryString(this.state.tab)
    const tab = query.tab || this.state.tab
    const term = query.term
    const type = query.type

    if(tab === 'managers' || tab === 'curators'){
      // show users relevant to selected tab
      this.props.dispatch(getUsers(this.getTabFilterID(tab), rowLimit, 0, true))

    } else if(tab === 'search' && term){
      // search users based on type parameter
      this.props.dispatch(searchUsers(this.getTabFilterID(type), rowLimit, 0, term))

    }
  }

  getTabFilterID(tab){
    if(tab === 'managers'){
      return 9
    } else if(tab === 'curators'){
      return 10
    }
  }

  selectTab(tab){
    this.setState({tab:tab},()=>{
      if(!tab.startsWith('tab=')) tab = 'tab=' + tab;
      this.props.history.push({
        search: `?${tab}`
      })
    })
  }

  closeTab(tab){
    const searchTabs = this.state.searchTab.filter(tb => tb !== tab)
    this.setState({
      searchTab:searchTabs,
      tab:'managers',
      url:'users'
    },()=>{
      this.selectTab('managers')
    })
  }

  clearUsers(){
    this.props.dispatch(clearUsers())
  }

  getUsers(limit,offset){
    const params = parseQueryString(this.state.tab)
    if(params.type === 'search'){
      // standard search
      this.props.dispatch(searchUsers(this.getTabFilterID(this.state.tab), limit, offset, params.term))
    } else {
      // show all users for current tab (manager or curator)
      this.props.dispatch(getUsers(this.getTabFilterID(this.state.tab), limit, offset))
    }
  }

  moreActions(data){
    this.props.dispatch(toggleOverlay(true,data,'users'))
  }

  searchUsers(term, initialLoad){
    const type = (this.state.tab.includes('type=') ? parseQueryString(this.state.tab).type : this.state.tab)
    const link = `tab=search&type=${type}&term=${encodeURIComponent(term)}`

    const state = {
      searchTab:this.state.searchTab.concat(
        {name:`${type === 'managers' ? 'Managers' : 'Curators'} - Search: ${term}`,link:link,dismiss:true}
      ),
      tab:link,
      url:`users/search/${term}`,
      disableTypeahead: true
    }

    if(initialLoad){
      return state

    } else {
      // If the user performs a search, disable the typeahead dropdown
      this.setState(state,()=>{
        this.props.history.push({
          search: `?${link}`
        })
      })

    }
  }

  searchUsersTypeahead(value){
    this.props.dispatch(searchUsersTypeahead(this.getTabFilterID(this.state.tab), value))
  }

  clearUsersTypeahead(value){
    this.props.dispatch(clearTypeahead())
  }

  typeaheadBusinesses(value){
    this.props.dispatch(getBusinessesTypeahead(value))
  }

  clearBusinessesTypeahead(){
    this.props.dispatch(clearBusinessesTypeahead())
  }

  createUser(data){
    this.props.dispatch(createUser(data))
  }

  getComponent(){
    const {
      businessesTypeahead,
      businessesTypeaheadLoading,
      count,
      error,
      loading,
      storedUserData,
      typeahead,
      typeaheadLoading,
      users
    } = this.props

    const {
      message,
      tab
    } = this.state

    switch(tab){
      default:
      case 'all':
        return (
          <Container height="100%" classname={`${classname} container-tabview`} column>
            <TableWrapper
              controls
              classname={classname}
              data={users}
              count={count}
              clearData={()=>this.clearUsers()}
              getData={(limit,offset)=>this.getUsers(limit,offset)}
              loading={loading}
              rowActions={<UsersTableActions
                more={(data)=>this.moreActions(data)}
                classname={classname}/>
              }
              tableAction={<TableTypeahead
                classname={classname}
                action={(playlist)=>this.addPlaylist(playlist)}
                typeaheadSearch={(value)=>this.searchUsersTypeahead(value)}
                typeaheadLoading={typeaheadLoading}
                search={(value)=>this.searchUsers(value)}
                clear={()=>this.clearUsersTypeahead()}
                data={typeahead}/>
              }
            />
          </Container>
        )
      case 'create':
        return (
          <Container height="100%" classname={`${classname} container-tabview`} maxHeight column>
            {loading ? (
              <Loader/>
            ) : (
              <Form
                classname='createUser'
                submit={(data)=>this.createUser(data)}
                messages={message}
                data={storedUserData}
                userTypes={this.userTypes}
                typeaheadSearch={(value)=>this.typeaheadBusinesses(value)}
                typeaheadData={businessesTypeahead}
                typeaheadLoading={businessesTypeaheadLoading}
                disableEnterToSearch={true}
                clearTypeahead={()=>this.clearBusinessesTypeahead()}
                serverError={error && error.response.data.res}
              />
            )}
          </Container>
        )
    }
  }

  render(){
    return (
      <Container classname={`${classname}-wrapper`} height="100%" column>
        <Container classname="tabs">
          <Tabs
            match={this.props.match}
            classname={classname}
            select={(tab)=>this.selectTab(tab)}
            active={this.state.tab}
            add={this.state.searchTab}
            closeTab={(tab)=>this.closeTab(tab)}
          />
        </Container>
          {this.getComponent()}
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    businessesTypeahead: store.businesses.typeahead,
    businessesTypeaheadLoading: store.businesses.typeaheadLoading,
    users: store.users.data,
    typeahead: store.users.typeahead,
    typeaheadLoading: store.users.typeaheadLoading,
    count: store.users.count,
    createdUserID: store.users.createdUserID,
    storedUserData: store.users.storedUserData,
    error: store.users.error,
    loading: store.users.loading
  }
}

export default withRouter(connect(mapStateToProps)(UsersContainer))
