import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  getPlaylistTracksSplit,
  filterPlaylistTracks,
  removeTrackFromPlaylist,
  removeTracksFromPlaylist,
  addSelectedTrack,
  clearSelectedPlaylistTracks,
  removeSelectedTrack,
  setPlaylistTracksSorted
} from 'store/actions/playlistAction'

import {
  getTrackInfo,
  getTrackUrl
} from 'store/actions/playerAction'

import { toggleOverlay } from 'store/actions/overlayAction'

import { toggleConfirm } from 'store/actions/confirmAction'

import Confirm from 'ui/Confirm'
import Container from 'ui/Container'
import Loader from 'ui/Loader'
import PlaylistTracksSplitTableAction from './playlist-tracks/PlaylistTracksSplitTableAction'
import PlaylistTracksRow from './playlist-tracks/PlaylistTracksRow'

import TableWrapper from 'ui/TableWrapper'
import PlaylistTracksTableRowActions from './playlist-tracks/PlaylistTracksTableRowActions'

import { debounce } from 'helpers/Debounce'
import { tableSorter } from 'helpers/TableHelper'

const classname = 'playlistTracksSplit'

class PlaylistTracksSplit extends Component {

  constructor(props){
    super(props)
    this.state = {
      filter: null,
      actionActive:false,
    }
    this.filterTracksDispatch = debounce(this.filterTracksDispatch,300)
  }

  componentDidMount(){
    const {
      dispatch,
      selectedTracks
    } = this.props

    dispatch(getPlaylistTracksSplit(this.props.id,this.props.designationType))

    //clear filter on mount
    this.filterTracksDispatch('')

    // if the component loads with tracks already selected,
    // show the table actions
    if (selectedTracks.length > 0) {
      this.setState({
        actionActive:true
      })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      tracksSplit,
      filter,
      selectedTracks,
      sorter
    } = this.props

    if (prevProps.tracksSplit !== tracksSplit) {
      this.setTracks()
    }

    //filter
    if (prevProps.filter !== filter) {
      this.setTracks()
    }

    if (prevProps.selectedTracks !== selectedTracks) {
      this.setState({
        actionActive: selectedTracks.length > 0
      })
    }

    if (JSON.stringify(prevProps.sorter) !== JSON.stringify(sorter)) {
      this.setTracks()
    }
  }

  playTrack(track){
    const {
      dispatch
    } = this.props

    dispatch(getTrackInfo(track, 'playlist'))
    dispatch(getTrackUrl(track, 'playlist '))
  }

  filterTracks(e){
    const filter = e.target.value
    this.filterTracksDispatch(filter)
  }

  filterTracksDispatch(term){
    this.props.dispatch(filterPlaylistTracks(term))
  }

  moreActions(data){
    this.props.dispatch(toggleOverlay(true,data,'playlistTracksMore'))
  }

  sortType(x,y){
    const {field, direction} = this.props.sorter

    // if no direction is provided, due to the sorting icon being reset,
    // or there is no value provided to be sorted on, use default values
    if (direction === null || !(field in x)) {
      return tableSorter(x,y,'added','desc')
    }

    return tableSorter(x,y,field,direction)
  }

  setTracks(){
    const {
      dispatch,
      filter,
      tracksSplit
    } = this.props

    // create new array, so that we do not mutate the props data
    // allows proper reset of sorting order
    const tracksArray = [...tracksSplit]

    //sort tracks by date added to playlist and filter
    const sortedTracks = tracksArray
      .sort((x, y) => this.sortType(x, y))
      .filter(track => {
        if (track.filter && track.filter.toLowerCase().includes(filter.toLowerCase())) {
          return track
        }
        return false
      })

    dispatch(setPlaylistTracksSorted(sortedTracks))
  }

  removeTrack(data){
    this.props.dispatch(removeTrackFromPlaylist(data))
  }

  checkboxAction(e,data,index){
    const {
      dispatch,
      selectedTracks
    } = this.props

    if(e.nativeEvent.metaKey || e.nativeEvent.shiftKey){
      return
    }
    if(e.target.checked){
      data.index = index
      dispatch(addSelectedTrack(data))
    } else {
      dispatch(removeSelectedTrack(data))
    }
  }

  clickRow(e,data,isChecked,index){
    const {
      dispatch,
      selectedTracks
    } = this.props

    e.stopPropagation()
    //cmd + click
    if(e.metaKey){
      if(!isChecked){
        data.index = index
        dispatch(addSelectedTrack(data))
      } else {
        dispatch(removeSelectedTrack(data))
      }
      if(selectedTracks.length > 0){
        this.setState({
          actionActive:true
        })
      } else {
        this.setState({
          actionActive:false
        })
      }
    }
    if(e.shiftKey){
      if(selectedTracks.length === 1){
        for (let i = selectedTracks[0].index + 1; i <= index; i++) {
          dispatch(addSelectedTrack(this.state.tracks[i]))
        }
        this.setState({
          actionActive:true
        })
      }
    }
  }

  deselectTracks(){
    this.props.dispatch(clearSelectedPlaylistTracks())
    this.setState({
      actionActive:false
    })
  }

  getBreakdown(data){
    this.props.dispatch(toggleOverlay(true,data,'breakdown'))
  }

  removeTracks(){
    const {
      dispatch,
      selectedTracks
    } = this.props

    const confirmData = {
      action: () => {
        dispatch(removeTracksFromPlaylist(selectedTracks));
        this.deselectTracks()
      },
      question: `Permanently remove selected tracks from playlist?`
    }

    dispatch(toggleConfirm(true, confirmData))
  }

  openPlaylistOverlay(){

  }

  changeDesignation(data){
    console.log(data.event.target.value)
  }

  favouriteTrack(data){
    console.log(data)
  }

  sortByDesignation(split){

  }

  getTable(){
    const {
      loading,
      openDesignation,
      playingTrack,
      selectedTracks,
      sortedTracks,
      tracks,
      tracksSplit
    } = this.props

    return <TableWrapper
      classname={classname}
      data={sortedTracks}
      loading={loading}
      rowComp={
        <PlaylistTracksRow
          play={(id)=>this.playTrack(id)}
          playingTrack={playingTrack}
          checkboxSelected={selectedTracks}
          checkboxAction={(e,data,index)=>this.checkboxAction(e,data,index)}
          clickRow={(e,data,isChecked,index)=>this.clickRow(e,data,isChecked,index)}
          />
      }
      rowActions={<PlaylistTracksTableRowActions
        favouriteTrack={(data)=>this.favouriteTrack(data)}
        moreActions={(data)=>this.moreActions(data)}
        removeTrack={(data)=>this.removeTrack(data)}
        classname={classname}
        isSplit={true}/>
      }
      tableAction={<PlaylistTracksSplitTableAction
        active={this.state.actionActive}
        classname={classname}
        count={tracks.length}
        countSplit={tracksSplit.length}
        placeholder="Filter tracks..."
        numSelected={selectedTracks.length}
        deselectTracks={()=>this.deselectTracks()}
        action={(e)=>this.filterTracks(e)}
        changeInfo={()=>this.changeInfo()}
        addToQueue={()=>this.addToQueue()}
        removeTracks={()=>this.removeTracks()}
        getBreakdown={(data)=>this.getBreakdown(data)}
        openDesignation={(designation,id)=>openDesignation(designation,id)}/>
      }
      />
  }

  render(){
    if(this.props.loading){
      return <Loader/>
    } else {
      return (
        <Container classname="playlist-tracks" height="100%">
          {this.getTable()}
          <Confirm />
        </Container>
      )
    }
  }
}
function mapStateToProps(store){
  return {
    tracksSplit:store.playlist.tracksSplit,
    tracks:store.playlist.tracks,
    sortedTracks:store.playlist.sortedTracks,
    selectedTracks:store.playlist.selectedTracks,
    metadata:store.playlist.metadata,
    filter:store.playlist.filter,
    playingTrack: store.player.track,
    sorter: store.table.sorter,
    loading:store.playlist.loading
  }
}

export default connect(mapStateToProps)(PlaylistTracksSplit)
