import React from 'react'

import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'

const PlaylistTabActions = (props) => {
  const {isPinned, pinPlaylist, playlistMore} = props
  const pinnedIcon = isPinned ?
    <Icon name="star" active={true}/> : 
    <Icon name="star" action={()=>pinPlaylist()}>
      <Tooltip text="Pin playlist" pos="left"/>
    </Icon>
  return (
    <div className="tabs-actions playlist-tab-actions">
      {pinnedIcon}
      <Icon name="copy" action={()=>playlistMore()}/>
    </div>
  )
}

export default PlaylistTabActions