import React, {Component} from 'react'

import TableCell from 'ui/TableCell'
import TableCellLink from 'ui/TableCellLink'
import TableCellEnergy from 'ui/TableCellEnergy'
import TableCellGenres from 'ui/TableCellGenres'
import LibraryTableCell from 'modules/library/LibraryTableCell'
import Icon from 'ui/Icon'

import TableModel from 'models/TableModel'

class PlaylistTracksRow extends Component {

  constructor(props){
    super(props)
    this.ref = React.createRef()
  }

  render(){
    const {
      data,
      classname,
      size,
      checkboxAction,
      checkboxSelected,
      index,
      libraryAction,
      play,
      playingTrack,
      clickRow
    } = this.props

    //is row checked
    const isChecked = checkboxSelected.find(elem => elem.id === data.id) ? true : false
    const isSelected = playingTrack && (playingTrack.id === data.id)
    const currentlyPlaying = isSelected && playingTrack.isPlaying
    //keep selected track in view
    //if(isSelected){
    //  this.ref.current.scrollIntoViewIfNeeded(false)
    //}

    const classes = `
      table-row
      table-row-${classname}
      table 
      ${isSelected ? 'isPlaying' : ''}
      ${isChecked ? 'checked' : ''}
    `

    let isPlaying = false

    const playTrack = (track) => {
      isPlaying = !isPlaying
      play(track)
    }

    let cells = TableModel[classname]['headers'].map((header,index)=>{
      if(header.field === 'energy') {
        return <TableCellEnergy
          track={data}
          key={index}
          datum={data[header.field]}
          classname={classname}
          size={header.size}/>
      } else if(header.field === 'genres') {
        return <TableCellGenres
          track={data}
          key={index}
          datum={data[header.field]}
          classname={classname}
          size={header.size}/>
      } else if(header.link) {
        return <TableCellLink
          key={index}
          datum={data[header.field]}
          field={header.field}
          classname={classname}
          size={header.size}/>
      } else if(header.libraryAction){
        return <LibraryTableCell
          key={index}
          datum={data[header.field]}
          field={header.field}
          classname={classname}
          size={header.size}
          libraryAction={(data,e) => libraryAction(data,e)}/>
      } else {
        return <TableCell
          key={index}
          datum={data[header.field]}
          classname={classname}
          size={header.size}/>
      }
    })

    cells.unshift(
      <td key="play" className={`table-cell table-cell-play table-cell-${classname}`}>
        <div className="icon-wrapper">
          {data.duplicate && <Icon name="alert"/>}
          <Icon name={`${currentlyPlaying ? 'volume-high' : 'play'}`} action={()=>playTrack(data)} />
        </div>
      </td>
    )

    cells.unshift(
      <td key="checkbox" className={`table-cell table-cell-checkbox table-cell-${classname}`}>
        <input
          value={index || ''}
          name={index || ''}
          type="checkbox"
          onChange={(e)=>checkboxAction(e,data,index)}
          checked={isChecked || false}/>
      </td>
    )
    return (
      <tr className={classes} ref={this.ref} onDoubleClick={()=>playTrack(data)} onClick={(e)=>clickRow(e,data,isChecked ? true : false,index)}>
        {cells}
        <td className={`table-cell table-cell-actions table-cell-actions-${classname} table-cell-actions-${size}`}>
          {this.props.actionsComp}
        </td>
      </tr>
    )
  }
}

export default PlaylistTracksRow
