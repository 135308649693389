import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  getVenueUser,
  updateVenueUser
 } from 'store/actions/venueAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Form from 'ui/Form'

const classname = 'venueUser'

class VenueUser extends Component {
  constructor(props){
    super(props)
    this.state = {
      displayName:null
    }
  }

  componentDidMount(){
    this.props.dispatch(getVenueUser(this.props.id))
  }


  onSubmit(form){
    this.props.dispatch(updateVenueUser(
      this.props.id,
      form
    ))
  }

  render(){
    const {
      user,
      loading,
      error
    } = this.props

    if(loading){
      return <Loader/>
    } else {
      return (
        <Container classname={classname} height="100%">
          <Form
            classname={classname}
            submit={(form)=>this.onSubmit(form)}
            messages={this.state.message}
            data={user}
            serverError={error && error.response.data.res}
            scroll
          />
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    venue:store.venue,
    user:store.venue.user,
    loading:store.venue.loading,
    error:store.venue.error
  }
}

export default connect(mapStateToProps)(VenueUser)
