import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  getContactsFields,
  updateContact
} from 'store/actions/contactsAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Form from 'ui/Form'

const classname = 'overlayContactUpdate';

class OverlayContactUpdate extends Component {
  componentDidMount() {
    this.props.dispatch(getContactsFields())
  }

  onSubmit(form){
    const {
      data,
      dispatch
    } = this.props


    dispatch(updateContact(form))
  }

  render(){
    const {
      contactPositions,
      contactStates,
      data,
      businessContactsLoading,
      venueContactsLoading
    } = this.props

    if (businessContactsLoading || venueContactsLoading) {
      return <Loader/>
    } else {
      return (
        <Container classname="overlay-contact-update" maxWidth height="100%" column>
          <Form
            classname={classname}
            data={data}
            submit={(form)=>this.onSubmit(form)}
            contactStates={contactStates}
            contactPosition={contactPositions}
            scroll
            isOverlay
          />
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    contactPositions: store.contacts.contactsPositions,
    contactStates: store.contacts.contactsStates,
    overlayBusinessContactLoading: store.business.overlayBusinessContactLoading,
    businessContactsLoading: store.business.businessContactsLoading,
    venueContactsLoading: store.venue.venueContactsLoading,
    error: store.business.error
  }
}

export default connect(mapStateToProps)(OverlayContactUpdate)
