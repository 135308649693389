import React, {Component} from 'react'
import { connect } from 'react-redux'

import Container from 'ui/Container'
import PlaylistsCreate from '../../modules/playlists/PlaylistsCreate'

import { createImporterPlaylist } from 'store/actions/playlistAction'
import { toggleOverlay } from 'store/actions/overlayAction'

const classname = 'overlayPlaylistImporter'

class OverlayPlaylistImporterCreatePlaylist extends Component{
  componentDidUpdate(prevProps) {
    const {
      dispatch,
      playlistCreatedVenuesAdded
    } = this.props

    if ((prevProps.playlistCreatedVenuesAdded !== playlistCreatedVenuesAdded) && playlistCreatedVenuesAdded) {
      dispatch(toggleOverlay(false, [], 'playlistImporterCreatePlaylist'))
    }
  }

  createImporterPlaylist(formData) {
    const {
      data,
      dispatch
    } = this.props

    const importerPlaylistData ={
      ...formData,
      sp_playlist_id: data.sp_playlist_id,
      tracks: data.tracks
    }

    dispatch(createImporterPlaylist(importerPlaylistData))
  }

  render(){
    const {
      data,
      dispatch
    } = this.props

    return (
      <Container classname={classname} maxWidth height="100%" column>
        <PlaylistsCreate
          customClassname='createImporterPlaylist'
          history={data.history}
          tracks={data.tracks}
          closeOverlay={()=>dispatch(toggleOverlay(false, [], 'playlistImporterCreatePlaylist'))}
          customCreatePlaylistAction={(formData)=>this.createImporterPlaylist(formData)}
        />
      </Container>
    )
  }
}
function mapStateToProps(store){
  return {
    importerFinalTracklist: store.playlists.importerFinalTracklist,
    playlistTypes:store.playlist.playlistTypes,
    playlistCreatedVenuesAdded: store.playlist.playlistCreatedVenuesAdded
  }
}
export default connect(mapStateToProps)(OverlayPlaylistImporterCreatePlaylist)
