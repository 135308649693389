import React from 'react'

import Filter from 'ui/Filter'
import Icon from 'ui/Icon'
import Badge from 'ui/Badge'

const PlaylistTracksSplitTableAction = (props) => {
  const {
    classname,
    action,
    placeholder,
    removeTracks,
    addToQueue,
    changeInfo,
    active,
    count,
    countSplit,
    numSelected
  } = props
  const selectedIndicator = numSelected > 0 ? <div className="selected-indicator">{numSelected} selected</div> : null
  return (
    <div className={`table-actions table-actions-${classname}`}>
      <div className="table-actions-left">
        <Filter placeholder={placeholder} action={(e)=>action(e)}/>
      </div>
      <div className={`table-actions-right ${active ? 'active' : ''}`}>
        {selectedIndicator}
        <Icon name="ios-create" action={()=>changeInfo()}/>
        <Icon name="ios-list-box" action={()=>addToQueue()}/>
        <Icon name="ios-arrow-dropright-circle" action={()=>removeTracks()}/>
      </div>
      <div className={`metadata`}>
          {countSplit ? <Badge data={`Total ${countSplit}`}/> : ''}
          {count ? <Badge data={`Playlist total ${count}`}/> : ''}
        </div>
    </div>
  )
}

export default PlaylistTracksSplitTableAction
