import React from 'react'

import FormLabel from 'ui/FormLabel'
import Radio from 'ui/Radio'

const FormRadio = (props) => {
  const {
    change,
    name,
    label,
    classname,
    options,
    vertical
  } = props

  const wrapperClasses = `form-input-wrapper input-wrapper-${classname}`
  const labelClasses = `input-label input-label-${classname}`
  const inputClasses = `input checkbox input-${classname}`

  if (options.length === 0) {
    return false
  }

  return (
    <div className={wrapperClasses}>
      <FormLabel label={label} className={labelClasses}/>
      <Radio
        action={(e)=>change(e)}
        className={inputClasses}
        name={name}
        options={options}
        vertical={vertical}
      />
    </div>
  )
}

export default FormRadio
