import React, {Component} from 'react'
import { connect } from 'react-redux'

import { 
  updateTracks
} from 'store/actions/libraryAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Form from 'ui/Form'

const classname = 'overlayMultiEditTracks'

class OverlayMultiEditTracks extends Component{

  constructor(props){
    super(props)
    this.state = {
      message:null,
      track:{
        title:null,
        artist:null,
        album:null,
        total_length:null,
        year:null,
        genres:[]
      }
    }
  }

  onSubmit(form){
    const {
      data
    } = this.props
    this.props.dispatch(updateTracks(data,form))
  }

  render(){
    const {
      loading,
      allGenres
    } = this.props

    const {
      track
    } = this.state

    return (
      <Container classname="overlay-multi-edit-tracks" height="100%" maxWidth column>
        {
          loading ? 
          <Loader/> : 
          <Form
            height="100%"
            classname={classname}
            submit={(e)=>this.onSubmit(e)}
            messages={this.state.message}
            data={track}
            allGenres={allGenres}
            scroll
            isOverlay
          />
        }
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    allGenres:store.library.genres,
    loading:store.library.trackLoading
  }
}

export default connect(mapStateToProps)(OverlayMultiEditTracks)