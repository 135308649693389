import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  addContactToVenue,
  clearOverlayContacts
} from 'store/actions/venueAction'

import {
  searchContacts
} from 'store/actions/contactsAction'

import { getRowLimit } from 'helpers/TableHelper'

import OverlayContactsAddToVenuesTableRowActions from './contacts/OverlayContactsAddToVenuesTableRowActions'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import TableWrapper from 'ui/TableWrapper'
import Typeahead from 'ui/Typeahead'
import Confirm from 'ui/Confirm'

const classname = 'overlayContactsAddToVenue'

class OverlayContactsAddToVenue extends Component{

  constructor(props){
    super(props)

    this.state = {
      searchTerm:null
    }
  }

  componentDidMount(){
    const {
      dispatch
    } = this.props

    dispatch(clearOverlayContacts())
  }

  getContacts(limit,offset){
    const {
      data,
      dispatch
    } = this.props

    const {
      searchTerm
    } = this.state

    dispatch(
      searchContacts(
        limit,
        offset,
        searchTerm,
        data.playlist_id
      )
    )
  }

  searchContacts(term){
    const {
      data,
      dispatch
    } = this.props

    this.setState({
      searchTerm:term
    },()=>{
      const {
        searchTerm
      } = this.state

      dispatch(clearOverlayContacts())

      dispatch(
        searchContacts(
          getRowLimit(classname),
          0,
          searchTerm,
          data.venue_id,
          true
        )
      )
    })
  }

  clearTypeahead(){
    this.props.dispatch(clearOverlayContacts())
  }

  addContactToVenue(contact){
    const {
      data,
      dispatch
    } = this.props

    dispatch(addContactToVenue(data.venueID, contact.id))
  }

  render(){
    const {
      addContactToVenueLoading,
      addContactToVenueOverlayLoading,
      count,
      overlayContacts,
      data
    } = this.props

    return (
      <Container height="100%" classname={classname} column>
        <Typeahead
          typeaheadSearch={(value)=>this.searchContacts(value)}
          clear={()=>this.clearTypeahead()}
          data={overlayContacts}
          classname={classname}
          hideDropdown
          disableEnterToSearch
        />
        <TableWrapper
          controls
          classname={classname}
          data={overlayContacts}
          count={count}
          getData={()=>{return null}}
          loading={addContactToVenueOverlayLoading}
          rowActions={
            <OverlayContactsAddToVenuesTableRowActions
              addContactToVenue={(contact)=>this.addContactToVenue(contact)}
              addContactToVenueLoading={addContactToVenueLoading}
              classname={classname}
              venueID={data.venueID}
            />
          }
        />
        {addContactToVenueOverlayLoading && (
          <div className='overlay-playlist-add-to-venues__updating'>
            <Loader />
            Updating
          </div>
        )}
        <Confirm />
      </Container>
    )
  }
}
function mapStateToProps(store){
  return {
    addContactToVenueLoading: store.venue.addContactToVenueLoading,
    addContactToVenueOverlayLoading: store.venue.addContactToVenueOverlayLoading,
    overlayContacts: store.venue.overlayContacts,
    error: store.venue.error
  }
}
export default connect(mapStateToProps)(OverlayContactsAddToVenue)
