import React from 'react'

import FormLabel from 'ui/FormLabel'

export const FormSelect = (props) => {
  const {
    classname,
    name,
    options,
    action,
    selected,
    label,
    required,
    hasError,
    errorMessage,
    hasServerError,
    serverError,
    disabled
  } = props

  return (
    <div className={`form-select-wrapper`}>
      <FormLabel label={label} required={required}/>
      <select
        name={name}
        className={`form-select select-${classname}`}
        onChange={(e)=>action(e)}
        value={selected}
      >
        {options.map((option, index) => {
          return (
            <option
              key={index}
              value={option.value}
            >
              {option.display}
            </option>
          )
        })}
      </select>
      {hasServerError && !disabled && (
        <p className='form-select__error-message'>{serverError.message}</p>
      )}
      {hasError && errorMessage && !disabled && (
        <p className='form-select__error-message'>{errorMessage}</p>
      )}
    </div>
  )
}

export default FormSelect
