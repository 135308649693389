import React, { Component } from 'react'

import Icon from 'ui/Icon'

class FitnessTimelineEdit extends Component {
  constructor(props) {
    super(props)

    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  handleClickOutside(e) {
    const {
      hidePlaylist,
      updating,
      wrapperRef
    } = this.props

    // close the edit field if the user clicks outside of it,
    // unless the timeline is updating
    if (wrapperRef && !wrapperRef.contains(e.target) && !updating) {
      hidePlaylist()
    }
  }

  render() {
    const {
      editDuration,
      playlistInfo,
      removePlaylist,
      unitWidth
    } = this.props

    return (
      <div
        className='fitness-timeline-edit'
      >
        <div className='fitness-timeline-edit__controls'>
          <Icon
            name='trash'
            action={removePlaylist}
          />
        </div>
        <p className='fitness-timeline-edit__title'>{playlistInfo.name}</p>
        <p className='fitness-timeline-edit__duration'>
          <Icon
            name="remove-circle"
            action={() => {editDuration(playlistInfo.id, false)}}
          />
          {playlistInfo.width/unitWidth} mins
          <Icon
            name="add-circle"
            action={() => {editDuration(playlistInfo.id, true)}}
          />
        </p>
      </div>
    );
  }
}

export default FitnessTimelineEdit;
