import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  getFitnessClasses,
  clearFitnessClasses,
  createFitnessClass,
  deleteFitnessClass,
  searchFitnessClassesTypeahead,
  clearFitnessClassesTypeahead
} from 'store/actions/fitnessAction'

import { toggleOverlay } from 'store/actions/overlayAction'

import Tabs from 'ui/Tabs'
import Container from 'ui/Container'
import TableWrapper from 'ui/TableWrapper'
import TableTypeahead from 'ui/TableTypeahead'
import Form from 'ui/Form'
import Loader from 'ui/Loader'
import FitnessTableActions from './fitness/FitnessTableActions'

import queryString from 'qs'

const classname = 'fitnessClasses'

class FitnessClassesContainer extends Component {

  constructor(props){
    super(props)
    this.state = {
      tab:'classes'
    }
  }

  componentDidMount(){
    const {
      dispatch
    } = this.props

    dispatch(getFitnessClasses())

    this.getTabQuery()
  }

  componentDidUpdate(prevProps){
    const {
      fitnessClass,
      history
    } = this.props

    if(fitnessClass.id !== prevProps.fitnessClass.id){
      history.push(`/class/${fitnessClass.id}`)
    }

    //  handle forward and back browser button usage
    if ((this.props.location !== prevProps.location) && this.props.history.action === 'POP') {
      this.getTabQuery()
    }
  }

  getTabQuery(){
    const query = queryString.parse(this.props.location.search.slice(1))

    if(typeof query.tab !== 'undefined' && query.tab !== this.state.tab){
      this.setState({tab: query.tab})
    } else if (typeof query.tab === 'undefined') {
      // defines the default tab to loaded when no tab query is passed in the URL
      this.setState({tab: 'classes'})
    }
  }

  selectTab(tab){
    this.setState({tab:tab},()=>{
      this.props.history.push({
        search: `?tab=${tab}`
      })
    })
  }

  clearFitnessClasses(){
    this.props.dispatch(clearFitnessClasses())
  }

  moreActions(data){
    this.props.dispatch(toggleOverlay(true,data,'venues'))
  }

  createFitnessClass(form){
    this.props.dispatch(createFitnessClass(form))
  }

  deleteFitnessClass(id){
    const confirm = window.confirm('Are you sure you want to delete this class?')

    if(confirm){
      this.props.dispatch(deleteFitnessClass(id))
    }
  }

  typeaheadSearch(term){
    this.props.dispatch(searchFitnessClassesTypeahead(term))
  }

  clearFitnessClassesTypeahead(){
    this.props.dispatch(clearFitnessClassesTypeahead())
  }

  getComponent(){
    const {
      count,
      fitnessClasses,
      loading,
      removingClassIDs,
      typeahead
    } = this.props

    switch(this.state.tab){
      case 'classes':
        return (
          <TableWrapper
            classname={classname}
            data={fitnessClasses}
            clearData={()=>this.clearFitnessClasses()}
            count={count}
            loading={loading}
            rowActions={<FitnessTableActions
              more={(data)=>this.moreActions(data)}
              classname={classname}
              deleteFitnessClass={(id)=>this.deleteFitnessClass(id)}
              removingClassIDs={removingClassIDs}
            />}
            tableAction={<TableTypeahead
              classname={classname}
              action={(value)=>this.searchVenues(value)}
              clear={()=>this.clearFitnessClassesTypeahead()}
              data={typeahead}
              typeaheadSearch={(term)=>this.typeaheadSearch(term)}
              disableEnterToSearch
            />}
          />
        )
      case 'create':
        return (
          <Form
            classname={classname}
            submit={(e)=>this.createFitnessClass(e)}
            messages={this.state.message}
          />
        )
      default: // no default case
    }
  }

  render(){
    const {
      loading
    } = this.props

    return (
      <Container classname={`${classname}-wrapper container-tabview`} column>
        <Container classname="tabs">
          <Tabs
            match={this.props.match}
            classname={classname}
            select={(tab)=>this.selectTab(tab)}
            active={this.state.tab}
          />
        </Container>
        <Container classname={classname} column height='100%'>
          {loading ? (
            <Loader />
          ) : (
            this.getComponent()
          )}
        </Container>
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    count:store.fitness.count,
    fitnessClasses:store.fitness.data,
    fitnessClass:store.fitness.class,
    loading:store.fitness.loading,
    removingClassIDs:store.fitness.removingClassIDs,
    typeahead:store.fitness.typeahead
  }
}

export default withRouter(connect(mapStateToProps)(FitnessClassesContainer))
