import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  searchVenuesTypeahead,
  clearTypeahead
} from 'store/actions/venueAction'

import {
  addFitnessClassToVenue
} from 'store/actions/fitnessAction'

import OverlayFitnessVenuesTableRowActions from './fitness-venues/OverlayFitnessVenuesTableRowActions.js'
import OverlayFitnessVenuesTableRow from './fitness-venues/OverlayFitnessVenuesTableRow'

import Loader from 'ui/Loader'
import Container from 'ui/Container'
import Typeahead from 'ui/Typeahead'
import TableWrapper from 'ui/TableWrapper'

const classname = 'overlayFitnessVenues'

class OverlayFitnessVenues extends Component {

  constructor(props){
    super(props)
    this.state = {
      searchTerm:null
    }
  }

  componentDidMount(){
    this.props.dispatch(clearTypeahead())
  }

  typeaheadSearch(term){
    this.props.dispatch(searchVenuesTypeahead(term))
  }

  clearTypeahead(){
    this.props.dispatch(clearTypeahead())
  }

  assignClass(venueID){
    const {
      data,
      dispatch
    } = this.props

    dispatch(addFitnessClassToVenue({
      class_id: data.id,
      venue_id: venueID
    }))
  }

  getTable(){
    const {
      assignedVenues,
      count,
      loading,
      updating,
      updatingVenueIDs,
      venues
    } = this.props

    if(loading){
      return <Loader/>
    } else {
      return <TableWrapper
        classname={classname}
        data={venues}
        count={count}
        clearData={()=>this.clearPlaylists()}
        getData={(limit,offset)=>this.getPlaylists(limit,offset)}
        loading={loading}
        rowComp={
          <OverlayFitnessVenuesTableRow/>
        }
        rowActions={
          <OverlayFitnessVenuesTableRowActions
            assignClass={(data)=>this.assignClass(data)}
            classname={classname}
            assignedVenues={assignedVenues}
            loading={updating}
            updatingVenueIDs={updatingVenueIDs}
          />
        }
      />
    }
  }

  render(){
    return (
      <Container classname={`${classname}-wrapper`} height='100%' column maxWidth>
        <div className='playlists-search-typeahead-wrapper'>
          <Typeahead
            typeaheadSearch={(term)=>this.typeaheadSearch(term)}
            clear={()=>this.clearTypeahead()}
            data={this.props.venues}
            classname={classname}
            disableEnterToSearch
            hideDropdown
          />
        </div>
        <Container height='100%' classname={`${classname}`} column>
          <Container height='100%'>
            {this.getTable()}
          </Container>
        </Container>
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    assignedVenues: store.fitness.venues,
    venues:store.venues.typeahead,
    typeahead:store.venues.typeahead,
    count:store.playlists.count,
    loading:store.venues.typeaheadLoading,
    updating: store.fitness.loading,
    updatingVenueIDs: store.fitness.updatingVenueIDs
  }
}

export default withRouter(connect(mapStateToProps)(OverlayFitnessVenues))
