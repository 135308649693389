import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  createVenueDevice,
  getVenueDeviceTypes
} from 'store/actions/venueAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Form from 'ui/Form'

const classname = 'overlayVenueHardwareCreate';

class OverlayVenueHardwareCreate extends Component{
  componentDidMount() {
    this.props.dispatch(getVenueDeviceTypes())
  }

  onSubmit(form){
    const {
      data,
      dispatch
    } = this.props


    dispatch(createVenueDevice(data.venueID, form))
  }

  render(){
    const {
      connectionTypes,
      deviceTypes,
      overlayVenueHardwareLoading,
      warrantyTypes
    } = this.props

    if (overlayVenueHardwareLoading) {
      return <Loader/>
    } else {
      return (
        <Container classname="overlay-venue-saved-calendar-create" maxWidth height="100%" column>
          <Form
            classname={classname}
            submit={(form)=>this.onSubmit(form)}
            deviceTypes={deviceTypes}
            warrantyTypes={warrantyTypes}
            connectionTypes={connectionTypes}
            scroll
            isOverlay
          />
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    connectionTypes: store.venue.connectionTypes,
    deviceTypes: store.venue.deviceTypes,
    warrantyTypes: store.venue.warrantyTypes,
    overlayVenueHardwareLoading: store.venue.overlayVenueHardwareLoading,
    error: store.venue.error
  }
}

export default connect(mapStateToProps)(OverlayVenueHardwareCreate)

