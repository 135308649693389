import React, { Component, Fragment } from 'react'

import moment from 'moment/moment'
import 'react-calendar/dist/Calendar.css'
import Calendar from "react-calendar";
import FormLabel from "./FormLabel";

class FormDate extends Component {
  constructor(props){
    super(props)
    this.state = {
      showCalendar: false,
    }

    this.setRef = this.setRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  setRef(node) {
    this.ref = node
  }

  handleClickOutside(e) {
    if (this.ref && !this.ref.contains(e.target)) {
      this.setState({
        showCalendar: false
      })
    }
  }

  toggleCalendar() {
    const {
      showCalendar
    } = this.state

    if (showCalendar) {
      document.getElementsByClassName('form')[0].removeEventListener('click', this.handleClickOutside)
    } else {
      document.getElementsByClassName('form')[0].addEventListener('click', this.handleClickOutside)
    }

    this.setState({
      showCalendar: !showCalendar
    })
  }

  render() {
    const {
      classname,
      date,
      label,
      name,
      required,
      setDate,
      hasError,
      errorMessage,
      hasServerError,
      serverError,
      disabled
    } = this.props

    const {
      showCalendar
    } = this.state

    const inputClasses = `input input-${classname} ${(hasError || hasServerError) && !disabled ? 'has-error' : ''}`
    const wrapperClasses = `form-input-wrapper input-wrapper-${classname}`
    const labelClasses = `input-label input-label-${classname}`

    return (
      <div
        className={wrapperClasses}
        ref={this.setRef}
      >
        <FormLabel
          label={label}
          className={labelClasses}
          required={required}
        />
        <input
          name={name}
          type='text'
          className={inputClasses}
          onClick={()=>this.toggleCalendar()}
          value={date ? moment(date).format('DD/MM/YY') : date}
          readOnly
        />
        {hasServerError && !disabled && (
          <p className='form-input__error-message'>{serverError.message}</p>
        )}
        {hasError && errorMessage && !disabled && (
          <p className='form-input__error-message'>{errorMessage}</p>
        )}
        {showCalendar && (
          <Calendar
            value={date ? moment(date).toDate() : moment().toDate()}
            onChange={(date) => {
              this.setState({
                showCalendar: false
              })
              setDate(moment(date).format('YYYY-MM-DD'))
            }}
          />
        )}
      </div>
    )
  }
}

export default FormDate
