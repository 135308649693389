import React from 'react'

export const Select = React.forwardRef((props, ref) => {
  const {
    classname,
    options,
    action,
    selected,
    data,
    defaultOption
  } = props

  return (
    <select
      className={`select select-${classname}`}
      onChange={(e)=>action({event:e,data:data})}
      value={selected}
      defaultValue={defaultOption && 'DEFAULT'}
      ref={ref}
    >
        {defaultOption && <option disabled value="DEFAULT">{defaultOption}</option>}
        {options.map((option, index) => <option key={index} value={option.value}>{option.display}</option>)}
    </select>
  )
})

export default Select
