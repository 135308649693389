import React, { Component } from 'react'

import Icon from 'ui/Icon'
import Container from 'ui/Container'
import Loader from 'ui/Loader'

export default class VenuePlaylists extends Component{

  selectPtp(name,tracks){
    let data = {
      name:name,
      tracks:tracks
    }
    this.props.selectPtp(data)
  }

  getPlaylists(){
    if(this.props.playlists && this.props.playlists.length > 0){
      return this.props.playlists.map(
        (playlist,index) => {
          return (
            <div className="list-item playlist" key={index}>
              <Icon name="play" action={()=>this.selectPtp(playlist.name,playlist.tracks)}/>
              <div className="list-item-row">
                <div className="title">{playlist.name}</div>
                <div className="tracks">{playlist.tracks.length} tracks</div>
              </div>
            </div>
          )
        }
      )
    }
  }
  getLibrary(){
    if(this.props.playlists && this.props.playlists.length > 0){
      return (
        <div className="list-item playlist">
          <Icon name="play" action={()=>this.selectPtp('New tracks',this.props.vptp.new)}/>
          <div className="list-item-row">
            <div className="title">New tracks from your library</div>
            <div className="tracks">{this.props.vptp.new.length} tracks</div>
          </div>
        </div>
      )
    }
  }
  getVptp(){
    if(this.props.vptp && this.props.vptp.genres.keys.length > 0){
      return this.props.vptp.genres.keys.map(
        (key,index) => {
          return (
            <div className="list-item playlist" key={index}>
              <Icon name="play" action={()=>this.selectPtp(key,this.props.vptp.genres[key])}/>
              <div className="list-item-row">
                <div className="title">{key}</div>
                <div className="tracks">{this.props.vptp.genres[key].length} tracks</div>
              </div>
            </div>
          )
        }
      )
    }
  }


  render(){
    if(this.props.hasPlayer && this.props.swapping){
      return <Loader/>
    } else {
      return (
        <Container classname="venue-controls-playlists" column maxHeight>
          <h2>Playlists</h2>
          {this.getPlaylists()}
          <h2>From your library</h2>
          {this.getLibrary()}
          {this.getVptp()}
        </Container>
      )
    }
  }
}
