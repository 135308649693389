import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Prompt } from 'react-router'

import * as jsmediatags from 'jsmediatags'
import S3 from 'react-aws-s3'
import { v4 as uuidv4 } from 'uuid'

import { secondsToHoursMinsSecs } from 'helpers/TimeHelper'

import {
  addTrack,
  filesUploading,
  fileUploadComplete,
  trackUploadReset
} from 'store/actions/uploadAction'

import {
  addToFinalTracklist,
  getImporterMatches
} from 'store/actions/playlistAction'

import { toggleOverlay } from 'store/actions/overlayAction'
import { toggleConfirm } from 'store/actions/confirmAction'

import UploadTableRowActions from './upload/UploadTableRowActions'

import Button from 'ui/Button'
import Container from 'ui/Container'
import Icon from 'ui/Icon'
import Loader from 'ui/Loader'
import TableWrapper from 'ui/TableWrapper'
import Confirm from 'ui/Confirm'

const classname = 'upload'

window.Buffer = window.Buffer || require("buffer").Buffer

class UploadContainer extends Component {

  constructor(props){
    super(props)
    this.state = {
      files: [],
      loadingFiles: false,
      processingTrackLengthBPM: false,
      tracks: [],
      processingFails: [],
      uploadFails: []
    }

    // test bucket url is mp3uploadtest.openearmusic.com
    // change bucketName to 'mp3uploadtest.openearmusic.com'
    // and s3Url to 'https://s3-eu-west-1.amazonaws.com/mp3uploadtest.openearmusic.com'
    this.config = {
      bucketName: 'oe-media-raw.openearmusic.com',
      region: 'eu-west-1',
      accessKeyId: 'AKIARLNPHEHH24JQCE4O',
      secretAccessKey: '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',
      s3Url: 'https://s3-eu-west-1.amazonaws.com/oe-media-raw.openearmusic.com'
    }
    this.configImg = {
      bucketName: 'artwork.openearmusic.com',
      region: 'eu-west-1',
      accessKeyId: 'AKIARLNPHEHH24JQCE4O',
      secretAccessKey: '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',
      s3Url: 'https://s3-eu-west-1.amazonaws.com/artwork.openearmusic.com'
    }

    // used to prevent memory leaks on async functions on component unmount
    // need to find a better way of handling this
    this._isMounted = false

    this.exitConfirmationMessage = 'Upload in process: are you sure you want to leave?'
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleLeavePage)
    this._isMounted = false
  }

  componentDidUpdate(prevProps, prevState){
    const {
      dispatch,
      importerMatches,
      uploadComplete
    } = this.props

    const {
      tracks
    } = this.state

    // add exact matches to the final tracklist
    if (importerMatches && (importerMatches !== prevProps.importerMatches)) {
      const newMatch = importerMatches
        .filter(x => !prevProps.importerMatches.includes(x))
        .concat(prevProps.importerMatches.filter(x => !importerMatches.includes(x)))

      if (newMatch[0] && newMatch[0].tracks && newMatch[0].tracks.length === 1 && newMatch[0].match_type === 'exact') {
        dispatch(addToFinalTracklist(newMatch[0].tracks[0]))
      }
    }

    // add and remove the event listener to catch page reloads,
    // only if the user has started the process of uploading tracks,
    if (prevState.tracks !== tracks) {
      if (prevState.tracks.length === 0 && tracks.length > 0) {
        window.addEventListener('beforeunload', this.handleLeavePage)
      } else if (prevState.tracks.length > 0 && tracks.length === 0) {
        window.removeEventListener('beforeunload', this.handleLeavePage)
      }
    }

    // remove the event listener to catch page reloads, if the upload has been completed
    if ((prevProps.uploadComplete !== uploadComplete) && uploadComplete) {
      window.removeEventListener('beforeunload', this.handleLeavePage)
    }
  }

  handleLeavePage(e) {
    const confirmationMessage = this.exitConfirmationMessage
    e.preventDefault()
    return e.returnValue = confirmationMessage
  }

  getImageFormat(format){
    switch(format){
      case 'JPG':
        return 'image/jpeg'
    }
    return format
  }

  getAlbumArtworkBlob(image){
    return new Blob([new Uint8Array(image.data)], { type: this.getImageFormat(image.format) })
    //return URL.createObjectURL(blob)
  }

  handleFileSelect(event) {
    const files = event.currentTarget.files

    this.setState({
      tracks: [],
      processingFails: [],
      uploadFails: []
    }, () => {
      const {
        tracks
      } = this.state

      const tracksArray = [...tracks]

      this.uploadReset()

      if (!files || !files[0]) {
        this.uploadInput.value = ''
        return false
      }

      if (files.length > 40) {
        alert("Please select a maximum of 40 tracks")
        this.uploadInput.value = ''
        return false
      }

      this.setState({
        files: Array.from(Object.values(files))
      }, async() => {
        for(const file of this.state.files) {
          // catch errors within media tag package
          try {
            let uploadedTrack = await this.readMetadataAsync(file)
            const tags = uploadedTrack.tags
            //get artwork
            var [artworkBlob, artworkURL] = [null,null]
            if(tags.picture && tags.picture.data){
              artworkBlob = this.getAlbumArtworkBlob(tags.picture)
              artworkURL = URL.createObjectURL(artworkBlob)
            }
            
            tracksArray.push({
              album: tags.album || '',
              artist: tags.artist || 'MISSING ARTIST INFO!',
              bpm: <Loader />,
              fileName: file.name,
              length: <Loader />,
              title: tags.title || `MISSING TITLE! -- ${file.name}`,
              year: tags.year,
              artworkURL: artworkURL,
              artworkBlob:artworkBlob
            })
          } catch (e) {
            console.log(`Could not read ${file.name}`)
            tracksArray.push({
              album: '',
              artist: 'MISSING ARTIST INFO!',
              bpm: <Loader />,
              fileName: file.name,
              length: <Loader />,
              title: `MISSING TITLE! -- ${file.name}`,
              year: '',
              artworkURL: artworkURL,
              artworkBlob:artworkBlob
            })
          }

          this._isMounted && this.setState({
            tracks: tracksArray,
            loadingFiles: tracksArray.length !== files.length
          }, () => {
            const {
              tracks,
              files
            } = this.state

            const promiseArray = []
            if (tracks.length === files.length) {
              // sort/group the tracks by album
              const  sortedTracksArray = [...this.state.tracks]
              sortedTracksArray.sort((a, b) => {
                if (a.album > b.album) {
                  return 1
                }
                if (a.album < b.album) {
                  return -1
                }
                return 0
              })

              this.setState({
                processingTrackLengthBPM: true,
                tracks: sortedTracksArray
              }, async () => {
                for(const track of this.state.tracks) {
                  const file = files.find(file => file.name === track.fileName)
                  promiseArray.push(await this.getTrackLengthAndBPM(file))
                }

                Promise.all(promiseArray).then(() => {
                  this._isMounted && this.setState({
                    loadingFiles: false,
                    processingTrackLengthBPM: false
                  }, () => {
                    if (this.state.processingFails.length > 0) {
                      const {
                        dispatch,
                      } = this.props

                      const confirmData = {
                        action: () => {
                          this.startNewUpload()
                        },
                        question: `Errors processing track length and BPM. Reset form and try again?`
                      }

                      dispatch(toggleConfirm(true, confirmData))
                    }
                  })
                })
              })
            }
          })
        }
      })
    })
  }

  async readMetadataAsync (file) {
    return new Promise((resolve, reject) => {
      new jsmediatags
        .Reader(file)
        .setTagsToRead(['album', 'artist', 'title', 'year','picture'])
        .read({
        onSuccess: resolve,
        onError: reject
      })
    })
  }

  async getTrackLengthAndBPM(file) {
    const blob = new Blob([(file)], {type: 'audio/mpeg'})

    const arrayBuffer = await blob.arrayBuffer()

    const OfflineContext = window.OfflineAudioContext || window.webkitOfflineAudioContext
    const offlineContext = new OfflineContext(1, 2, 44100)

    await offlineContext.decodeAudioData(arrayBuffer).then((buffer) => {
      const trackLength = secondsToHoursMinsSecs(buffer.duration)
      const source = offlineContext.createBufferSource()
      source.buffer = buffer
      // create filter
      const filter = offlineContext.createBiquadFilter()
      filter.type = 'lowpass'
      // pipe the song into the filter, and the filter into the offline context
      source.connect(filter);
      filter.connect(offlineContext.destination);
      // schedule the song to start playing at time:0
      source.start(0)
      let peaks,
        initialThresold = 0.9,
        thresold = initialThresold,
        minThresold = 0.3,
        minPeaks = 30

      do {
        peaks = this.getPeaksAtThreshold(buffer.getChannelData(0), thresold);
        thresold -= 0.05;
      } while (
        peaks.length < minPeaks && thresold >= minThresold
      )

      const intervals = this.countIntervalsBetweenNearbyPeaks(peaks)
      const groups = this.groupNeighborsByTempo(intervals, buffer.sampleRate)
      const top = groups.sort((intA, intB) => {
        return intB.count - intA.count
      }).splice(0,5)

      this._isMounted && this.setState(prevState => ({
        tracks: prevState.tracks.map(
          track => track.fileName === file.name ? {
            ...track,
            bpm: (top[0] && top[0].tempo) || 0,
            length: trackLength
          } : track
        )
      }))

      //clear the buffer to prevent memory leaks
      source.buffer = null
    }, (error) => {
      // if decodeAudioData fails, log the error, set the bpm to 0 and the track length to 00:00:00
      console.log('error', error)
      this.addToProcessingFails(file.name)
      this._isMounted && this.setState(prevState => ({
        tracks: prevState.tracks.map(
          track => track.fileName === file.name ? {
            ...track,
            bpm: 0,
            length: '00:00:00'
          } : track
        )
      }))
    })
  }

  getPeaksAtThreshold(data, threshold) {
    const peaksArray = []
    const length = data.length
    for(let i = 0; i < length;) {
      if (data[i] > threshold) {
        peaksArray.push(i)
        // skip forward ~ 1/4s to get past this peak.
        i += 10000;
      }
      i++;
    }
    return peaksArray
  }

  countIntervalsBetweenNearbyPeaks(peaks) {
    const intervalCounts = [];
    peaks.forEach((peak, index) => {
      for(let i = 0; i < 10; i++) {
        const interval = peaks[index + i] - peak;
        const foundInterval = intervalCounts.some((intervalCount) => {
          if (intervalCount.interval === interval) {
            return intervalCount.count++
          }
          return false
        })
        if (!foundInterval) {
          intervalCounts.push({
            interval: interval,
            count: 1
          })
        }
      }
    })
    return intervalCounts
  }

  groupNeighborsByTempo(intervalCounts, sampleRate) {
    const tempoCounts = []
    intervalCounts.forEach((intervalCount, i) => {
      if (intervalCount.interval !== 0) {
        // convert an interval to tempo
        let theoreticalTempo = 60 / (intervalCount.interval / sampleRate);

        // adjust the tempo to fit within the 90-180 BPM range
        while (theoreticalTempo < 90) theoreticalTempo *= 2;
        while (theoreticalTempo > 180) theoreticalTempo /= 2;

        theoreticalTempo = Math.round(theoreticalTempo);
        const foundTempo = tempoCounts.some((tempoCount) => {
          if (tempoCount.tempo === theoreticalTempo) {
            return tempoCount.count += intervalCount.count;
          }
          return false
        })
        if (!foundTempo) {
          tempoCounts.push({
            tempo: theoreticalTempo,
            count: intervalCount.count
          })
        }
      }
    })
    return tempoCounts
  }

  addToProcessingFails(file) {
    const {
      processingFails
    } = this.state

    const processingFailureArray = [...processingFails]

    processingFailureArray.push(file)

    this.setState({
      processingFails: processingFailureArray
    })
  }

  async scanForDuplicates(tracks) {
    const {
      dispatch
    } = this.props

    for(const track of tracks) {
      const data = {
        artist: track.artist,
        title: track.title,
        spotifyID: track.fileName,
        fileName: track.fileName
      }

      await dispatch(getImporterMatches(data))
    }
  }

  getUploadTracks() {
    const {
      importerMatchPerformed,
      importerMatches
    } = this.props

    const {
      tracks
    } = this.state

    return tracks.map((track) => {
      const trackFound = importerMatches.filter(match=>match.spotifyID === track.fileName)

      return {
        ...track,
        importerMatchPerformed: importerMatchPerformed,
        tracks: trackFound[0] ? trackFound[0].tracks : []
      }
    })
  }

  toggleUploadTracksOverlay(data) {
    const {
      dispatch
    } = this.props

    dispatch(toggleOverlay(true, data, 'uploadTracks'))
  }

  removeTrack(track) {
    const {
      files,
      tracks
    } = this.state

    let tracksArray = [...tracks]
    let filesArray = [...files]

    tracksArray = tracksArray.filter(arrayTrack => arrayTrack.fileName !== track)
    filesArray = filesArray.filter((arrayFile, index) => arrayFile.name !== track)

    this.setState({
      files: filesArray,
      tracks: tracksArray
    })
  }

  async uploadTracks() {
    const {
      dispatch
    } = this.props

    const {
      tracks
    } = this.state

    const promiseArray = []

    dispatch(filesUploading(true))

    for(const track of tracks) {
      promiseArray.push(await this.uploadFileToS3(track))
    }

    Promise.all(promiseArray).then(() => {
      dispatch(filesUploading(false))
      dispatch(fileUploadComplete(true))
    })
  }

  async uploadFileToS3(track) {
    const {
      dispatch
    } = this.props

    const {
      files
    } = this.state

    const ReactS3Client = new S3(this.config)
    const file = files.find(file => file.name === track.fileName)
    const blob = new Blob([file], {type: "audio/mp3"})
    const uuid = uuidv4()
    const trackData = {
      ...track,
      filename: uuid,
      original: file.name,
      size: file.size,
      total_length: track.length
    }
    //upload artwork to S3
    if(track.artworkBlob){

      const ReactS3ClientImg = new S3(this.configImg)
      await ReactS3ClientImg
        .uploadFile(track.artworkBlob, uuid)
        .catch((err) => {
          // upload failed, so add the track to the failure array
          console.log('ReactS3ClientImg upload error', err)
          this.addToUploadFails(track)
        })
    }
    // upload file to S3
    await ReactS3Client
      .uploadFile(blob, uuid)
      .then(async () => {
        // save track/file data to db
        await dispatch(addTrack(trackData))
      })
      .catch((err) => {
        // upload failed, so add the track to the failure array
        console.log('ReactS3Client upload error', err)
        this.addToUploadFails(track)
      })
  }

  addToUploadFails(track) {
    const {
      uploadFails
    } = this.state

    const uploadFailureArray = [...uploadFails]

    uploadFailureArray.push(track)

    this.setState({
      uploadFails: uploadFailureArray
    })
  }

  uploadReset() {
    const {
      dispatch
    } = this.props

    dispatch(trackUploadReset())

    this.setState({
      files: [],
      tracks: []
    })
  }

  startNewUpload() {
    this.uploadReset()
    this.uploadInput.value = ''
  }

  retryUpload() {
    const {
      dispatch
    } = this.props

    const {
      tracks,
      uploadFails
    } = this.state

    const tracksArray = [...tracks]

    // if a track is not in the upoloadFails array, it was successfully uploaded,
    // so we can remove it from the list of tracks that still need to be uploaded
    for (const track of tracksArray) {
      if (!uploadFails.includes(track)) {
        this.removeTrack(track.fileName)
      }
    }

    dispatch(fileUploadComplete(false))
  }

  getProcessingButtonText() {
    const {
      uploadedFiles,
      uploading
    } = this.props

    const {
      tracks
    } = this.state

    if(uploading) {
      return `Uploaded ${uploadedFiles.length}/${tracks.length}`
    } else {
      return `Processing track length and BPM`
    }
  }

  render() {
    const {
      importerMatchesLoaded,
      importerMatchPerformed,
      importerFinalTracklist,
      uploading,
      uploadedFiles,
      uploadedTracks,
      uploadComplete
    } = this.props

    const {
      loadingFiles,
      processingTrackLengthBPM,
      tracks,
      uploadFails
    } = this.state

    return (
      <Container classname={`${classname}-wrapper container-tabview`} height="100%" column>
        <input
          type='file'
          name='file'
          accept='.mp3'
          multiple='multiple'
          onChange={(event)=>this.handleFileSelect(event)}
          className={uploadComplete || uploading ? 'upload-input upload-input--upload-complete': 'upload-input'}
          ref={ref=> this.uploadInput = ref}
        />
        {tracks && (tracks.length > 0) && (
          <Container height="100%" column>
            {loadingFiles ? (
              <Loader />
            ) : (
              <Fragment>
                <div className={uploadComplete || uploading ? 'upload-controls upload-controls--upload-complete': 'upload-controls'}>
                  {importerMatchesLoaded.length < tracks.length && (
                    <Fragment>
                      {importerMatchPerformed && (tracks.length > importerMatchesLoaded.length) ? (
                        <button
                          className='button button__processing'
                        >
                          <Loader />
                          <span>Scanning {importerMatchesLoaded.length}/{tracks.length}</span>
                        </button>
                      ) : (
                        <Button
                          action={()=>this.scanForDuplicates(tracks)}
                          name='Scan for duplicates'
                        />
                      )}
                    </Fragment>
                  )}
                  {processingTrackLengthBPM || uploading ? (
                    <button
                      className='button button__processing'
                    >
                      <Loader />
                      <span>{this.getProcessingButtonText()}</span>
                    </button>
                  ) : (
                    <Button
                      action={()=>this.uploadTracks()}
                      name='Upload tracks'
                    />
                  )}
                </div>
                <TableWrapper
                  classname={classname}
                  data={this.getUploadTracks()}
                  userSelect
                  rowActions={(
                    <UploadTableRowActions
                      classname={classname}
                      toggleUploadTracksOverlay={(tracks)=>this.toggleUploadTracksOverlay(tracks)}
                      importerFinalTracklist={importerFinalTracklist}
                      importerMatchesLoaded={importerMatchesLoaded}
                      uploadTracks={tracks}
                      processingTrackLengthBPM={processingTrackLengthBPM}
                      removeTrack={(track)=>this.removeTrack(track)}
                      uploading={uploading}
                      uploadedFiles={uploadedFiles}
                      uploadedTracks={uploadedTracks}
                    />
                  )}
                />
              </Fragment>
            )}
            <Prompt
              message={this.exitConfirmationMessage}
            />
          </Container>
        )}
        {uploadComplete && (
          <div className='upload__completion-overlay'>
            <div className='upload__completion-message'>
              {uploadFails.length > 0 ? (
                <Fragment>
                  <Icon name='alert' />
                  <p>Upload complete with errors</p>
                  <p>The following files failed to upload, please try again</p>
                  <ul>
                    {uploadFails.map((track, index) => {
                      return (
                        <li key={index}>{track.fileName}</li>
                      )}
                    )}
                  </ul>
                  <Button
                    action={()=>this.retryUpload()}
                    name='Retry?'
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <Icon name='checkmark-circle' />
                  <p>Upload complete</p>
                  <Button
                    action={()=>this.startNewUpload()}
                    name='Start new upload?'
                  />
                </Fragment>
              )}
            </div>
          </div>
        )}
        <Confirm />
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    importerFinalTracklist: store.playlists.importerFinalTracklist,
    importerMatchPerformed: store.playlists.importerMatchPerformed,
    importerMatches: store.playlists.importerMatches,
    importerMatchesLoaded: store.playlists.importerMatchesLoaded,
    uploading: store.upload.uploading,
    uploadedFiles: store.upload.uploadedFiles,
    uploadedTracks: store.upload.uploadedTracks,
    uploadComplete: store.upload.uploadComplete
  }
}
export default withRouter(connect(mapStateToProps)(UploadContainer))
