import React, {Component} from 'react'

import { connect } from 'react-redux'

import {
  saveSchedule
} from 'store/actions/venueAction'

import Container from 'ui/Container'
import Button from 'ui/Button'
import Input from 'ui/Input'

class OverlayVenueScheduleSave extends Component{

  constructor(props){
    super(props)
    this.state = {
      value:''
    }
  }

  saveSchedule(){
    this.props.dispatch(
      saveSchedule(
        this.props.data.venueID,
        this.state.value
      )
    )
  }
  
  render(){
    const venue = this.props.data
    return (
      <Container classname="overlay-venues" maxWidth height="100%" column>
        <h2>Save schedule for {venue.details.name}</h2>
        <Input
          change={(e) => this.setState({
            value:e.target.value
          })}
          placeholder={`Enter saved schedule name`}
        />
        <Button 
          action={()=>this.saveSchedule()} 
          name={`Save schedule`}
          disabled={this.state.value.length === 0 || false}/>
      </Container>
    )
  }
}

export default connect()(OverlayVenueScheduleSave)