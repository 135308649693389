import axios from 'axios'

export function addTrack(data){
  return (dispatch) => {
    return dispatch({
      type:'TRACK_UPLOAD',
      payload: axios.post(`/library/track/add`,data)
    })
  }
}

export function filesUploading(file){
  return (dispatch) => {
    return dispatch({
      type:'FILES_UPLOADING',
      payload: file
    })
  }
}

export function fileUploadComplete(isComplete){
  return (dispatch) => {
    return dispatch({
      type:'FILE_UPLOAD_FULFILLED',
      payload: isComplete
    })
  }
}

export function trackUploadReset(){
  return (dispatch) => {
    return dispatch({
      type:'TRACK_UPLOAD_RESET'
    })
  }
}
