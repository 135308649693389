import React, { Fragment } from 'react'

import Icon from 'ui/Icon'
import Loader from 'ui/Loader'

const UploadTableRowActions = (props) => {
  const {
    id,
    album,
    artist,
    length,
    title,
    total_length,
    importerFinalTracklist,
    importerMatchPerformed,
    importerMatchesLoaded,
    tracks,
    toggleUploadTracksOverlay,
    uploadTracks,
    removeTrack,
    fileName,
    processingTrackLengthBPM,
    uploading,
    uploadedFiles,
    uploadedTracks
  } = props

  const hasLoaded = importerMatchesLoaded ? importerMatchesLoaded.includes(fileName) : false

  let hasDuplicateTrack = false
  let isUploaded = uploadedFiles.includes(fileName) && uploadedTracks.includes(fileName)

  tracks.map((track) => {
    if (importerFinalTracklist.includes(track)) {
      hasDuplicateTrack = true
    }

    return false
  })

  return (
    <div>
      {isUploaded ? (
        <Icon
          name='checkmark-circle'
          success
        />
      ) : (
        <Fragment>
          {uploading ? (
            <Loader />
          ) : (
            <Fragment>
              {importerMatchPerformed && (
                <Fragment>
                  {!hasLoaded ? (
                    <Loader />
                  ) : (
                    <div
                      onClick={()=>toggleUploadTracksOverlay({id, album, artist, length, total_length, title, tracks, uploadTracks, fileName })}
                      style={{cursor: 'pointer'}}
                    >
                      {hasDuplicateTrack ? (
                        <Icon
                          name='alert'
                        />
                      ) : (
                        <Icon
                          name='help-circle'
                        />
                      )}
                    </div>
                  )}
                </Fragment>
              )}
              {!processingTrackLengthBPM && (
                <div
                  onClick={()=>removeTrack(fileName || title)}
                  style={{cursor: 'pointer'}}
                >
                  <Icon
                    name='trash'
                  />
                </div>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  )
}

export default UploadTableRowActions
