import React from 'react'

import Typeahead from 'ui/Typeahead'

const TableTypeahead = (props) => {
  const {classname, typeaheadSearch, search, typeaheadAction, typeaheadLoading, getData, clear, data, disableEnterToSearch} = props
  return (
    <div className={`table-typeahead table-typeahead-${classname}`}>
      <Typeahead
        getData={(value)=>getData(value)}
        search={(value) => search(value)}
        typeaheadSearch={(value)=>typeaheadSearch(value)}
        typeaheadAction={(data)=>typeaheadAction(data)}
        typeaheadLoading={typeaheadLoading}
        clear={()=>clear()}
        data={data}
        classname={classname}
        disableEnterToSearch={disableEnterToSearch}
      />
    </div>
  )
}

export default TableTypeahead
