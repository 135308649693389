import React from 'react'

import Icon from 'ui/Icon'
import Select from 'ui/Select'
import Tooltip from 'ui/Tooltip'

const PlaylistTracksTableRowActions = (props) => {
  const {
    openPlaylistOverlay,
    editTrack,
    removeTrack,
    addTrackToQueue,
    designation,
    changeDesignation,
    track,
    isSplit
  } = props

  const select = <Select
    options={[
      {value:'A',display:'A'},
      {value:'B',display:'B'},
      {value:'C',display:'C'},
      {value:'D',display:'D'}
    ]}
    data={track}
    selected={designation}
    action={(data)=>changeDesignation(data)}/>
  return (
    <div>
      {designation && !isSplit ? select : null}
      {/*<Icon name="star" action={()=>favouriteTrack(track)}/>*/}
      <Icon name="add-circle" action={()=>openPlaylistOverlay(track)}>
        <Tooltip pos="left" text="Add track to playlist"/>
      </Icon>
      <Icon name="ios-list" action={()=>addTrackToQueue({track:track})} stopPropagation>
        <Tooltip pos="left" text="Add track to queue"/>
      </Icon>
      <Icon name="ios-create" action={()=>editTrack(track)} stopPropagation>
        <Tooltip pos="left" text="Edit track"/>
      </Icon>
      <Icon name="ios-arrow-dropright-circle" action={()=>removeTrack(track)}>
        <Tooltip pos="left" text="Remove track"/>
      </Icon>
      {/*<Icon name="more" action={()=>moreActions(track)}/>*/}
    </div>
  )
}

export default PlaylistTracksTableRowActions
