import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import {
  getBusinessContacts,
  removeContactFromBusiness
} from 'store/actions/businessAction'

import {
  getContactsFields
} from 'store/actions/contactsAction'

import { toggleOverlay } from 'store/actions/overlayAction'
import { toggleConfirm } from 'store/actions/confirmAction'

import Button from 'ui/Button'
import Confirm from 'ui/Confirm'
import Container from 'ui/Container'
import Loader from 'ui/Loader'

import ContactCard from 'components/ContactCard'

const classname = 'businessContacts'

class BusinessContacts extends Component {
  componentDidMount(){
    this.props.dispatch(getBusinessContacts(this.props.id))
    this.props.dispatch(getContactsFields())
  }

  toggleContactCreateOverlay() {
    const {
      dispatch,
      id
    } = this.props

    dispatch(
      toggleOverlay(
        true,
        {
          businessID: id
        },
        'contactCreate'
      )
    )
  }

  toggleContactUpdateOverlay(device) {
    const {
      dispatch,
      id
    } = this.props

    const data = {
      businessID: id,
      ...device
    }

    dispatch(
      toggleOverlay(
        true,
        data,
        'contactUpdate'
      )
    )
  }

  toggleAddContactToBusinessOverlay() {
    const {
      businessContactsLoading,
      dispatch,
      id
    } = this.props

    dispatch(
      toggleOverlay(
        true,
        {
          businessID: id,
          loading: businessContactsLoading
        },
        'contactsAddToBusiness'
      )
    )
  }

  removeContactFromBusiness(contactID) {
    const {
      dispatch,
      id
    } = this.props

    const confirmData = {
      action: () => {
        dispatch(removeContactFromBusiness(id, contactID))
      },
      question: `Are you sure you want to remove this contact?`
    }

    dispatch(toggleConfirm(true, confirmData))
  }

  render(){
    const {
      contacts,
      businessContactsLoading
    } = this.props

    if (businessContactsLoading){
      return <Loader/>
    } else {
      return (
        <Container classname={classname} height="100%" maxWidth scroll>
          <div className='business-contacts__add-new'>
            <Button
              action={()=>{ this.toggleContactCreateOverlay() }}
              name='Add new contact'
            />
            <Button
              action={()=>{ this.toggleAddContactToBusinessOverlay() }}
              name='Add existing contact'
            />
          </div>
          <div className='business-contacts'>
            {contacts.map((contact, index) => {
              return (
                <Fragment
                  key={index}
                >
                  <ContactCard
                    index={index}
                    contact={contact}
                    toggleContactUpdateOverlay={(device)=>this.toggleContactUpdateOverlay(device)}
                    removeContact={(contactID) => {this.removeContactFromBusiness(contactID)}}
                  />
                </Fragment>
              )
            })}
          </div>
          <Confirm />
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    contacts: store.business.contacts,
    businessContactsLoading: store.business.businessContactsLoading,
    error: store.business.error
  }
}

export default connect(mapStateToProps)(BusinessContacts)