import React  from 'react'

import Button from 'ui/Button'
import Icon from 'ui/Icon'

const ContactCard = (props) => {
  const {
    contact,
    toggleContactUpdateOverlay,
    removeContact
  } = props

  return (
    <div
      className={`
        contact-card
        ${contact.statusID === 1 ? 'contact-card--primary' : ''}
        ${contact.statusID === 3 ? 'contact-card--archive' : ''}
      `}
    >
      {contact.contactStatusID === 1 && (
        <Icon name='star' />
      )}
      <div className='contact-card__item'>
        <h4>Name</h4>
        <p>{contact.name}</p>
      </div>
      <div className='contact-card__item'>
        <h4>Status</h4>
        <p>{contact.status}</p>
      </div>
      <div className='contact-card__item'>
        <h4>Job title</h4>
        <p>{contact.jobTitle}</p>
      </div>
      <div className='contact-card__item'>
        <h4>Position</h4>
        <p>{contact.position}</p>
      </div>
      <div className='contact-card__item'>
        <h4>Email</h4>
        <p>{contact.email}</p>
      </div>
      <div className='contact-card__item'>
        <h4>Phone Number</h4>
        <p>{contact.phoneNumber}</p>
      </div>
      <div className='contact-card__item--notes'>
        <h4>Notes</h4>
        <textarea readOnly value={contact.notes || ''} />
      </div>
      <div className='contact-card__item-cta'>
        <Button
          action={()=>{
            toggleContactUpdateOverlay(contact)
          }}
          name='Edit contact'
        />
        <Button
          action={()=>{
            removeContact(contact.id)
          }}
          name='Remove contact'
        />
      </div>
    </div>
  )
}

export default ContactCard