import React, {Fragment} from 'react'

import Icon from 'ui/Icon'
import Loader from 'ui/Loader'

const OverlayPlaylistsLibraryTableRowActions = (props) => {
  const {
    assignClass,
    assignedVenues,
    classname,
    id,
    updatingVenueIDs,
  } = props

  const isClassAssigned = assignedVenues.some(venue => venue.id === id)

  return (
    <div className={`${classname}`}>
      {updatingVenueIDs.includes(id) ? (
        <Loader />
      ) : (
        <Fragment>
          {isClassAssigned ?
              <Icon
                name="checkmark-circle"
                success
              />
              :
              <Icon
                name="add-circle"
                action={()=>assignClass(id)}
              />
          }
        </Fragment>
      )}
    </div>
  )
}

export default OverlayPlaylistsLibraryTableRowActions
