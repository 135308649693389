import axios from 'axios'

export function getDashboard(){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_DASHBOARD_VENUES',
      payload:axios.get('/dashboard/venues')
    }).catch(error => {
      console.log('FETCH_DASHBOARD_VENUES error', error)
    }).then(() => {
      return dispatch({
        type:'FETCH_DASHBOARD_BUSINESSES',
        payload:axios.get('/dashboard/businesses')
      }).catch(error => {
        console.log('FETCH_DASHBOARD_BUSINESSES error', error)
      })
    }).then(() => {
      return dispatch({
        type:'FETCH_DASHBOARD_MUSIC',
        payload:axios.get('/dashboard/music')
      }).catch(error => {
        console.log('FETCH_DASHBOARD_MUSIC error', error)
      })
    }).then(() => {
      return dispatch({
        type:'FETCH_DASHBOARD_CLASSES',
        payload:axios.get('/dashboard/classes')
      }).catch(error => {
        console.log('FETCH_DASHBOARD_CLASSES error', error)
      })
    }).then(() => {
      return dispatch({
        type:'FETCH_DASHBOARD_MUSIC_PLAYLISTS_FOR_APPROVAL',
        payload:axios.get('/dashboard/music/playlists-for-approval')
      }).catch(error => {
        console.log('FETCH_DASHBOARD_MUSIC_PLAYLISTS_FOR_APPROVAL error', error)
      })
    }).then(() => {
      return dispatch({
        type:'FETCH_DASHBOARD_MUSIC_TRACK_GENRES_LAST_MONTH',
        payload:axios.get('/dashboard/music/track-genres-last-month')
      }).catch(error => {
        console.log('FETCH_DASHBOARD_MUSIC_TRACK_GENRES_LAST_MONTH error', error)
      })
    })
  }
}
