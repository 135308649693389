import React, {Component} from 'react'
import { connect } from 'react-redux'

import Button from 'ui/Button'
import Loader from 'ui/Loader'
import Radio from 'ui/Radio'
import FormInput from 'ui/FormInput'

import {
  getPlaylistSettings,
  updatePlaylistSettings,
  getParentPlaylistTypeahead,
  updateParent,
  splitPlaylist,
  mergePlaylist,
  updatePlaylistType,
  updateRotation,
  updateAutoAdd,
  updateAutoDelete,
  getOwnerTypeahead,
  updateOwner
} from 'store/actions/playlistAction'
import {
  toggleConfirm,
  toggleConfirmError
} from 'store/actions/confirmAction'
import {
  canBeChild,
  isSplit,
  isParent,
  isChild
} from 'helpers/PlaylistHelper'
import Icon from "ui/Icon";
import { toggleOverlay } from "store/actions/overlayAction"
import Typeahead from "ui/Typeahead"
import Select from "ui/Select"
import Label from "ui/Label"
import ConfirmError from "ui/ConfirmError"
import Confirm from "ui/Confirm"

class PlaylistSettings extends Component {

  constructor(props){
    super(props)
    this.state = {
      overlayOpen:false,
      editParent:false,
      editOwner:false,
      rotation:{
        title:'Playlist rotation',
        name:'rotation',
        options:[
          {
            label: '\'A\' always in (A,B,C)',
            value: 0
          },
          {
            label: 'Rotate all (A,B,C)',
            value: 1
          },
          {
            label: '\'A\' always in (A,B,C,D)',
            value: 2
          },
          {
            label: 'Rotate all (A,B,C,D)',
            value: 3
          }
        ]
      },
      autoAdd:{
        title:'Auto or manual add',
        name:'autoAdd',
        options:[
          {label:'Auto',value:0},
          {label:'Manual',value:1}
        ]
      },
      autoDelete:{
        title:'Auto or manual delete',
        name:'autoDelete',
        options:[
          {label:'Auto',value:0},
          {label:'Manual',value:1}
        ]
      },
      selected:{
        rotation:null,
        autoAdd:null,
        autoDelete:null
      }
    }
  }

  componentDidMount(){
    this.props.dispatch(getPlaylistSettings(this.props.id))
  }

  componentDidUpdate(prevProps){
    if(prevProps.playlist !== this.props.playlist){
      this.props.dispatch(getPlaylistSettings(this.props.id))
    }
  }

  getSettingsUI(){
    const {
      rotation,
      autoAdd,
      autoDelete
    } = this.state
    const {
      settings,
      details,
      playlist,
      radioLoading
    } = this.props

    //get selected value in options
    const _rotation = {
      ...rotation,
      options:rotation.options.map(option => {
        if(option.value === settings.rotation){
          option.defaultChecked = true
        }
        return option
      })
    }

    const _autoAdd = {
      ...autoAdd,
      options:autoAdd.options.map(option => {
        if(option.value === settings.auto_add){
          option.defaultChecked = true
        }
        return option
      })
    }
    
    const _autoDelete = {
      ...autoDelete,
      options:autoDelete.options.map(option => {
        if(option.value === settings.auto_delete){
          option.defaultChecked = true
        }
        return option
      })
    }

    return (
      <>
        {
          details.isSplit && 
          <Radio
            loading={radioLoading}
            vertical
            title={_rotation.title}
            action={(e)=>this.setRotation(e)}
            name={_rotation.name}
            options={_rotation.options}/>
        }
        {
          settings.auto_add !== null && isChild(playlist.type) ? 
          <Radio
            vertical
            title={_autoAdd.title}
            action={(e)=>this.setAutoAdd(e)}
            name={_autoAdd.name}
            options={_autoAdd.options}/> :
          null
        }
        {
          settings.auto_delete !== null && isChild(playlist.type) ? 
          <Radio
            vertical
            title={_autoDelete.title}
            action={(e)=>this.setAutoDelete(e)}
            name={_autoDelete.name}
            options={_autoDelete.options}/> :
          null
        }
      </>
    )
  }

  setRotation(e){
    const rotation = parseInt(e.target.value)
    this.props.dispatch(updateRotation(this.props.playlist.id,rotation))
  }

  setAutoAdd(e){
    const autoAdd = parseInt(e.target.value)
    this.props.dispatch(updateAutoAdd(this.props.playlist.id,autoAdd))
  }

  setAutoDelete(e){
    const autoDelete = parseInt(e.target.value)
    this.props.dispatch(updateAutoDelete(this.props.playlist.id,autoDelete))
  }

  typeaheadSearchParent(term){
    this.props.dispatch(getParentPlaylistTypeahead(term))
  }

  typeaheadSearchOwner(term){
    this.props.dispatch(getOwnerTypeahead(term))
  }

  typeaheadParent(parent){
    const {
      playlist,
      dispatch
    } = this.props
    const data = {
      parent:parent,
      playlist:playlist
    }

    if(canBeChild(parent.type_id,playlist.type)){
      dispatch(updateParent(data))
    } else {
      const confirmData = {
        action: () => {
          dispatch(updateParent(data))
        },
        question: `
          Parent ${isSplit(parent.type_id) ? 'is' : 'is not'} split, but this playlist ${isSplit(playlist.type) ? 'is' : 'is not'} split.
          Change this playlist type to ${isSplit(playlist.type) ? 'Child' : 'Child Split'} to add that playlist as a parent.
        `
      }
      dispatch(toggleConfirmError(true,confirmData))
    }
    this.setState({
      editParent:false
    })
  }

  typeaheadOwner(owner){
    this.props.dispatch(updateOwner(this.props.playlist.id,owner))
  }

  clearTypeahead(){
    this.setState({
      editParent:false
    })
  }

  clearOwnerTypeahead(){
    this.setState({
      editOwner:false
    })
  }

  parentHasError(){
    const {
      playlist,
      parent
    } = this.props
    if(isChild(playlist.type) && !parent){
      console.log('TRUE')
      return true
    }
    return false
  }

  getParentInput(){

    const {
      editParent
    } = this.state
    const {
      playlist,
      parent
    } = this.props

    if(isParent(playlist.type)){
      return null
    }

    return (
      !editParent ? 
      <FormInput
        hasErrorDisabled={isChild(playlist.type) && parent.length === 0 ? true : false}
        type="text"
        label="Parent playlist"
        value={(parent && parent.name) || ''}
        editAction={<Icon name="create" action={()=>this.setState({
          editParent:true
        })}/>}
        placeholder="Enter parent playlist..."
        disabled/> :
        <div className="parent-typeahead-wrapper">
          <div className="typeahead-label">
            Parent playlist
          </div>
          <Typeahead
            typeaheadSearch={(term)=>this.typeaheadSearchParent(term)}
            typeaheadAction={(data)=>this.typeaheadParent(data)}
            clear={()=>this.clearTypeahead()}
            data={this.props.parentTypeahead}
            classname={'overlaySettingsPlaylist'}
            disableEnterToSearch/>
        </div>        
    )
  }

  updateTypeAndSplit(type){
    const {
      playlist,
      dispatch
    } = this.props
    dispatch(splitPlaylist(playlist.id))
    dispatch(updatePlaylistType(playlist.id,type))
  }

  updateTypeAndMerge(type){
    const {
      playlist,
      dispatch
    } = this.props
    dispatch(mergePlaylist(playlist.id))
    dispatch(updatePlaylistType(playlist.id,type))
  }

  changePlaylistType(data){
    const {
      dispatch,
      playlist
    } = this.props
    const newType = parseInt(data.event.target.value)
    const currIsSplit = isSplit(playlist.type)
    const changeIsSplit = isSplit(newType)

    //if changing from different type eg. normal to split, or vice versa, remove parent
    if(!currIsSplit && changeIsSplit){
      //open confirm box
      const confirmData = {
        action: () => {
          this.updateTypeAndSplit(newType)
        },
        question: `
          This playlist will need to be split. Do you want to split this playlist? Also, if this playlist has a parent, the parent playlist will be removed.
        `
      }
      dispatch(toggleConfirm(true,confirmData))
      return
    }
    if(currIsSplit && !changeIsSplit){
      const confirmData = {
        action: () => {
          this.updateTypeAndMerge(newType)
        },
        question: `
          This playlist will need to be merged. Do you want to merge this playlist? Also, if this playlist has a parent, the parent playlist will be removed.
        `
      }
      dispatch(toggleConfirm(true,confirmData))
      return
    }
    
    dispatch(updatePlaylistType(playlist.id,newType))

  }

  render(){
    
    const {
      settings,
      parent,
      playlist,
      owner,
      loading
    } = this.props
    const {
      overlayOpen,
      editOwner
    } = this.state

    if(loading){
      return(
        <div className="settings-container">
          <Loader/>
        </div>
      )
    }

    return(
      <div className="settings-container">
        {
          !editOwner ?
          <FormInput
            type="text"
            label="Owner"
            value={(owner && owner.username) || ''}
            editAction={<Icon name="create" action={()=>this.setState({
              editOwner:true
            })}/>}
            disabled/> :
          <div className="owner-typeahead-wrapper">
            <div className="typeahead-label">
              Owner
            </div>
            <Typeahead
              typeaheadSearch={(term)=>this.typeaheadSearchOwner(term)}
              typeaheadAction={(data)=>this.typeaheadOwner(data)}
              clear={()=>this.clearOwnerTypeahead()}
              data={this.props.ownerTypeahead}
              classname={'playlistSettingsOwner'}
              disableEnterToSearch/>
          </div>
        }
        <Label name="Playlist type"/>
        <Select
          options={playlist.allTypes ? playlist.allTypes.map(type => {
            const data = {
              value:type.id,
              display:type.type
            }
            return data
          }) : []}
          selected={playlist.type}
          action={(data)=>this.changePlaylistType(data)}/>
        {
          this.getParentInput()
        }
        {
          settings.id && this.getSettingsUI()
        }
        <ConfirmError/>
        <Confirm/>
      </div>
    )
  }
}

function mapStateToProps(store){
  return {
    playlist:store.playlist.details,
    settings:store.playlist.settings,
    owner:store.playlist.owner,
    parent:store.playlist.parent,
    details:store.playlist.details,
    parentTypeahead:store.playlists.parentTypeahead,
    ownerTypeahead:store.playlist.ownerTypeahead,
    loading:store.playlist.playlistMoreLoading
  }
}

export default connect(mapStateToProps)(PlaylistSettings)
