import React from 'react'
import Loader from 'ui/Loader'

const LoaderFixedHeight = (props) => {
  const {
    height
  } = props

  return (
    <div
      className='loader-fixed-height'
      style={{minHeight: `${height}px`}}
    >
      <Loader />
    </div>
  )
}

export default LoaderFixedHeight
