const initialState = {
  details:{
    name:null,
    contactName:null,
    contactEmail:null,
    contactNumber:null,
    url:null,
    username:null,
    status:null,
    statuses:[],
    sectors: [],
    accountLeads: [],
    musicLeads: []
  },
  venues:[],
  contacts: [],
  report: {},
  reportPlaylistData: [],
  playlists:[],
  totalTracks:0,
  loading:false,
  loaded:false,
  error:null,
  library: null,
  settings:{
    importer:null,
    playback:null
  },
  settingsLoading:false,
  accounts: [],
  accountLeads: [],
  accountStatuses: [],
  billingCycles: [],
  billingTerritories: [],
  billingTypes: [],
  consultancyStatuses: [],
  contactFrequencies: [],
  contractStatuses: [],
  currencies: [],
  statuses: [],
  tiers: [],
  accountsLoading:false,
  businessContactsLoading: false,
  addContactToBusinessLoading: [],
  addContactToBusinessOverlayLoading: false,
  overlayContacts: [],
  musicLeads: [],
  musicLoading: false
}
export default function reducer(state = initialState, action){
  switch (action.type){
    case 'CLEAR_BUSINESS':{
      state = {...state,details:{},venues:[]}
      break
    }
    case 'FETCH_BUSINESS_DETAILS_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_BUSINESS_DETAILS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_BUSINESS_DETAILS_FULFILLED':{
      const data = action.payload.data.business

      const initialValue = {
        display: '------',
        value: 0
      }

      const sectors = data.sectors.map(sector => {
        return {
          display: sector.name,
          value: sector.id
        }
      })

      sectors.unshift(initialValue)

      const accountLeads = data.account_leads[0].users.map(lead => {
        return {
          display: lead.email,
          value: lead.id
        }
      })

      accountLeads.unshift(initialValue)

      const musicLeads = data.music_leads[0].users.map(lead => {
        return {
          display: lead.email,
          value: lead.id
        }
      })

      musicLeads.unshift(initialValue)

      state = {
        ...state,
        loading:false,
        details:{
          name:data.name,
          contactName:data.contact_name,
          contactEmail:data.contact_email,
          contactNumber:data.contact_number,
          generalInfo: data.general_info || '',
          url:data.url,
          username: data.users[0] ? data.users[0].username : null,
          sector: data.sector_id,
          musicLead: data.music ? data.music.music_lead_id : 0,
          accountLead: data.accounts ? data.accounts.account_lead_id : 0,
          sectors: sectors,
          accountLeads: accountLeads,
          musicLeads: musicLeads
        }
      }
      break
    }
    case 'UPDATE_BUSINESS_DETAILS_PENDING':{
      state = {
        ...state,
        loading:true,
      }
      break
    }
    case 'UPDATE_BUSINESS_DETAILS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'UPDATE_BUSINESS_DETAILS_FULFILLED':{
      const data = action.payload.data
      state = {
        ...state,
        loading:false,
        details:{
          ...state.details,
          name:data.name,
          contactName:data.contact_name,
          contactEmail:data.contact_email,
          contactNumber:data.contact_number,
          generalInfo: data.general_info || '',
          url:data.url || '',
          username:data.users[0].username,
          sector: data.sector_id,
          musicLead: data.music ? data.music.music_lead_id : 0,
          accountLead: data.accounts ? data.accounts.account_lead_id : 0
        }
      }
      break
    }
    case 'FETCH_BUSINESS_USER_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_BUSINESS_USER_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_BUSINESS_USER_FULFILLED':{
      const {data} = action.payload

      state =  {
        ...state,
        loading:false,
        loaded:true,
        user:{
          ...state.user,
          id:data.id,
          name:data.name,
          username:data.users[0].username,
          loginHash:data.users[0].login_hash
        }
      }
      break
    }
    case 'UPDATE_BUSINESS_USER_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'UPDATE_BUSINESS_USER_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'UPDATE_BUSINESS_USER_FULFILLED':{
      const {data} = action.payload

      state =  {
        ...state,
        error:null,
        loading:false,
        loaded:true,
        user:{
          ...state.user,
          id:data.id,
          name:data.name,
          username:data.users[0].username,
          loginHash:data.users[0].login_hash
        }
      }
      break
    }
    case 'FETCH_BUSINESS_VENUES_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_BUSINESS_VENUES_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_BUSINESS_VENUES_FULFILLED':{
      const venues = action.payload.data.venues.map((venue)=>{
        const data = {
          id:venue.id,
          name:venue.name,
          venue_user:venue.user[0] ? venue.user[0].username : '',
          playlists_count:venue.playlists.length,
          total_tracks:venue.total_tracks,
          blocked_count:venue.blocked_count,
          created_at:venue.created_at.standard,
          last_login:venue.user[0] ? venue.user[0].last_login.standard : '',
          authToken:venue.authToken,
          online:false
        }
        return data
      })
      state = {
        ...state,
        venues:venues,
        loading:false,
        loaded:true
      }
      break
    }
    case 'DELETE_VENUE_FULFILLED':{
      const venues = state.venues.filter(venue => venue.id !== action.meta)
      state = {...state,venues:venues}
      break
    }

    case 'FETCH_BUSINESS_GENRES_BREAKDOWN_PENDING':{
      state = {...state,loading:true, loaded: false}
      break
    }
    case 'FETCH_BUSINESS_GENRES_BREAKDOWN_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_BUSINESS_GENRES_BREAKDOWN_FULFILLED':{
      state = {
        ...state,
        report: action.payload.data,
        loading:false
      }
      break
    }
    case 'FETCH_BUSINESS_TRACKS_BREAKDOWN_PENDING':{
      state = {...state,loading:true, loaded: false}
      break
    }
    case 'FETCH_BUSINESS_TRACKS_BREAKDOWN_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_BUSINESS_TRACKS_BREAKDOWN_FULFILLED':{
      let reportPlaylistData = {}
      delete action.payload.data.exists

      console.log("action.payload.data", action.payload.data, Object.entries(action.payload.data))

        reportPlaylistData = Object.entries(action.payload.data).map((playlistData) => {
          const date = new Date(playlistData[0]);  // 2009-11-10
          const month = date.toLocaleString('default', { month: 'short' });
          const data = {
            name:month,
            Existing: playlistData[1].total,
            New:playlistData[1].new
          }

          return data
        })

      state = {
        ...state,
        reportPlaylistData: reportPlaylistData,
        loading:false
      }
      break
    }
    case 'FETCH_BUSINESS_LIBRARY_PENDING':{
      state = {...state,loading:true, loaded: false}
      break
    }
    case 'FETCH_BUSINESS_LIBRARY_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_BUSINESS_LIBRARY_FULFILLED':{
      state = {
        ...state,
        library: action.payload.data,
        loading:false
      }
      break
    }
    case 'FETCH_BUSINESS_PLAYLISTS_PENDING':{
      state = {...state, loading:true, playlists:[]}
      break
    }
    case 'FETCH_BUSINESS_PLAYLISTS_REJECTED':{
      state = {...state, loading:false, error:action.payload}
      break
    }
    case 'FETCH_BUSINESS_PLAYLISTS_FULFILLED':{
      let totalTracks = 0
      const playlists = action.payload.data.map((playlist) => {
        const data = {
          id: playlist.id,
          name: playlist.name,
          tracksCount: playlist.tracks_count,
          type: playlist.playlist_types.type,
          createdAt: playlist.created_at
        }
        totalTracks += playlist.tracks_count
        return data
      })
      state =  {
        ...state,
        loading: false,
        loaded: true,
        playlists: playlists,
        totalTracks: totalTracks,
      }
      break
    }
    case 'ADD_PLAYLIST_TO_BUSINESS_FULFILLED':{
      state = {
        ...state,
        playlists:state.playlists.concat(action.meta)
      }
      break
    }
    case 'REMOVE_PLAYLIST_FROM_BUSINESS_FULFILLED':{
      state = {
        ...state,
        playlists:state.playlists.filter(playlist => playlist.id !== action.meta.id)
      }
      break
    }
    case 'DELETE_INTERLEAVES_FROM_BUSINESS_PENDING':{
      state = {
        ...state,
        loading: true
      }
      break
    }
    case 'DELETE_INTERLEAVES_FROM_BUSINESS_REJECTED':{
      state = {
        ...state,
        loading: false,
        error: action.payload
      }
      break
    }
    case 'DELETE_INTERLEAVES_FROM_BUSINESS_FULFILLED':{
      state = {
        ...state,
        loading: false
      }
      break
    }
    case 'FETCH_BUSINESS_SETTINGS_PENDING':{
      state = {
        ...state,
        settingsLoading: true,
        settings:{}
      }
      break
    }
    case 'FETCH_BUSINESS_SETTINGS_REJECTED':{
      state = {
        ...state,
        settingsLoading: false
      }
      break
    }
    case 'FETCH_BUSINESS_SETTINGS_FULFILLED':{
      state = {
        ...state,
        settingsLoading: false,
        settings:action.payload.data
      }
      break
    }

    case 'FETCH_BUSINESS_ACCOUNTS_PENDING':{
      state = {
        ...state,
        accountsLoading: true,
        accounts: []
      }
      break
    }
    case 'FETCH_BUSINESS_ACCOUNTS_REJECTED':{
      state = {
        ...state,
        accountsLoading: false,
        accounts: []
      }
      break
    }
    case 'FETCH_BUSINESS_ACCOUNTS_FULFILLED':{
      const data = action.payload.data
      const accountsData = data.accounts

      const initialValue = {
        display: '------',
        value: 0
      }

      const accountLeads = data.account_leads[0].users.map(lead => {
        return {
          display: lead.email,
          value: lead.id
        }
      })

      accountLeads.unshift(initialValue)

      const billingCycles = data.billing_cycles.map(cycle => {
        return {
          display: cycle.cycle,
          value: cycle.id
        }
      })

      billingCycles.unshift(initialValue)

      const billingTerritories = data.billing_territories.map(territory => {
        return {
          display: territory.name,
          value: territory.id
        }
      })

      billingTerritories.unshift(initialValue)

      const billingTypes = data.billing_types.map(type => {
        return {
          display: type.type,
          value: type.id
        }
      })

      billingTypes.unshift(initialValue)

      const consultancyStatuses = data.consultancy_statuses.map(status => {
        return {
          display: status.status,
          value: status.id
        }
      })

      const contactFrequencies = data.contact_frequencies.map(freq => {
        return {
          display: freq.freq,
          value: freq.id
        }
      })

      contactFrequencies.unshift(initialValue)

      const contractStatuses = data.contract_statuses.map(status => {
        return {
          display: status.status,
          value: status.id
        }
      })

      contractStatuses.unshift(initialValue)

      const currencies = data.subscription_currencies.map(currency => {
        return {
          display: currency.symbol,
          value: currency.id
        }
      })

      currencies.unshift(initialValue)

      const tiers = data.tiers.map(tier => {
        return {
          display: tier.tier,
          value: tier.id
        }
      })

      tiers.unshift(initialValue)

      const accountStatuses = data.statuses.map(status => {
        return {
          display: status.status,
          value: status.id
        }
      })

      accountStatuses.unshift(initialValue)

      state = {
        ...state,
        accountsLoading: false,
        accounts: accountsData ? {
          accountLead: accountsData.account_lead_id || 0,
          billingCycle: accountsData.billing_cycle_id | 0,
          billingTerritory: accountsData.billing_territory_id || 0,
          billingType: accountsData.billing_type_id || 0,
          consultancyDescription: accountsData.consultancy_desc,
          consultancyFee: accountsData.consultancy_fee,
          consultancyStatus: accountsData.consultancy_status_id || 0,
          contactFrequency: accountsData.contact_frequency_id || 0,
          contractStatus: accountsData.contract_status_id || 0,
          customerConnect: accountsData.customer_connect || 0,
          currency: accountsData.contract_currency_id || 0,
          setup: data.setup || 0,
          startDate: accountsData.start_date || null,
          status: data.state_id || 0,
          subscriptionFee: accountsData.subscription_fee,
          tier: accountsData.tier_id || 0,
          venueAddressCompleted: accountsData.venue_address_complete || 0,
        } : {
          consultancyStatus: 0,
          setup: data.setup,
          status: data.state_id || 0,
        },
        accountLeads: accountLeads,
        accountStatuses: accountStatuses,
        billingCycles: billingCycles,
        billingTerritories: billingTerritories,
        billingTypes: billingTypes,
        consultancyStatuses: consultancyStatuses,
        contactFrequencies: contactFrequencies,
        contractStatuses: contractStatuses,
        currencies: currencies,
        tiers: tiers
      }
      break
    }

    case 'UPDATE_BUSINESS_ACCOUNTS_PENDING':{
      state = {
        ...state,
        accountsLoading: true,
      }
      break
    }
    case 'UPDATE_BUSINESS_ACCOUNTS_REJECTED':{
      state = {
        ...state,
        accountsLoading: false,
        error: action.payload
      }
      break
    }
    case 'UPDATE_BUSINESS_ACCOUNTS_FULFILLED':{
      const data = action.payload.data
      const accountsData = data.accounts

      state = {
        ...state,
        accountsLoading: false,
        accounts: accountsData ? {
          accountLead: accountsData.account_lead_id || 0,
          billingCycle: accountsData.billing_cycle_id | 0,
          billingTerritory: accountsData.billing_territory_id || 0,
          billingType: accountsData.billing_type_id || 0,
          consultancyDescription: accountsData.consultancy_desc,
          consultancyFee: accountsData.consultancy_fee,
          consultancyStatus: accountsData.consultancy_status_id || 0,
          contactFrequency: accountsData.contact_frequency_id || 0,
          contractStatus: accountsData.contract_status_id || 0,
          customerConnect: accountsData.customer_connect || 0,
          currency: accountsData.contract_currency_id || 0,
          setup: data.setup || 0,
          startDate: accountsData.start_date || null,
          status: data.state_id || 0,
          subscriptionFee: accountsData.subscription_fee,
          tier: accountsData.tier_id || 0,
          venueAddressCompleted: accountsData.venue_address_complete || 0,
        } : {
          consultancyStatus: 0,
          setup: data.setup,
          status: data.state_id || 0,
        },
      }
      break
    }

    case 'FETCH_BUSINESS_MUSIC_PENDING':{
      state = {
        ...state,
        musicLoading: true,
        music: []
      }
      break
    }

    case 'FETCH_BUSINESS_MUSIC_REJECTED':{
      state = {
        ...state,
        musicLoading: false,
        music: []
      }
      break
    }

    case 'FETCH_BUSINESS_MUSIC_FULFILLED':{
      const data = action.payload.data
      const musicData = data.music

      const initialValue = {
        display: '------',
        value: 0
      }

      const musicLeads = data.music_leads[0].users.map(lead => {
        return {
          display: lead.email,
          value: lead.id
        }
      })

      musicLeads.unshift(initialValue)

      state = {
        ...state,
        musicLoading: false,
        music: {
          musicLead: musicData ? musicData.music_lead_id : 0,
          brief: musicData ? musicData.brief : null,
        } ,
        musicLeads: musicLeads
      }
      break
    }

    case 'UPDATE_BUSINESS_MUSIC_PENDING':{
      state = {
        ...state,
        musicLoading: true,
      }
      break
    }

    case 'UPDATE_BUSINESS_MUSIC_REJECTED':{
      state = {
        ...state,
        musicLoading: false,
        error: action.payload
      }
      break
    }

    case 'UPDATE_BUSINESS_MUSIC_FULFILLED':{
      const data = action.payload.data.res.data

      state = {
        ...state,
        musicLoading: false,
        music: {
          musicLead: data.music_lead_id || 0,
          brief: data.brief
        }
      }
      break
    }


    case 'FETCH_BUSINESS_CONTACTS_PENDING':{
      state = {
        ...state,
        businessContactsLoading: true
      }
      break
    }

    case 'FETCH_BUSINESS_CONTACTS_REJECTED':{
      state = {
        ...state,
        businessContactsLoading: false,
        error: action.payload
      }
      break
    }

    case 'FETCH_BUSINESS_CONTACTS_FULFILLED':{
      const contacts = action.payload.data.contacts.map(contact => {
        return {
          id: contact.id,
          name: contact.name,
          status: contact.status ? contact.status.status : null,
          statusID: contact.status_id,
          email: contact.email,
          phoneNumber: contact.phone_number,
          notes: contact.notes,
          jobTitle: contact.job_title,
          position: contact.position ? contact.position.position : null,
          positionID: contact.position_id
        }
      })

      state = {
        ...state,
        businessContactsLoading: false,
        contacts: contacts
      }
      break
    }

    case 'CREATE_CONTACT_PENDING':{
      state = {
        ...state,
        businessContactsLoading:true
      }
      break
    }

    case 'CREATE_CONTACT_REJECTED':{
      state = {
        ...state,
        businessContactsLoading:false,
        error:action.payload
      }
      break
    }

    case 'CREATE_CONTACT_FULFILLED':{
      const data = action.payload.data

      const newContact = {
        id: data.id,
        name: data.name,
        status: data.status ? data.status.status : null,
        email: data.email,
        phoneNumber: data.phone_number,
        notes: data.notes,
        jobTitle: data.job_title,
        position: data.position ? data.position.position : null,
      }

      state =  {
        ...state,
        businessContactsLoading:false,
        contacts: state.contacts.concat(newContact)
      }
      break
    }

    case 'UPDATE_CONTACT_PENDING':{
      state = {
        ...state,
        businessContactsLoading:true
      }
      break
    }

    case 'UPDATE_CONTACT_REJECTED':{
      state = {
        ...state,
        businessContactsLoading:false,
        error:action.payload
      }
      break
    }

    case 'UPDATE_CONTACT_FULFILLED':{
      const data = action.payload.data
      let contacts = [...state.contacts]

      const updatedIndex = contacts.findIndex(contact => contact.id === action.payload.data.id)

      contacts[updatedIndex] = {
        id: data.id,
        name: data.name,
        status: data.status ? data.status.status : null,
        statusID: data.status_id,
        email: data.email,
        phoneNumber: data.phone_number,
        notes: data.notes,
        jobTitle: data.job_title,
        position: data.position ? data.position.position : null,
        positionID: data.position_id
      }

      state =  {
        ...state,
        businessContactsLoading: false,
        contacts: contacts
      }
      break
    }

    case 'SEARCH_CONTACTS_PENDING':{
      state = {
        ...state,
        addContactToBusinessOverlayLoading: true
      }
      break
    }

    case 'SEARCH_CONTACTS_REJECTED':{
      state = {
        ...state,
        addContactToBusinessOverlayLoading: false,
        error:action.payload
      }
      break
    }

    case 'SEARCH_CONTACTS_FULFILLED':{
      const contacts = action.payload.data.contacts.map(data => {
        return ({
          id: data.id,
          name: data.name,
          email: data.email,
          phoneNumber: data.phone_number,
          jobTitle: data.job_title,
          position: data.position ? data.position.position : null,
          status: data.status ? data.status.status : null,
          notes: data.notes,
          businesses: data.businesses,
          venues: data.venues,
          added: data.created_at
          }
        )
      })

      state =  {
        ...state,
        addContactToBusinessOverlayLoading: false,
        overlayContacts: action.meta ? contacts : state.overlayContacts.concat(contacts),
        count: action.payload.data.count
      }
      break
    }

    case 'ADD_CONTACT_TO_BUSINESS_PENDING':{
      state = {
        ...state,
        addContactToBusinessLoading: state.addContactToBusinessLoading.concat(action.meta.contactID)
      }
      break
    }

    case 'ADD_CONTACT_TO_BUSINESS_REJECTED':{
      state = {
        ...state,
        addContactToBusinessLoading: state.addContactToBusinessLoading.filter(contactID => contactID !== action.meta.contactID),
        error: action.payload
      }
      break
    }

    case 'REMOVE_CONTACT_FROM_BUSINESS_REJECTED':{
      state = {
        ...state,
        error: action.payload
      }
      break
    }

    case 'REMOVE_CONTACT_FROM_BUSINESS_FULFILLED':{
      const data = action.payload.data

      state =  {
        ...state,
        contacts: state.contacts.filter(contact => contact.id !== data.id)
      }
      break
    }

    case 'ADD_CONTACT_TO_BUSINESS_FULFILLED':{
      const data = action.payload.data

      const newContact = {
        id: data.id,
        name: data.name,
        status: data.status ? data.status.status : null,
        statusID: data.status_id,
        email: data.email,
        phoneNumber: data.phone_number,
        notes: data.notes,
        jobTitle: data.job_title,
        position: data.position ? data.position.position : null,
        positionID: data.position_id
      }

      const overlayContacts = [...state.overlayContacts]

      const updatedOverlayIndex = overlayContacts.findIndex(contact => contact.id === data.id)

      overlayContacts[updatedOverlayIndex].businesses.push({
        id: action.meta.businessID
      })

      state =  {
        ...state,
        addContactToBusinessLoading: state.addContactToBusinessLoading.filter(contactID => contactID !== data.id),
        contacts: state.contacts.concat(newContact),
        overlayContacts: overlayContacts
      }
      break
    }

    case 'CLEAR_OVERLAY_CONTACTS':{
      state = {
        ...state,
        overlayContacts: []
      }
      break
    }

    //check player online
    case 'WS_PLAYER_ONLINE':{
      
      const venues = state.venues.map(venue => {
        const id = parseInt(action.payload.vid)
        if(venue.id === id){
          venue.online = action.payload.online
          venue.onlineChecked = true
        }
        return venue
      })
      state =  {
        ...state,
        venues:venues,
        loading:false,
        loaded:true
      }
      break
    }
    default: // no default
  }
  return state
}
