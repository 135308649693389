const initialState = {
  details:{
    id:null
  },
  // tracks:[],
  // totalTracks:0,
  // playlists:[],
  // schedules:[],
  // saved_schedules:[],
  // saveScheduleLoaded:false,
  // schedule_template: [],
  // announcements:[],
  loading:false,
  loaded:false,
  // scheduleLoading:false,
  error:null,
  businessVenues:[],
  settings:{},
  // newPlaylistID: null,
  // newInterleaveID: null,
  // newInterleaveIDs:false,
  // newInterleaveRatios:false,
  // showScheduleCreator: false,
  // activePlaylistInfo: null,
  // businessID:null
}
export default function reducer(state = initialState, action){
  switch (action.type){
    // case 'APP_BODY_CLICK':{
    //   state = {...state,showScheduleCreator:false,activePlaylistInfo:null}
    //   break
    // }
    // case 'TOGGLE_SCHEDULE_CREATOR':{
    //   state = {...state, showScheduleCreator:action.payload,activePlaylistInfo:null}
    //   break
    // }
    // case 'TOGGLE_VENUE_INTERLEAVE_RATIO_CHANGER':{
    //   state = {
    //     ...state,
    //     showInterleaveRatioChanger:action.payload
    //   }
    //   break
    // }
    // case 'SET_PLAYLIST_INFO':{
    //   state = {...state, activePlaylistInfo:action.payload,showScheduleCreator:false}
    //   break
    // }
    case 'CLEAR_USER':{
      state = {...state,details:{id: null},businessVenues:[]}
      break
    }
    // case 'FETCH_VENUE_TRACKS_PENDING':{
    //   state = {...state,loading:true,tracks:[]}
    //   break
    // }
    // case 'FETCH_VENUE_TRACKS_REJECTED':{
    //   state = {...state,loading:false,error:action.payload}
    //   break
    // }
    // case 'FETCH_VENUE_TRACKS_FULFILLED':{
    //   state =  {
    //     ...state,
    //     loading:false,
    //     loaded:true,
    //     tracks:action.payload.data.tracks
    //   }
    //   break
    // }
    // case 'FETCH_VENUE_VENUES_PENDING':{
    //   state = {...state,loading:true}
    //   break
    // }
    // case 'FETCH_VENUE_VENUES_REJECTED':{
    //   state = {...state,loading:false,error:action.payload}
    //   break
    // }
    // case 'FETCH_VENUE_VENUES_FULFILLED':{
    //   state =  {
    //     ...state,
    //     loading:false,
    //     loaded:true,
    //     venues:state.venues.concat(action.payload.data.venues)
    //   }
    //   break
    // }
    // case 'FETCH_VENUE_SCHEDULE_PENDING':{
    //   state = {
    //     ...state,
    //     scheduleLoading:true,
    //     newInterleaveIDs:false,
    //     newInterleaveRatios:false
    //   }
    //   break
    // }
    // case 'FETCH_VENUE_SCHEDULE_REJECTED':{
    //   state = {...state,scheduleLoading:false,error:action.payload}
    //   break
    // }
    // case 'FETCH_VENUE_SCHEDULE_FULFILLED':{
    //   state =  {
    //     ...state,
    //     scheduleLoading:false,
    //     loaded:true,
    //     schedules:action.payload.data.schedule_template,
    //     playlists:action.payload.data.playlists,
    //     businessID:action.payload.data.business_id,
    //     venueID:action.payload.data.id
    //   }
    //   break
    // }
    // case 'CREATE_VENUE_SCHEDULE_FULFILLED':{
    //     state = {
    //     ...state,
    //     newPlaylistID: {
    //       index: action.meta,
    //       id: action.payload.data.res.data.id
    //     }
    //   }
    //   break
    // }
    // case 'UPDATE_VENUE_SCHEDULE_FULFILLED':{
    //   state = {
    //     ...state
    //   }
    //   break
    // }
    // case 'DELETE_VENUE_SCHEDULE_FULFILLED':{
    //   state = {
    //     ...state
    //   }
    //   break
    // }
    // case 'CREATE_VENUE_INTERLEAVE_FULFILLED':{
    //   state = {
    //     ...state,
    //     newInterleaveID: {
    //       index: `${action.meta}` ,
    //       id: action.payload.data.id
    //     }
    //   }
    //   break
    // }
    // case 'CREATE_VENUE_INTERLEAVES_FULFILLED':{
    //   state = {
    //     ...state,
    //     newInterleaveIDs:true
    //   }
    // }
    // case 'UPDATE_VENUE_INTERLEAVE_RATIOS_FULFILLED':{
    //   state = {
    //     ...state,
    //     newInterleaveRatios:true
    //   }
    //   break
    // }
    // case 'DELETE_VENUE_INTERLEAVE_FULFILLED':{
    //   state = {
    //     ...state
    //   }
    //   break
    // }
    // case 'FETCH_VENUE_CALENDAR_PENDING':{
    //   state = {...state,scheduleLoading:true}
    //   break
    // }
    // case 'FETCH_VENUE_CALENDAR_REJECTED':{
    //   state = {...state,scheduleLoading:false,error:action.payload}
    //   break
    // }
    // case 'FETCH_VENUE_CALENDAR_FULFILLED':{
    //   state =  {
    //     ...state,
    //     scheduleLoading: false,
    //     loaded: true,
    //     schedules: action.payload.data.schedule_calendar,
    //     schedule_template: action.payload.data.schedule_template,
    //     playlists: action.payload.data.playlists
    //   }
    //   break
    // }
    // case 'CREATE_VENUE_CALENDAR_FULFILLED':{
    //   state = {
    //     ...state,
    //     newPlaylistID: {
    //       index: action.meta,
    //       id: action.payload.data.id
    //     }
    //   }
    //   break
    // }
    // case 'FETCH_VENUE_ANNOUNCEMENTS_PENDING':{
    //   state = {
    //     ...state,
    //     loading:true,
    //     announcements:[],
    //     playlists:[]
    //   }
    //   break
    // }
    // case 'FETCH_VENUE_ANNOUNCEMENTS_REJECTED':{
    //   state = {...state,loading:false,error:action.payload}
    //   break
    // }
    // case 'FETCH_VENUE_ANNOUNCEMENTS_FULFILLED':{
    //   state =  {
    //     ...state,
    //     loading:false,
    //     loaded:true,
    //     announcements:action.payload.data.schedule_template,
    //     playlists:action.payload.data.playlists,
    //   }
    //   break
    // }
    // case 'UPDATE_VENUE_ANNOUNCEMENTS_FULFILLED':{

    //   const announcements = state.announcements.map(announcement => {
    //     if(announcement.pivot.id === action.meta.id){
    //       announcement.pivot.start = action.meta.time
    //     }
    //     return announcement
    //   })
    //   state =  {
    //     ...state,
    //     announcements:announcements
    //   }
    //   break
    // }
    case 'FETCH_USER_DETAILS_PENDING':
    case 'UPDATE_USER_DETAILS_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_USER_DETAILS_REJECTED':
    case 'UPDATE_USER_DETAILS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_USER_DETAILS_FULFILLED':
    case 'UPDATE_USER_DETAILS_FULFILLED':{
      const {data} = action.payload
      state =  {
        ...state,
        loading:false,
        loaded:true,
        details:{
          ...state.details,
          id:data.id,
          group_id:data.group_id,
          username:data.username,
        }
      }
      break
    }

    case 'FETCH_USER_SETTINGS_PENDING':
    case 'UPDATE_USER_SETTINGS_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_USER_SETTINGS_REJECTED':
    case 'UPDATE_USER_SETTINGS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_USER_SETTINGS_FULFILLED':
    case 'UPDATE_USER_SETTINGS_FULFILLED':{
      const {data} = action.payload
      state =  {
        ...state,
        loading:false,
        loaded:true,
        settings:{
          ...state.settings,
          isFitness:data.isFitness,
          hasVenues:data.hasVenues,
          venue_hasControls:data.venue_hasControls,
          playlists_hasDashboard:data.playlists_hasDashboard,
          playlists_hasOEPlaylists:data.playlists_hasOEPlaylists,
          playlists_hasSubscribed:data.playlists_hasSubscribed,
          playlists_hasAssigned:data.playlists_hasAssigned,
          library_isRestricted:data.library_isRestricted,
        }
      }
      break
    }

    // case 'FETCH_VENUE_PLAYLISTS_PENDING':{
    //   state = {...state,loading:true,playlists:[]}
    //   break
    // }
    // case 'FETCH_VENUE_PLAYLISTS_REJECTED':{
    //   state = {...state,loading:false,error:action.payload}
    //   break
    // }
    // case 'FETCH_VENUE_PLAYLISTS_FULFILLED':{
    //   let totalTracks = 0
    //   const playlists = action.payload.data.map((playlist)=>{
    //     const data = {
    //       id:playlist.id,
    //       name:playlist.name,
    //       tracksCount:playlist.tracks_count,
    //       type:playlist.playlist_types.type,
    //       createdAt:playlist.created_at
    //     }
    //     totalTracks+=playlist.tracks_count
    //     return data
    //   })
    //   state =  {
    //     ...state,
    //     loading:false,
    //     loaded:true,
    //     playlists:playlists,
    //     totalTracks:totalTracks,
    //     businessID:action.payload.data.business_id
    //   }
    //   break
    // }
    // case 'ADD_PLAYLIST_TO_VENUE_FULFILLED':{
    //   state = {
    //     ...state,
    //     playlists:state.playlists.concat(action.meta)
    //   }
    //   break
    // }
    // case 'REMOVE_PLAYLIST_FROM_VENUE_FULFILLED':{
    //   state = {
    //     ...state,
    //     playlists:state.playlists.filter(playist => playist.id !== action.meta.id)
    //   }
    //   break
    // }
    // case 'FETCH_VENUE_CONTROLS_PENDING':{
    //   state = {...state,loading:true,loaded:false}
    //   break
    // }
    // case 'FETCH_VENUE_CONTROLS_REJECTED':{
    //   state = {...state,loading:false,error:action.payload}
    //   break
    // }
    // case 'FETCH_VENUE_CONTROLS_FULFILLED':{
    //   state =  {...state,loading:false,loaded:true,data:{...action.payload.data}}
    //   break
    // }
    // case 'LOAD_SCHEDULE_PENDING':{
    //   state = {...state,loading:true,saveScheduleLoaded:false}
    //   break
    // }
    // case 'LOAD_SCHEDULE_REJECTED':{
    //   state = {...state,loading:false,error:action.payload}
    //   break
    // }
    // case 'LOAD_SCHEDULE_FULFILLED':{
    //   state =  {
    //     ...state,
    //     loading:false,
    //     loaded:true,
    //     saveScheduleLoaded:true
    //   }
    //   break
    // }
    // case 'FETCH_SAVED_SCHEDULES_PENDING':{
    //   state = {
    //     ...state,
    //     loading:true,
    //     loaded:false,
    //     saved_schedules:[]
    //   }
    //   break
    // }
    // case 'FETCH_SAVED_SCHEDULES_REJECTED':{
    //   state = {
    //     ...state,
    //     loading:false,
    //     error:action.payload
    //   }
    //   break
    // }
    // case 'FETCH_SAVED_SCHEDULES_FULFILLED':{
    //   state =  {
    //     ...state,
    //     loading:false,
    //     loaded:true,
    //     saved_schedules:action.payload.data
    //   }
    //   break
    // }
    // case 'FETCH_VENUE_USER_PENDING':{
    //   state = {...state,loading:true}
    //   break
    // }
    // case 'FETCH_VENUE_USER_REJECTED':{
    //   state = {...state,loading:false,error:action.payload}
    //   break
    // }
    // case 'FETCH_VENUE_USER_FULFILLED':{
    //   const {data} = action.payload

    //   state =  {
    //     ...state,
    //     loading:false,
    //     loaded:true,
    //     user:{
    //       ...state.user,
    //       id:data.id,
    //       name:data.name,
    //       username:data.user[0].username,
    //       loginHash:data.user[0].login_hash
    //     }
    //   }
    //   break
    // }
    // case 'UPDATE_VENUE_USER_PENDING':{
    //   state = {...state,loading:true}
    //   break
    // }
    // case 'UPDATE_VENUE_USER_REJECTED':{
    //   state = {...state,loading:false,error:action.payload}
    //   break
    // }
    // case 'UPDATE_VENUE_USER_FULFILLED':{
    //   const {data} = action.payload

    //   state =  {
    //     ...state,
    //     error:null,
    //     loading:false,
    //     loaded:true,
    //     user:{
    //       ...state.user,
    //       id:data.id,
    //       name:data.name,
    //       username:data.user[0].username,
    //       loginHash:data.user[0].login_hash
    //     }
    //   }
    //   break
    // }
    // case 'LOGOUT':{
    //   state = {
    //     details:{
    //       name:null
    //     },
    //     tracks:[],
    //     playists:[],
    //     loading:false,
    //     loaded:false,
    //     error:null
    //   }
    //   break
    // }
    default: // no default case
  }
  return state
}
