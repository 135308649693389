const initalState = {
  data:{
    id:null,
    name:null,
    display:{
      track:null,
      artist:null,
      playlist:null,
      ends:null
    },
    authToken:null
  },
  lastPlayed:[],
  loading:false,
  loaded:false,
  error:null,
  ws:{
    init:false,
    open:false,
    connected:false,
    ready:false,
    swapping:true,
  },
  energy:{
    isActive:false,
    level:null
  },
  volume:null,
  isPlaying:true,
  isFullscreen:false,
  downloading:{
    isDownloading:false,
    msg:null
  },
  alreadyConnected:false,
  hasPlayer:false
}

export default function reducer(state = initalState, action){
  switch (action.type){
    //venue
    case 'FETCH_VENUE_CONTROLS_PENDING':{
      state = {...state,loading:true,loaded:false}
      break
    }
    case 'FETCH_VENUE_CONTROLS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_VENUE_CONTROLS_FULFILLED':{
      state =  {...state,loading:false,loaded:true,data:{...action.payload.data}}
      break
    }
    //ws init
    case 'WS_INIT':{
      state = {...state,
        ws:{
          ...state.ws,
          init:action.payload.init,
          open:false,
          connected:false,
          ready:false,
          swapping:true,
        },
        downloading:{
          isDownloading:false,
          msg:null
        },
        lastPlayed:[]
      }
      break
    }
    case 'WS_MANAGER_INIT':{
      state = {...state,
        ws:{
          ...state.ws,
          init:action.payload.init,
          open:false,
          connected:false,
          ready:false
        }
      }
      break
    }
    //websocket open
    case 'WS_OPEN':{
      state = {...state,ws:{
        ...state.ws,
        open:action.payload.open
      }}
      break
    }
    //websocket close
    case 'WS_CLOSE':{
      state = {...state,ws:{
        ...state.ws,
        init:action.payload.init,
        open:action.payload.open,
        ready:action.payload.ready,
        connected:action.payload.connected
      }}
      break
    }
    //ws ready
    case 'WS_READY':{
      state = {...state,ws:{
        ...state.ws,
        ready:action.payload.ready,
        swapping:action.payload.swapping
      }}
      break
    }
    case 'WS_CONNECTED':{
      state = {...state,ws:{
        ...state.ws,
        connected:action.payload.connected
      }}
      break
    }
    //reset for new load
    case 'WS_RESET':{
      state = {...state,ws:{
        ...state.ws,
        init:false,
        open:false,
        connected:false,
        ready:false
      }}
      break
    }
    case 'WS_DOWNLOADING':{
      state = {
        ...state,
        hasPlayer:true,
        downloading:{
          ...state.downloading,
            isDownloading:action.payload.isDownloading,
            msg:action.payload.msg
        }
      }
      break
    }
    //swapping tracks
    case 'WS_SWAPPING_TRACKS':{
      state = {...state,ws:{
        ...state.ws,
        swapping:action.payload.swapping
      }}
      break
    }
    case 'WS_ALREADY_CONNECTED':{
      state = {
        ...state,
        alreadyConnected:true
      }
      break
    }
    //received current track
    case 'WS_CURR_TRACK':{
      if(action.payload){
        state = {...state,
          hasPlayer:true,
          data:{
          ...state.data,
          display:{
            ...state.data.display,
            track:action.payload.title,
            artist:action.payload.artist
          }
        }}
      }
      break
    }
    //got current playlist
    case 'WS_CURR_PLAYLIST':{
      if(action.payload){
        state = {...state,
          hasPlayer:true,
          data:{
          ...state.data,
          display:{
            ...state.data.display,
            playlist:action.payload.name,
            ends:action.payload.ends
          }
        }}
      }
      break
    }
    //set volume from player
    case 'WS_SET_VOLUME':{
      state = {...state,volume:action.payload}
      break
    }
    case 'WS_LAST_PLAYED':{
      state = {...state}
      if(action.payload instanceof Array){
        state.lastPlayed = state.lastPlayed.concat(action.payload)
      } else {
        state.lastPlayed = [action.payload,...state.lastPlayed]
      }
      break
    }
    case 'WS_NO_PLAYER':{
      state = {
        ...state,
        hasPlayer:false,
      }
      break
    }
    //toggle energy
    case 'TOGGLE_ENERGY_SLIDER':{
      state = {
        ...state,
        energy:{
          ...state.data.energy,
          isActive:action.payload
        }
      }
      break
    }
    //set energy
    case 'UPDATE_VENUE_ENERGY':{
      state = {
        ...state,
        loading:false,
        loaded:true,
        energy:{
          ...state.data.energy,
          isActive:true,
          level:action.payload
        }
      }
      break
    }
    //toggle is playing
    case 'VENUE_ISPLAYING':{
      state = {...state,isPlaying:action.payload}
      break
    }
    //toggle is fullscreen
    case 'VENUE_TOGGLE_FULLSCREEN':{
      state = {...state,isFullscreen:action.payload}
      break
    }
    case 'WS_SET_ENERGY':{
      state = {
        ...state,
        energy:{
          ...state.energy,
          isActive:action.payload.status,
          level:action.payload.level
        }
      }
      break
    }
    default: // no default case
  }
  return state
}
