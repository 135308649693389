import React from 'react'

import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'

const PlaylistCuratorsTableRowActions = (props) => {
  const {id, removeCurator} = props
  return (
    <div>
      <Icon name="trash" action={()=>removeCurator(id)}>
        <Tooltip pos="left" text="Remove curator from playlist"/>
      </Icon>
    </div>
  )
}

export default PlaylistCuratorsTableRowActions
