import React, {Fragment} from 'react'

import Icon from 'ui/Icon'
import Loader from 'ui/Loader'

const OverlayPlaylistAddToVenueTableRowActions = (props) => {
  const {
    addPlaylistToVenue,
    addPlaylistToVenueLoading,
    classname,
    venue
  } = props

  return (
    <div className={`${classname}`}>
      {addPlaylistToVenueLoading.includes(venue.id) ? (
        <Loader />
      ): (
        <Fragment>
          {venue.isVenueAssigned ?
            <div>
              <Icon
                name="checkmark-circle"
                success
              />
            </div>
            :
            <div onClick={()=>addPlaylistToVenue(venue)}>
              <Icon
                name="add-circle"
              />
            </div>
          }
        </Fragment>
      )}
    </div>
  )
}

export default OverlayPlaylistAddToVenueTableRowActions
