import React, { Component } from 'react'

import Loader from 'ui/Loader'

class Waveform extends Component {

  constructor(props) {
    super(props)
    this.state = {
      lineLimit:4000,
      ready:false,
      canvasWidth:0,
      canvasHeight:0,
      drawComplete:false
    }
    this.waveform = {
      elemRef:null,
      sizes:{
        height:null,
        width:null
      },
      leftChannel:null,
      totalLength:null,
      blocks:null
    }

    this.canvas = {
      elemRef:null,
      context:null,
      width:null
    }


    this.setWaveElement = elem => {
      this.waveform.elemRef = elem
    }
    this.setCanvasElem = elem =>{
      this.canvas.elemRef = elem
    }
    
  }

  componentDidMount() {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    this._audioContext = new AudioContext()
    this.waveform.sizes = {
      height:this.waveform.elemRef.offsetHeight,
      width:this.waveform.elemRef.offsetWidth
    }
    //set canvas width and height
    if(this.waveform.elemRef.offsetWidth !== this.canvas.elemRef.offsetWidth &&
      this.waveform.elemRef.offsetHeight !== this.canvas.elemRef.offsetHeight
    ){
      this.setState({
        canvasWidth:this.waveform.elemRef.offsetWidth,
        canvasHeight:this.waveform.elemRef.offsetHeight,
      })
    }
  }

  componentWillUnmount() {
    this._audioContext.close()
  }

  componentDidUpdate(prevProps){
    if (this.props.src !== prevProps.src) {
      //this._audioContext.close()
      //this._audioContext = new AudioContext()
      this.setState({drawComplete:false},()=>{
        this.canvas.context = this.canvas.elemRef.getContext("2d")
        this.canvas.context.clearRect(0,0,this.state.canvasWidth,this.state.canvasHeight)
        this.initialiseCanvas()
      })
    }
  }

  initialiseCanvas(){
    
    this.canvas.context.save();
    this.canvas.context.fillStyle = 'rgba(0,0,0,0)';
    this.canvas.context.fillRect(0,0,this.state.canvasWidth,this.state.canvasHeight );
    this.canvas.context.globalCompositeOperation = 'lighter';
    this.canvas.context.translate(0,this.state.canvasHeight / 2);
    this.canvas.context.lineWidth=1
    this.canvas.context.beginPath()
    this.getArrayBuffer()
  }

  getArrayBuffer(){
    if(this.props.src){
      fetch(this.props.src)
        .then(res=>res.arrayBuffer())
        .then(arrayBuffer=>this._audioContext.decodeAudioData(arrayBuffer))
        .then(audioBuffer =>{
          this.displayBuffer(audioBuffer)
        })
    }
  }

  displayBuffer(buff){
    this.waveform.leftChannel = buff.getChannelData(0) // Float32Array describing left channel
    this.waveform.totalLength = this.waveform.leftChannel.length
    this.waveform.blocks = Math.floor(this.waveform.totalLength / this.state.lineLimit)
    this.drawLinesCanvas()
  }

  drawLinesCanvas = function(){
    this.canvas.context.strokeStyle = '#f0b35b'
    let lineGap = (this.state.canvasWidth/this.state.lineLimit)
    for(let i=0;i<=this.state.lineLimit;i++){
      let audioBuffKey = Math.floor(this.waveform.blocks * i)
      let x = i*lineGap
      let y = this.waveform.leftChannel[audioBuffKey] * this.state.canvasHeight / 2
      this.canvas.context.moveTo( x, y )
      this.canvas.context.lineTo( x, (y*-1));
    }
    this.canvas.context.clearRect(0,0,this.state.canvasWidth,this.state.canvasHeight)
    this.canvas.context.stroke()
    this.canvas.context.restore()
    this.setState({drawComplete:true})
  }

  mouseMove(e){
    const clientWidth = e.nativeEvent.srcElement.clientWidth
    const offsetX = e.nativeEvent.offsetX
    const pos = Math.round(
      100 - ((clientWidth - offsetX)/clientWidth)
    *100)
    this.props.setScrubberSelectPos(pos)
  }

  mouseDown(e){
    const clientWidth = e.nativeEvent.srcElement.clientWidth
    this.props.selectScrubberPos(clientWidth)
  }

  mouseOut(e){
    this.props.onMouseOut(e)
  }

  render() {
    let loader
    if(this.props.src && !this.state.drawComplete){
      loader = <Loader/>
    } else {
      loader = null
    }
    return (
      <div 
        className="waveform" 
        ref={this.setWaveElement} 
        onMouseMove={(e)=>this.mouseMove(e)}
        onMouseDown={(e)=>this.mouseDown(e)}
        onMouseOut={(e)=>this.mouseOut(e)}>
        <canvas ref={this.setCanvasElem} width={this.state.canvasWidth} height={this.state.canvasHeight}/>
        {loader}
        <span className="waveform-horizon"></span>
      </div>
    )
  }
}

export default Waveform
