import React, { Component } from 'react'
import { connect } from 'react-redux';

import { getPlaylistTracks } from 'store/actions/playlistAction'

import LibraryTable from '../library/LibraryTable'

class PlaylistLibrary extends Component {
  componentDidMount() {
    const {
      dispatch,
      id
    } = this.props

    dispatch(getPlaylistTracks(id))
  }

  render(){
    const {
      history,
      location,
      playlist,
      playingTrack,
      tracks,
      playlistMetadata
    } = this.props

    return (
      <LibraryTable
        history={history}
        location={location}
        playlist={playlist}
        playlistMetadata={playlistMetadata}
        playingTrack={playingTrack}
        playlistTracks={tracks}
      />
    )
  }
}

function mapStateToProps(store){
  return {
    tracks:store.playlist.tracks
  }
}

export default connect(mapStateToProps)(PlaylistLibrary)