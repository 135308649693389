const initalState = {
  confirmActive:false,
  data:null
}

export default function reducer(state = initalState, action) {
  switch (action.type){
    case 'TOGGLE_CONFIRM':{
      state = {
        ...state,
        confirmActive:action.payload.confirmActive,
        data:action.payload.data
      }
      break
    }
    case 'TOGGLE_CONFIRM_ERROR':{
      state = {
        ...state,
        confirmErrorActive:action.payload.confirmErrorActive,
        data:action.payload.data
      }
      break
    }
    default: // no default
  }
  return state
}
