const initialState = {
  data:[],
  typeahead:[],
  typeaheadLoading: false,
  count:null,
  loading:false,
  loaded:false,
  venueCreated:false,
  storedVenueData:{},
  createdVenueID:null,
  businessVenues: [],
  searchedBusinesses: [],
  error:null
}
export default function reducer(state = initialState, action){
  switch (action.type){
    //playlists
    case 'CLEAR_VENUES':{
      state = {...state,data:[]}
      break
    }
    case 'FETCH_VENUES_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_VENUES_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_VENUES_FULFILLED':{
      const venues = action.payload.data.venues.map((venue)=>{
        const data = {
          id:venue.id,
          name:venue.name,
          venue_user:venue.user[0] ? venue.user[0].username : '',
          business:venue.business ? venue.business.name : '',
          business_id:venue.business_id && venue.business_id,
          playlists_count:venue.playlists.length,
          blocked_count:venue.blocked_count,
          total_tracks:venue.total_tracks,
          created_at:venue.created_at.standard,
          last_login:venue.user[0] ? venue.user[0].last_login.standard : '',
          authToken:venue.authToken
        }
        return data
      })
      state =  {
        ...state,
        loading:false,
        loaded:true,
        data: action.meta ? venues : state.data.concat(venues),
        count:action.payload.data.count
      }
      break
    }
    case 'CREATE_VENUE_PENDING':{
      state = {
        ...state,
        loading: true,
        createdVenueID: null
      }
      break
    }
    case 'CREATE_VENUE_REJECTED':{
      state = {
        ...state,
        loading: false,
        error: action.payload,
        storedVenueData: action.meta
      }
      break
    }
    case 'CREATE_VENUE_FULFILLED':{
      state = {
        ...state,
        loading: false,
        error: null,
        venueCreated: true,
        storedVenueData: {},
        createdVenueID: action.payload.data.res.data.id
      }
      break
    }
    case 'DELETE_VENUE_FULFILLED':{
      const venues = state.data.filter(venue => venue.id !== action.meta)
      state = {...state,data:venues}
      break
    }
    case 'COPY_VENUE_PENDING':{
      state = {
        ...state,
        loading: true,
        copiedVenueID: null
      }
      break
    }
    case 'COPY_VENUE_REJECTED':{
      state = {
        ...state,
        loading: false,
        error: action.payload,
        storedVenueData: action.meta
      }
      break
    }
    case 'COPY_VENUE_FULFILLED':{
      state = {
        ...state,
        loading: false,
        error: null,
        venueCopied: true,
        storedVenueData: {},
        copiedVenueID: action.payload.data.res.data.id
      }
      break
    }
    case 'SEARCH_VENUES_PENDING':{
      state = {
        ...state,
        loading:true
      }
      break
    }
    case 'SEARCH_VENUES_REJECTED':{
      state = {
        ...state,
        loading:false,
        error:action.payload
      }
      break
    }
    case 'SEARCH_VENUES_FULFILLED':{
      const venues = action.payload.data.venues.map((venue)=>{
        const data = {
          id:venue.id,
          name:venue.name,
          venue_user:venue.user[0] ? venue.user[0].username : '',
          business:venue.business ? venue.business.name : '',
          business_id:venue.business_id && venue.business_id,
          playlists_count:venue.playlists.length,
          blocked_count:venue.blocked_count,
          total_tracks:venue.total_tracks,
          created_at:venue.created_at.standard,
          last_login:venue.user[0] ? venue.user[0].last_login.standard : '',
          authToken:venue.authToken
        }
        return data
      })
      state =  {
        ...state,
        loading:false,
        loaded:true,
        data: action.meta ? venues : state.data.concat(venues),
        count:action.payload.data.count
      }
      break
    }
    case 'SEARCH_VENUES_TYPEAHEAD_PENDING':{
      state = {
        ...state,
        typeahead:[],
        typeaheadLoading:true
      }
      break
    }
    case 'SEARCH_VENUES_TYPEAHEAD_REJECTED':{
      state = {
        ...state,
        typeaheadLoading:false,
        error:action.payload
      }
      break
    }
    case 'SEARCH_VENUES_TYPEAHEAD_FULFILLED':{
      state =  {
        ...state,
        typeahead:state.typeahead.concat(action.payload ? action.payload.data : []),
        typeaheadLoading: false
      }
      break
    }
    case 'CLEAR_TYPEAHEAD':{
      state = {...state,typeahead:[]}
      break
    }
    //check player online
    //this is in the business reducer. what the actual fuck Adam?
    /*case 'WS_PLAYER_ONLINE':{
      const venues = state.data.map(venue => {
        const id = parseInt(action.payload.vid)
        if(venue.id === id){
          venue.online = action.payload.online
        }
        return venue
      })
      state = {
        ...state,
        data:venues,
        loading:false,
        loaded:true
      }
      break
    }*/
    case 'CLEAR_BUSINESS_VENUES':{
      state =  {
        ...state,
        businessVenues: [],
        searchedBusinesses: []
      }
      break
    }
    case 'FETCH_VENUES_BY_BUSINESS_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_VENUES_BY_BUSINESS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_VENUES_BY_BUSINESS_FULFILLED':{
      state =  {
        ...state,
        loading:false,
        loaded:true,
        businessVenues: action.payload.data.length > 0 ?
        state.businessVenues.concat({
          name: action.meta.name,
          venues: action.payload.data
        }) : state.businessVenues,
        searchedBusinesses: state.searchedBusinesses.concat({
          id: action.meta.id,
          hasVenues: action.payload.data.length > 0
        })
      }
      break
    }
    case 'FETCH_USER_VENUES_PENDING':
    case 'UPDATE_USER_VENUES_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_USER_VENUES_REJECTED':
    case 'UPDATE_USER_VENUES_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_USER_VENUES_FULFILLED':
    case 'UPDATE_USER_VENUES_FULFILLED':{
      let data = action.payload.data
      state =  {
        ...state,
        loading:false,
        loaded:true,
        businessVenues:data
      }
      break
    }
    default: // no default case
  }
  return state
}
