import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import queryString from 'qs'

import {
  getContacts,
  clearContacts,
  deleteContact,
  searchContacts
} from 'store/actions/contactsAction'

import {
  toggleConfirm
} from 'store/actions/confirmAction'

import {
  clearOverlayContacts
} from 'store/actions/venueAction'

import { getRowLimit } from 'helpers/TableHelper'
import { parseQueryString } from 'helpers/StringHelper'

import { toggleOverlay } from 'store/actions/overlayAction'

import Tabs from 'ui/Tabs'
import Container from 'ui/Container'
import TableWrapper from 'ui/TableWrapper'
import Typeahead from "ui/Typeahead";
import ContactsTableActions from './contacts/ContactsTableActions'
import ContactCreate from './contacts/ContactCreate'
import Confirm from 'ui/Confirm'


const classname = 'contacts'

class ContactsContainer extends Component {

  constructor(props){
    super(props)
    this.state = {
      tab:'all'
    }
  }

  componentDidMount(){
    const {
      dispatch
    } = this.props

    dispatch(
      getContacts(
        getRowLimit(classname),
        0,
        true
        )
    )

    this.getTabQuery()
  }

  componentDidUpdate(prevProps, prevState){
    const {
      contacts,
      dispatch,
      sorter
    } = this.props

    const params = parseQueryString(this.state.tab)

    if (
      this.state.tab === 'create' &&
      prevProps.contacts.length > 0 &&
      prevProps.contacts.length < contacts.length
    ) {
      this.selectTab('all')
    }

    if(prevState.tab !== this.state.tab){
      // this.props.dispatch(clearBusinesses())
      if(typeof params.tab === 'undefined' || params.tab === 'all'){
        // show all businesses
        dispatch(getContacts(
          getRowLimit(classname),
          0,
          true
        ))
      }
    }

    //  handle forward and back browser button usage
    if ((this.props.location !== prevProps.location) && this.props.history.action === 'POP') {
      this.getTabQuery()
    }

    if (JSON.stringify(prevProps.sorter) !== JSON.stringify(sorter)) {
      dispatch(
        getContacts(
          getRowLimit(classname),
          0,
          true,
          sorter
        )
      )
    }
  }

  getTabQuery(){
    const query = queryString.parse(this.props.location.search.slice(1))

    if(typeof query.tab !== 'undefined' && query.tab !== this.state.tab){
      this.setState({tab: query.tab})
    } else if (typeof query.tab === 'undefined') {
      // defines the default tab to loaded when no tab query is passed in the URL
      this.setState({tab: 'all'})
    }
  }

  selectTab(tab){
    this.setState({tab:tab},()=>{
      this.props.history.push({
        search: `?tab=${tab}`
      })
    })
  }

  clearContacts(){
    this.props.dispatch(clearContacts())
  }

  searchContacts(term){
    const {
      data,
      dispatch
    } = this.props

    this.setState({
      searchTerm:term
    },()=>{
      const {
        searchTerm
      } = this.state

      dispatch(clearOverlayContacts())

      dispatch(
        searchContacts(
          getRowLimit(classname),
          0,
          searchTerm,
          true
        )
      )
    })
  }

  toggleContactUpdateOverlay(contact) {
    const {
      dispatch
    } = this.props

    dispatch(
      toggleOverlay(
        true,
        contact,
        'contactUpdate'
      )
    )
  }

  deleteContact(contactID) {
    const {
      dispatch
    } = this.props

    const confirmData = {
      action: () => {
        dispatch(deleteContact(contactID))
      },
      question: `Are you sure you want to delete this contact?`
    }

    dispatch(toggleConfirm(true, confirmData))
  }


  getComponent(){
    const {
      contacts,
      count,
      loading
    } = this.props

    switch(this.state.tab) {
      case 'all':
        return (
          <Container height="100%" classname={`${classname} container-tabview`} column>
            <TableWrapper
              controls
              classname={classname}
              data={contacts}
              count={count}
              clearData={()=>this.clearContacts()}
              getData={(limit,offset,sorter)=>this.props.dispatch(getContacts(limit,offset,sorter))}
              loading={loading}
              rowActions={
                <ContactsTableActions
                  classname={classname}
                  deleteContact={(id)=>this.deleteContact(id)}
                  toggleContactUpdateOverlay={(contact)=>this.toggleContactUpdateOverlay(contact)}
                />
              }
              tableAction={
                <Typeahead
                  typeaheadSearch={(value)=>this.searchContacts(value)}
                  search={(value)=>this.searchContacts(value)}
                  clear={()=>this.props.dispatch(getContacts(getRowLimit(classname),0))}
                  data={contacts}
                  classname={classname}
                  hideDropdown
                />
              }
            />
          </Container>
        )
      case 'create':
        return (
          <Container height="100%" classname={`${classname} container-tabview`} maxHeight column>
            <ContactCreate />
          </Container>
        )
      default: // no default case
    }
  }

  render(){

    return (
      <Container classname={classname} height="100%" column>
        <Container classname="tabs">
          <Tabs
            match={this.props.match}
            select={(tab)=>this.selectTab(tab)}
            active={this.state.tab}
            classname={classname}
            header={this.state.header}
          />
        </Container>
        {this.getComponent()}
        <Confirm />
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    contacts: store.contacts.data,
    count: store.contacts.count,
    error: store.contacts.error,
    loading: store.contacts.loading,
    sorter: store.table.sorter
  }
}

export default withRouter(connect(mapStateToProps)(ContactsContainer))
