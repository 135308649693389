import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toggleEnergySlider, updateVenueEnergy } from 'store/actions/venueAction'
import { openMessageOverlay } from 'store/actions/messageOverlayAction'

class VenueEnergy extends Component{

  constructor(props){
    super(props)
    this.state = {
      mousedown:false,
      position:null,
      style:{
        bottom:'50%',
        marginBottom:'-30px'
      }
    }
  }
  
  componentWillMount(){
    this.initSlider()
  }

  initSlider(){
    let pos = this.props.energy*10
    this.setState({position:pos},()=>{
      this.setViewPosition()
    })
  }

  toggleEnergy = (e) => {
    this.props.dispatch(toggleEnergySlider(e.target.checked))
    
    if(e.target.checked){
      this.props.dispatch(updateVenueEnergy(this.state.position/10))
      //this.props.dispatch(toggleOverlay(e.target.checked,'Energy slider is','ON'))
    }
  }

  sliderMouseUp = (e) => {
    e.preventDefault()
    console.log(this.state.position)
    if(
      this.state.mousedown && 
      this.props.isActive &&
      this.state.position <= 100 && 
      this.state.position >= 10
    ){
      this.setEnergy()
    }
    this.setState({mousedown:false})
  }
  sliderTouchEnd = (e) => {
    if(
      this.state.mousedown && 
      this.props.isActive &&
      this.state.position <= 100 && 
      this.state.position >= 10
    ){
      this.setEnergy()
    }
    this.setState({mousedown:false})
  }

  sliderMouseDown = (e) => {
    e.preventDefault()
    if(
      this.props.isActive && 
      e.nativeEvent.srcElement.id === 'energy-slider-thumb' && 
      !this.state.mousedown
    ){
      this.setState({mousedown:true})
    }  else if(
      this.props.isActive && 
      e.nativeEvent.srcElement.id === 'energy-slider-track-container' && 
      !this.state.mousedown
    ){
      //only move slider on track event
      this.setState({mousedown:true})
      this.sliderSetPos(e);
    }
  }

  sliderTouchStart = (e) => {
    if(
      this.props.isActive && 
      e.nativeEvent.srcElement.id === 'energy-slider-thumb' && 
      !this.state.mousedown
    ){
      this.setState({mousedown:true})
    }
  }

  sliderMouseMove = (e) => {
    e.preventDefault()
    if(
      this.props.isActive && 
      this.state.mousedown && 
      e.nativeEvent.srcElement.id === 'energy-slider-track-container'
    ){
      this.sliderSetPos(e);
    }
  }
  sliderTouchMove = (e) => {
    if(
      this.props.isActive && 
      this.state.mousedown && 
      e.nativeEvent.srcElement.id === 'energy-slider-thumb'
    ){
      this.sliderSetPos(e);
    }
  }

  sliderSetPos = (e) => {
    let clientHeight,offsetY
    //if touch
    if(e.targetTouches){
      clientHeight = e.nativeEvent.srcElement.parentElement.clientHeight
      const rect = this.sliderContainer.getBoundingClientRect()
      offsetY = (rect.y - e.nativeEvent.touches[0].clientY) * -1
    } else {
      clientHeight = e.nativeEvent.srcElement.clientHeight
      offsetY = e.nativeEvent.offsetY
    }
    var pos = Math.round(
      ((clientHeight - offsetY)/clientHeight)
    *100)
    var sliderPos = pos < 10  ? 10 : pos > 100 ? 100 : Math.ceil(pos / 10) * 10;

    this.setState({position:sliderPos})
    this.setViewPosition()
  }

  setEnergy(){
    let energy = this.state.position/10
    setTimeout(()=>{
      this.props.dispatch(updateVenueEnergy(energy))
      this.props.dispatch(openMessageOverlay('info',`Energy set to ${energy}`))
    },1000)
  }

  setViewPosition(){
    if(this.state.position <= 100 && this.state.position >= 10){
      let margin = (this.state.position <= 10 ? '-60px' : '-30px')
      this.setState({
        style:{
          bottom:this.state.position+'%',
          marginBottom:margin
        }
      })
    }
  }

  render(){
    const energyActive = this.props.isActive ? 'active' : null
    return (
      <div id="energy-slider-wrapper" className={energyActive} onMouseUp={this.sliderMouseUp}>
        <h2>Energy slider</h2>
        <input type="checkbox" id="switch" defaultChecked={this.props.isActive} onChange={this.toggleEnergy}/><label htmlFor="switch">Toggle</label>
        <span className="icon high ion-flame"></span>
        <div 
          id="energy-slider-track-container"
          onMouseUp={this.sliderMouseUp}
          onMouseDown={this.sliderMouseDown}
          onMouseMove={this.sliderMouseMove}
          ref={ref => this.sliderContainer = ref}
          >
          <div id="energy-slider-track">
            <div 
              id="energy-slider-thumb"
              style={this.state.style}
              onMouseUp={this.sliderMouseUp}
              onTouchStart={this.sliderTouchStart}
              onTouchMove={this.sliderTouchMove}
              onTouchEnd={this.sliderTouchEnd}>
            </div>
          </div>
        </div>
        <span className="icon low ion-flame"></span>
      </div>
    )
  }
}
function mapStateToProps(store){
  return {
    energy:store.venueWS.energy.level,
    isActive:store.venueWS.energy.isActive
  }
}
export default connect(mapStateToProps)(VenueEnergy)