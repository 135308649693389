import React, {Component} from 'react'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import {
  splitPlaylist,
  copyPlaylist,
  mergePlaylist,
  deletePlaylist,
  playlistTypeaheadAll,
  mergePlaylists
} from 'store/actions/playlistAction'

import {
  toggleOverlay
} from 'store/actions/overlayAction'

import Container from 'ui/Container'
import Button from 'ui/Button'
import Radio from "ui/Radio"
import Loader from "ui/Loader"
import Typeahead from "ui/Typeahead"
import Input from "ui/Input"
import Icon from "ui/Icon"

class OverlayPlaylists extends Component{

  constructor(props){
    super(props)
    this.state = {
      copyPlaylist:{
        display:false,
        name:'',
        copyOptions:{
          type:{
            title:'Choose copy type',
            name:'type',
            options:[
              {label:'Child',value:'clone',defaultChecked:true},
              {label:'Copy',value:'copy'}
            ]
          },
          add:{
            title:'Auto or manual add',
            name:'add',
            options:[
              {label:'Auto',value:'auto',defaultChecked:true},
              {label:'Manual',value:'manual'}
            ]
          },
          remove:{
            title:'Auto or manual delete',
            name:'delete',
            options:[
              {label:'Auto',value:'auto',defaultChecked:true},
              {label:'Manual',value:'manual'}
            ]
          }
        }
      },
      mergePlaylist:{
        display:false,
        playlists:[],
        name:''
      },
      selected:{
        type:'clone',
        add:'auto',
        remove:'auto'
      }
    }
  }

  componentDidMount(){
    //console.log(this.props.data)
    this.setState({
      copyPlaylist:{
        ...this.state.copyPlaylist,
        name:`${this.props.data.name} (COPY)`
      },
      mergePlaylist:{
        ...this.state.mergePlaylist,
        playlists:this.state.mergePlaylist.playlists.concat(this.props.data),
        name:`${this.props.data.name} (MERGE)`
      }
    })
  }

  componentDidUpdate(prevProps,prevState){

    const {
      copiedPlaylistID,
      history,
      dispatch
    } = this.props

    if(copiedPlaylistID && prevProps.copiedPlaylistID !== copiedPlaylistID){
      history.push(`/playlists/${copiedPlaylistID}?tab=settings`)
      dispatch(toggleOverlay(false))
    }
  }

  copyPlaylistDisplay(){
    this.setState({
      copyPlaylist:{
        ...this.state.copyPlaylist,
        display:true
      }
    })
  }

  splitPlaylist(){
    this.props.dispatch(splitPlaylist(this.props.data.id))
  }

  copyPlaylist(){
    this.props.dispatch(copyPlaylist(this.props.data.id,this.state.selected,this.state.copyPlaylist.name))
  }

  mergePlaylist(){
    this.props.dispatch(mergePlaylist(this.props.data.id))
  }

  mergePlaylists(){
    const {
      mergePlaylist
    } = this.state
    this.props.dispatch(mergePlaylists(
      mergePlaylist.name,
      mergePlaylist.playlists
    ))
  }

  deletePlaylist(){
    this.props.dispatch(deletePlaylist(this.props.data.id))
  }

  setCopyOptions(e,type){
    this.setState({
      selected:{
        ...this.state.selected,
        [type]:e.target.value
      }
    })
  }

  getCopyForm(){
    const {
      copyPlaylist,
      selected
    } = this.state

    return (
      <div className="copy-form">
        <Input 
          change={(e)=>this.setState({
            copyPlaylist:{
              ...this.state.copyPlaylist,
              name:e.target.value
            }
          })}
          value={copyPlaylist.name}
          placeholder="Enter playlist name..."/>
        <Radio 
          vertical
          action={(e)=>this.setCopyOptions(e,'type')}
          title={copyPlaylist.copyOptions.type.title}
          name={copyPlaylist.copyOptions.type.name}
          options={copyPlaylist.copyOptions.type.options}/>
          {
            selected.type === 'clone' &&
            <>
              <Radio 
                vertical
                action={(e)=>this.setCopyOptions(e,'add')}
                title={copyPlaylist.copyOptions.add.title}
                name={copyPlaylist.copyOptions.add.name}
                options={copyPlaylist.copyOptions.add.options}/>
              <Radio 
                vertical
                action={(e)=>this.setCopyOptions(e,'remove')}
                title={copyPlaylist.copyOptions.remove.title}
                name={copyPlaylist.copyOptions.remove.name}
                options={copyPlaylist.copyOptions.remove.options}/>
            </>
          }
          <Button action={()=>this.copyPlaylist()} name="Create"/>
      </div>
    )
  }

  typeaheadSearch(term){
    this.props.dispatch(playlistTypeaheadAll(term))
  }

  typeaheadAction(playlist){
    this.setState({
      mergePlaylist:{
        ...this.state.mergePlaylist,
        playlists:this.state.mergePlaylist.playlists.concat(playlist)
      }
    })
  }

  clearTypeahead(){

  }

  removeMergePlaylist(playlist){

    const {
      mergePlaylist
    } = this.state
    this.setState({
      mergePlaylist:{
        ...this.state.mergePlaylist,
        playlists:mergePlaylist.playlists.filter((_playlist)=>_playlist.id !== playlist.id)
      }
    })
  }

  getMergeForm(){
    const {
      mergePlaylist
    } = this.state
    return (
      <div className="merge-form">
        <Typeahead
          typeaheadSearch={(term)=>this.typeaheadSearch(term)}
          typeaheadAction={(data)=>this.typeaheadAction(data)}
          clear={()=>this.clearTypeahead()}
          data={this.props.playlists}
          classname={'overlayPlaylistsMore'}
          disableEnterToSearch/>
        <Input 
          change={(e)=>this.setState({
            mergePlaylist:{
              ...this.state.mergePlaylist,
              name:e.target.value
            }
          })}
          value={mergePlaylist.name}
          placeholder="Enter playlist name..."/>
        {
          mergePlaylist.playlists.map((playlist,index) => {
            return(
              <div key={index} className="playlist-box">
                {playlist.name}<Icon name="close-circle-outline" action={()=>this.removeMergePlaylist(playlist)}/>
              </div>
            )
          })
        }
        <div className="button-wrapper">
          <Button 
            name="Merge playlists"
            action={()=>this.mergePlaylists()}/>
        </div>
      </div>
    )
  }

  getButtons(){
    const {
      data
    } = this.props

    return(
      <>
        {!data.isSplit && <Button action={()=>this.splitPlaylist()} name="Split playlist"/>}
        <Button action={()=>this.copyPlaylistDisplay()} name="Copy playlist"/>
        {data.isSplit && <Button action={()=>this.mergePlaylist()} name="Merge this playlist"/>}
        <Button action={()=>this.setState({
          mergePlaylist:{
            ...this.state.mergePlaylist,
            display:true
          }
        })} name="Merge with other playlists"/>
        {
          !data.fromPlaylist &&
          <Button action={()=>this.deletePlaylist()} name="Delete playlist"/>
        }
      </>
    )
  }

  render(){
    const {
      copyPlaylist,
      mergePlaylist
    } = this.state

    if(this.props.loading){
      return(
        <Container classname="overlay-playlists-more" maxWidth height="100%" column>
          <Loader/>
          <div className="loader-message">Yes, this will take a minute. Go have a cigarette. Or a cup of tea. Or both.</div>
        </Container>
      )
    }
    
    return (
      <Container classname="overlay-playlists-more" maxWidth height="100%" column overflowVisible>
        <h2>{copyPlaylist.display ? 'Copy' :'Playlist Actions for'} {this.props.data.name}</h2>
        {
          copyPlaylist.display ?
          this.getCopyForm() :
          mergePlaylist.display ?
          this.getMergeForm() :
          this.getButtons()
        }
      </Container>
    )
  }
}

function mapStateToProps(store){
  return{
    copiedPlaylistID:store.playlist.copiedPlaylistID,
    loading:store.playlist.playlistMoreLoading,
    playlists:store.playlists.typeahead
  }
}

export default withRouter(connect(mapStateToProps)(OverlayPlaylists))