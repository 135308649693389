const initialState = {
  data:[]
}

export default function reducer(state = initialState, action){
  switch (action.type){
    case 'PLAYLISTS_TYPEAHEAD_ALL_PENDING':{
      state = {
        ...state,
        data:[]
      }
      break
    }
    case 'PLAYLISTS_TYPEAHEAD_ALL_FULFILLED':{
      // if the request has been cancelled by another request,
      // set the data to be empty
      if (action.payload === 'cancelled') {
        state = {...state, data:[]}
        break
      }

      state = {
        ...state,
        data:action.payload.data.playlists
      }
      break
    }
    case 'ADD_PLAYLIST_TO_VENUE_FULFILLED':{
      state = {
        ...state,
        data:[]
      }
      break
    }
    default: // no default case
  }
  return state
}
