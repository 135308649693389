import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { BrowserView, MobileView } from 'react-device-detect'

import packageJson from '../../package.json'

import Logo from 'ui/Logo'
import Icon from 'ui/Icon'
import Container from 'ui/Container'
import Messages from 'components/Messages'

class Nav extends Component{

  constructor(props){
    super(props)
    this.state = {
      menuOpen:false
    }
  }

  render(){
    const {
      menuOpen
    } = this.state
    return (
      <>
        <BrowserView>
          <Container classname="nav">
            <Container classname="nav-left">
              <Logo/>
              <NavLink exact={true} activeClassName="is-active" to="/">Dashboard</NavLink>
              <NavLink activeClassName="is-active" to="/businesses">Businesses</NavLink>
              <NavLink activeClassName="is-active" to="/venues">Venues</NavLink>
              <NavLink activeClassName="is-active" to="/playlists">Playlists</NavLink>
              <NavLink activeClassName="is-active" to="/library">Library</NavLink>
              <NavLink activeClassName="is-active" to="/contacts">Contacts</NavLink>
              <NavLink activeClassName="is-active" to="/classes">Classes</NavLink>
              <NavLink activeClassName="is-active" to="/users">Users</NavLink>
              <NavLink activeClassName="is-active" to="/upload">Upload</NavLink>
            </Container>
            <Container classname="nav-right">
              <Messages/>
              <Icon name="settings"/>
              <Icon name="ios-log-out" action={()=>this.props.logout()}/>
              <div className="version">v{packageJson.version} {process.env.REACT_APP_STAGE} {process.env.REACT_APP_TESTING}</div>
            </Container>
          </Container>
        </BrowserView>
        <MobileView>
          <Container classname="nav-mobile">
            <div 
              onClick={()=>this.setState({
                menuOpen:true
              })}
              className="nav-mobile-logo-wrapper">
              <Logo/>
              <div className="version">v{packageJson.version} {process.env.REACT_APP_STAGE}{process.env.REACT_APP_TESTING}</div>
            </div>
            <div 
              onClick={()=>this.setState({
                menuOpen:false
              })}
              className={`nav-mobile-menu-wrapper ${menuOpen && 'open'}`}>
              <div className="nav-mobile-header">
                <Icon name="close" action={()=>this.setState({
                  menuOpen:false
                })}/>
              </div>  
              <NavLink exact={true} activeClassName="is-active" to="/">Dashboard</NavLink>
              <NavLink activeClassName="is-active" to="/businesses">Businesses</NavLink>
              <NavLink activeClassName="is-active" to="/venues">Venues</NavLink>
              <NavLink activeClassName="is-active" to="/playlists">Playlists</NavLink>
              <NavLink activeClassName="is-active" to="/library">Library</NavLink>
              <NavLink activeClassName="is-active" to="/classes">Classes</NavLink>
              <NavLink activeClassName="is-active" to="/users">Users</NavLink>
              <NavLink activeClassName="is-active" to="/upload">Upload</NavLink>
            </div>
          </Container>
        </MobileView>
      </>
    )
  }
}

export default Nav
