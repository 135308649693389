import React from 'react'
import { CSVLink } from 'react-csv'

const CSVDownload = (props) => {

  const {
    ctaText,
    data,
    fileName,
    useButton
  } = props

  return (
      <CSVLink
        filename={fileName}
        extension='.csv'
        separator=';'
        data={data}
        className='csv-download'
      >
        {useButton ?
          <button
            className='button button__csv-download'
          >
            {ctaText}
          </button> :
          <span>
            {ctaText}
          </span>
        }
      </CSVLink>
  )
}

export default CSVDownload
