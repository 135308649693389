import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { 
  searchPlaylists,
  clearPlaylists,
  pinPlaylist,
  unpinPlaylist,
  playlistTypeaheadAll,
  clearPlaylistTypeahead
} from 'store/actions/playlistAction'

import { openMessageOverlay } from 'store/actions/messageOverlayAction'

import { 
  getRowLimit,
  tableSorter
} from 'helpers/TableHelper'

import PlaylistsTableActions from 'modules/playlists/PlaylistsTableActions'

import Loader from 'ui/Loader'
import Container from 'ui/Container'
import Typeahead from 'ui/Typeahead'
import TableWrapper from 'ui/TableWrapper'

const classname = 'playlistsSearch'

class PlaylistsSearch extends Component {

  constructor(props){
    super(props)
    this.state = {
      searchTerm:null,
      playlists:[]
    }
  }

  componentDidMount(){
    this.props.dispatch(clearPlaylists())
  }

  componentDidUpdate(prevProps) {
    const {
      playlists,
      sorter
    } = this.props

    if (
      (prevProps.playlists !== playlists) ||
      (JSON.stringify(prevProps.sorter) !== JSON.stringify(sorter))
    ){
      this.setPlaylists()
    }
  }

  typeaheadSearch(term){
    this.props.dispatch(playlistTypeaheadAll(term))
  }

  searchPlaylists(term){
    this.props.dispatch(clearPlaylists())
    this.setState({
      searchTerm:term
    },()=>{
      this.props.dispatch(searchPlaylists(this.state.searchTerm,getRowLimit(classname),0))
    })
  }

  getPlaylists(limit,offset){
    this.props.dispatch(searchPlaylists(this.state.searchTerm,limit,offset))
  }

  clearTypeahead(){
    this.props.dispatch(clearPlaylistTypeahead())
  }

  pinPlaylist(data){
    this.props.dispatch(pinPlaylist(data.id))
    this.props.dispatch(openMessageOverlay('info',`Playlist pinned.`))
  }

  unpinPlaylist(data){
    const playlist = this.props.playlists.find(playlist => playlist.id === data.id)
    this.props.dispatch(unpinPlaylist(playlist))
    this.props.dispatch(openMessageOverlay('info',`Playlist unpinned.`))
  }

  clearPlaylists(){
    this.props.dispatch(clearPlaylists())
  }

  sortType(x,y){
    const {field, direction} = this.props.sorter

    // if no direction is provided, due to the sorting icon being reset,
    // or there is no value provided to be sorted on, use default values
    if (direction === null || !(field in x)) {
     return tableSorter(x,y,'name','desc')
    }

    return tableSorter(x,y,field,direction)
  }

  setPlaylists(){
    const {
      playlists
    } = this.props

    //sort tracks by date added to playlist and filter
    const sortedPlaylists = playlists
      .sort((x, y) => this.sortType(x, y))
    //set tracks in state for use in table
    this.setState({
      playlists:sortedPlaylists
    })
  }

  getTable(){
    if(this.props.loading){
      return <Loader/>
    }else if(this.props.playlists.length > 0 ){
      return <TableWrapper
        classname={classname}
        data={this.state.playlists}
        count={this.props.count}
        clearData={()=>this.clearPlaylists()}
        getData={(limit,offset)=>this.getPlaylists(limit,offset)}
        loading={this.props.loading}
        rowActions={<PlaylistsTableActions 
          more={(data)=>this.props.more(data)} 
          pinPlaylist={(data)=>this.pinPlaylist(data)}
          classname={classname}/>
        }
        />
    } else {
      return null
    }
  }

  render(){
    return (
      <Container classname={`${classname}-wrapper`} height="100%" column maxWidth>
        <div className="playlists-search-typeahead-wrapper">
          <Typeahead 
            typeaheadSearch={(term)=>this.typeaheadSearch(term)}
            search={(term)=>this.searchPlaylists(term)}
            clear={()=>this.clearTypeahead()}
            data={this.props.typeahead} 
            classname={classname}/>
        </div>
        <Container height="100%" classname={`${classname}`} column>
          <Container height="100%">
            {this.getTable()}
          </Container>
        </Container>
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    playlists:store.playlists.data,
    typeahead:store.playlists.typeahead,
    sorter: store.table.sorter,
    count:store.playlists.count,
    loading:store.playlists.loading
  }
}

export default withRouter(connect(mapStateToProps)(PlaylistsSearch))