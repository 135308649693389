import React from 'react'

import Icon from 'ui/Icon'

const MessageItem = (props) => {
  return (
    <div className={`message-item ${props.type}`}>
      <Icon name={props.icon}/><span>{props.text}</span>
    </div>
  )
}

export default MessageItem