import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  deleteVenue
} from 'store/actions/venueAction'

import { toggleOverlay } from 'store/actions/overlayAction'

import Container from 'ui/Container'
import Button from 'ui/Button'

class OverlayVenues extends Component{

  copyVenue(){
    const {
      data,
      dispatch
    } = this.props

    const overlayData = {
      venueID: data.id,
      venueName: data.name
    }

    dispatch(toggleOverlay(true, overlayData, 'venueCopy'))
  }

  deleteVenue(){
    this.props.dispatch(deleteVenue(this.props.data.id))
  }

  render(){
    return (
      <Container classname="overlay-venues" maxWidth height="100%" column>
        <h2>Venue Actions for {this.props.data.name}</h2>
        <Button action={()=>this.copyVenue()} name={`Copy venue`}/>
        <Button action={()=>this.deleteVenue()} name={`Delete venue`}/>
      </Container>
    )
  }
}

export default connect()(OverlayVenues)
