import React from 'react'

import Select from 'ui/Select'
import Icon from 'ui/Icon'

const Announcement = (props) => {

  const {
    id,
    day,
    startHrs,
    startMins,
    name,
    order,
    changeTime,
    deleteAnnouncement
  } = props

  //14400 = 4 hours from midnight for 4am
  //divide by 2 for 1px = 2minutes
  const styles = {
    top:order*70,
    left:`${(14.28*day)}%`
  }

  const hrsOptions = [...Array(24).keys()].map(key => {
    const hour = key < 10  ?  `0${key}` : key
    return {value:hour,display:hour}
  })
  const minOptions = [...Array(60).keys()].map(key => {
    const mins = key < 10  ?  `0${key}` : key
    return {value:mins,display:mins}
  })

  const changeHr = (data,id,day) => {
    const hr = data.event.target.value
    const time = [hr,startMins,'00'].join(':')
    changeTime(time,id,day)
  }
  
  const changeMins = (data,id,day) => {
    const mins = data.event.target.value
    const time = [startHrs,mins,'00'].join(':')
    changeTime(time,id,day)
  }

  return (
    <div className="announcement" style={styles}>
      <div className="playlist">{name}</div>
      <div className="controls">
        <Select 
          options={hrsOptions} 
          selected={startHrs}
          action={(data) => changeHr(data,id,day)}/>
        <Select 
          options={minOptions} 
          selected={startMins}
          action={(data) => changeMins(data,id,day)}/>
        <Icon name="trash" action={() => deleteAnnouncement(id)}/>
      </div>
    </div>
  )
}

export default Announcement