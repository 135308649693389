import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  getVenuePlaylists,
  addPlaylistToVenue,
  removePlaylistFromVenue,
  updateVenuePlaylistSettings
} from 'store/actions/venueAction'

import {
  playlistTypeaheadAll,
  clearPlaylistTypeahead
} from 'store/actions/playlistAction'

import { toggleConfirm } from 'store/actions/confirmAction'

import PlaylistTableRowActions from './playlist/PlaylistTableRowActions'

import Container from 'ui/Container'
import TableWrapper from 'ui/TableWrapper'
import Confirm from 'ui/Confirm'
import PlaylistTableActions from './playlist/PlaylistTableActions'

const classname = 'venuePlaylists'

class VenuePlaylists extends Component {
  constructor(props){
    super(props)
    this.state = {
      displayName:null
    }
  }

  componentDidMount(){
    this.props.dispatch(getVenuePlaylists(this.props.id))
  }

  searchPlaylists(term){
    this.props.dispatch(playlistTypeaheadAll(term))
  }

  removePlaylist(playlist){
    const {
      id,
      dispatch,
    } = this.props

    if (playlist.isScheduled !== 'No') {
      const confirmData = {
        action: () => {
          dispatch(removePlaylistFromVenue(id,playlist))
        },
        question: `Playlist is currently scheduled for use by this venue. Delete anyway?`
      }

      dispatch(toggleConfirm(true, confirmData))
    } else {
      dispatch(removePlaylistFromVenue(id,playlist))
    }
  }

  addPlaylist(playlist){
    const {
      id,
      businessID,
      dispatch,
      totalTracks
    } = this.props

    const data = {
      venue_id: id,
      playlist_id: playlist.id,
      business_id: businessID
    }

    if (totalTracks > 1500) {
      const confirmData = {
        action: () => {
          dispatch(addPlaylistToVenue(data,playlist))
        },
        question: `Venue has over 1500 tracks. Add playlist anyway?`
      }

      dispatch(toggleConfirm(true, confirmData))
    } else {
      dispatch(addPlaylistToVenue(data,playlist))
    }
  }

  getTypeaheadData(){

    let results = []
    //filter out playlists that we already have from the typeahead
    if(this.props.typeahead){
      for(let ta of this.props.typeahead){
        let found = this.props.playlists.find(pl => pl.id === ta.id)
        if(!found){
          results.push(ta)
        }
      }
    }
    return results

  }

  changePlaylistActive(playlist){
    this.props.dispatch(updateVenuePlaylistSettings(playlist,this.props.id))
  }

  clearTypeahead(){
    this.props.dispatch(clearPlaylistTypeahead())
  }

  render(){
    return (
      <Container classname={classname} height="100%" maxWidth column>
        <TableWrapper
          classname={classname}
          data={this.props.playlists}
          count={this.props.count}
          clearData={()=>this.clearVenues()}
          getData={(limit,offset)=>this.getVenues(limit,offset)}
          loading={this.props.loading}
          rowActions={<PlaylistTableRowActions
            classname={classname}
            removePlaylist={(playlist)=>this.removePlaylist(playlist)}
            changePlaylistActive={(playlist)=>this.changePlaylistActive(playlist)}
            />
          }
          tableAction={<PlaylistTableActions
            classname={classname}
            typeaheadSearch={(value)=>this.searchPlaylists(value)}
            typeaheadAction={(id)=>this.addPlaylist(id)}
            typeaheadLoading={this.props.typeaheadLoading}
            clear={()=>this.clearTypeahead()}
            data={this.getTypeaheadData()}
            totalTracks={this.props.totalTracks}
            disableEnterToSearch/>
          }
        />
        <Confirm />
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    playlists:store.venue.playlists,
    totalTracks:store.venue.totalTracks,
    typeahead:store.playlists.typeahead,
    typeaheadLoading:store.playlists.typeaheadLoading,
    loading:store.venue.loading,
    businessID:store.venue.details.businessID
  }
}

export default connect(mapStateToProps)(VenuePlaylists)
