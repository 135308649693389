import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import { BrowserRouter as Router} from 'react-router-dom'
import createHistory from 'history/createBrowserHistory'

import './index.scss'
import App from './App'

import store from 'store'
import http from './store/actions'
import reportWebVitals from './reportWebVitals'

const history = createHistory()
http.setup(store,history)
/* jshint expr: true */
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
