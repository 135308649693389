import React, {PureComponent, Fragment} from 'react'
import {connect} from 'react-redux'

import InterleaveCreator from './InterleaveCreator'

import Icon from 'ui/Icon'

class SchedulePlaylistOverlay extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      height: null,
      positioning: {
        top: 'auto',
        right: 'auto',
        bottom: 'auto',
        left: 'auto'
      }
    }

    this.ref = React.createRef()
  }

  componentDidMount() {
    const {
      isInterleave,
      schedule,
      setSelectedInterleaveRatio,
      setSelectedPlaylist
    } = this.props;

    if (isInterleave && schedule.interleave) {
      setSelectedInterleaveRatio(schedule.interleave.ratio)
      setSelectedPlaylist(schedule.interleave.playlist ? schedule.interleave.playlist.name : `Missing playlist`, schedule.interleave.playlist_id)
    }

    this.setState({
      height: this.ref.current.clientHeight
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      schedule,
      selectedPlaylist,
      selectedInterleaveRatio,
      typeahead
    } = this.props

    const {
      height
    } = this.state

    if (
      (!schedule.interleave && (prevProps.selectedPlaylist !== selectedPlaylist)) ||
      (prevProps.selectedInterleaveRatio !== selectedInterleaveRatio) ||
      (prevProps.typeahead !== typeahead)
    ) {
      this.setState({
        height: this.ref.current.clientHeight
      }, () => {
        this.setState({
          height: this.ref.current.clientHeight
        })
      })
    }

    if (prevState.height !== height) {
      this.setState({
        positioning: this.setPositioning()
      })
    }
  }

  deletePlaylist() {
    const {
      isInterleave,
      removePlaylist,
      setActivePlaylistInfo,
      deleteVenueSchedule,
      deleteInterleave,
      schedule
    } = this.props;

    if (isInterleave) {
      deleteInterleave(schedule.interleave.id);
    } else {
      removePlaylist(schedule.i);
      deleteVenueSchedule(schedule.id);
    }

    setActivePlaylistInfo('');
  }

  deleteButton() {
    return(
      <p
        className='schedule-playlist-overlay__delete'
        onClick={() => {
          this.deletePlaylist()
        }}
      >
        <Icon
          name="trash"
        />
        DELETE
      </p>
    )
  }

  getRatios() {
    const {
      interleaveRatios
    } = this.props;

    return interleaveRatios.map(ratio => {
      return (
        <option
          key={ratio}
          value={ratio}
        >
          {ratio}
        </option>
      )
    })
  }

  setPositioning() {
    const {
      schedule,
      scheduleRef
    } = this.props

    const container = scheduleRef
    const element = this.ref.current
    const parent = element.parentElement
    const parentTop = schedule.y * 15
    const parentRight = schedule.x * parent.clientWidth
    const boxBottom = parentTop + element.clientHeight
    const boxRight = parentRight + element.clientWidth
    // check if the element is fully visible at the bottom of the schedule
    const offBottom = boxBottom > container.clientHeight
    // check if the element is visible at the right of the schedule
    const offRight = boxRight > container.clientWidth

    return {
      top: offBottom ? 'auto' : 0,
      right: offRight ?  element.clientWidth - parent.clientWidth + 3 + 'px' : 'auto',
      bottom: offBottom ?  element.clientHeight- parent.clientHeight + 2 + 'px' : 'auto',
      left: offRight ?  'auto' : 0
    }
  }

  render() {
    const {
      days,
      addInterleave,
      fullTimes,
      isInterleave,
      setActivePlaylistInfo,
      setSelectedInterleaveRatio,
      schedule,
      selectedInterleaveRatio
    } = this.props

    const {
      positioning
    } = this.state

    return (
      <div
        className='schedule-playlist-overlay'
        style={positioning}
        onClick={(e) => { e.stopPropagation() }}
        ref={this.ref}
      >
        <div className='schedule-playlist-overlay__icons'>
          <Icon
            name="close"
            action={() => {
              setActivePlaylistInfo('')
            }}
          />
        </div>
        {isInterleave ? (
          <Fragment>
            {!schedule.interleave ? (
              <InterleaveCreator
                schedule={schedule}
                {...this.props}
              />
            ) : (
              <Fragment>
                <p className='schedule-playlist-overlay__playlist'>{schedule.playlistName}</p>
                <p className='schedule-playlist-overlay__secondary'>with</p>
                <p>{schedule.interleave.playlist ? schedule.interleave.playlist.name : `Missing playlist`}</p>
                {schedule.interleave.playlist && (
                  <Fragment>
                    <p>
                      <span className='schedule-playlist-overlay__secondary'>every</span>
                      &nbsp;
                      <select
                        className='interleave-creator__ratio-select'
                        onChange={(event) => {
                          setSelectedInterleaveRatio(event.target.value)
                        }}
                        defaultValue={schedule.interleave.ratio}
                      >
                        {this.getRatios()}
                      </select>
                      &nbsp;
                      <span className='schedule-playlist-overlay__secondary'>tracks</span>
                    </p>
                    <div className='schedule-creator__submit-btn-container'>
                      {selectedInterleaveRatio !== schedule.interleave.ratio && (
                        <button
                          type='button'
                          className='schedule-creator__submit-btn'
                          onClick={() => {
                            addInterleave(schedule.interleave.playlist.name, schedule.id, schedule.i);
                          }}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </Fragment>
                )}
                {this.deleteButton()}
              </Fragment>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <p className='schedule-playlist-overlay__playlist'>
              {schedule.playlistName}
            </p>
            <p>{days[schedule.x].display}</p>
            <p>{`${schedule.h / 2} hours`}</p>
            <p>{fullTimes[schedule.y]} - {fullTimes[schedule.y + schedule.h]}</p>
            {this.deleteButton()}
          </Fragment>
        )}
      </div>
    )}

}

function mapStateToProps(store){
  return {
    typeahead:store.playlists.typeahead
  }
}

export default connect(mapStateToProps)(SchedulePlaylistOverlay)
