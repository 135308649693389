const initialState = {
  businessCountTotal: 0,
  businessCountLastMonth: 0,
  businessCountLastYear: 0,
  businessLatest: {},
  businessOnTrial: [],
  venueCountTotal: 0,
  venueCountLastMonth: 0,
  venueCountLastYear: 0,
  venueLatest: {},
  playlistCount: 0,
  playlistLatest: [],
  playlistsToApprove: [],
  trackCount: 0,
  trackLatest: [],
  trackCountLastMonth: 0,
  trackGenresLastMonth: [],
  genreCount: 0,
  classes: 0,
  curatorLatest: [],
  venuesLoading: false,
  businessLoading: false,
  musicLoading: false,
  classesLoading: false,
  musicPlaylistsForApprovalLoading: false,
  trackGenresLastMonthLoading: false,
  error:null
}
export default function reducer(state = initialState, action){
  switch (action.type){
    case 'FETCH_DASHBOARD_VENUES_PENDING':{
      state = {
        ...state,
        venuesLoading: true,
        businessLoading: true,
        musicLoading: true,
        classesLoading: true,
        musicPlaylistsForApprovalLoading: true,
        trackGenresLastMonthLoading: true,
        tracks: []
      }
      break
    }

    case 'FETCH_DASHBOARD_VENUES_REJECTED':{
      state = {
        ...state,
        venuesLoading: false,
        error: action.payload
      }
      break
    }

    case 'FETCH_DASHBOARD_VENUES_FULFILLED':{
      const data = action.payload.data
      state =  {
        ...state,
        venuesLoading: false,
        venueCountTotal: data.venueCountTotal,
        venueCountLastMonth: data.venueCountLastMonth,
        venueCountLastYear: data.venueCountLastYear,
        venueLatest: data.venueLatest
      }
      break
    }

    case 'FETCH_DASHBOARD_BUSINESSES_REJECTED':{
      state = {
        ...state,
        businessesLoading: false,
        error: action.payload
      }
      break
    }

    case 'FETCH_DASHBOARD_BUSINESSES_FULFILLED':{
      const data = action.payload.data
      state =  {
        ...state,
        businessesLoading: false,
        businessCountTotal: data.businessCountTotal,
        businessCountLastMonth: data.businessCountLastMonth,
        businessCountLastYear: data.businessCountLastYear,
        businessLatest: data.businessLatest,
        businessOnTrial: data.businessOnTrial
      }
      break
    }

    case 'FETCH_DASHBOARD_MUSIC_REJECTED':{
      state = {
        ...state,
        musicLoading: false,
        error: action.payload
      }
      break
    }

    case 'FETCH_DASHBOARD_MUSIC_FULFILLED':{
      const data = action.payload.data
      state =  {
        ...state,
        musicLoading: false,
        genreCount: data.genreCount,
        playlistCount: data.playlistCount,
        playlistLatest: data.playlistLatest,
        trackCount: data.trackCount,
        trackLatest: data.trackLatest,
        trackCountLastMonth: data.trackCountLastMonth
      }
      break
    }

    case 'FETCH_DASHBOARD_CLASSES_REJECTED':{
      state = {
        ...state,
        classesLoading: false,
        error: action.payload
      }
      break
    }

    case 'FETCH_DASHBOARD_CLASSES_FULFILLED':{
      const data = action.payload.data
      state =  {
        ...state,
        classesLoading: false,
        classes: data.classes,
        curatorLatest: data.curatorLatest
      }
      break
    }

    case 'FETCH_DASHBOARD_MUSIC_PLAYLISTS_FOR_APPROVAL_REJECTED':{
      state = {
        ...state,
        musicPlaylistsForApprovalLoading: false,
        error: action.payload
      }
      break
    }

    case 'FETCH_DASHBOARD_MUSIC_PLAYLISTS_FOR_APPROVAL_FULFILLED':{
      const data = action.payload.data
      state =  {
        ...state,
        musicPlaylistsForApprovalLoading: false,
        playlistsToApprove: data.playlistsToApprove
      }
      break
    }

    case 'FETCH_DASHBOARD_MUSIC_TRACK_GENRES_LAST_MONTH_REJECTED':{
      state = {
        ...state,
        trackGenresLastMonthLoading: false,
        error: action.payload
      }
      break
    }

    case 'FETCH_DASHBOARD_MUSIC_TRACK_GENRES_LAST_MONTH_FULFILLED':{
      const data = action.payload.data
      state =  {
        ...state,
        trackGenresLastMonthLoading: false,
        trackGenresLastMonth: data.trackGenresLastMonth
      }
      break
    }

    case 'LOGOUT':{
      state = {
        businessCountTotal: 0,
        businessCountLastMonth: 0,
        businessCountLastYear: 0,
        businessLatest: {},
        businessOnTrial: [],
        venueCountTotal: 0,
        venueCountLastMonth: 0,
        venueCountLastYear: 0,
        venueLatest: {},
        playlistCount: 0,
        playlistLatest: [],
        playlistsToApprove: [],
        trackCount: 0,
        trackLatest: [],
        trackCountLastMonth: 0,
        trackGenresLastMonth: [],
        genreCount: 0,
        classes: 0,
        curatorLatest: [],
        venuesLoading: false,
        businessLoading: false,
        musicLoading: false,
        classesLoading: false,
        musicPlaylistsForApprovalLoading: false,
        trackGenresLastMonthLoading: false,
        error: null
      }
      break
    }
    default: // no default case
  }
  return state
}
