export function closeMessageOverlay(){
  return {
    type:'CLOSE_MESSAGE_OVERLAY'
  }
}
export function openMessageOverlay(type,message){
  return {
    type:'OPEN_MESSAGE_OVERLAY',
    payload:{message:message,type:type}
  }
}