import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  deleteInterleavesFromBusiness
} from 'store/actions/businessAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Button from 'ui/Button'

const classname = 'businessActions'

class BusinessActions extends Component {
  deleteInterleavesFromBusiness() {
    const {
      id,
      dispatch
    } = this.props

    const confirm = window.confirm('Are you sure you want to delete all interleaves this business?')

    if(confirm){
      dispatch(deleteInterleavesFromBusiness(id))
    }
  }

  render(){
    if(this.props.loading){
      return <Loader/>
    } else {
      return (
        <Container classname={classname} height="100%" maxWidth column>
          <Button
            action={()=>this.deleteInterleavesFromBusiness()}
            name='DELETE ALL INTERLEAVES FROM THIS BUSINESS'
          />
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    loading:store.business.loading
  }
}

export default connect(mapStateToProps)(BusinessActions)
