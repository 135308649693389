import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux'

import {indexOfArrayObject} from 'helpers/ArrayHelper'
import { updateTrackGenres } from 'store/actions/libraryAction'

import Icon from 'ui/Icon'
import Select from 'ui/Select'

class TableCellGenres extends Component {

  constructor(props){
    super(props)
    this.state = {
      isClicked:false,
      trackGenres:[],
      allGenres:[]
    }

    this.ref = React.createRef()
  }

  componentDidMount(){
    this.setState({
      allGenres:this.props.genres,
      trackGenres:this.props.track.genres
    },()=>{
      this.setGenres()
    })
  }

  componentDidUpdate(prevProps){
    if(prevProps !== this.props){
      this.setState({
        allGenres:this.props.genres,
        trackGenres:this.props.track.genres
      },()=>{
        this.setGenres()
      })
    }
  }

  setGenres(){
    const {
      allGenres,
      trackGenres
    } = this.state
    const filteredGenres = allGenres
      .filter((elem)=> {
        if(indexOfArrayObject(trackGenres,elem,'id') === -1){
          return elem
        }
        return false
      })
    this.setState({
      allGenres:filteredGenres
    })
  }

  getGenresSelect(){
    return this.state.allGenres
      .map(genre => {
        return {
          value:genre.id,
          display:genre.genre
        }
      })
  }

  onClick(e,isClicked){
    if(e.metaKey || e.shiftKey){
      return
    }
    this.setState({
      isClicked:isClicked
    })
  }

  addGenre(data){
    const {
      allGenres,
      trackGenres
    } = this.state
    const genre_id = parseInt(data.event.target.value)
    const genre = allGenres.find(elem => elem.id === genre_id)

    this.setState({
      allGenres:allGenres.filter(allGenre => allGenre.id !== genre_id),
      trackGenres:trackGenres.concat(genre).sort((a,b) => {
        if(a.genre > b.genre){
          return 1
        }
        if(a.genre < b.genre){
          return -1
        }
        return 0
      })
    },()=>{
      this.props.dispatch(updateTrackGenres(data.data.id,this.state.trackGenres))
      this.ref.current[0].selected = true
    })
  }

  removeGenre(genre){
    this.setState({
      trackGenres:this.state.trackGenres.filter(tkGenre => tkGenre.id !== genre.id),
      allGenres:this.state.allGenres.concat(genre).sort((a,b) => {
        if(a.genre > b.genre){
          return 1
        }
        if(a.genre < b.genre){
          return -1
        }
        return 0
      })
    },()=>{
      this.props.dispatch(updateTrackGenres(this.props.track.id,this.state.trackGenres))
    })
  }

  renderTrackGenres(edit){
    return this.state.trackGenres
      .map(
        (genre,index) =>
          <span
            key={index}
            className="genre-wrapper">
              {genre.genre}{edit && <Icon name="close-circle" action={()=>this.removeGenre(genre)}/>}
              {!edit && ', '}
          </span>
        )
  }

  render(){
    const {
      classname,
      size,
      track
    } = this.props

    const {
      isClicked
    } = this.state

    return (
      <td
        className={`table-cell table-cell-genres table-cell-${classname} table-cell-size-${isClicked ? 'large' :size} ${isClicked && 'table-cell-genres-edit'}`}
        onClick={(e)=>this.onClick(e,true)}>
        {
          isClicked ?
          <Fragment>
            {this.renderTrackGenres(true)}
            <div className="select-wrapper">
              <Select
                data={track}
                defaultOption="select genre"
                action={(data)=>this.addGenre(data)}
                options={this.getGenresSelect()}
                ref={this.ref}
              />
              <Icon name="close-circle" action={()=>this.onClick(false)} stopPropagation/>
            </div>
          </Fragment> :
          this.renderTrackGenres()
        }
      </td>
    )
  }
}

function mapStateToProps(store){
  return{
    genres:store.library.genres
  }
}

export default connect(mapStateToProps)(TableCellGenres)
