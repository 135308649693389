import React from 'react'

import LibraryTypeahead from './LibraryTypeahead'
import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'
import Badge from 'ui/Badge'

const LibraryTableAction = (props) => {
  const {
    classname,
    search,
    typeaheadAction,
    typeaheadLoading,
    disableTypeahead,
    searchData,
    placeholder,
    showFilter,
    clickTypeahead,
    active,
    numSelected,
    editTracks,
    addToQueue,
    addTracksToPlaylist,
    addTracksToMultiPlaylists,
    clearTypeahead,
    filterLength,
    deselectTracks,
    isLibrary,
    toggleSearch,
    advancedSearch
  } = props

  return (
    <div className={`table-actions table-actions-${classname}`}>
      <div className="table-actions-left">
        <Icon name="funnel" action={()=>showFilter()}>
          <Tooltip pos="right" text="Show filter"/>
        </Icon>
        <Icon name="checkmark-circle" classname={advancedSearch && 'active'} action={()=>toggleSearch()}>
          <Tooltip pos="right" text={advancedSearch ? 'Advanced search off' : 'Advanced search on'}/>
        </Icon>
        <Badge data={filterLength}/>
        <LibraryTypeahead
          classname={classname}
          placeholder={placeholder}
          search={(e)=>search(e)}
          typeaheadAction={(e)=>typeaheadAction(e)}
          typeaheadLoading={typeaheadLoading}
          showTypeahead={!disableTypeahead}
          data={searchData}
          clickTypeahead={(type,term)=>clickTypeahead(type,term)}
          clearTypeahead={()=>clearTypeahead()}
        />
      </div>
      <div className={`table-actions-right ${active ? 'active' : ''}`}>
        {
          numSelected > 0 &&
          <div className="selected-indicator">{numSelected} selected</div>
        }
        <Icon name="close-circle-outline" action={()=>deselectTracks()}>
          <Tooltip text="Deselect tracks" pos="left"/>
        </Icon>
        <Icon name="ios-create" action={()=>editTracks()}>
          <Tooltip text="Edit tracks" pos="left"/>
        </Icon>
        <Icon name="ios-list-box" action={()=>addToQueue()}/>
        {isLibrary || (
          <Icon name="add" action={()=>addTracksToPlaylist()}>
            <Tooltip pos="left" text="Add tracks to playlist"/>
          </Icon>
        )}
        <Icon name="add-circle" action={()=>addTracksToMultiPlaylists()}>
          <Tooltip
            pos="left"
            text={isLibrary ? `Add tracks to playlists` : `Add tracks to other playlists`}
          />
        </Icon>
      </div>
    </div>
  )
}

export default LibraryTableAction
