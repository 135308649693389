import React from 'react'

import Typeahead from 'ui/Typeahead'
import FormLabel from 'ui/FormLabel'

const FormTypeahead = (props) => {
  const {
    classname,
    hasError,
    label,
    errorMessage
  } = props

  return (
    <div className='form-typeahead-wrapper'>
      {label && (
        <FormLabel label={label} />
      )}
      <div className={`form-typeahead form-typeahead-${classname} ${hasError ? 'has-error' : ''}`}>
        <Typeahead {...props} />
      </div>
      {hasError && errorMessage && (
        <p className='form-typeahead__error-message'>{errorMessage}</p>
      )}
    </div>
  )
}

export default FormTypeahead
