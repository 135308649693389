import React from 'react'

const MessageOverlayInfo = (props) => {
  return(
    <div>
      <div className="swal2-icon swal2-success swal2-animate-success-icon" style={{display: 'flex'}}>
        <span className="swal2-success-line-tip"></span>
        <span className="swal2-success-line-long"></span>
        <div className="swal2-success-ring"></div> 
      </div>
      <div className="message">{props.message}</div>
    </div>
  )
}
export default MessageOverlayInfo