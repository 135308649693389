import React, {Component} from 'react'
import { connect } from 'react-redux'

import { getUserDetails, updateUserDetails } from 'store/actions/userAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Form from 'ui/Form'

const classname = 'userUser'

class UserUser extends Component {
  constructor(props){
    super(props)
    this.state = {
      displayName:null
    }
    this.userTypes = [
      {display:'Manager',value:9},
      {display:'Curator',value:10},
    ]
  }

  componentDidMount(){
    this.props.dispatch(getUserDetails(this.props.id))
  }

  componentDidUpdate(prevProps){
    const {
      setHeader,
      user
    } = this.props

    if(user !== prevProps.user){
      setHeader(`${user.name}`)
    }
  }

  onSubmit(form){
    this.props.dispatch(updateUserDetails(
      this.props.id,
      form
    ))
  }

  render(){
    const {
      error,
      loading,
      user,
    } = this.props

    if(loading){
      return <Loader/>
    } else {
      return (
        <Container classname={classname} height="100%" maxWidth>
          <Form
            classname={classname}
            submit={(form)=>this.onSubmit(form)}
            messages={this.state.message}
            data={user}
            userTypes={this.userTypes}
            serverError={error && error.response.data.res}
            scroll
          />
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    user:store.user.details,
    loading:store.user.loading,
    error:store.user.error,
  }
}

export default connect(mapStateToProps)(UserUser)
