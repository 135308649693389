import React from 'react'
import Loader from "./Loader"

const Radio = (props) => {
  const {
    action,
    classname,
    name,
    options,
    title,
    vertical,
    loading
  } = props

  const radioClasses = `radio ${classname} radio--${vertical ? 'vertical' : 'horizontal'}`

  return (
    <div
      className={radioClasses}
      onChange={(e)=>action(e)}
    >
      {title && (
        <h4>{title} {loading && <Loader/>}</h4>
      )}
      {options.map((option,index) => {
        if(!option.hidden) {
          return (
            <label
              key={index}
              htmlFor={option.value + name}
            >
              <input
                type='radio'
                value={option.value}
                id={option.value + name}
                name={name}
                defaultChecked={option.defaultChecked}
              />
              <span>{option.label}</span>
            </label>
          )
        }
        return false
      })}
    </div>
  )
}

export default Radio
