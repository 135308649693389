import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  getBusinessPlaylists,
  addPlaylistToBusiness,
  removePlaylistFromBusiness
} from 'store/actions/businessAction'
import {
  playlistTypeaheadAll,
  clearPlaylistTypeahead
} from 'store/actions/playlistAction'

import PlaylistTableRowActions from './subscribed/PlaylistTableRowActions'

import Container from 'ui/Container'
import TableWrapper from 'ui/TableWrapper'
import PlaylistTableActions from './subscribed/PlaylistTableActions'

const classname = 'businessSubscribed'

class BusinessSubscribed extends Component {
  constructor(props){
    super(props)
    this.state = {
      displayName:null
    }
  }

  componentDidMount(){
    this.props.dispatch(getBusinessPlaylists(this.props.id))
  }

  searchPlaylists(term){
    this.props.dispatch(playlistTypeaheadAll(term))
  }

  removePlaylist(playlist){
    const confirm = window.confirm('This will remove the playlist from all venues in this business, are you sure you want to unsubscribe?')

    if(confirm){
      this.props.dispatch(removePlaylistFromBusiness(this.props.id,playlist))
    }
  }

  addPlaylist(playlist){
    const data = {
      playlist_id:playlist.id,
      business_id:this.props.id
    }
    this.props.dispatch(addPlaylistToBusiness(data,playlist))
  }

  getTypeaheadData(){

    let results = []
    //filter out playlists that we already have from the typeahead
    if(this.props.typeahead){
      for(let ta of this.props.typeahead){
        let found = this.props.playlists.find(pl => pl.id === ta.id)
        if(!found){
          results.push(ta)
        }
      }
    }
    return results

  }

  clearTypeahead(){
    this.props.dispatch(clearPlaylistTypeahead())
  }

  render(){
    return (
      <Container classname={classname} height="100%" maxWidth scroll column>
        <TableWrapper
          classname={classname}
          data={this.props.playlists}
          count={this.props.count}
          loading={this.props.loading}
          rowActions={<PlaylistTableRowActions
            classname={classname}
            removePlaylist={(playlist)=>this.removePlaylist(playlist)}
            />
          }
          tableAction={<PlaylistTableActions
            classname={classname}
            typeaheadSearch={(value)=>this.searchPlaylists(value)}
            typeaheadAction={(id)=>this.addPlaylist(id)}
            typeaheadLoading={this.props.typeaheadLoading}
            clear={()=>this.clearTypeahead()}
            data={this.getTypeaheadData()}
            totalTracks={this.props.totalTracks}/>
          }
        />
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    playlists:store.business.playlists,
    totalTracks:store.business.totalTracks,
    typeahead:store.playlists.typeahead,
    typeaheadLoading:store.playlists.typeaheadLoading,
    loading:store.business.loading,
  }
}

export default connect(mapStateToProps)(BusinessSubscribed)
