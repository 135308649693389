import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  addContactToBusiness,
  clearOverlayContacts
} from 'store/actions/businessAction'

import {
  searchContacts
} from 'store/actions/contactsAction'

import { getRowLimit } from 'helpers/TableHelper'

import OverlayContactsAddToBusinessTableRowActions from './contacts/OverlayContactsAddToBusinessTableRowActions'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import TableWrapper from 'ui/TableWrapper'
import Typeahead from 'ui/Typeahead'
import Confirm from 'ui/Confirm'

const classname = 'overlayContactsAddToBusiness'

class OverlayContactsAddToBusiness extends Component{

  constructor(props){
    super(props)

    this.state = {
      searchTerm:null
    }
  }

  componentDidMount(){
    const {
      dispatch
    } = this.props

    dispatch(clearOverlayContacts())
  }

  getContacts(limit,offset){
    const {
      data,
      dispatch
    } = this.props

    const {
      searchTerm
    } = this.state

    dispatch(
      searchContacts(
        limit,
        offset,
        searchTerm,
        data.playlist_id
      )
    )
  }

  searchContacts(term){
    const {
      data,
      dispatch
    } = this.props

    this.setState({
      searchTerm:term
    },()=>{
      const {
        searchTerm
      } = this.state

      dispatch(clearOverlayContacts())

      dispatch(
        searchContacts(
          getRowLimit(classname),
          0,
          searchTerm,
          data.business_id,
          true
        )
      )
    })
  }

  clearTypeahead(){
    this.props.dispatch(clearOverlayContacts())
  }

  addContactToBusiness(contact){
    const {
      data,
      dispatch
    } = this.props

    dispatch(addContactToBusiness(data.businessID, contact.id))
  }

  render(){
    const {
      addContactToBusinessLoading,
      addContactToBusinessOverlayLoading,
      count,
      overlayContacts,
      data
    } = this.props

    return (
      <Container height="100%" classname={classname} column>
        <Typeahead
          typeaheadSearch={(value)=>this.searchContacts(value)}
          clear={()=>this.clearTypeahead()}
          data={overlayContacts}
          classname={classname}
          hideDropdown
          disableEnterToSearch
        />
        <TableWrapper
          controls
          classname={classname}
          data={overlayContacts}
          count={count}
          getData={()=>{return null}}
          loading={addContactToBusinessOverlayLoading}
          rowActions={
            <OverlayContactsAddToBusinessTableRowActions
              addContactToBusiness={(contact)=>this.addContactToBusiness(contact)}
              addContactToBusinessLoading={addContactToBusinessLoading}
              classname={classname}
              businessID={data.businessID}
            />
          }
        />
        {addContactToBusinessOverlayLoading && (
          <div className='overlay-playlist-add-to-venues__updating'>
            <Loader />
            Updating
          </div>
        )}
        <Confirm />
      </Container>
    )
  }
}
function mapStateToProps(store){
  return {
    addContactToBusinessLoading: store.business.addContactToBusinessLoading,
    addContactToBusinessOverlayLoading: store.business.addContactToBusinessOverlayLoading,
    overlayContacts: store.business.overlayContacts,
    error: store.business.error
  }
}
export default connect(mapStateToProps)(OverlayContactsAddToBusiness)
