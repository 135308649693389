import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

import {
  getDashboard
} from 'store/actions/dashboardAction'

import {
  getTrackInfo,
  getTrackUrl
} from 'store/actions/playerAction'

import Container from 'ui/Container'
import Icon from 'ui/Icon'
import LoaderFixedHeight from 'ui/LoaderFixedHeight'

import ReportsRow from 'components/reports/ReportsRow'
import ReportsUnit from 'components/reports/ReportsUnit'
import ReportsData from 'components/reports/ReportsData'
import ReportsPieChart from 'components/reports/ReportsPieChart'

const classname = 'dashboard'

class DashboardContainer extends Component {
  componentDidMount(){
    this.props.dispatch(getDashboard())
  }

  playTrack(track){
    const {
      dispatch
    } = this.props

    dispatch(getTrackInfo(track))
    dispatch(getTrackUrl(track))
  }

  render(){
    const {
      businessCountLastMonth,
      businessCountLastYear,
      businessCountTotal,
      businessOnTrial,
      businessLatest,
      venueCountLastMonth,
      venueCountLastYear,
      venueCountTotal,
      playlistCount,
      playlistLatest,
      playlistsToApprove,
      player,
      trackCount,
      trackLatest,
      trackCountLastMonth,
      trackGenresLastMonth,
      genreCount,
      curatorLatest,
      classes,
      venueLatest,
      venuesLoading,
      businessesLoading,
      musicLoading,
      classesLoading,
      musicPlaylistsForApprovalLoading,
      trackGenresLastMonthLoading
    } = this.props

    return (
      <Container classname={`${classname}-wrapper`} height="100%" column maxWidth scroll>
        <div className='dashboard'>
          <h3>Venues</h3>
          {venuesLoading ? (
            <LoaderFixedHeight
              height={135}
            />
          ) : (
            <ReportsRow>
              <ReportsUnit
                header='Last month'
                width='25'
              >
                <ReportsData>
                  {venueCountLastMonth}
                </ReportsData>
              </ReportsUnit>
              <ReportsUnit
                header='Last year'
                width='25'
              >
                <ReportsData>
                  {venueCountLastYear}
                </ReportsData>
              </ReportsUnit>
              <ReportsUnit
                header='Total'
                width='25'
              >
                <ReportsData>
                  {venueCountTotal}
                </ReportsData>
              </ReportsUnit>
              <ReportsUnit
                header='Latest venue'
                width='25'
              >
                <ReportsData>
                  <p>
                    <Link to={`/venues/${venueLatest.id}`}>{venueLatest.name}</Link>
                    <span className='reports-data__secondary'>{venueLatest.town}</span>
                  </p>
                </ReportsData>
              </ReportsUnit>
            </ReportsRow>
          )}
          <h3>Music</h3>
          {musicLoading ? (
            <LoaderFixedHeight
              height={750}
            />
          ) : (
            <Fragment>
              <ReportsRow>
                <ReportsUnit
                  header='Tracks'
                  width='25'
                >
                  <ReportsData>
                    {trackCount}
                  </ReportsData>
                </ReportsUnit>
                <ReportsUnit
                  header='Tracks uploaded last month'
                  width='25'
                >
                  <ReportsData>
                    {trackCountLastMonth}
                  </ReportsData>
                </ReportsUnit>
                <ReportsUnit
                  header='Playlists'
                  width='25'
                >
                  <ReportsData>
                    {playlistCount}
                  </ReportsData>
                </ReportsUnit>
                <ReportsUnit
                  header='Genres'
                  width='25'
                >
                  <ReportsData>
                    {genreCount}
                  </ReportsData>
                </ReportsUnit>
              </ReportsRow>
              <ReportsRow>
                <ReportsUnit
                  header='Latest tracks'
                  width='33'
                >
                  <ReportsData>
                    <ul>
                      {trackLatest.map((track, index) => {
                        const isPlaying = player.track && track.id === player.track.id && player.track.isPlaying ? 'isPlaying' : null
                        return (
                          <li
                            key={index}
                            onClick={()=>{this.playTrack(track)}}
                            className='reports-data__list-item--has-icon'
                          >
                            <Icon classname="play" name={`ios-${isPlaying ? 'volume-high' : 'play'}`} />
                            <span>{track.artist} - {track.title}</span>
                          </li>
                        )
                      })}
                    </ul>
                  </ReportsData>
                </ReportsUnit>
                <ReportsUnit
                  header='Tracks uploaded last month by genre'
                  width='33'
                >
                  {trackGenresLastMonthLoading ? (
                    <LoaderFixedHeight
                      height={350}
                    />
                  ) : (
                    <ReportsPieChart
                      data={trackGenresLastMonth}
                    />
                  )}
                </ReportsUnit>
                <ReportsUnit
                  header='Latest playlists'
                  width='33'
                >
                  <ReportsData>
                    <ul>
                      {playlistLatest.map((playlist, index) => {
                        return (
                          <li key={index}>
                            <Link to={`/playlists/${playlist.id}`}>{playlist.name}</Link>
                            <p className='reports-data__secondary'>{playlist.owner ? playlist.owner.email : 'NO OWNER DATA'}</p>
                          </li>
                        )
                      })}
                    </ul>
                  </ReportsData>
                </ReportsUnit>
              </ReportsRow>
              <ReportsRow>
                <ReportsUnit
                  header={`Playlists with changes for approval (${playlistsToApprove.length})`}
                  width='33'
                >
                  {musicPlaylistsForApprovalLoading ? (
                    <LoaderFixedHeight
                      height={350}
                    />
                  ) : (
                    <ReportsData>
                      <ul>
                        {playlistsToApprove.map((playlist, index) => {
                          return (
                            <li key={index}>
                              <Link to={`/playlists/${playlist.id}?tab=changes_to_approve`}>{playlist.name}</Link>
                              &nbsp;&ndash;&nbsp;({playlist.tracks_pending_count})
                            </li>
                          )
                        })}
                      </ul>
                    </ReportsData>
                  )}
                </ReportsUnit>
              </ReportsRow>
            </Fragment>
          )}
          <h3>Businesses</h3>
          {businessesLoading ? (
            <LoaderFixedHeight
              height={420}
            />
          ) : (
            <Fragment>
              <ReportsRow>
                <ReportsUnit
                  header='Last month'
                  width='25'
                >
                  <ReportsData>
                    {businessCountLastMonth}
                  </ReportsData>
                </ReportsUnit>
                <ReportsUnit
                  header='Last year'
                  width='25'
                >
                  <ReportsData>
                    {businessCountLastYear}
                  </ReportsData>
                </ReportsUnit>
                <ReportsUnit
                  header='Total'
                  width='25'
                >
                  <ReportsData>
                    {businessCountTotal}
                  </ReportsData>
                </ReportsUnit>
              </ReportsRow>
              <ReportsRow>
                <ReportsUnit
                  header='Latest trials'
                  width='25'
                >
                  <ReportsData>
                    <ul>
                      {businessOnTrial.map((business, index) => {
                        return (
                          <li key={index}>
                            <Link to={`/businesses/${business.id}`}>{business.name}</Link>
                          </li>
                        )
                      })}
                    </ul>
                  </ReportsData>
                </ReportsUnit>
                <ReportsUnit
                  header='Latest sign up'
                  width='25'
                >
                  <ReportsData>
                    <p><Link to={`/businesses/${businessLatest.id}`}>{businessLatest.name}</Link></p>
                  </ReportsData>
                </ReportsUnit>
              </ReportsRow>
            </Fragment>
          )}
          <h3>Classes</h3>
          {classesLoading ? (
            <LoaderFixedHeight
              height={290}
            />
          ) : (
            <ReportsRow>
              <ReportsUnit
                header='Latest curators'
                width='25'
              >
                <ReportsData>
                  <ul>
                    {curatorLatest.map((curator, index) => {
                      return (
                        <li key={index}>
                          <Link to={`/users/${curator.id}/?tab=user`}>{curator.username}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </ReportsData>
              </ReportsUnit>
              <ReportsUnit
                header='Classes'
                width='25'
              >
                <ReportsData>
                  {classes}
                </ReportsData>
              </ReportsUnit>
            </ReportsRow>
          )}
        </div>
      </Container>
    )
  }
}
function mapStateToProps(store,myProps){
  return {
    businessCountLastMonth: store.dashboard.businessCountLastMonth,
    businessCountLastYear: store.dashboard.businessCountLastYear,
    businessCountTotal: store.dashboard.businessCountTotal,
    businessLatest: store.dashboard.businessLatest,
    businessOnTrial: store.dashboard.businessOnTrial,
    venueCountLastMonth: store.dashboard.venueCountLastMonth,
    venueCountLastYear: store.dashboard.venueCountLastYear,
    venueCountTotal: store.dashboard.venueCountTotal,
    venueLatest: store.dashboard.venueLatest,
    playlistCount: store.dashboard.playlistCount,
    playlistLatest: store.dashboard.playlistLatest,
    playlistsToApprove: store.dashboard.playlistsToApprove,
    trackCount: store.dashboard.trackCount,
    trackLatest: store.dashboard.trackLatest,
    trackCountLastMonth: store.dashboard.trackCountLastMonth,
    trackGenresLastMonth: store.dashboard.trackGenresLastMonth,
    genreCount: store.dashboard.genreCount,
    curatorLatest: store.dashboard.curatorLatest,
    classes: store.dashboard.classes,
    player: store.player,
    venuesLoading: store.dashboard.venuesLoading,
    businessesLoading: store.dashboard.businessesLoading,
    musicLoading: store.dashboard.musicLoading,
    classesLoading: store.dashboard.classesLoading,
    musicPlaylistsForApprovalLoading: store.dashboard.musicPlaylistsForApprovalLoading,
    trackGenresLastMonthLoading: store.dashboard.trackGenresLastMonthLoading
  }
}

export default withRouter(connect(mapStateToProps)(DashboardContainer))
