import React from 'react'

import Icon from 'ui/Icon'

const ContactsTableActions = (props) => {
  const {
    deleteContact,
    toggleContactUpdateOverlay,
    contact
  } = props

  return (
    <div>
      <Icon
        name='create'
        action={()=>toggleContactUpdateOverlay(contact)}
      />
      <Icon
        name='trash'
        action={()=>deleteContact(contact.id)}
      />
    </div>
  )
}

export default ContactsTableActions
