import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux'

import {
  playlistTypeaheadAll,
  clearPlaylistTypeahead
} from 'store/actions/playlistAction'

import Container from 'ui/Container'
import Icon from 'ui/Icon'
import Loader from 'ui/Loader'
import Typeahead from 'ui/Typeahead'

const classname = 'overlayFitnessTimeline'

class OverlayFitnessTimeline extends Component {

  constructor(props){
    super(props);

    this.state = {
      duration: 100,
      selectedPlaylist: null
    };

    this.editDuration = this.editDuration.bind(this)
  }

  componentDidMount() {
    const {
      data
    } = this.props

    this.setState({
      duration: data.unitWidth * 10
    })
  }

  typeaheadSearch(term){
    this.props.dispatch(playlistTypeaheadAll(term))
  }

  clearTypeahead(){
    this.props.dispatch(clearPlaylistTypeahead())
  }

  playlistSelect(selectData) {
    const {
      data
    } = this.props

    const maxDuration = data.maxDuration - data.totalDuration
    this.setState({
      duration: maxDuration < data.unitWidth * 10 ? maxDuration : data.unitWidth * 10,
      selectedPlaylist: selectData
    })
  }

  editDuration(add) {
    const {
      data
    } = this.props;

    const {
      duration
    } = this.state;

    const availableTime = data.maxDuration - data.totalDuration

    if (add && (duration < availableTime)) {
      this.setState({
        duration: duration + data.unitWidth
      })
    } else if (!add && duration > data.unitWidth) {
      this.setState({
        duration: duration - data.unitWidth
      })
    }
  }

  render(){
    const {
      data,
      loading,
      typeaheadLoading
    } = this.props;

    const {
      duration,
      selectedPlaylist
    } = this.state;

    const availableTime = data.maxDuration - data.totalDuration

    if(loading){
      return <Loader/>
    } else {
      return (
        <Container classname="overlay-fitness-timeline" maxWidth height="100%" column>
          <h2>Add a playlist</h2>
            {(availableTime < 50) ? (
                <p>Maximum total class duration has been reached.</p>
            ) : (
              <Fragment>
                <Typeahead
                  typeaheadSearch={(term)=>this.typeaheadSearch(term)}
                  typeaheadAction={(value)=>{this.playlistSelect(value)}}
                  typeaheadLoading={typeaheadLoading}
                  clear={()=>this.clearTypeahead()}
                  data={this.props.typeahead}
                  classname={classname}
                  disableEnterToSearch
                />
                {selectedPlaylist && (
                  <Fragment>
                    <p>{selectedPlaylist.name}</p>
                    <p className='fitness-timeline-edit__duration'>
                      <Icon
                        name="remove-circle"
                        action={() => {this.editDuration(false)}}
                      />
                      <span>{duration/data.unitWidth} mins</span>
                      <Icon
                        name="add-circle"
                        action={() => {this.editDuration(true)}}
                      />
                    </p>
                    <button
                      className='button'
                      onClick={() => { data.addPlaylist(selectedPlaylist, duration); }}
                      type="button"
                    >
                      Add playlist
                    </button>
                  </Fragment>
                )}
              </Fragment>
            )}
        </Container>
      )
    }
  }
}
function mapStateToProps(store){
    return {
      overlay: store.overlay,
      playlists: store.playlists.data,
      typeahead: store.playlists.typeahead,
      typeaheadLoading: store.playlists.loading,
      loading: store.overlay.loading
    }
  }
export default connect(mapStateToProps)(OverlayFitnessTimeline)
