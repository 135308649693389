import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import {
  getVenueDevices,
  deleteVenueDevice,
  deleteReplacementSD
} from 'store/actions/venueAction'

import { toggleOverlay } from 'store/actions/overlayAction'
import { toggleConfirm } from 'store/actions/confirmAction'

import Button from 'ui/Button'
import Confirm from 'ui/Confirm'
import Container from 'ui/Container'
import Loader from 'ui/Loader'

import VenueDeviceCard from "./hardware/VenueDeviceCard";

const classname = 'venueHardware'

class VenueHardware extends Component {
  constructor(props){
    super(props)

    this.state ={
      showOldDevices: false
    }
  }

  componentDidMount(){
    this.props.dispatch(getVenueDevices(this.props.id))
  }

  toggleDeviceCreateOverlay() {
    const {
      dispatch,
      id
    } = this.props

    dispatch(
      toggleOverlay(
        true,
        { venueID: id },
        'venueHardwareCreate'
      )
    )
  }

  toggleDeviceUpdateOverlay(device) {
    const {
      dispatch,
      id
    } = this.props

    const data = {
      venueID: id,
      ...device
    }

    dispatch(
      toggleOverlay(
        true,
        data,
        'venueHardwareUpdate')
    )
  }

  toggleReplacementSDOverlay(device) {
    const {
      dispatch,
      id
    } = this.props

    const data = {
      venueID: id,
      ...device
    }

    dispatch(
      toggleOverlay(
        true,
        data,
        'venueHardwareReplacementSD'
      )
    )
  }

  deleteVenueDevice(deviceID) {
    const {
      dispatch
    } = this.props

    const confirmData = {
      action: () => {
        dispatch(deleteVenueDevice(deviceID))
      },
      question: `Please do not delete old devices, as we want to keep a record of the venue's device history. Delete anyway?`
    }

    dispatch(toggleConfirm(true, confirmData))
  }

  deleteReplacementSD(replacementID) {
    const {
      dispatch
    } = this.props

    const confirmData = {
      action: () => {
        dispatch(deleteReplacementSD(replacementID))
      },
      question: `Please do not delete old SD cards, as we want to keep a record of the venue's SD card history. Delete anyway?`
    }

    dispatch(toggleConfirm(true, confirmData))
  }

  render(){
    const {
      devices,
      venueHardwareLoading,
      venueReplacementLoading
    } = this.props

    const {
      showOldDevices
    } = this.state

    if (venueHardwareLoading){
      return <Loader/>
    } else {
      return (
        <Container classname={classname} height="100%" maxWidth scroll>
          <div className='devices'>
            <div className='devices__add-new'>
              <Button
                className='devices__add-new'
                action={()=>{ this.toggleDeviceCreateOverlay() }}
                name='Add new device to venue'
              />
            </div>
            {devices.map((device, index) => {
              return (
                <Fragment
                  key={index}
                >
                  {index === 1 && (
                    <div className='venue-hardware__old-device-toggle' >
                      <Button
                        action={()=>{ this.setState({ showOldDevices: !showOldDevices }) }}
                        name={`${showOldDevices ? 'Hide' : 'Show' } old devices (${devices.length - 1})`}
                      />
                    </div>
                  )}
                  {(index === 0 || showOldDevices) && (
                    <VenueDeviceCard
                      index={index}
                      device={device}
                      toggleDeviceUpdateOverlay={(device)=>this.toggleDeviceUpdateOverlay(device)}
                      toggleReplacementSDOverlay={(device)=>this.toggleReplacementSDOverlay(device)}
                      deleteVenueDevice={(deviceID) => {this.deleteVenueDevice(deviceID)}}
                      deleteReplacementSD={(replacementID) => {this.deleteReplacementSD(replacementID)}}
                      venueReplacementLoading={venueReplacementLoading}
                    />
                  )}
                </Fragment>
              )
            })}
          </div>
          <Confirm />
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    connectionTypes: store.venue.connectionTypes,
    devices: store.venue.devices,
    deviceTypes: store.venue.deviceTypes,
    warrantyTypes: store.venue.warrantyTypes,
    venueHardware: store.venue.venueHardware,
    venueHardwareLoading: store.venue.venueHardwareLoading,
    venueReplacementLoading: store.venue.venueReplacementLoading,
    error: store.venue.error
  }
}

export default connect(mapStateToProps)(VenueHardware)
