import React, { Component, Fragment } from 'react'

import Icon from 'ui/Icon'
import Loader from 'ui/Loader'

class OverlayPlaylistImporterTableRowActions extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isFakeUpdating: false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      importerFinalTracklist,
      track
    } = this.props

    // a fake loading state to provide some user feedback that the track has been added/removed
    const previouslyAdded = prevProps.importerFinalTracklist.includes(track)
    const currentlyAdded = importerFinalTracklist.includes(track)

    if (
      (prevProps.importerFinalTracklist !== importerFinalTracklist) &&
      (
        (!previouslyAdded && currentlyAdded) ||
        (previouslyAdded && !currentlyAdded)
      )
    ) {
      this.setState({
        isFakeUpdating: true
      })

      setTimeout(()=> {
        this.setState({
          isFakeUpdating: false
        })
      }, 500)
    }
  }

  render() {
    const {
      track,
      addTrack,
      removeTrack,
      importerFinalTracklist
    } = this.props

    const {
      isFakeUpdating
    } = this.state

    const isFinalTrack = importerFinalTracklist.includes(track)

    return (
      <div>
        {isFakeUpdating ? (
          <Loader />
        ) : (
          <Fragment>
            {isFinalTrack ? (
              <Icon
                name='remove-circle'
                action={() => removeTrack(track)}
              />
            ) : (
              <Icon
                name='add-circle'
                action={() => addTrack(track)}
              />
            )}
          </Fragment>
        )}
      </div>
    )
  }
}

export default OverlayPlaylistImporterTableRowActions
