import React, { Component } from 'react'
import { withRouter, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import { isAuthenticated, login, logout } from 'store/actions/authAction'
import { appBodyClick } from 'store/actions/appAction'
import AuthContainer from 'modules/AuthContainer'
import DashboardContainer from 'modules/DashboardContainer'
import PlaylistsContainer from 'modules/PlaylistsContainer'
import PlaylistContainer from 'modules/PlaylistContainer'
import LibraryContainer from 'modules/LibraryContainer'
import VenuesContainer from 'modules/VenuesContainer'
import VenueContainer from 'modules/VenueContainer'
import BusinessesContainer from 'modules/BusinessesContainer'
import BusinessContainer from 'modules/BusinessContainer'
import ContactsContainer from 'modules/ContactsContainer'
import FitnessClassesContainer from 'modules/FitnessClassesContainer'
import FitnessClassContainer from 'modules/FitnessClassContainer'
import UsersContainer from 'modules/UsersContainer'
import UserContainer from 'modules/UserContainer'
import UploadContainer from 'modules/UploadContainer'

import Nav from 'components/Nav'
import Player from 'components/Player'
import Overlay from 'components/Overlay'
import MessageOverlay from 'components/MessageOverlay'

import Container from 'ui/Container'

class App extends Component {

  constructor(props){
    super(props)
    this.state = {
      isAuthenticated:false
    }
  }

  componentDidMount(){
    this.props.dispatch(isAuthenticated())
  }

  login(data){
    this.props.dispatch(login(data.username,data.password))
  }

  logout(){
    this.props.dispatch(logout())
  }

  appClick(e){
    this.props.dispatch(appBodyClick())
  }

  render() {
    const {
      auth,
      overlayActive
     } = this.props
    if(auth.isAuthenticated){
      return (
        <Container classname="app" height="100%" action={(e)=>this.appClick(e)}>
          <Nav
          logout={()=>this.logout()}/>
          <Route exact path="/" component={DashboardContainer} />
          <Route exact path="/playlists" component={PlaylistsContainer} />
          <Route path="/playlists/:id" component={PlaylistContainer} />
          <Route path="/library" component={LibraryContainer} />
          <Route path="/contacts" component={ContactsContainer} />
          <Route exact path="/venues" component={VenuesContainer} />
          <Route path="/venues/:id" component={VenueContainer} />
          <Route exact path="/businesses" component={BusinessesContainer} />
          <Route path="/businesses/:id" component={BusinessContainer} />
          <Route exact path="/classes" component={FitnessClassesContainer} />
          <Route path="/class/:id" component={FitnessClassContainer} />
          <Route exact path="/users" component={UsersContainer} />
          <Route path="/users/:id" component={UserContainer} />
          <Route exact path="/upload" component={UploadContainer} />
          <Player />
          {
            overlayActive &&
            <Overlay/>
          }
          <MessageOverlay/>
        </Container>
      )
    } else {
      return (
        <AuthContainer
          loading={auth.loading}
          error={auth.error}
          login={(data)=>this.login(data)}/>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    auth:store.auth,
    overlayActive:store.overlay.isActive
  }
}
export default withRouter(connect(mapStateToProps)(App))
