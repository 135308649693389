import React from 'react'

import FormLabel from 'ui/FormLabel'

const FormTextarea = (props) => {
  const {
    name,
    value,
    change,
    label,
    classname,
    size,
    autocomplete,
    disabled,
    required,
    hasError,
    errorMessage,
    hasServerError,
    serverError,
  } = props

  const textareaClasses = `texarea texarea-${classname} ${size ? size : ''} ${(hasError || hasServerError) && !disabled ? 'has-error' : ''}`
  const wrapperClasses = `form-texarea-wrapper texarea-wrapper-${classname}`
  const labelClasses = `texarea-label texarea-label-${classname}`

  return (
    <div className={wrapperClasses}>
      <FormLabel className={labelClasses} label={label} required={required} />
      <textarea
        name={name}
        value={value}
        className={textareaClasses}
        onChange={(e)=>change(e)}
        autoComplete={autocomplete}
      />
      <span className="focus-border"></span>
      {hasServerError && !disabled && (
        <p className='texarea__error-message'>{serverError.message}</p>
      )}
      {hasError && errorMessage && !disabled && (
        <p className='texarea__error-message'>{errorMessage}</p>
      )}
    </div>
  )
}

export default FormTextarea
