import { getQueue, updateQueue } from 'helpers/QueueHelper'

const initialState = {
  queue:[],
  loading:false,
  loaded:false,
  error:null
}
export default function reducer(state = initialState, action){
  switch (action.type){
    case 'FETCH_TRACK_FULFILLED':{
      const lsQueue = getQueue()
      if(lsQueue){
        const tracks = lsQueue.map((track)=>{
          if(track.id === action.meta){
            track.isPlaying = true
          } else {
            track.isPlaying = false
          }
          return track
        })
        updateQueue(tracks)
      }
      break
    }
    default: // no default case
  }
  return state
}
