import React, {Component} from 'react'
import { connect } from 'react-redux'

import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

import {
  getBusinessGenreBreakdown,
  getBusinessTracksBreakdown,
  getBusinessLibrary,
  getBusinessVenues,
  bloop
} from 'store/actions/businessAction'

import Container from 'ui/Container'
import FormSelect from 'ui/FormSelect'
import Loader from 'ui/Loader'

import ReportsRow from '../../components/reports/ReportsRow'
import ReportsUnit from '../../components/reports/ReportsUnit'
import ReportsData from '../../components/reports/ReportsData'
import ReportsBarChart from '../../components/reports/ReportsBarChart'
import ReportsPieChart from '../../components/reports/ReportsPieChart'
import ReportsPlaylist from '../../components/reports/ReportsPlaylist'
// import ReportsMap from '../../components/reports/ReportsMap'

const playlistData = [
  {
    name: 'Cafe Beats | Uptempo',
    genres: {
      'Pop': 295,
      'Soul': 216,
      'Indie': 179,
      'Jazz': 110,
      'Instrumental': 91,
      'Acoustic': 83,
      'Rock': 79,
      'Dance/EDM': 68,
      'Electronica': 40,
      'Funk': 36,
      'Folk': 35,
      'Downtempo': 31,
      'Blues': 21,
      'RnB': 17,
      'Crooners/Swing': 11,
      'Latin': 7,
      'Nu-Jazz': 7,
      'Scottish': 5
    },
    track_breakdown: [
      {
        name: 'Dec', Existing: 4400, New: 1000
      },
      {
        name: 'Jan', Existing: 4398, New: 1000
      },
      {
        name: 'Feb', Existing: 4800, New: 1000
      },
      {
        name: 'Mar', Existing: 4908, New: 1000
      },
      {
        name: 'Apr', Existing: 4800, New: 1000
      },
      {
        name: 'May', Existing: 4800, New: 1000
      },
      {
        name: 'Jun', Existing: 4300, New: 1000
      },
    ],
    total_tracks: 124,
    total_length: '3h 13m 33s'
  },
  {
    name: 'Lounge Jazz | Chilled',
    genres: {
      'Funk': 236,
      'Folk': 235,
      'Downtempo': 231,
      'Blues': 221,
      'RnB': 217,
      'Crooners/Swing': 211,
      'Latin': 27,
      'Nu-Jazz': 27,
      'Scottish': 25
    },
    track_breakdown: [
      {
        name: 'Dec', Existing: 345, New: 42
      },
      {
        name: 'Jan', Existing: 522, New: 33
      },
      {
        name: 'Feb', Existing: 345, New: 24
      },
      {
        name: 'Mar', Existing: 765, New: 54
      },
      {
        name: 'Apr', Existing: 223, New: 45
      },
      {
        name: 'May', Existing: 895, New: 32
      },
      {
        name: 'Jun', Existing: 322, New: 64
      },
    ],
    total_tracks: 375,
    total_length: '1h 36m 54s'
  },
  {
    name: 'Indie Rock | Uptempo',
    genres: {
      'Indie': 179,
      'Jazz': 110,
      'Instrumental': 91,
      'Acoustic': 83,
      'Rock': 79,
      'Dance/EDM': 68,
      'Electronica': 40
    },
    track_breakdown: [
      {
        name: 'Dec', Existing: 246, New: 30
      },
      {
        name: 'Jan', Existing: 278, New: 25
      },
      {
        name: 'Feb', Existing: 321, New: 36
      },
      {
        name: 'Mar', Existing: 644, New: 46
      },
      {
        name: 'Apr', Existing: 344, New: 63
      },
      {
        name: 'May', Existing: 444, New: 42
      },
      {
        name: 'Jun', Existing: 555, New: 44
      },
    ],
    total_tracks: 789,
    total_length: '3h 16m 24s'
  }
]

const classname = 'businessReports'

class BusinessReports extends Component {
  constructor(props){
    super(props)

    this.state = {
      pdfMode: false,
      bleep: null
    };

    this.createPdf = this.createPdf.bind(this);
  }

  componentDidMount(){
    const {
      dispatch,
      id
    } = this.props

    dispatch(getBusinessGenreBreakdown(id))
    dispatch(getBusinessTracksBreakdown(id))
    dispatch(getBusinessLibrary(id))
    dispatch(getBusinessVenues(id))
    dispatch(bloop(653))
  }

  componentDidUpdate(prevProps) {
    const {
      reportPlaylistData
    } = this.props

    if (reportPlaylistData !== prevProps.reportPlaylistData && reportPlaylistData.length > 0) {
      // reportPlaylistData.playlists
    }
  }

  createPdf() {
    const {
      business
    } = this.props

    const input = document.getElementById('business-reports__report');

    this.setState({
        pdfMode: true
      },
      () => {
        setTimeout(() => {html2canvas(input)
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('l', 'mm', 'a4');
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const imgRatio = imgWidth/imgHeight;
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const pageRatio = pageWidth/pageHeight;

            // Adds the canvas object to the dom, userful for testing
            // document.querySelector('.container-businessReports').append(canvas);

            const wc = imgWidth / pageWidth;
            if (imgRatio >= pageRatio) {
              pdf.addImage(imgData, 'JPEG', 0, 0, pageWidth, imgHeight / wc, null, 'NONE');
            }
            else {
              const pi = pageRatio / imgRatio;
              pdf.addImage(imgData, 'JPEG', 0, 0, pageWidth / pi, (imgHeight / pi) / wc, null, 'NONE');
            }

            pdf.save(`open-ear-report-${business.name}__${this.getReportDate()}.pdf`);

            this.setState({
              pdfMode: false
            });
          })}, 5000);
      }
    );
  }

  getReportDate () {
    const dateObj = new Date();
    const month = dateObj.getMonth() + 1; //months from 1-12
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();

    return `${day}/${month}/${year}`;
  }

  getTotalBlockedTracks() {
    const {
      venues
    } = this.props

    let totalBlocked = 0

    venues.forEach((venue) => {
      totalBlocked = totalBlocked + venue.blocked_count
    })

    return totalBlocked
  }

  formatTimeTotal(time) {
    if (!time) return;
    const timeArray = time.split(":");
    return `${timeArray[0]}d  ${timeArray[1]}h ${timeArray[2]}m ${timeArray[3]}s`;
  }

  render() {
    const {
      business,
      report,
      reportPlaylistData,
      venues,
      loading
    } = this.props;

    const {
      pdfMode
    } = this.state;

    if(loading){
      return <Loader/>
    } else {
      return (
        <Container classname={classname} height="100%" maxWidth column scroll>
          {pdfMode && (
            <div className='business-reports__pdf-loader-container'>
              <div className='business-reports__loader'>
                <Loader/>
              </div>
            </div>
          )}
          {report && Object.keys(report).length > 0 ? (
            <div id='business-reports__report' style={{ width: pdfMode ? 'auto' : 'auto' }} className={pdfMode ? 'business-reports__pdf-mode' : ''}>
              <div className='business-reports__header'>
                <h3 style={{ color: pdfMode ? '#021b2b' : '#fff' }}>
                  {business.name}
                </h3>
                {pdfMode || (<FormSelect
                  action={(e) => {
                    this.setState({
                      bleep: e.currentTarget.value
                    })
                    console.log("e.currentTarget.value", e.currentTarget.value, e.currentTarget)
                  }}
                  options={venues.map((venue, index) => {
                    // if (index === 0) {
                    //   return (
                    //     {
                    //       'display': venue.name,
                    //       'value': index
                    //     },
                    //       {
                    //         'display': 'Select a venue',
                    //         'value': index + 1
                    //       }
                    //   )
                    // }
                    return (
                      {
                        'display': venue.name,
                        'value': index + 1
                      }
                    )
                  })}
                />)}
                {pdfMode ? (
                  <img src='/logo-black.png' alt='Open Ear Music' style={{ height: '25px', margin: '1rem 1rem 0 auto', width: '120px' }} />
                ) : (
                  <button
                    className='business-reports__pdf-download-btn'
                    type='button'
                    onClick={() => { this.createPdf() }}
                    data-html2canvas-ignore='true'
                  >
                    Create PDF
                  </button>
                )}
              </div>
              <ReportsRow>
                <ReportsUnit
                  header='Total tracks'
                  width='33'
                >
                  <ReportsData>
                    {report.count}
                  </ReportsData>
                </ReportsUnit>
                <ReportsUnit
                  header='Total playlists'
                  width='33'
                >
                  <ReportsData>
                    {report.playlistCount}
                  </ReportsData>
                </ReportsUnit>
                <ReportsUnit
                  header='Total duration'
                  width='33'
                >
                  <ReportsData>
                    {this.formatTimeTotal(report.total_length)}
                  </ReportsData>
                </ReportsUnit>
              </ReportsRow>
              <ReportsRow>
                <ReportsUnit
                  header='New tracks this month'
                  width='33'
                >
                  <ReportsData>
                    {report.new}
                  </ReportsData>
                </ReportsUnit>
                <ReportsUnit
                  header='Average track energy'
                  width='33'
                >
                  <ReportsData>
                    {report.energy}
                  </ReportsData>
                </ReportsUnit>
                <ReportsUnit
                  header='Blocked tracks'
                  width='33'
                >
                  <ReportsData>
                    {this.getTotalBlockedTracks()}
                  </ReportsData>
                </ReportsUnit>
              </ReportsRow>
              <ReportsRow>
                <ReportsUnit header='Top Genres' width='25'>
                  <ReportsPieChart data={report.genres} />
                </ReportsUnit>
                <ReportsUnit header='New tracks by month' width='75'>
                  <ReportsBarChart data={reportPlaylistData} />
                </ReportsUnit>
              </ReportsRow>
              <div data-html2canvas-ignore>
                {pdfMode || (
                  <ReportsRow>
                    <ReportsUnit header='Playlists'>
                      {pdfMode ? (
                        playlistData.map((playlist, index) => {
                          return (
                            <ReportsPlaylist
                              key={`${playlistData[index].name}-${index}`}
                              data={playlistData}
                              playlistIndex={index}
                              pdfMode={pdfMode}
                            />
                          )
                        })
                      ) : (
                        <ReportsPlaylist
                          data={playlistData}
                          pdfMode={pdfMode}
                        />
                      )}
                    </ReportsUnit>
                  </ReportsRow>
                )}
                {/*<ReportsRow>*/}
                {/*<ReportsUnit header='A Map' width='75'>*/}
                {/*<ReportsMap />*/}
                {/*</ReportsUnit>*/}
                {/*</ReportsRow>*/}
              </div>
            </div>
          ) : (
            <Loader />
          )}
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    business:store.business.details,
    report:store.business.report,
    reportPlaylistData: store.business.reportPlaylistData,
    loading:store.business.loading,
    library:store.business.library,
    venues:store.business.venues
  }
}

export default connect(mapStateToProps)(BusinessReports)
