import React from 'react'

import TableModel from 'models/TableModel'
import Badge from 'ui/Badge'
import Icon from "./Icon"

const TableCellBadge = (props) => {
  const header = TableModel[props.classname].headers.find(header => header.field === props.field)
  return (
    <td className={`table-cell-img table-cell-${props.classname} table-cell-size-${props.size} table-cell-link`}>
      {
        props.datum ?
        <img src={props.datum}/> :
        <Icon name="images"/>
      }
    </td>
  )
}

export default TableCellBadge
