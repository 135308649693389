const initialState = {
  data: [],
  loading: false,
  newNoteLoading: false,
  noteDeleting: [],
  overlayNotesAddLoading: false,
  types: [],
  error: null
}

export default function reducer(state = initialState, action){
  switch (action.type){
    case 'FETCH_NOTES_PENDING':{
      state = {
        ...state,
        loading: true
      }
      break
    }
    case 'FETCH_NOTES_REJECTED':{
      state = {
        ...state,
        loading: false,
        error: action.payload
      }
      break
    }
    case 'FETCH_NOTES_FULFILLED':{
      const notes = action.payload.data.notes.map((note) => {
        return ({
          content: note.content,
          date: note.created_at ? note.created_at.iso : null,
          id: note.id,
          type: note.type ? note.type.type : 'Unknown',
          user: note.user
        })
      })

      notes.sort((a,b) => {
        return new Date(b.date) - new Date(a.date);
      });

      state = {
        ...state,
        data: notes,
        loading: false
      }
      break
    }
    case 'FETCH_NOTE_TYPES_PENDING':{
      state = {
        ...state,
        overlayNotesAddLoading: true
      }
      break
    }
    case 'FETCH_NOTE_TYPES_REJECTED':{
      state = {
        ...state,
        overlayNotesAddLoading: false,
        error: action.payload
      }
      break
    }
    case 'FETCH_NOTE_TYPES_FULFILLED':{
      const types = action.payload.data.map((type) => {
        const data = {
          display: type.type,
          value: type.id
        }
        return data
      })

      state =  {
        ...state,
        types: types,
        overlayNotesAddLoading: false
      }
      break
    }
    case 'CREATE_NOTE_PENDING':{
      state = {
        ...state,
        newNoteLoading: true
      }
      break
    }
    case 'CREATE_NOTE_REJECTED':{
      state = {
        ...state,
        newNoteLoading: false,
        error: action.payload
      }
      break
    }
    case 'CREATE_NOTE_FULFILLED':{
      const notes = [...state.data]
      const noteData = action.payload.data

      const newNote = {
        content: noteData.content,
        date: noteData.created_at ? noteData.created_at.iso : null,
        id: noteData.id,
        type: noteData.type ? noteData.type.type : 'Unknown',
        user: noteData.user
      }

      notes.push(newNote)
      notes.sort((a,b) => {
        return new Date(b.date) - new Date(a.date);
      });

      state = {
        ...state,
        data: notes,
        newNoteLoading: false
      }
      break
    }
    case 'DELETE_NOTE_PENDING':{
      state = {
        ...state,
        noteDeleting: state.noteDeleting.concat(action.meta.id)
      }
      break
    }
    case 'DELETE_NOTE_REJECTED':{
      state = {
        ...state,
        noteDeleting: state.noteDeleting.filter(noteID => noteID !== action.meta.id),
        error: action.payload
      }
      break
    }
    case 'DELETE_NOTE_FULFILLED':{
      let notes = [...state.data]

      notes = notes.filter(note => note.id !== action.payload.data)

      state = {
        ...state,
        data: notes,
        noteDeleting: state.noteDeleting.filter(noteID => noteID !== action.meta.id),
      }
      break
    }
    default: // no default
  }
  return state
}
