import React, {PureComponent, Fragment} from 'react'
import {connect} from "react-redux";

import {
  toggleConfirmError
} from 'store/actions/confirmAction'

class ConfirmError extends PureComponent {
  render(){
    const {
      confirmErrorActive,
      data
    } = this.props

    return (
      <Fragment>
        {confirmErrorActive && (
          <div className='confirm-wrapper'>
            <div className='confirm'>
              <p>{data && data.question}</p>
              <div className='confirm__controls'>
                <button
                  type='button'
                  className='confirm__cancel-btn'
                  onClick={() =>{ this.props.dispatch(toggleConfirmError(false)) }}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    )
  }

}

function mapStateToProps(store){
  return {
    confirmErrorActive:store.confirm.confirmErrorActive,
    data: store.confirm.data
  }
}

export default connect(mapStateToProps)(ConfirmError)
