import React, { Component } from 'react'
import queryString from 'qs'

import BusinessDetails from 'modules/business/BusinessDetails'
import BusinessAccounts from 'modules/business/BusinessAccounts'
import BusinessMusic from 'modules/business/BusinessMusic'
import BusinessUser from 'modules/business/BusinessUser'
import BusinessVenues from 'modules/business/BusinessVenues'
import BusinessContacts from 'modules/business/BusinessContacts'
import BusinessNotes from 'modules/business/BusinessNotes'
import BusinessActions from 'modules/business/BusinessActions'
import BusinessReports from 'modules/business/BusinessReports'
import BusinessSubscribed from 'modules/business/BusinessSubscribed'
//import VenueSchedule from 'modules/business/VenueSchedule'
//import VenueAnnouncements from 'modules/business/VenueAnnouncements'

import Tabs from 'ui/Tabs'
import Container from 'ui/Container'
import { connect } from "react-redux"
import BusinessSettings from "./business/BusinessSettings"

const classname = 'business'

class BusinessContainer extends Component {

  constructor(props){
    super(props)
    this.state = {
      tab:'details'
    }
  }

  componentDidMount(){
    this.getTabQuery()
  }

  componentDidUpdate(prevProps){
    //  handle forward and back browser button usage
    if ((this.props.location !== prevProps.location) && this.props.history.action === 'POP') {
      this.getTabQuery()
    }
  }

  getTabQuery(){
    const query = queryString.parse(this.props.location.search.slice(1))

    if(typeof query.tab !== 'undefined' && query.tab !== this.state.tab){
      this.setState({tab: query.tab})
    } else if (typeof query.tab === 'undefined') {
      // defines the default tab to loaded when no tab query is passed in the URL
      this.setState({tab: 'details'})
    }
  }

  selectTab(tab){
    this.props.history.push({
      search: `?tab=${tab}`
    })
    this.setState({tab:tab})
  }

  setHeader(header){
    this.setState({header:header})
  }

  getComponent(){
    const id = this.props.match.params.id
    //@TODO clean up get header as name is in store
    switch(this.state.tab){
      case 'details':
        return <BusinessDetails setHeader={(header)=>this.setHeader(header)} id={id}/>
      case 'accounts':
        return <BusinessAccounts setHeader={(header)=>this.setHeader(header)} id={id}/>
      case 'music':
        return <BusinessMusic setHeader={(header)=>this.setHeader(header)} id={id}/>
      case 'user':
        return <BusinessUser setHeader={(header)=>this.setHeader(header)} id={id}/>
      case 'venues':
        return <BusinessVenues id={id}/>
      case 'contacts':
        return <BusinessContacts setHeader={(header)=>this.setHeader(header)} id={id}/>
      case 'notes':
        return <BusinessNotes id={id}/>
      case 'actions':
        return <BusinessActions id={id}/>
      case 'reports':
        return <BusinessReports setHeader={(header)=>this.setHeader(header)} id={id} />
      case 'subscribed':
        return <BusinessSubscribed setHeader={(header)=>this.setHeader(header)} id={id} />
      case 'settings':
        return <BusinessSettings id={id} />
      default: //no default case
    }
  }

  render(){
    return (
      <Container classname={classname} height="100%" column maxWidth>
        <Container classname="tabs">
          <Tabs
            match={this.props.match}
            classname={classname}
            select={(tab)=>this.selectTab(tab)}
            active={this.state.tab}
            header={this.props.business.name}/>
        </Container>
        <Container height="100%" classname="tabview" column>
          {this.getComponent()}
        </Container>
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    business:store.business.details
  }
}

export default connect(mapStateToProps)(BusinessContainer)
