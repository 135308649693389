import React from 'react'
import {Link} from 'react-router-dom'

import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'
import TableModel from 'models/TableModel'

const PlaylistsTableActions = (props) => {
  const {classname,id,name,more,pinPlaylist, pinned, playlist} = props
  const pinnedIcon = pinned ?
    <Icon name="star" active={true}/> : 
    <Icon name="star" action={()=>pinPlaylist({id:id})}>
      <Tooltip text="Pin playlist" pos="left"/>
    </Icon>
  return (
    <div>
      <Link to={`${TableModel[classname].viewLink}/${id}?tab=tracks`}><Icon name="eye"/></Link>
      {pinnedIcon}
      <Icon name="more" action={()=>more(playlist)}/>
    </div>
  )
}

export default PlaylistsTableActions