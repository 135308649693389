import axios from 'axios'

export function getFitnessClasses(){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_FITNESS_CLASSES',
      payload:axios.get('fitness/classes')
    })
  }
}
export function clearFitnessClasses(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_FITNESS_CLASSES',
      payload:null
    })
  }
}
export function searchFitnessClassesTypeahead(term){
  return (dispatch) => {
    return dispatch({
      type:'SEARCH_FITNESS_CLASSES_TYPEAHEAD',
      payload:axios.post(`fitness/typeahead/${term}`)
    })
  }
}
export function clearFitnessClassesTypeahead(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_FITNESS_CLASSES_TYPEAHEAD',
      payload:null
    })
  }
}
export function getFitnessClass(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_FITNESS_CLASS',
      payload:axios.get(`fitness/class/${id}`)
    })
  }
}
export function clearFitnessClass(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_FITNESS_CLASS',
      payload:null
    })
  }
}
export function getFitnessPlaylists(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_FITNESS_PLAYLISTS',
      payload:axios.get(`fitness/playlists/${id}`)
    })
  }
}
export function createFitnessClass(data){
  return (dispatch) => {
    return dispatch({
      type:'CREATE_FITNESS_CLASS',
      payload:axios.post(`fitness/class`, data)
    })
  }
}
export function updateFitnessClass(data){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_FITNESS_CLASS',
      payload:axios.post(`fitness/class/update`, data)
    })
  }
}
export function deleteFitnessClass(id){
  return (dispatch) => {
    return dispatch({
      type:'DELETE_FITNESS_CLASS',
      payload:axios.delete(`fitness/${id}`),
      meta:id
    })
  }
}
export function getFitnessClassVenues(id, showLoader){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_FITNESS_CLASS_VENUES',
      payload:axios.get(`fitness/class/${id}/venues`),
      meta:showLoader
    })
  }
}
export function addFitnessClassToVenue(data){
  return (dispatch) => {
    return dispatch({
      type:'ADD_FITNESS_CLASS_TO_VENUE',
      payload:axios.post(`fitness/class/venue`,data),
      meta:data.venue_id
    })
  }
}
export function removeFitnessClassFromVenue(data){
  return (dispatch) => {
    return dispatch({
      type:'REMOVE_FITNESS_CLASS_FROM_VENUE',
      payload:axios.delete(`fitness/venue/${data.venue_id}/${data.class_id}`),
      meta:data.venue_id
    })
  }
}
