import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  getTracks,
  clearTracks,
  deleteTrack,
  searchLibrary,
  searchLibraryTypeahead,
  addTrackToQueue,
  getGenres,
  getTrack,
  addSelectedTrack,
  removeSelectedTrack,
  clearSelectedLibraryTracks
} from 'store/actions/libraryAction'

import {
  addTrackToPlaylistOwned,
  removeTrackFromPlaylistOwned,
  addTracksToPlaylist as addTracksToPlaylistAction
} from 'store/actions/playlistAction'

import { toggleConfirm } from 'store/actions/confirmAction'
import { getTrackInfo, getTrackUrl } from 'store/actions/playerAction'

import { getRowLimit } from 'helpers/TableHelper'
import {  getQueue } from 'helpers/QueueHelper'
import { parseQueryString } from 'helpers/StringHelper'

import { toggleOverlay } from 'store/actions/overlayAction'

import Container from 'ui/Container'
import Tabs from 'ui/Tabs'
import TableWrapper from 'ui/TableWrapper'
import LibraryTableActions from './LibraryTableActions'
import LibraryTableRowActions from './LibraryTableRowActions'
import LibrarySearchFilter from './LibrarySearchFilter'
import LibraryTracksRow from './LibraryTracksRow'
import Confirm from 'ui/Confirm'

const classname = 'library'

class LibraryTable extends Component {

  constructor(props){
    super(props)
    this.state = {
      tab:'',
      url:'library',
      searchTab:[],
      actionActive:false,
      showFilter:false,
      filter:null,
      filterLength:null,
      disableTypeahead: false,
      tracks:[],
      advancedSearch:true
    }
  }

  componentDidMount(){
    const {
      location,
      dispatch,
      selectedTracks,
      sorter
    } = this.props

    const {
      advancedSearch
    } = this.state

    const params = parseQueryString(location.search)
    dispatch(clearTracks())
    dispatch(getGenres())

    if(params.type && params.type === 'search' && params.term) {
      // standard search
      this.libraryAction(location.search)
      dispatch(
        searchLibrary(
          params.term,
          getRowLimit(classname),
          0,
          sorter,
          advancedSearch
        )
      )
    } else if(typeof params.tab !== 'undefined' && params.tab !== 'all' && params.tab !== 'library'){
      // type search
      this.libraryAction(location.search)
      dispatch(
        getTracks(
          `library/${params.type}/${params.term}`,
          getRowLimit(classname),
          0,
          sorter
        )
      )
    } else if(typeof params.tab === 'undefined' || params.tab === 'all' || params.tab === 'library'){
      // show all tracks
      this.setState({
        tab: 'all'
      })
      dispatch(
        getTracks(
          this.state.url,
          getRowLimit(classname),
          0,
          sorter
        )
      )
    }

    // if the component loads with tracks already selected,
    // show the table actions
    if (selectedTracks.length > 0) {
      this.setState({
        actionActive:true
      })
    }
  }

  componentDidUpdate(prevProps,prevState){
    const {
      dispatch,
      selectedTracks,
      sorter
    } = this.props

    const {
      advancedSearch,
      filter,
      tab
    } = this.state

    const params = parseQueryString(tab)

    if (
      prevState.tab &&
      prevState.tab !== this.state.tab
    ) {
      dispatch(clearTracks())
      if(params.tab === 'all' || typeof params.tab === 'undefined'){
        dispatch(
          getTracks(
            '/library',
            getRowLimit(classname),
            0,
            sorter,
            filter
          )
        )
      } else if(params.type && params.type === 'search' && params.term){
        dispatch(
          searchLibrary(
            params.term,
            getRowLimit(classname),
            0,
            sorter,
            advancedSearch
          )
        )
      } else if(params.type && params.term && params.tab !== 'all'){
        dispatch(
          getTracks(
            `library/${params.type}/${params.term}`,
            getRowLimit(classname),
            0,
            sorter,
            filter
          )
        )
      }
    }

    if (prevProps.selectedTracks !== selectedTracks) {
      this.setState({
        actionActive: selectedTracks.length > 0
      })
    }

    if (prevProps.sorter !== sorter) {
      this.sortLibrary()
    }
  }

  selectTab(tab){
    //get url
    let url
    if(tab === 'all'){
      url = 'library'
    } else {
      const params = parseQueryString(tab)
      url = `library/${params.type}/${params.term}`
    }

    this.setState({
      tab:tab,
      url:url
    },()=>{
      if(!tab.startsWith('?tab=')){
        tab = '?tab=' + tab;
      }
      this.props.history.push({
        search: `${tab}`
      })
    })
  }

  clearTracks(){
    this.props.dispatch(clearTracks())
  }

  getTracks(limit,offset,sorter = null) {
    const {
      dispatch
    } = this.props

    const params = parseQueryString(this.state.tab)

    if(params.type === 'search'){
      dispatch(
        searchLibrary(
          params.term,
          limit,
          offset,
          sorter,
          this.state.advancedSearch
        )
      )
    } else {
      dispatch(
        getTracks(
          this.state.url,
          limit,
          offset,
          sorter,
          this.state.filter
        )
      )
    }
  }

  moreActions(data){
    this.props.dispatch(toggleOverlay(true,data,'venues'))
  }

  searchTracks(term){
    const link = `tab=search&type=search&term=${encodeURIComponent(term)}`
    // If the user performs a search, disable the typeahead dropdown
    this.setState({
      searchTab:this.state.searchTab.concat(
        {name:`Search: ${term}`,link:link,dismiss:true}
      ),
      tab:link,
      url:`library/search/${term}`,
      disableTypeahead: true
    },()=>{
      this.props.history.push({
        search: `?${link}`
      })
    })
  }

  searchLibraryTypeahead(value){
    // if the user has entered text in the search field, re-enable the typeahead
    this.setState({
      disableTypeahead: false
    })
    this.props.dispatch(searchLibraryTypeahead(value))
  }

  addTrackToPlaylist(data){
    const {
      dispatch,
      playlist
    } = this.props

    if (playlist) {
      dispatch(addTrackToPlaylistOwned(data.track, playlist))
    } else {
      dispatch(toggleOverlay(true,data,'playlistsAdd'))
    }
  }

  addTracksToPlaylist() {
    const {
      dispatch,
      playlist,
      selectedTracks
    } = this.props

    dispatch(addTracksToPlaylistAction(
      playlist.id, 
      selectedTracks.map(track => track.id)
    ))
  }

  addTracksToMultiPlaylists(){
    const {
      dispatch,
      selectedTracks
    } = this.props

    dispatch(toggleOverlay(true,selectedTracks,'multiPlaylistsAdd'))
  }

  removeTrackFromPlaylist(data){
    const {
      dispatch
    } = this.props
    dispatch(removeTrackFromPlaylistOwned(data))
  }

  addTrackToQueue(data){
    // addToQueue(data.track)f
    this.props.dispatch(addTrackToQueue(data.track))
    getQueue()
  }

  checkboxAction(e,data,index){
    const {
      dispatch
    } = this.props

    if(e.nativeEvent.metaKey || e.nativeEvent.shiftKey){
      return
    }
    if(e.target.checked){
      data.index = index
      dispatch(addSelectedTrack(data))
    } else {
      dispatch(removeSelectedTrack(data))
    }
  }

  playTrack(track){
    const {
      dispatch,
      playlistTracks
    } = this.props

    const trackType = playlistTracks && playlistTracks.length > 0 ? 'playlist-library' : 'library'

    dispatch(getTrackInfo(track, trackType))
    dispatch(getTrackUrl(track, trackType))
  }

  pauseTrack(track){
    this.props.dispatch()
  }

  favouriteTrack(track){

  }

  sortLibrary(header){
    const {
      dispatch,
      sorter
    } = this.props

    const params = parseQueryString(this.state.tab)

    this.clearTracks()

    if(params.type === 'search'){
      dispatch(searchLibrary(
        params.term,
        getRowLimit(classname),
        0,
        sorter,
        this.state.advancedSearch
      ))
    } else {
      dispatch(
        getTracks(
          this.state.url,
          getRowLimit(classname),
          0,
          sorter,
          this.state.filter
        )
      )
    }
  }

  showFilter(){
    this.setState(
      {showFilter:!this.state.showFilter}
    )
  }

  setFilter(filter){
    const {
      dispatch,
      sorter
    } = this.props

    this.setState({
      filter:filter,
      filterLength:filter.genres.length + filter.excludedGenres.length + filter.energies.length
    },() => {
      dispatch(clearTracks())
      dispatch(
        getTracks(
          '/library',
          getRowLimit(classname),
          0,
          sorter,
          this.state.filter
        )
      )
    })
  }

  clickTypeahead(type,term){
    //this.props.history.push(url)
    const link = `tab=search&type=${type}&term=${encodeURIComponent(term)}`
    // If the user has selected a typeahead option, disable the typeahead dropdown
    this.setState({
      searchTab:this.state.searchTab.concat(
        {name:`${type}: ${term}`,link:link,dismiss:true}
      ),
      tab:link,
      url:`library/${type}/${term}`,
      disableTypeahead: true
    },()=>{
      this.props.history.push({
        search: `?${link}`
      })
    })
  }

  clearTypeahead() {
    this.setState({
      disableTypeahead: true
    })
  }

  closeTab(tab){
    const searchTabs = this.state.searchTab.filter(tb => tb !== tab)
    this.setState({
      searchTab:searchTabs,
      tab:'all',
      url:'library'
    },()=>{
      this.selectTab('all')
    })
  }

  libraryAction(data,e){
    const params = parseQueryString(data)
    const url = `library/${params.type}/${params.term}`
    if(e && e.metaKey){
      e.stopPropagation()
      window.open(data,'_blank')
    } else {
      this.setState({
        searchTab:this.state.searchTab.concat(
          {name:`${params.type}: ${params.term}`,link:data,dismiss:true}
        ),
        tab:data,
        url:url
      },()=>{
        this.props.history.push({
          search: data
        })
      })
    }
  }

  editTrack(track){
    this.props.dispatch(getTrack(track.id))
    this.props.dispatch(toggleOverlay(true,null,'editTrack'))
  }

  editTracks(){
    this.props.dispatch(toggleOverlay(true,this.props.selectedTracks,'multiEditTracks'))
  }

  deleteTrack(track) {
    const {
      dispatch
    } = this.props

    const confirmData = {
      action: () => {
        dispatch(deleteTrack(track.id))
      },
      question: `Delete ${track.artist} - ${track.title}?`
    }

    dispatch(toggleConfirm(true, confirmData))
  }

  clickRow(e,data,isChecked,index){
    const {
      dispatch,
      selectedTracks
    } = this.props

    e.stopPropagation()
    //cmd + click
    if(e.metaKey){
      if(!isChecked){
        data.index = index
        dispatch(addSelectedTrack(data))
      } else {
        dispatch(removeSelectedTrack(data))
      }
      if(selectedTracks.length > 0){
        this.setState({
          actionActive:true
        })
      } else {
        this.setState({
          actionActive:false
        })
      }
    }
    if(e.shiftKey){
      if(selectedTracks.length === 1){
        for (let i = selectedTracks[0].index + 1; i <= index; i++) {
          dispatch(addSelectedTrack(this.props.tracks[i]))
        }
        this.setState({
          actionActive:true
        })
      }
    }
  }

  deselectTracks(){
    this.props.dispatch(clearSelectedLibraryTracks())
    this.setState({
      actionActive:false
    })
  }

  toggleSearch(){
    this.setState({
      advancedSearch:!this.state.advancedSearch
    })
  }

  render(){
    const {
      playlist,
      playlistTracks,
      selectedTracks,
      addTrackToOwnedPlaylistLoading,
      isLibrary
    } = this.props

    return (
      <Container classname={`${isLibrary || 'isPlaylist'}`} column height="100%">
        <Container classname="tabs">
          <Tabs
            match={this.props.match}
            classname={classname}
            select={(tab)=>this.selectTab(tab)}
            active={this.state.tab}
            add={this.state.searchTab}
            closeTab={(tab)=>this.closeTab(tab)}
          />
        </Container>
        <Container height="100%" classname={`
            ${classname} 
            ${isLibrary ? 'container-tabview' : 'isPlaylist'}
          `}
          column>
          <LibrarySearchFilter
            showFilter={this.state.showFilter}
            genres={this.props.genres}
            setFilter={(filter)=>this.setFilter(filter)}
            closeFilter={()=>this.showFilter()}
          />
          <TableWrapper
            controls
            play={(track)=>this.playTrack(track)}
            pause={(track)=>this.pauseTrack(track)}
            libraryAction={(data,e)=>this.libraryAction(data,e)}
            //checkboxAction={(e,data,index)=>this.checkboxAction(e,data,index)}
            checkboxSelected={selectedTracks}
            classname={classname}
            data={this.props.tracks}
            count={this.props.count}
            clearData={()=>this.clearTracks()}
            getData={(limit,offset,sorter)=>this.getTracks(limit,offset,sorter)}
            loading={this.props.loading}
            typeaheadLoading={this.props.typeaheadLoading}
            playingTrack={this.props.playingTrack}
            rowComp={
              <LibraryTracksRow
                play={(track)=>this.playTrack(track)}
                playingTrack={this.props.playingTrack}
                checkboxSelected={selectedTracks}
                checkboxAction={(e,data,index)=>this.checkboxAction(e,data,index)}
                clickRow={(e,data,isChecked,index)=>this.clickRow(e,data,isChecked,index)}
                libraryAction={(data,e)=>this.libraryAction(data,e)}
                />
            }
            rowActions={<LibraryTableRowActions
              more={(data)=>this.moreActions(data)}
              addTrackToPlaylist={(data)=>this.addTrackToPlaylist(data)}
              removeTrackFromPlaylist={(data)=>this.removeTrackFromPlaylist(data)}
              addTrackToOwnedPlaylistLoading={addTrackToOwnedPlaylistLoading}
              favouriteTrack={(data)=>this.favouriteTrack(data)}
              addTrackToQueue={(data) => this.addTrackToQueue(data)}
              deleteTrack={(track) => this.deleteTrack(track)}
              editTrack={(track) => this.editTrack(track)}
              classname={classname}
              playlist={playlist}
              playlistTracks={playlistTracks}/>
            }
            tableAction={<LibraryTableActions
              active={this.state.actionActive}
              numSelected={selectedTracks.length}
              classname={classname}
              typeaheadAction={(value)=>this.searchLibraryTypeahead(value)}
              typeaheadLoading={this.props.typeaheadLoading}
              search={(value)=>this.searchTracks(value)}
              clear={()=>this.clearTracks()}
              disableTypeahead={this.state.disableTypeahead}
              searchData={this.props.typeahead}
              selectGenres={()=>this.selectGenres()}
              clickTypeahead={(type,term)=>this.clickTypeahead(type,term)}
              clearTypeahead={()=>this.clearTypeahead()}
              showFilter={()=>this.showFilter()}
              toggleSearch={()=>this.toggleSearch()}
              advancedSearch={this.state.advancedSearch}
              filterLength={this.state.filterLength}
              addTracksToPlaylist={()=>this.addTracksToPlaylist()}
              addTracksToMultiPlaylists={()=>this.addTracksToMultiPlaylists()}
              deselectTracks={()=>this.deselectTracks()}
              editTracks={()=>this.editTracks()}
              isLibrary={isLibrary}
            />
            }
          />
        </Container>
        <Confirm />
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    tracks:store.library.tracks,
    selectedTracks: store.library.selectedTracks,
    typeahead:store.library.typeahead,
    typeaheadLoading:store.library.typeaheadLoading,
    count:store.library.count,
    loading:store.library.loading,
    addTrackToOwnedPlaylistLoading: store.playlist.addTrackToOwnedPlaylistLoading,
    playingTrack: store.player.track,
    genres:store.library.genres,
    sorter: store.table.sorter
  }
}

export default connect(mapStateToProps)(LibraryTable)
