import React, {Fragment} from 'react'

import Icon from 'ui/Icon'
import Loader from 'ui/Loader'

const OverlayPlaylistAddToBusinessTableRowActions = (props) => {
  const {
    addContactToBusiness,
    addContactToBusinessLoading,
    classname,
    contact,
    businessID
  } = props

  return (
    <div className={`${classname}`}>
      {addContactToBusinessLoading.includes(contact.id) ? (
        <Loader />
      ): (
        <Fragment>
          {contact.businesses.some(business => parseInt(business.id) === parseInt(businessID)) ?
            <div>
              <Icon
                name="checkmark-circle"
                success
              />
            </div>
            :
            <div onClick={()=>addContactToBusiness(contact)}>
              <Icon
                name="add-circle"
              />
            </div>
          }
        </Fragment>
      )}
    </div>
  )
}

export default OverlayPlaylistAddToBusinessTableRowActions
