import React, { Component } from 'react'
import { connect } from 'react-redux'

import { clearSelectedLibraryTracks } from 'store/actions/libraryAction'

import Container from 'ui/Container'

import LibraryTable from './library/LibraryTable'

const classname = 'library'

class LibraryContainer extends Component {
  // clear selected tracks when the user navigates away
  componentWillUnmount() {
    const {
      dispatch
    } = this.props

    dispatch(clearSelectedLibraryTracks())
  }

  render(){
    const {
      history,
      location
    } = this.props

    return (
      <Container classname={`${classname}-wrapper`} height="100%" column maxWidth>
          <LibraryTable
            history={history}
            location={location}
            isLibrary={true}
          />
      </Container>
    )
  }
}



export default connect()(LibraryContainer)
