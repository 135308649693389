export function secondsToTime(s){return(s-(s%=60))/60+(9<s?':':':0')+s}
export function hoursMinSecStringToSecs(hms){
  const a = hms.split(':')
  return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2])
}
export function minSecsStringToSecs(minSecs){
  const a = minSecs.split(':')
  return (+a[0]) * 60 + (+a[1])
}
export function getTimeIndex(timeArray, time) {
  return timeArray.findIndex((item) => {
    return item === `${time.split(':')[0]}:${time.split(':')[1]}`
  })
}
export function millisToMinutesAndSeconds(millis) {
  const minutes = Math.floor(millis / 60000)
  const seconds = parseInt(((millis % 60000) / 1000).toFixed(0))
  return (
    seconds === 60 ?
      `${minutes+1}:00` :
      `${minutes}:${(seconds < 10 ? '0' : '')}${seconds}`
  )
}
export function secondsToHoursMinsSecs(s) {
  const hours = Math.floor((s / 3600)).toLocaleString('en-GB', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })
  const minutes = parseInt((s / 60) % 60).toLocaleString('en-GB', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })
  const seconds = Math.ceil(s % 60).toLocaleString('en-GB', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })

  return `${hours}:${minutes}:${seconds}`
}
